import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import Loader from '@app/src/Components/Common/Loader/Loader';
import VerifyIcon from '@app/src/assets/verify.svg?react';
import getChargeableAccounts from '@app/src/utils/pricing/getChargeableAccounts';
import { setIRSAccount } from '@app/src/services/taxFlowService';
import { updateQuestionAnswer } from '@app/src/taxflow/main/services/mainService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  PATH_COMPONENT__SUBMIT_BANK_NUMBERS,
  PATH_COMPONENT__SUBMIT_DEBIT_MANUAL
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { setIRSPaymentAccount } from '@app/src/taxflow/sections/submit/actions/submitActions';
import '@app/src/Components/TaxFlow/Question/TaxFlowPaymentOptionsElement.scss';
import { irsPaymentAccountSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import { trackActivity } from '@app/src/services/analyticsService';
import { taxAmountsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

class TaxFlowPaymentOptionsElement extends React.Component {
  constructor(props) {
    super(props);

    this.messageRef = React.createRef();
  }

  componentDidMount = () => {
    const searchParams = new URLSearchParams(_.get(this.props, ['history', 'location', 'search']));
    const account_id = searchParams.get('account_id');
    const fromPlaidLink = searchParams.get('plaid');
    const account = this.props.accounts.find((account) => account.account_id === account_id);

    if (_.get(this.props, ['currentAnswer', 'value']) === null && account) {
      this.props.setCurrentAnswer({ value: 'ach' });
      this.props.setIRSPaymentAccount(account);
      this.props.setIRSAccount();
      if (fromPlaidLink) {
        trackActivity('submit-debit: plaid linked', {
          institution_name: account.institution_name,
          institution_id: account.institution_id
        });
      }
      this.props.history.replace(this.props.history.location.pathname);
    }
  };

  componentDidUpdate(prevProps) {
    const prevMessage = this.getMessage({
      answer: prevProps.currentAnswer,
      irsPaymentAccount: prevProps.irsPaymentAccount
    });

    const currentMessage = this.getMessage({
      answer: this.props.currentAnswer,
      irsPaymentAccount: this.props.irsPaymentAccount
    });

    if (currentMessage && currentMessage !== prevMessage && this.messageRef.current) {
      this.messageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onClickAccount = async (account) => {
    if (this.props.currentAnswer.value === 'ach' && account.account_id === this.props.irsPaymentAccount.account_id) {
      this.props.setCurrentAnswer({ value: null });

      return;
    }

    this.props.setCurrentAnswer({ value: 'ach' });
    this.props.setIRSPaymentAccount(account);

    await this.props.setIRSAccount();

    if (account.institution_id === 'ins_56') {
      this.props.history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_BANK_NUMBERS}`);
    }
  };

  onClickAddAccountEntry = async () => {
    if (this.props.currentAnswer.value === 'addAccount') {
      this.props.setCurrentAnswer({ value: null });

      return;
    }

    this.props.setCurrentAnswer({ value: 'addAccount' });
    await this.props.updateQuestionAnswer({});
    this.props.history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_DEBIT_MANUAL}`);
  };

  onClickPaymentPlanEntry = async () => {
    if (this.props.currentAnswer.value === 'paymentPlan') {
      this.props.setCurrentAnswer({ value: null });

      return;
    }

    this.props.setCurrentAnswer({ value: 'paymentPlan' });
  };

  getMessage = ({ answer }) => {
    const { currentQuestion } = this.props;

    const paymentPlanInfo = _.get(currentQuestion, ['question_meta', 'paymentPlanInfo']);

    if (_.get(answer, 'value') === 'paymentPlan' && paymentPlanInfo) {
      return paymentPlanInfo;
    }
  };

  nameContainsMask = (account) => {
    if (!account || !account.name || !account.mask) return false;

    return account.name.includes(account.mask);
  };

  render() {
    const { className, accounts, currentQuestion, currentAnswer, irsPaymentAccount, taxFlowLoading, taxAmounts } =
      this.props;

    const accountHeader = _.get(currentQuestion, ['question_meta', 'accountHeader']);

    const message = this.getMessage({ answer: currentAnswer, irsPaymentAccount });
    const showPayLater = taxAmounts.federal_amount > 0 || taxAmounts.state_amounts.some(({ amount }) => amount > 0);

    return (
      <>
        {taxFlowLoading && <Loader type='page' />}
        <h1 className='taxflow-payment-options-element-header'>{accountHeader}</h1>
        <div className={classNames('taxflow-payment-options-element-list', className)}>
          {accounts.map((account) => (
            <TaxFlowListItem
              key={account.account_id}
              handleChange={() => this.onClickAccount(account)}
              checked={currentAnswer.value === 'ach' && account.account_id === irsPaymentAccount.account_id}
            >
              {account.institution_name} - {account.name}
              {account.mask && !this.nameContainsMask(account) && ` (...${account.mask})`}
            </TaxFlowListItem>
          ))}
          <TaxFlowListItem handleChange={this.onClickAddAccountEntry}>Add a bank account</TaxFlowListItem>
          {showPayLater && (
            <TaxFlowListItem
              handleChange={this.onClickPaymentPlanEntry}
              checked={currentAnswer.value === 'paymentPlan'}
            >
              Pay Later
            </TaxFlowListItem>
          )}
          {message && (
            <div className='taxflow-payment-options-element-info' ref={this.messageRef}>
              <div className='steps-benefits-text-new-icon-container'>
                <VerifyIcon width={16} height={16} />
              </div>
              {message}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const accounts = getChargeableAccounts({ bankLinks: _.get(state, ['pricing2', 'bankLinks']) });

  return {
    accounts,
    currentAnswer: _.get(state, ['taxFlow', 'currentAnswer']),
    taxAmounts: taxAmountsSelector(state),
    irsPaymentAccount: irsPaymentAccountSelector(state),
    taxFlowLoading: _.get(state, ['taxFlow', 'loading'])
  };
};

const mapDispatchToProps = {
  setIRSPaymentAccount,
  setIRSAccount,
  setCurrentAnswer,
  updateQuestionAnswer
};

const ConnectedTaxFlowPaymentOptionsElement = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowPaymentOptionsElement);

export default ConnectedTaxFlowPaymentOptionsElement;
