import { addMoreItemsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import React, { useCallback } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import '@app/src/Components/TaxFlow/Question/TaxFlowAddMoreItem.scss';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { trackActivity } from '@app/src/services/analyticsService';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import history from '@app/src/keeperHistory';
import url from 'url';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

const TaxFlowAddMoreItem = ({ addMoreItems, currentQuestion }) => {
  const navigateToLinkedQuestion = useCallback(({ startSlug, collectionType, collectionId, text }) => {
    trackActivity('review: add collection item', { collectionType, collectionId, pillName: text });
    const nextPathComponent = getPathComponentBySlug(startSlug);
    if (_.isNil(nextPathComponent)) {
      defaultCaptureMessage('review: could not advance to next item', {
        coll_type: collectionType,
        coll_id: collectionId,
        slug: startSlug
      });
      return;
    }
    history.push(
      url.format({
        pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
        query: {
          collectionId: collectionId === '0' ? undefined : collectionId
        }
      })
    );
  }, []);
  return (
    <div className='taxflow-add-more-items'>
      {_.map(addMoreItems, ({ collectionType, collectionId, text, startSlug, iconUrl, learnMoreInfo }) => (
        <div key={text} className='taxflow-add-more-items-item-container'>
          <div
            className='taxflow-add-more-items-item'
            role='button'
            onClick={() => navigateToLinkedQuestion({ startSlug, collectionType, collectionId, text })}
          >
            <div className='taxflow-add-more-items-item-lhs'>
              {iconUrl && <img src={iconUrl} alt={collectionType}></img>}
              <div className='taxflow-add-more-items-item-lhs-text'>{text}</div>
            </div>
            {learnMoreInfo && (
              <div className='taxflow-add-more-items-item-info' onClick={(e) => e.stopPropagation()}>
                <TaxFlowInfoButton learnMoreInfoItem={learnMoreInfo} currentQuestion={currentQuestion} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({ addMoreItems: addMoreItemsSelector(state) });

const ConnectedTaxFlowAddMoreItem = connect(mapStateToProps)(TaxFlowAddMoreItem);

export default ConnectedTaxFlowAddMoreItem;
