import { SET_TAXFILING_HOME_OFFICE_AMOUNTS, SET_TAXFILING_HOME_OFFICE_EXPENSES } from '@app/src/actions/types';

export const setHomeOfficeAmounts = (payload) => ({
  type: SET_TAXFILING_HOME_OFFICE_AMOUNTS,
  payload
});

export const setHomeOfficeExpenses = (payload) => ({
  type: SET_TAXFILING_HOME_OFFICE_EXPENSES,
  payload
});
