import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setHolisticOnboardingEmail,
  setHolisticOnboardingFirstName,
  setHolisticOnboardingLastName
} from '@app/src/actions/holisticOnboardingActions';
import { emailSelector, firstNameSelector, lastNameSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';
import { isEmail } from '@app/src/global/Helpers';

const OnboardingSignup = ({
  firstname,
  lastname,
  email,
  setHolisticOnboardingFirstName,
  setHolisticOnboardingLastName,
  setHolisticOnboardingEmail,
  onContinue
}) => {
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const { pathname } = useLocation();
  const { push } = useHistory();

  const nameInput = useRef(null);
  const emailInput = useRef(null);

  const invalidateName = () => {
    if (firstname.length === 0 || lastname.length === 0) {
      setNameError('Full name is required');
      return false;
    }

    setNameError(false);
    return true;
  };

  const invalidateEmail = () => {
    if (email.length === 0) {
      setEmailError('Email is required');
      return false;
    } else if (!isEmail(email)) {
      setEmailError('Invalid email');
      return false;
    }

    setEmailError(false);
    return true;
  };

  const handleInvalidate = ({ key, target: { name } }) => {
    if (key !== 'Enter') {
      return;
    }

    const isValidName = invalidateName();

    if (name === 'name' && isValidName) {
      emailInput.current.focus();
    }

    const isValidEmail = invalidateEmail();

    if (firstname && lastname && email && isValidName && isValidEmail) {
      onContinue({ pathname, push });
    }
  };

  const handleInput = ({ target: { value, name } }) => {
    if (name === 'name') {
      const [firstName, ...lastName] = value.trim().split(' ');

      setHolisticOnboardingFirstName(firstName.trim());
      setHolisticOnboardingLastName(lastName.join(' ').trim());
    }

    if (name === 'email') {
      setHolisticOnboardingEmail(value.trim());
    }
  };

  return (
    <Stack spacing={1} direction='column'>
      <div className='flex column'>
        <label className='h-onboarding-content-question-label' htmlFor='name'>
          Full name
        </label>
        <TextField
          inputRef={nameInput}
          id='name'
          name='name'
          variant='outlined'
          margin='none'
          placeholder='e.g. Jordan Smith'
          onBlur={invalidateName}
          onChange={handleInput}
          onKeyDown={handleInvalidate}
          error={!!nameError}
          helperText={nameError}
        />
      </div>
      <div className='flex column'>
        <label className='h-onboarding-content-question-label' htmlFor='email'>
          Email
        </label>
        <TextField
          inputRef={emailInput}
          id='email'
          name='email'
          variant='outlined'
          margin='none'
          placeholder='e.g. jordan@gmail.com'
          onBlur={invalidateEmail}
          onChange={handleInput}
          onKeyDown={handleInvalidate}
          inputProps={{ inputMode: 'email' }}
          error={!!emailError}
          helperText={emailError}
        />
      </div>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  firstname: firstNameSelector(state),
  lastname: lastNameSelector(state),
  email: emailSelector(state)
});

const mapDispatchToProps = {
  setHolisticOnboardingFirstName,
  setHolisticOnboardingLastName,
  setHolisticOnboardingEmail,
  onContinue
};

const ConnectedOnboardingSignup = connect(mapStateToProps, mapDispatchToProps)(OnboardingSignup);

export default ConnectedOnboardingSignup;
