import React, { useCallback } from 'react';
import _ from 'lodash';
import { isPreSubmitSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { connect } from 'react-redux';
import '@app/src/Components/TaxFlow/Common/TaxFlowNavigationBar.scss';
import classNames from 'classnames';
import { trackActivity } from '@app/src/services/analyticsService';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import { useGetNavigationSectionsQuery } from '@app/src/api/taxDataApi';
import { API_POLLING_INTERVAL_MS } from '@app/src/api/constants';

const TaxFlowNavigationBar = ({ isPreSubmit }) => {
  const { data: navigationSections } = useGetNavigationSectionsQuery(undefined, {
    pollingInterval: API_POLLING_INTERVAL_MS
  });
  const isUserBeingImpersonated = REACT_APP_ENV === 'staging-prod-db';
  const isNavigationDisabled = !isPreSubmit && !isUserBeingImpersonated;
  const history = useHistory();
  const navigateToSection = useCallback(
    ({ slug, name, linkedQuestionSlug, seen }) => {
      if (!seen || isNavigationDisabled) {
        return;
      }
      trackActivity('navigation: go to navigation item', { name, slug });
      const nextPathComponent = getPathComponentBySlug(linkedQuestionSlug);
      if (_.isNil(nextPathComponent)) {
        defaultCaptureMessage('could not advance to navigation item', {
          linkedQuestionSlug
        });
        return;
      }
      history.push(`/${TAXFLOW_BASE_URL}/${nextPathComponent}`);
    },
    [history, isNavigationDisabled]
  );

  return (
    <div className='taxflow-navigation-bar'>
      {navigationSections?.map(({ slug, name, seen, linkedQuestionSlug }) => (
        <div
          key={slug}
          onClick={() => navigateToSection({ slug, name, linkedQuestionSlug, seen })}
          className={classNames([
            'taxflow-navigation-bar-section',
            {
              'taxflow-navigation-bar-section-marked': seen,
              'taxflow-navigation-bar-section-disabled': isNavigationDisabled
            }
          ])}
        >
          <div>{name}</div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPreSubmit: isPreSubmitSelector(state)
});

const mapDispatchToProps = {};

const ConnectedTaxFlowNavigationBar = connect(mapStateToProps, mapDispatchToProps)(TaxFlowNavigationBar);

export default ConnectedTaxFlowNavigationBar;
