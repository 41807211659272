import React from 'react';
import Link from '@mui/material/Link';
import HelpSupportIcon from '@app/src/assets/HelpSupportIcon';
import '@app/src/Components/TaxFlow/Common/TaxFlowLearnMoreElement.scss';
import { colorAccent } from '@app/src/theme';

/**
 * @typedef AskLinkProps
 * @property {import('react').MouseEventHandler} onClick - Callback function to be called when the link is clicked
 * @property {React.ReactNode} children - Children to be displayed in the link
 */

/**
 * @param {AskLinkProps} props
 * @returns {React.ReactElement}
 */
const AskLink = ({ onClick, children }) => {
  return (
    <Link
      className='learn-more-link'
      component='button'
      underline='none'
      variant='ask'
      onClick={onClick}
      style={{
        color: colorAccent
      }}
    >
      {children}
      <HelpSupportIcon color={colorAccent} width={24} height={24} style={{ marginLeft: '5px' }} />
    </Link>
  );
};

export default AskLink;
