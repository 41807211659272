import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import LoginOtp from '@app/src/Components/Login/LoginWithOTP/LoginOtp';
import LoginResend from '@app/src/Components/Login/LoginWithOTP/LoginResend';
import { phoneNumberFormatHelper } from '@app/src/global/Helpers';
import { typeSelector } from '@app/src/selectors/onboardingSelectors';
import { workDetailsSelector } from '@app/src/selectors/workSelectors';

import '@app/src/Components/Onboarding/Question/OnboardingVerifyTypeItem.scss';

const OnboardingVerifyTypeItem = ({ className, history, currentQuestion, workDetails, type }) => {
  const [otp, setOtp] = useState('');

  const id = type === 'phone' ? (workDetails.phone || '').slice(1) : workDetails.email;
  const displayId = type === 'phone' ? phoneNumberFormatHelper(id) : id;

  const onSuccess = () => {
    history.push('/onboarding/link');
  };

  return (
    <div className={classNames('onboarding-verify-type-item', className)}>
      <div className='steps-head'>
        <h1>{currentQuestion.title}</h1>
        <div className='step-description'>
          <div className='step-summary'>
            <p>
              It looks like you already have an account with Keeper. To log in, enter the security code sent to{' '}
              <strong className='onboarding-resend-link'>{displayId}</strong>.
            </p>
          </div>
        </div>
      </div>
      <div className='onboarding-verify-type-item-inputs'>
        <LoginOtp type={type} id={id} otp={otp} onOtpChange={setOtp} onSuccess={onSuccess} />
        <LoginResend type={type} id={id} onResendSuccess={() => setOtp('')} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  workDetails: workDetailsSelector(state),
  type: typeSelector(state),
  resError: _.get(state, ['auth', 'resError'])
});

const ConnectedOnboardingVerifyTypeItem = connect(mapStateToProps)(OnboardingVerifyTypeItem);

export default ConnectedOnboardingVerifyTypeItem;
