import React from 'react';
import { connect } from 'react-redux';
import MailIcon from '@app/src/assets/mail.svg?react';
import { extractDrakePdf } from '@app/src/services/taxFlowService';
import { TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';
import TaxFlowActionButton from '@app/src/Components/TaxFlow/Common/TaxFlowActionButton';

const TaxFlowPdfButton = ({ className, extractDrakePdf, year = TAX_FILING_YEAR }) => {
  const onClick = async () => {
    await extractDrakePdf(year);
  };

  return (
    <TaxFlowActionButton className={className} onClick={onClick}>
      <MailIcon fontSize='small' />
      Email me a copy of my return
    </TaxFlowActionButton>
  );
};

const mapDispatchToProps = {
  extractDrakePdf
};

const ConnectedTaxFlowPdfButton = connect(null, mapDispatchToProps)(TaxFlowPdfButton);

export default ConnectedTaxFlowPdfButton;
