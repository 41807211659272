import classNames from 'classnames';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { currencyWith0DecimalPlaces } from '@app/src/global/Helpers';
import { getTaxEstimate } from '@app/src/services/holisticOnboardingService';
import { loadingSelector, taxEstimateSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import '@app/src/Components/HolisticOnboarding/components/TaxSavingsEstimate/TaxSavingsEstimate.scss';

const TaxSavingsEstimate = ({ getTaxEstimate, loading, taxEstimate, w2 }) => {
  useEffect(() => {
    getTaxEstimate();
  }, [getTaxEstimate]);

  const agi = _.get(taxEstimate, 'agi', 0);
  const standardDeduction = _.get(taxEstimate, 'standardDeduction', 0);
  const taxableIncome = _.get(taxEstimate, 'taxableIncome', 0);
  const grossTaxes = _.get(taxEstimate, 'grossTaxes', 0);
  const taxesWithheld = _.get(taxEstimate, 'taxesWithheld', 0);
  const netBalance = _.get(taxEstimate, 'netBalance', 0);
  const writeOffDeductions = _.get(taxEstimate, 'writeOffDeductions', 0);
  const earnedIncomeCredit = _.get(taxEstimate, 'earnedIncomeCredit', 0);
  const childTaxCredit = _.get(taxEstimate, 'childTaxCredit', 0);
  const aotcCredit = _.get(taxEstimate, 'aotcCredit', 0);
  const qbi = _.get(taxEstimate, 'qbi', 0);

  const otherDeductions = writeOffDeductions + qbi;

  return (
    <div className='tax-savings-container'>
      <div>
        <div className='tax-savings-line'>
          <div>Adjusted gross income</div>
          <div>{loading ? <CircularProgress size={16} /> : currencyWith0DecimalPlaces(agi)}</div>
        </div>
        <div className='tax-savings-line'>
          <div>Standard deduction</div>
          <div>{loading ? <CircularProgress size={16} /> : currencyWith0DecimalPlaces(standardDeduction)}</div>
        </div>
        {!w2 && (
          <div className='tax-savings-line'>
            <div>Business deductions</div>
            {loading ? (
              <CircularProgress size={16} />
            ) : (
              <div className='tax-savings-purple-rain'>
                <div>???</div>
              </div>
            )}
          </div>
        )}
        <div className='tax-savings-line'>
          <div>Other deductions</div>
          <div>{loading ? <CircularProgress size={16} /> : currencyWith0DecimalPlaces(Math.abs(otherDeductions))}</div>
        </div>
        <Divider sx={{ margin: '8px 0' }} />
        <div className='tax-savings-line'>
          <div>Taxable income</div>
          <div>{loading ? <CircularProgress size={16} /> : currencyWith0DecimalPlaces(taxableIncome)}</div>
        </div>
        <div className='tax-savings-line'>
          <div>Credits</div>
          <div>
            {loading ? (
              <CircularProgress size={16} />
            ) : (
              currencyWith0DecimalPlaces(earnedIncomeCredit + childTaxCredit + aotcCredit)
            )}
          </div>
        </div>
        <Divider sx={{ margin: '8px 0' }} />
        <div className='tax-savings-line'>
          <div>Gross taxes</div>
          <div>{loading ? <CircularProgress size={16} /> : currencyWith0DecimalPlaces(grossTaxes)}</div>
        </div>
        <div className='tax-savings-line'>
          <div>Taxes witheld</div>
          <div>{loading ? <CircularProgress size={16} /> : currencyWith0DecimalPlaces(taxesWithheld)}</div>
        </div>
        <Divider sx={{ margin: '8px 0' }} />
        <div className='tax-savings-line'>
          <div>
            <div className='tax-savings-large-text'>Estimated tax {netBalance < 0 ? 'refund' : 'bill'}</div>
            <div>(before deductions)</div>
          </div>
          <div
            className={classNames('tax-savings-large-text', {
              'tax-savings-green': netBalance <= 0,
              'tax-savings-navy': netBalance > 0
            })}
          >
            {loading ? <CircularProgress size={16} /> : currencyWith0DecimalPlaces(Math.abs(netBalance))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  taxEstimate: taxEstimateSelector(state),
  loading: loadingSelector(state)
});

const mapDispatchToProps = {
  getTaxEstimate
};

const ConnectedTaxSavingsEstimate = connect(mapStateToProps, mapDispatchToProps)(TaxSavingsEstimate);

export default ConnectedTaxSavingsEstimate;
