import _ from 'lodash';
import { serverUrl } from '@app/src/global/Environment';
import {
  setQueryResults,
  setIsSpecialDone,
  setCogsSummary,
  setTaxDeadline,
  setDeductions,
  setQuestionsAnswered,
  setHomePriorDepreciation
} from '@app/src/taxflow/shared/actions/sharedActions';
import {
  setSelectedCollectionTypes,
  setAboutYouPredictions,
  setBusinessCode,
  setCurrentAnswer,
  setIsFilingQualified,
  setReviewPills,
  setAddMoreItems
} from '@app/src/actions/taxFlowActions';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  COLLECTION_TYPE__SPECIAL,
  ENDPOINT_ATTRIBUTE__FILING_QUALIFIED,
  ENDPOINT_ATTRIBUTE__SPECIAL_DONE,
  ENDPOINT_ATTRIBUTE__SPECIAL_START,
  SLUG__BULK_UPLOAD_QUESTIONS_SUMMARY,
  SLUG__FIND_WRITE_OFFS,
  SLUG__SPECIAL_WHO
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import axios from 'axios';
import {
  getDoneUpdates,
  getQuestionById,
  getQuestionQueries,
  getQuestionQueryResults,
  getQuestionUpdates,
  getStartedUpdates
} from '@app/src/taxflow/main/utils/mainUtils';
import {
  allDerivedQuestionsSelector,
  allQuestionsSelector,
  isPremiumSubscriberSelector,
  savedDefaultAnswerSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  allCollectionTypesSelector,
  bulkUploadItemsSelector,
  flattenedUploadAttemptsSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  currentCollectionIdSelector,
  deductionsSelector,
  queryResultsSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { requireWork } from '@app/src/taxflow/common/services/userInfo';
import { setTaxFilingError } from '@app/src/taxflow/common/services/errors';
import { getBankLinks, requireAccountDetails, requireFeatures } from '@app/src/services/pricingService';
import {
  SLUG__SUBMIT_ACCEPTED_BY_IRS,
  SLUG__SUBMIT_BLOCKED_FINAL_REVIEW,
  SLUG__SUBMIT_BYE,
  SLUG__SUBMIT_BYE_MANUAL,
  SLUG__SUBMIT_BYE_MANUAL_ESC,
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_FILED_WITH_IRS,
  SLUG__SUBMIT_FINISH,
  SLUG__SUBMIT_SENT_TO_IRS,
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_TAX_AMOUNT,
  SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { getPaid, getTaxAmounts, getSubmitTimestamp } from '@app/src/taxflow/sections/submit/services/submitService';
import {
  SLUG__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_COGS_END_INVENTORY,
  SLUG__CREDIT_COGS_NEW_INVENTORY,
  SLUG__CREDIT_COGS_START_INVENTORY,
  SLUG__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_STANDARD_EXIT_BOUNCE,
  SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS,
  SLUG__CREDIT_STANDARD_RESULT
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  CATEGORY_TYPE_ADD_MORE,
  CATEGORY_TYPE_SUMMARY,
  CATEGORY_TYPE_TAXFLOW_FORM
} from '@app/src/constants/constants';
import { getCollectionTypeItems } from '@app/src/services/taxFlowContentfulService';
import { getCategories, getExpenses } from '@app/src/actions/dashboardActions';
import { getCampaign, getWorkInfoLazy } from '@app/src/services/workService';
import {
  COLLECTION_TYPE__INCOME_FREELANCE,
  COLLECTION_TYPE__INCOME_INVEST,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE,
  SLUG__INCOME_FREELANCE_JOB,
  SLUG__INCOME_INVEST_INFO,
  SLUG__INCOME_INVEST_UNIFICATION
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  navigationDoneQueriesSelector,
  navigationQueriesSelector,
  navigationStartedQueriesSelector
} from '@app/src/selectors/navigationListSelectors';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import { deserializeQuestionAnswer } from '@app/src/taxflow/mapping/utils/mappingUtils';
import { setCurrentCollectionId, setCurrentTaxState } from '@app/src/taxflow/collection/actions/collectionActions';
import { SLUG__STATE_RETURN } from '@app/src/taxflow/sections/state/constants/stateConstants';
import { fetchQuestionnaireSummaryPills } from '@app/src/services/questionnaireService';
import { setHomeOfficeAmounts, setHomeOfficeExpenses } from '@app/src/taxflow/sections/home/actions/homeActions';
import {
  COLLECTION_TYPE__HOME,
  HOME_ENDPOINT_ATTRIBUTES,
  HOME_SLUGS
} from '@app/src/taxflow/sections/home/constants/homeConstants';
import { getNavigationSections, getSubmitIssues, getSubmitWarnings, updateTaxData } from '@app/src/api/taxDataApi';

const baseUrl = serverUrl();

// Gets a value by endpoint attribute and converts it to a number, coalescing to 0
const getNumericValue = ({ queryResults, collectionId, collectionType, slug }) =>
  Number(
    _.get(getQueryResultByEndpointAttribute({ queryResults, collectionType, collectionId, slug }), [
      'answer',
      'value'
    ]) || 0
  );

const getHomePriorDepreciation = (queryResults, currentCollectionId) => async (dispatch) => {
  const totalHomeSize = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_TOTAL
  });

  const workSpaceSize = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_WORK_SPACE
  });

  const businessPercent = (workSpaceSize / totalHomeSize) * 100;

  const homePurchasePrice = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_PURCHASE_PRICE
  });

  const homeImprovements = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_IMPROVEMENTS
  });

  const valueOfLand = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_LAND
  });

  const cost = homePurchasePrice + homeImprovements - valueOfLand;

  const yearsDepreciation = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.YEARS_DEPRECIATION
  });

  const res = await axios.post(`${baseUrl}api/taxes/home-depreciation-calc`, {
    yearsDepreciation,
    businessPercent,
    cost
  });

  const { priorDepreciation } = res.data.data;
  dispatch(setHomePriorDepreciation(priorDepreciation));
};

const getCogsSummary = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/tax-validation/cogs-qualified`);
  const summary = _.get(res, ['data', 'data']);
  dispatch(setCogsSummary(summary));
};

const getTaxDeadline = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/tax-deadline`);
  const data = _.get(res, ['data', 'data']);
  dispatch(setTaxDeadline(data));
};

export const getDeductions = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/deductions`);
  const data = _.get(res, ['data', 'data']);
  dispatch(setDeductions(data));
};

const getAboutYouPredictions = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/about-you-predictions`);
  const data = _.get(res, ['data', 'data', 'preselected']);
  dispatch(setAboutYouPredictions(data));
};

export const getNextCollectionId = async ({ collectionType }) => {
  const res = await axios.get(`${baseUrl}api/taxes/get-next-collection-id`, { params: { coll_type: collectionType } });
  const nextCollectionId = _.get(res, ['data', 'data', 'coll_id']);
  return nextCollectionId;
};

export const fetchReviewPills = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/review-pills`);
  const pills = _.get(res, ['data', 'data', 'reviewPills']);
  dispatch(setReviewPills(pills));
};

const fetchAddMoreItems =
  ({ sectionSlug }) =>
  async (dispatch) => {
    const res = await axios.get(`${baseUrl}api/taxes/add-more-items`, { params: { sectionSlug } });
    const addMoreItems = _.get(res, ['data', 'data', 'addMoreItems']);
    dispatch(setAddMoreItems(addMoreItems));
  };

const getAboutYouInfo = () => async (dispatch, getState) => {
  const res = await axios.post(`${baseUrl}api/taxes/user-tax-data/batch-get`, {
    queries: [
      {
        coll_type: COLLECTION_TYPE__SPECIAL,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPECIAL_DONE
      },
      {
        coll_type: COLLECTION_TYPE__SPECIAL,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPECIAL_START
      },
      {
        coll_type: COLLECTION_TYPE__SPECIAL,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__FILING_QUALIFIED
      }
    ]
  });

  const data = _.get(res, ['data', 'data'], []);

  const selectedAboutYouItems = _.isArray(data)
    ? data.find((item) => item.slug === ENDPOINT_ATTRIBUTE__SPECIAL_START)
    : [];
  const parsedItems = _.get(selectedAboutYouItems, ['value']) ? JSON.parse(selectedAboutYouItems.value) : [];
  dispatch(setSelectedCollectionTypes(parsedItems));

  const specialDone = data.find((item) => item.slug === ENDPOINT_ATTRIBUTE__SPECIAL_DONE);
  const isSpecialDone = _.get(specialDone, ['value']) === '1';
  dispatch(setIsSpecialDone(isSpecialDone));

  const filingQualifiedData = data.find((item) => item.slug === ENDPOINT_ATTRIBUTE__FILING_QUALIFIED);
  const isPremiumSubscriber = isPremiumSubscriberSelector(getState());
  const isFilingQualified =
    _.get(filingQualifiedData, 'value') === '0' ||
    (!_.isEmpty(_.get(filingQualifiedData, 'value')) && isPremiumSubscriber);
  dispatch(setIsFilingQualified(isFilingQualified));
};

export const updateAboutYouInfo = (selectedCollectionTypes) => async (dispatch) => {
  const selectedAboutYouItems = _.uniq(selectedCollectionTypes);
  dispatch(setSelectedCollectionTypes(selectedAboutYouItems));

  await dispatch(
    updateTaxData({
      taxData: [
        {
          coll_type: COLLECTION_TYPE__SPECIAL,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SPECIAL_START,
          value: JSON.stringify(selectedAboutYouItems)
        }
      ],
      generateSharedCollectionId: false
    })
  );
};

export const removeAboutYouItem = (collectionType) => async () => {
  await axios.post(`${baseUrl}api/taxes/remove-about-you-item`, {
    aboutYouItem: collectionType
  });
};

export const getQueryResults =
  ({ currentQuestion }) =>
  async (dispatch, getState) => {
    try {
      const allQuestions = allDerivedQuestionsSelector(getState());
      const currentCollectionId = currentCollectionIdSelector(getState());
      const allCollectionTypes = allCollectionTypesSelector(getState());

      const queries = [
        ...getQuestionQueries({
          question: currentQuestion,
          collectionId: currentQuestion.slug === SLUG__INCOME_INVEST_INFO ? null : currentCollectionId,
          allCollectionTypes
        }),
        ...navigationQueriesSelector(getState()),
        ...navigationStartedQueriesSelector(getState()),
        ...navigationDoneQueriesSelector(getState())
      ];

      if (currentQuestion.slug === SLUG__INCOME_INVEST_INFO) {
        queries.push(
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: currentQuestion.slug === SLUG__INCOME_INVEST_INFO ? currentCollectionId : null,
            slug: SLUG__INCOME_INVEST_UNIFICATION
          },
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: null,
            slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED
          }
        );
      }

      let queryResults = [];
      const res = await axios.post(`${baseUrl}api/taxes/user-tax-data/batch-get`, {
        queries
      });
      const items = _.get(res, ['data', 'data'], []);
      queryResults = getQuestionQueryResults({ allQuestions, items });
      dispatch(setQueryResults(queryResults));
    } catch (e) {
      await dispatch(setTaxFilingError(e));
    }
  };

export const getCurrentQuestionData =
  ({ currentQuestion }) =>
  async (dispatch) => {
    await Promise.all([
      dispatch(getCampaign()),
      dispatch(requireWork()),
      dispatch(requireAccountDetails()),
      dispatch(requireFeatures()),
      dispatch(getAboutYouInfo()),
      dispatch(getWorkInfoLazy()),
      dispatch(getNavigationSections()),
      ...(currentQuestion.slug === SLUG__SUBMIT_FINISH
        ? [dispatch(getSubmitWarnings()), dispatch(getSubmitIssues())]
        : []),
      ...(/submit/.test(currentQuestion.slug) ? [dispatch(fetchReviewPills())] : []),
      ...(currentQuestion.question_type === CATEGORY_TYPE_ADD_MORE
        ? [dispatch(fetchAddMoreItems({ sectionSlug: _.get(currentQuestion, ['question_meta', 'sectionSlug']) }))]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_EMAIL_INFO ||
      currentQuestion.slug === SLUG__SUBMIT_SIGNATURE ||
      currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION
        ? [dispatch(getPaid())]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_BYE ||
      currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL ||
      currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL_ESC ||
      currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
        ? [dispatch(getSubmitTimestamp())]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_TAX_AMOUNT ||
      currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION ||
      currentQuestion.slug === SLUG__SUBMIT_DEBIT ||
      currentQuestion.slug === SLUG__SUBMIT_BLOCKED_FINAL_REVIEW ||
      currentQuestion.slug === SLUG__SUBMIT_SENT_TO_IRS ||
      currentQuestion.slug === SLUG__SUBMIT_FILED_WITH_IRS ||
      currentQuestion.slug === SLUG__SUBMIT_ACCEPTED_BY_IRS
        ? [dispatch(getTaxAmounts())]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_FINISH ||
      currentQuestion.slug === SLUG__CREDIT_STANDARD_DEDUCTION ||
      currentQuestion.slug === SLUG__CREDIT_STANDARD_RESULT ||
      currentQuestion.slug === SLUG__CREDIT_HOMEOWNER_DETAIL ||
      currentQuestion.slug === SLUG__CREDIT_CHARITY_AMOUNT ||
      currentQuestion.slug === SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS
        ? [dispatch(getDeductions())]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_FINISH ||
      currentQuestion.slug === SLUG__SUBMIT_BYE ||
      currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL ||
      currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL_ESC ||
      currentQuestion.slug === SLUG__SUBMIT_SENT_TO_IRS ||
      currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
        ? [dispatch(getTaxDeadline())]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_DEBIT ? [dispatch(getBankLinks())] : []),
      ...(currentQuestion.slug === SLUG__FIND_WRITE_OFFS ? [dispatch(getExpenses()), dispatch(getCategories())] : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_FINISH ||
      currentQuestion.slug === SLUG__SUBMIT_BYE ||
      currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL ||
      currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL_ESC ||
      currentQuestion.slug === SLUG__SUBMIT_TAX_AMOUNT ||
      currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION ||
      currentQuestion.slug === SLUG__SUBMIT_ACCEPTED_BY_IRS ||
      currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
        ? [dispatch(getExpenses())]
        : []),
      ...(currentQuestion.slug === SLUG__SPECIAL_WHO
        ? [dispatch(getCollectionTypeItems()), dispatch(getAboutYouPredictions())]
        : []),
      ...(currentQuestion.question_type === CATEGORY_TYPE_SUMMARY ? [dispatch(getCollectionTypeItems())] : []),
      ...(currentQuestion.slug === SLUG__CREDIT_COGS_NEW_INVENTORY ||
      currentQuestion.slug === SLUG__CREDIT_COGS_START_INVENTORY ||
      currentQuestion.slug === SLUG__CREDIT_COGS_END_INVENTORY
        ? [dispatch(getCogsSummary())]
        : []),
      ...(currentQuestion.slug === SLUG__BULK_UPLOAD_QUESTIONS_SUMMARY
        ? [dispatch(fetchQuestionnaireSummaryPills())]
        : [])
    ]);
  };

export const getQuestionsAnswered = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/user-tax-data/questions-answered`);

  dispatch(setQuestionsAnswered(_.get(res, ['data', 'data', 'fields'], [])));
};

export const updateItemizedDeduction = () => async (dispatch, getState) => {
  const allQuestions = allQuestionsSelector(getState());

  await dispatch(getDeductions());

  const deductions = deductionsSelector(getState());

  if (!deductions.switchToItemized) {
    return;
  }

  const creditStandardDeduction = getQuestionById({
    allQuestions,
    slug: 'credit-standard-deduction',
    coll_id: DEFAULT_COLLECTION_ID
  });

  // Answer standard deduction with standard
  const exitBounce = getQuestionById({
    allQuestions,
    slug: SLUG__CREDIT_STANDARD_EXIT_BOUNCE
  });

  await dispatch(
    updateTaxData({
      taxData: [
        ...[getStartedUpdates, getQuestionUpdates, getDoneUpdates].flatMap((fn) =>
          fn({
            question: creditStandardDeduction,
            answer: { value: 'itemized' },
            collectionId: DEFAULT_COLLECTION_ID
          })
        ),
        {
          coll_type: exitBounce.collectionType,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: exitBounce.endpoint_attr,
          value: '1'
        }
      ],
      generateSharedCollectionId: false
    })
  );
};

export const dispatchHomeOfficeUpdates = async ({ dispatch, slug, queryResults, collectionId }) => {
  if (slug === HOME_SLUGS.DONE) {
    await dispatch(getHomeOfficeAmounts());
  } else if ([HOME_SLUGS.EXPENSES_RENT, HOME_SLUGS.EXPENSES_OWN].includes(slug)) {
    const duration = _.get(
      getQueryResultByEndpointAttribute({
        queryResults,
        collectionType: COLLECTION_TYPE__HOME,
        collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.DATES
      }),
      ['answer']
    );
    const months = (() => {
      if (Number(duration.value) === 1) {
        return duration.months;
      } else if (Number(duration.value) === 0) {
        // If user lived in the same place all year, send array with all 12 months
        return _.range(0, 12);
      }
      return [];
    })();
    await dispatch(getHomeOfficeExpenses(months));
  } else if (slug === HOME_SLUGS.DEPRECIATION_CONFIRMATION) {
    await dispatch(getHomePriorDepreciation(queryResults, collectionId));
  }
};

export const getQuestionnaireQuestionAsQuestion =
  ({ questionnaireQuestion }) =>
  async (dispatch, getState) => {
    const { type, coll_type, coll_id, slug: questionnaireQuestionSlug } = questionnaireQuestion;
    await dispatch(setCurrentCollectionId(coll_id || DEFAULT_COLLECTION_ID));
    const maybeMatchingUpload = _.find(flattenedUploadAttemptsSelector(getState()), {
      collectionType: coll_type,
      collectionId: _.toNumber(coll_id),
      status: 'prefilled'
    });
    const bulkUploadItems = bulkUploadItemsSelector(getState());
    // if type is form review instead of question, display form details screen
    const slug =
      type === 'form-review' && maybeMatchingUpload && coll_type
        ? _.chain(bulkUploadItems)
            .find({ collectionType: coll_type, subType: maybeMatchingUpload.subType })
            .get('formInputQuestionSlug')
            .defaultTo(questionnaireQuestionSlug)
            .value()
        : questionnaireQuestionSlug;

    const allQuestions = allDerivedQuestionsSelector(getState());
    let clarifyingQuestionContent = getQuestionById({ allQuestions, slug });
    if (type === 'form-review') {
      clarifyingQuestionContent = {
        ...clarifyingQuestionContent,
        title: '',
        summary: ''
      };
    }

    await dispatch(getQueryResults({ currentQuestion: clarifyingQuestionContent }));
    const queryResults = queryResultsSelector(getState());
    await dispatchHomeOfficeUpdates({ dispatch, slug, queryResults, collectionId: coll_id });

    if (slug === SLUG__INCOME_FREELANCE_JOB) {
      const businessCodeQuery = queryResults.find(
        (result) =>
          result.coll_type === COLLECTION_TYPE__INCOME_FREELANCE &&
          result.slug === SLUG__INCOME_FREELANCE_BUSINESS_CODE &&
          result.coll_id === coll_id
      );
      const businessCodeValue = _.get(businessCodeQuery, ['answer', 'value']);
      await dispatch(setBusinessCode(businessCodeValue));
    } else if (slug === 'bulk-upload-questions-state-info') {
      const currentState = queryResults.find((queryResult) => {
        return queryResult.slug === SLUG__STATE_RETURN && queryResult.coll_id === '1';
      });
      await dispatch(setCurrentTaxState(_.get(currentState, ['answer', 'value'])));
    } else {
      await dispatch(setBusinessCode(null));
    }

    return clarifyingQuestionContent;
  };

export const resetCurrentAnswer =
  ({ questionWithNewMeta }) =>
  async (dispatch, getState) => {
    const currentCollectionId = currentCollectionIdSelector(getState());
    const queryResults = queryResultsSelector(getState());

    if (questionWithNewMeta.slug === SLUG__INCOME_INVEST_INFO) {
      const unificationRecord = queryResults.find(
        (result) => result.slug === SLUG__INCOME_INVEST_UNIFICATION && result.coll_id === currentCollectionId
      );
      let collectionIds;
      if (unificationRecord) {
        collectionIds = _.get(unificationRecord, ['answer', 'value'], [currentCollectionId]);
      } else {
        collectionIds = [currentCollectionId];
      }

      const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
        collectionIds.forEach((collId) => {
          const queryResult = queryResults.find(
            (qr) =>
              qr.coll_type === subQuestion.collectionType &&
              collId === Number(qr.coll_id) &&
              qr.slug === subQuestion.endpoint_attr
          );

          const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null });
          result = {
            ...result,
            [collId]: {
              ..._.get(result, collId, {}),
              [subQuestion.slug]: _.get(queryResult, 'answer', defaultAnswer)
            }
          };
        });

        return result;
      }, {});
      const currentAnswer = { value: newValue };
      dispatch(setCurrentAnswer(currentAnswer));
    } else if (questionWithNewMeta.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
      const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
        const queryResult = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: subQuestion.collectionType,
          collectionId: currentCollectionId,
          slug: subQuestion.endpoint_attr
        });
        const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: subQuestion });
        const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null });
        return {
          ...result,
          [subQuestion.slug]: _.get(
            queryResult,
            ['answer'],
            !_.isNil(savedDefaultAnswer) ? savedDefaultAnswer : defaultAnswer
          )
        };
      }, {});

      const currentAnswer = { value: newValue };
      dispatch(setCurrentAnswer(currentAnswer));
    } else {
      const queryResult = queryResults.find(
        (queryResult) =>
          queryResult.slug === questionWithNewMeta.endpoint_attr && queryResult.coll_id === currentCollectionId
      );
      const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: questionWithNewMeta });
      const defaultAnswer = deserializeQuestionAnswer({
        question: questionWithNewMeta,
        value: null
      });

      const currentAnswer = _.get(
        queryResult,
        ['answer'],
        !_.isNil(savedDefaultAnswer) ? savedDefaultAnswer : defaultAnswer
      );
      dispatch(setCurrentAnswer(currentAnswer));
    }
  };

const getHomeOfficeAmounts = () => async (dispatch, getState) => {
  try {
    const collectionId = currentCollectionIdSelector(getState());
    const res = await axios.get(`${baseUrl}api/taxes/home-office-amounts`, { params: { collectionId } });
    const data = _.get(res, ['data', 'data']);
    dispatch(setHomeOfficeAmounts(data));
  } catch (e) {
    await dispatch(setTaxFilingError(e));
  }
};

const getHomeOfficeExpenses = (months) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}api/taxes/home-office-expenses`, { params: { months } });
    const data = _.get(res, ['data', 'data']);
    dispatch(setHomeOfficeExpenses(data));
  } catch (e) {
    await dispatch(setTaxFilingError(e));
  }
};
