import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { scrollToBottom, useVirtualizedItems } from '@app/src/utils/assistantUtils';
import AssistantVirtualizedMessages from '@app/src/Components/Assistant/AssistantVirtualizedMessages';

const AssistantBottomSheet = ({ open, footer, toggleOpen, setOpen, getImage, virtualizerProps }) => {
  const history = useHistory();
  const location = useLocation();

  const [items, virtualizer] = useVirtualizedItems({
    getScrollElement: () => document.querySelector('[data-rsbs-scroll="true"]'),
    open,
    estimateSize: () => 90,
    ...virtualizerProps
  });

  // Closed by default
  useEffect(() => {
    setOpen(false);
  }, [setOpen]);

  // Delete assistant query param on unmount
  useEffect(() => {
    return () => {
      const searchParams = new URLSearchParams(location.search);

      searchParams.delete('assistant');

      history.push({ search: searchParams.toString() });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Controls open state based on assistant query param
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    setOpen(searchParams.get('assistant') === '1');
  }, [location.search, setOpen]);

  const onSpringEnd = ({ type }) => {
    if (type === 'OPEN') {
      const searchParams = new URLSearchParams(location.search);

      searchParams.set('assistant', '1');

      history.push({ search: searchParams.toString() });

      if (!virtualizer.scrollElement) {
        virtualizer.measure();
      }

      scrollToBottom(virtualizer);
    }
  };

  return (
    <BottomSheet
      className='assistant assistant-bottom-sheet'
      open={open}
      onDismiss={() => {
        if (open) {
          toggleOpen('bottom sheet dismissed');
        }
      }}
      expandOnContentDrag
      onSpringEnd={onSpringEnd}
      footer={footer}
    >
      <AssistantVirtualizedMessages virtualizer={virtualizer} items={items} getImage={getImage} />
    </BottomSheet>
  );
};

export default AssistantBottomSheet;
