import {
  COLLECTION_TYPE__CAR,
  PATH_COMPONENT__CAR_OPTIONS,
  SLUG__CAR_SERVICE_DATE,
  SLUG__CAR_COST,
  SLUG__CAR_PERCENT,
  SLUG__CAR_MILEAGE_BOUNCE,
  SLUG__CAR_OPTIONS,
  SLUG__CAR_BRAND,
  PATH_COMPONENT__CAR_BRAND,
  PATH_COMPONENT__CAR_SERVICE_DATE,
  SLUG__CAR_PURCHASE_DATE,
  PATH_COMPONENT__CAR_PURCHASE_DATE,
  PATH_COMPONENT__CAR_NEW_USED,
  SLUG__CAR_NEW_USED,
  PATH_COMPONENT__CAR_COST,
  PATH_COMPONENT__CAR_LOAN,
  SLUG__CAR_LOAN,
  PATH_COMPONENT__CAR_LEASE,
  SLUG__CAR_LEASE,
  PATH_COMPONENT__CAR_WORK_MILES_TRACKED,
  SLUG__CAR_WORK_MILES_TRACKED,
  PATH_COMPONENT__CAR_WORK_MILES,
  SLUG__CAR_WORK_MILES,
  PATH_COMPONENT__CAR_TOTAL_MILES,
  SLUG__CAR_TOTAL_MILES,
  PATH_COMPONENT__CAR_PERCENT,
  PATH_COMPONENT__CAR_MILES_CONFIRMATION,
  SLUG__CAR_MILES_CONFIRMATION,
  PATH_COMPONENT__CAR_STANDARD_MILEAGE,
  SLUG__CAR_STANDARD_MILEAGE,
  PATH_COMPONENT__CAR_PAST_DEPRECIATION,
  SLUG__CAR_PAST_DEPRECIATION,
  SLUG__CAR_YEARS_DEPRECIATION,
  PATH_COMPONENT__CAR_YEARS_DEPRECIATION,
  PATH_COMPONENT__CAR_DEPRECIATION_CONFIRMATION,
  SLUG__CAR_DEPRECIATION_CONFIRMATION,
  PATH_COMPONENT__CAR_DONE,
  SLUG__CAR_DONE,
  PATH_COMPONENT__CAR_MILEAGE_BOUNCE,
  SLUG__CAR_EXIT_BOUNCE,
  PATH_COMPONENT__CAR_EXIT_BOUNCE,
  ENDPOINT_ATTRIBUTE__CAR_OPTIONS,
  ENDPOINT_ATTRIBUTE__CAR_SERVICE_DATE,
  ENDPOINT_ATTRIBUTE__CAR_WORK_MILES_TRACKED,
  ENDPOINT_ATTRIBUTE__CAR_TOTAL_MILES,
  ENDPOINT_ATTRIBUTE__CAR_PERCENT,
  ENDPOINT_ATTRIBUTE__CAR_COST,
  ENDPOINT_ATTRIBUTE__CAR_YEARS_DEPRECIATION,
  PATH_COMPONENT__CAR_OTHER_PERSONAL,
  SLUG__CAR_OTHER_PERSONAL
} from '@app/src/taxflow/sections/car/constants/carConstants';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import moment from 'moment';
import _ from 'lodash';
import { TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';

export const getNextPathComponentMap = () => {
  return {
    [SLUG__CAR_BRAND]: { nextPathComponent: PATH_COMPONENT__CAR_SERVICE_DATE, questionnaireEarlyExit: false },
    [SLUG__CAR_LEASE]: { nextPathComponent: PATH_COMPONENT__CAR_WORK_MILES_TRACKED, questionnaireEarlyExit: false },
    [SLUG__CAR_PURCHASE_DATE]: { nextPathComponent: PATH_COMPONENT__CAR_NEW_USED, questionnaireEarlyExit: false },
    [SLUG__CAR_NEW_USED]: { nextPathComponent: PATH_COMPONENT__CAR_COST, questionnaireEarlyExit: false },
    [SLUG__CAR_LOAN]: { nextPathComponent: PATH_COMPONENT__CAR_WORK_MILES_TRACKED, questionnaireEarlyExit: false },
    [SLUG__CAR_WORK_MILES]: { nextPathComponent: PATH_COMPONENT__CAR_PERCENT, questionnaireEarlyExit: false },
    [SLUG__CAR_DONE]: { nextPathComponent: PATH_COMPONENT__CAR_EXIT_BOUNCE, questionnaireEarlyExit: false },
    [SLUG__CAR_TOTAL_MILES]: { nextPathComponent: PATH_COMPONENT__CAR_PERCENT, questionnaireEarlyExit: false },
    [SLUG__CAR_YEARS_DEPRECIATION]: {
      nextPathComponent: PATH_COMPONENT__CAR_DEPRECIATION_CONFIRMATION,
      questionnaireEarlyExit: false
    },
    [SLUG__CAR_DEPRECIATION_CONFIRMATION]: {
      nextPathComponent: PATH_COMPONENT__CAR_DONE,
      questionnaireEarlyExit: false
    },
    [SLUG__CAR_SERVICE_DATE]: { nextPathComponent: PATH_COMPONENT__CAR_OTHER_PERSONAL, questionnaireEarlyExit: false }
  };
};

export const getOptionPathComponentMap = () => {
  return {
    [SLUG__CAR_OPTIONS]: {
      car_lease: { nextPathComponent: PATH_COMPONENT__CAR_BRAND, questionnaireEarlyExit: false },
      car_loan: { nextPathComponent: PATH_COMPONENT__CAR_BRAND, questionnaireEarlyExit: false },
      own_car: { nextPathComponent: PATH_COMPONENT__CAR_BRAND, questionnaireEarlyExit: false },
      no_car: { nextPathComponent: PATH_COMPONENT__CAR_EXIT_BOUNCE, questionnaireEarlyExit: false }
    },
    [SLUG__CAR_WORK_MILES_TRACKED]: {
      0: { nextPathComponent: PATH_COMPONENT__CAR_TOTAL_MILES, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__CAR_WORK_MILES, questionnaireEarlyExit: false }
    },
    [SLUG__CAR_STANDARD_MILEAGE]: {
      0: { nextPathComponent: PATH_COMPONENT__CAR_PAST_DEPRECIATION, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__CAR_DONE, questionnaireEarlyExit: false }
    },
    [SLUG__CAR_PAST_DEPRECIATION]: {
      0: { nextPathComponent: PATH_COMPONENT__CAR_DONE, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__CAR_YEARS_DEPRECIATION, questionnaireEarlyExit: false }
    }
  };
};

export const getNextPathComponent = ({ question, collectionId, queryResults }) => {
  let nextPathComponent = null;
  if (question.slug === SLUG__CAR_OTHER_PERSONAL) {
    const queryResult = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__CAR,
      collectionId,
      slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
    });
    if (_.get(queryResult, ['answer', 'value']) === 'car_lease') {
      nextPathComponent = PATH_COMPONENT__CAR_LEASE;
    } else {
      nextPathComponent = PATH_COMPONENT__CAR_PURCHASE_DATE;
    }
  } else if (question.slug === SLUG__CAR_COST) {
    const queryResult = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__CAR,
      collectionId,
      slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
    });
    if (_.get(queryResult, ['answer', 'value']) === 'car_loan') {
      nextPathComponent = PATH_COMPONENT__CAR_LOAN;
    } else {
      nextPathComponent = PATH_COMPONENT__CAR_WORK_MILES_TRACKED;
    }
  } else if (question.slug === SLUG__CAR_PERCENT) {
    const carWorkMilesTrackedQueryResult = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__CAR,
      collectionId,
      slug: ENDPOINT_ATTRIBUTE__CAR_WORK_MILES_TRACKED
    });

    if (_.get(carWorkMilesTrackedQueryResult, ['answer', 'value']) === '0') {
      nextPathComponent = PATH_COMPONENT__CAR_MILES_CONFIRMATION;
    } else {
      nextPathComponent = getMileagePathComponent({ collectionId, queryResults });
    }
  } else if (question.slug === SLUG__CAR_MILES_CONFIRMATION) {
    nextPathComponent = getMileagePathComponent({ collectionId, queryResults });
  }
  return { nextPathComponent };
};

const getMileagePathComponent = ({ collectionId, queryResults }) => {
  const optionsQueryResult = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId,
    slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
  });

  const serviceDateQueryResult = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId,
    slug: ENDPOINT_ATTRIBUTE__CAR_SERVICE_DATE
  });

  if (
    (_.get(optionsQueryResult, ['answer', 'value']) === 'car_loan' ||
      _.get(optionsQueryResult, ['answer', 'value']) === 'own_car') &&
    moment(_.get(serviceDateQueryResult, ['answer', 'value']), 'DD/MMM/YYYY').year() < TAX_FILING_YEAR
  ) {
    return PATH_COMPONENT__CAR_STANDARD_MILEAGE;
  } else {
    return PATH_COMPONENT__CAR_DONE;
  }
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__CAR_OPTIONS]: SLUG__CAR_OPTIONS,
    [PATH_COMPONENT__CAR_BRAND]: SLUG__CAR_BRAND,
    [PATH_COMPONENT__CAR_SERVICE_DATE]: SLUG__CAR_SERVICE_DATE,
    [PATH_COMPONENT__CAR_PURCHASE_DATE]: SLUG__CAR_PURCHASE_DATE,
    [PATH_COMPONENT__CAR_NEW_USED]: SLUG__CAR_NEW_USED,
    [PATH_COMPONENT__CAR_COST]: SLUG__CAR_COST,
    [PATH_COMPONENT__CAR_LOAN]: SLUG__CAR_LOAN,
    [PATH_COMPONENT__CAR_LEASE]: SLUG__CAR_LEASE,
    [PATH_COMPONENT__CAR_WORK_MILES_TRACKED]: SLUG__CAR_WORK_MILES_TRACKED,
    [PATH_COMPONENT__CAR_WORK_MILES]: SLUG__CAR_WORK_MILES,
    [PATH_COMPONENT__CAR_TOTAL_MILES]: SLUG__CAR_TOTAL_MILES,
    [PATH_COMPONENT__CAR_PERCENT]: SLUG__CAR_PERCENT,
    [PATH_COMPONENT__CAR_MILES_CONFIRMATION]: SLUG__CAR_MILES_CONFIRMATION,
    [PATH_COMPONENT__CAR_STANDARD_MILEAGE]: SLUG__CAR_STANDARD_MILEAGE,
    [PATH_COMPONENT__CAR_PAST_DEPRECIATION]: SLUG__CAR_PAST_DEPRECIATION,
    [PATH_COMPONENT__CAR_YEARS_DEPRECIATION]: SLUG__CAR_YEARS_DEPRECIATION,
    [PATH_COMPONENT__CAR_DEPRECIATION_CONFIRMATION]: SLUG__CAR_DEPRECIATION_CONFIRMATION,
    [PATH_COMPONENT__CAR_DONE]: SLUG__CAR_DONE,
    [PATH_COMPONENT__CAR_MILEAGE_BOUNCE]: SLUG__CAR_MILEAGE_BOUNCE,
    [PATH_COMPONENT__CAR_EXIT_BOUNCE]: SLUG__CAR_EXIT_BOUNCE,
    [PATH_COMPONENT__CAR_OTHER_PERSONAL]: SLUG__CAR_OTHER_PERSONAL
  };
};

export const getQuestionQueries = ({ question, collectionId }) => {
  if (question.slug === SLUG__CAR_SERVICE_DATE) {
    return [
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
      }
    ];
  } else if (question.slug === SLUG__CAR_COST) {
    return [
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
      }
    ];
  } else if (question.slug === SLUG__CAR_OTHER_PERSONAL) {
    return [
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
      }
    ];
  } else if (question.slug === SLUG__CAR_PERCENT) {
    return [
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_WORK_MILES_TRACKED
      },
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
      },
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_SERVICE_DATE
      }
    ];
  } else if (question.slug === SLUG__CAR_MILES_CONFIRMATION) {
    return [
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
      },
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_SERVICE_DATE
      },
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_TOTAL_MILES
      },
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_PERCENT
      }
    ];
  } else if (question.slug === SLUG__CAR_DEPRECIATION_CONFIRMATION) {
    return [
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_COST
      },
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_YEARS_DEPRECIATION
      },
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_PERCENT
      }
    ];
  } else if (question.slug === SLUG__CAR_MILEAGE_BOUNCE) {
    return [
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_OPTIONS
      },
      {
        coll_type: COLLECTION_TYPE__CAR,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CAR_SERVICE_DATE
      }
    ];
  } else {
    return [];
  }
};
