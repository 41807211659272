import React from 'react';
import { connect } from 'react-redux';
import Icon from '@mui/material/Icon';
import TickCircleIcon from '@app/src/assets/tick-circle.svg?react';
import { formatPhoneNumberToDisplay } from '@app/src/global/Helpers';
import { userSelector } from '@app/src/selectors/userSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitProgressElement.scss';
import {
  completedSummaryItemNamesSelector,
  instantReviewItemsSelector
} from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import {
  SLUG__SUBMIT_BYE,
  SLUG__SUBMIT_BYE_MANUAL,
  SLUG__SUBMIT_BYE_MANUAL_ESC,
  SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import TaxFlowSubmitProgressTimer from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitProgressTimer';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { colorSuccess } from '@app/src/theme';
import MailIcon from '@app/src/assets/mail.svg?react';
import PhoneIcon from '@app/src/assets/phone.svg?react';
import TaxFlowReviewPillsSection from '@app/src/Components/TaxFlow/Question/TaxFlowReviewPillsSection';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';

const TaxFlowSubmitProgressElement = ({ currentQuestion, user, reviewItems, formSlugs }) => {
  const inReview =
    currentQuestion.slug === SLUG__SUBMIT_BYE ||
    currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL ||
    currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL_ESC ||
    currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED;

  return (
    <div className='taxflow-submit-progress'>
      {inReview && (
        <div className='taxflow-submit-progress-chips'>
          <TaxFlowPill
            text={user.email}
            icon={
              <Icon className='taxflow-chip-icon'>
                <MailIcon width={16} height={16} />
              </Icon>
            }
          />
          <TaxFlowPill
            text={formatPhoneNumberToDisplay(user.phone)}
            icon={
              <Icon className='taxflow-chip-icon'>
                <PhoneIcon width={16} height={16} />
              </Icon>
            }
          />
        </div>
      )}

      <div className='taxflow-submit-progress-panel'>
        <div className='taxflow-submit-progress-panel-title'>
          {inReview ? 'Checking your tax return' : 'Passed review'}
        </div>
        {inReview && <TaxFlowSubmitProgressTimer />}

        <hr></hr>

        <div className='taxflow-submit-progress-panel-list'>
          {reviewItems.map((item, i) => {
            let itemLabel = item.label;
            let animateSlugs = false;

            if (item.label === 'Form completion') {
              if (formSlugs && item.actualDuration > 2) {
                animateSlugs = true;
                itemLabel = 'Form completion - ';
              } else if (
                currentQuestion.slug === SLUG__SUBMIT_BYE ||
                currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
              ) {
                itemLabel = 'Form completion - Done!';
              }
            }

            return (
              <div key={i} className='taxflow-submit-progress-panel-list-item'>
                <div
                  className='taxflow-submit-progress-panel-list-item-text'
                  style={{ animationDelay: item.actualDelay + 's' }}
                >
                  <div className='label'>{itemLabel}</div>
                  {animateSlugs && (
                    <div
                      className='slugs-container'
                      style={{
                        '--slugAnimationTranslate': -100 * (formSlugs.length - 1) + '%',
                        '--slugAnimationDuration': item.actualDuration + 's',
                        '--slugAnimationSteps': formSlugs.length - 1
                      }}
                    >
                      {formSlugs.map((slug) => (
                        <div className='slugs-container-item' key={slug}>
                          {slug}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div
                  className='taxflow-submit-progress-panel-list-item-checkmark'
                  style={{
                    animationDelay: animateSlugs ? item.actualDelay + item.actualDuration + 's' : item.actualDelay + 's'
                  }}
                >
                  <TickCircleIcon fill={colorSuccess} />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <TaxFlowReviewPillsSection editEnabled={false}></TaxFlowReviewPillsSection>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  history: props.history,
  currentQuestion: currentQuestionSelector(state),
  user: userSelector(state),
  reviewItems: instantReviewItemsSelector(state),
  formSlugs: completedSummaryItemNamesSelector(state)
});

const ConnectedTaxFlowSubmitProgressElement = connect(mapStateToProps)(TaxFlowSubmitProgressElement);

export default ConnectedTaxFlowSubmitProgressElement;
