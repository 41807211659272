import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import TaxFlowBillRow from '@app/src/Components/TaxFlow/Question/TaxFlowBillRow';
import TaxFlowBillContainer from '@app/src/Components/TaxFlow/Question/TaxFlowBillContainer';
import '@app/src/Components/TaxFlow/Question/TaxFlowBillElement.scss';
import classNames from 'classnames';
import { taxAmountsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

class TaxFlowBillElement extends React.Component {
  render() {
    const { className, federalAmt, states } = this.props;
    const isBill = federalAmt >= 0 || states.some(({ amount }) => amount >= 0);
    const isRefund = federalAmt < 0 || states.some(({ amount }) => amount < 0);

    const getFederalBillLabel = (billDue) => {
      if (billDue) {
        return 'Federal tax bill due';
      }

      return 'Federal refund';
    };

    const getStateBillLabel = (state) => (billDue) => {
      if (billDue) {
        return `${state} State tax bill`;
      }

      return `${state} State refund`;
    };

    return (
      <TaxFlowBillContainer
        className={classNames(className, 'taxflow-bill-element')}
        title={`Your tax ${_.compact([isBill && 'bill', isRefund && 'refund']).join(' and ')}`}
      >
        <TaxFlowBillRow label={getFederalBillLabel} amount={federalAmt} />
        {states.map((state) => (
          <TaxFlowBillRow key={state.state} label={getStateBillLabel(state.state)} amount={state.amount} />
        ))}
      </TaxFlowBillContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const taxAmounts = taxAmountsSelector(state);
  const federalAmt = taxAmounts.federal_amount;
  const states = _.get(taxAmounts, 'state_amounts', []);
  const total = federalAmt + _.sumBy(states, (stateObj) => stateObj.amount);

  return {
    federalAmt: federalAmt,
    states,
    totalDue: total >= 0
  };
};

const mapDispatchToProps = {};

const ConnectedTaxFlowBillElement = connect(mapStateToProps, mapDispatchToProps)(TaxFlowBillElement);

export default ConnectedTaxFlowBillElement;
