import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import LoginRedirect from '@app/src/Components/Login/LoginWithOTP/LoginRedirect';
import { currentQuestionSelector } from '@app/src/selectors/onboardingSelectors';

const OnboardingCard = ({ currentQuestion, isAuthenticated, location, children }) => {
  if (!isAuthenticated && !_.isEmpty(currentQuestion) && !currentQuestion.preSignup) {
    return <LoginRedirect location={location} />;
  }

  return <div className='container'>{children}</div>;
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  isAuthenticated: state.auth.isAuthenticated
});

const ConnectedOnboardingCard = connect(mapStateToProps)(OnboardingCard);

export default ConnectedOnboardingCard;
