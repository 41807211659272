import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import _ from 'lodash';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationDatePicker from '@app/src/Components/TaxValidation/TaxValidationDatePicker';

class TaxFlowDateItem extends Component {
  onChange = ({ value }) => {
    this.props.setCurrentAnswer({ value });
  };

  render() {
    const { currentQuestion, currentAnswer, resultLoading } = this.props;

    if (resultLoading) return null;

    return (
      <div className='steps-body'>
        <div className='tax-flow-input-wrap'>
          <TaxValidationDatePicker
            question={currentQuestion}
            answer={currentAnswer}
            placeholder={_.get(currentQuestion, ['question_meta', 'default'], '')}
            required={currentQuestion.required}
            onChange={this.onChange}
            value={_.get(currentAnswer, 'value')}
          />
          <TaxFlowInfoButton currentQuestion={currentQuestion} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  taxFlow: state.taxFlow,
  currentAnswer: state.taxFlow.currentAnswer,
  resultLoading: state.taxFlow.resultLoading
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowDateItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowDateItem);

export default ConnectedTaxFlowDateItem;
