import axios from 'axios';
import _ from 'lodash';
import { serverUrl } from '@app/src/global/Environment';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { setTaxFilingError } from '@app/src/taxflow/common';

const baseUrl = serverUrl();

export const getErrors = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}api/tax-validation/validate`);
    const errors = _.get(res, ['data', 'data', 'errors'], []);
    dispatch(setErrors(errors));
  } catch (e) {
    await dispatch(setTaxFilingError(e));
  }
};
