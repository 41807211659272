import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import {
  COLLECTION_TYPE__HOME,
  HOME_PATH_COMPONENTS,
  HOME_ENDPOINT_ATTRIBUTES,
  HOME_SLUGS
} from '@app/src/taxflow/sections/home/constants/homeConstants';
import {
  COLLECTION_TYPE__CREDIT_HOMEOWNER,
  ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import _ from 'lodash';
import {
  COLLECTION_TYPE__SPOUSE,
  ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';

export const getNextPathComponentMap = () => {
  return {
    [HOME_SLUGS.EXPENSES_OWN]: {
      nextPathComponent: HOME_PATH_COMPONENTS.DEPRECIATION,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.TYPE]: {
      nextPathComponent: HOME_PATH_COMPONENTS.ACQUIRED,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.EXPENSES_RENT]: {
      nextPathComponent: HOME_PATH_COMPONENTS.OFFICE_SIZE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.ACQUIRED]: {
      nextPathComponent: HOME_PATH_COMPONENTS.VALUE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.VALUE]: {
      nextPathComponent: HOME_PATH_COMPONENTS.PAST,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.DONE]: {
      nextPathComponent: HOME_PATH_COMPONENTS.EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.DEPRECIATION_TOTAL]: {
      nextPathComponent: HOME_PATH_COMPONENTS.DEPRECIATION_CONFIRMATION,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.DEPRECIATION_CONFIRMATION]: {
      nextPathComponent: HOME_PATH_COMPONENTS.OFFICE_SIZE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.OFFICE_SIZE]: {
      nextPathComponent: HOME_PATH_COMPONENTS.DONE,
      questionnaireEarlyExit: false
    },
    [HOME_SLUGS.EXIT_BOUNCE]: {
      questionnaireEarlyExit: true
    }
  };
};

export const getOptionPathComponentMap = () => {
  return {
    [HOME_SLUGS.OPTIONS]: {
      rent: {
        nextPathComponent: HOME_PATH_COMPONENTS.DURATION,
        questionnaireEarlyExit: false
      },
      own_home: {
        nextPathComponent: HOME_PATH_COMPONENTS.DURATION,
        questionnaireEarlyExit: false
      },
      free_housing: {
        nextPathComponent: HOME_PATH_COMPONENTS.EXIT_BOUNCE,
        questionnaireEarlyExit: false
      },
      no_home: {
        nextPathComponent: HOME_PATH_COMPONENTS.EXIT_BOUNCE,
        questionnaireEarlyExit: false
      }
    },
    [HOME_SLUGS.DEPRECIATION]: {
      0: {
        nextPathComponent: HOME_PATH_COMPONENTS.OFFICE_SIZE,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: HOME_PATH_COMPONENTS.TYPE,
        questionnaireEarlyExit: false
      }
    },
    [HOME_SLUGS.PAST]: {
      0: {
        nextPathComponent: HOME_PATH_COMPONENTS.OFFICE_SIZE,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: HOME_PATH_COMPONENTS.DEPRECIATION_TOTAL,
        questionnaireEarlyExit: false
      }
    }
  };
};

export const getNextPathComponent = ({ question, collectionId, queryResults }) => {
  if (question.slug === HOME_SLUGS.DURATION) {
    const queryResult = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__HOME,
      collectionId,
      slug: HOME_ENDPOINT_ATTRIBUTES.OPTIONS
    });
    if (_.get(queryResult, ['answer', 'value']) === 'rent') {
      return { nextPathComponent: HOME_PATH_COMPONENTS.EXPENSES_RENT, questionnaireEarlyExit: false };
    } else if (_.get(queryResult, ['answer', 'value']) === 'own_home') {
      return { nextPathComponent: HOME_PATH_COMPONENTS.EXPENSES_OWN, questionnaireEarlyExit: false };
    } else {
      return { nextPathComponent: HOME_PATH_COMPONENTS.EXIT_BOUNCE, questionnaireEarlyExit: false };
    }
  }
  return { nextPathComponent: null };
};

export const getSlugMap = () => {
  return {
    [HOME_PATH_COMPONENTS.OPTIONS]: HOME_SLUGS.OPTIONS,
    [HOME_PATH_COMPONENTS.DURATION]: HOME_SLUGS.DURATION,
    [HOME_PATH_COMPONENTS.OFFICE_SIZE]: HOME_SLUGS.OFFICE_SIZE,
    [HOME_PATH_COMPONENTS.DONE]: HOME_SLUGS.DONE,
    [HOME_PATH_COMPONENTS.EXPENSES_RENT]: HOME_SLUGS.EXPENSES_RENT,
    [HOME_PATH_COMPONENTS.EXPENSES_OWN]: HOME_SLUGS.EXPENSES_OWN,
    [HOME_PATH_COMPONENTS.TYPE]: HOME_SLUGS.TYPE,
    [HOME_PATH_COMPONENTS.ACQUIRED]: HOME_SLUGS.ACQUIRED,
    [HOME_PATH_COMPONENTS.VALUE]: HOME_SLUGS.VALUE,
    [HOME_PATH_COMPONENTS.PAST]: HOME_SLUGS.PAST,
    [HOME_PATH_COMPONENTS.DEPRECIATION]: HOME_SLUGS.DEPRECIATION,
    [HOME_PATH_COMPONENTS.DEPRECIATION_TOTAL]: HOME_SLUGS.DEPRECIATION_TOTAL,
    [HOME_PATH_COMPONENTS.DEPRECIATION_CONFIRMATION]: HOME_SLUGS.DEPRECIATION_CONFIRMATION,
    [HOME_PATH_COMPONENTS.EXIT_BOUNCE]: HOME_SLUGS.EXIT_BOUNCE
  };
};

export const getQuestionQueries = ({ question, collectionId }) => {
  if (question.slug === HOME_SLUGS.DURATION) {
    return [
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.OPTIONS
      }
    ];
  } else if (question.slug === HOME_SLUGS.DEPRECIATION_CONFIRMATION) {
    return [
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_TOTAL
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_WORK_SPACE
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_PURCHASE_PRICE
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_IMPROVEMENTS
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_LAND
      },
      {
        coll_type: COLLECTION_TYPE__HOME,
        coll_id: collectionId,
        slug: HOME_ENDPOINT_ATTRIBUTES.YEARS_DEPRECIATION
      }
    ];
  } else if (question.slug === HOME_SLUGS.EXPENSES_RENT) {
    return [
      { coll_type: COLLECTION_TYPE__HOME, coll_id: collectionId, slug: HOME_ENDPOINT_ATTRIBUTES.DATES },
      {
        coll_type: COLLECTION_TYPE__SPOUSE,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
      }
    ];
  } else if (question.slug === HOME_SLUGS.EXPENSES_OWN) {
    return [
      { coll_type: COLLECTION_TYPE__HOME, coll_id: collectionId, slug: HOME_ENDPOINT_ATTRIBUTES.DATES },
      {
        coll_type: COLLECTION_TYPE__SPOUSE,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_HOMEOWNER,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
      }
    ];
  } else {
    return [];
  }
};
