import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { updateCurrentAnswer } from '@app/src/services/onboardingService';
import isQuestionPresent from '@app/src/utils/onboarding/isQuestionPresent';
import OnboardingFormQuestionItem from '@app/src/Components/Onboarding/Question/OnboardingFormQuestionItem';
import { loadingSelector } from '@app/src/selectors/onboardingSelectors';
import { Url_ONBOARDING_DURATION_MONTH, Url_ONBOARDING_DURATION_YEAR } from '@app/src/constants/onboardingConstants';

class OnboardingFormItem extends Component {
  onFocus = () => {};

  onChange = (result) => {
    this.props.updateCurrentAnswer({
      slug: result.slug,
      answer: result
    });
  };

  onNext = () => {
    if (this.props.onNext) this.props.onNext();
  };

  render() {
    const { currentQuestion, loading } = this.props;

    if (loading) {
      return null;
    }

    const sub_question = _.get(currentQuestion, 'sub_question') || [];

    return (
      <div>
        <form className='steps-signup-form' onSubmit={this.onSubmit}>
          <div className='steps-body'>
            {sub_question.map((question) => {
              return <div key={question.slug}>{this.renderQuestionInputType(question)}</div>;
            })}
          </div>
        </form>
      </div>
    );
  }

  renderQuestionInputType = (question) => {
    const answer = _.get(this.props.currentAnswer, ['value', question.slug]);

    if (question.slug === Url_ONBOARDING_DURATION_YEAR || question.slug === Url_ONBOARDING_DURATION_MONTH) {
      return null;
    }

    if (!isQuestionPresent({ question, currentAnswer: this.props.currentAnswer })) {
      return null;
    }

    return (
      <OnboardingFormQuestionItem
        {...this.props}
        onChange={this.onChange}
        onFocus={this.onFocus}
        question={question}
        answer={answer}
        fullAnswer={this.props.currentAnswer}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  loading: loadingSelector(state)
});

const mapDispatchToProps = {
  updateCurrentAnswer
};

const ConnectedOnboardingFormItem = connect(mapStateToProps, mapDispatchToProps)(OnboardingFormItem);

export default ConnectedOnboardingFormItem;
