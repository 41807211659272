import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import PhoneField from '@app/src/Components/Common/PhoneField/PhoneField';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

class TaxValidationPhoneField extends React.Component {
  render() {
    const { className, hasError, mask, placeholder, onFocus } = this.props;

    return (
      <PhoneField
        mask={mask}
        placeholder={placeholder}
        className={classNames(
          'tax-validation-phone-field',
          { 'tax-validation-phone-field-error': hasError },
          'form-control',
          className
        )}
        error={hasError}
        onFocus={onFocus}
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
        {..._.omit(this.props, ['dispatch', 'className', 'hasError', 'question', 'answer'])}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxValidationPhoneField = connect(mapStateToProps)(TaxValidationPhoneField);

export default ConnectedTaxValidationPhoneField;
