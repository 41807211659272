import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { CloseCircle, SearchNormal1, TickCircle } from 'iconsax-react';
import _ from 'lodash';
import React, { useRef } from 'react';
import Chip from '@app/src/Components/Common/Chip/Chip';
import { colorFilterSecondary, colorSuccess } from '@app/src/theme';
import '@app/src/Components/Common/SearchList/SearchList.scss';

const SearchList = ({
  items,
  listHeaderText,
  query,
  onAddItem,
  onRemoveItem,
  selectedItems,
  onSearch,
  onFocus = _.identity,
  style,
  iconFilter = colorFilterSecondary,
  searchPlaceholderText = 'Search'
}) => {
  const sortedCheckedItems = _.chain(selectedItems).sortBy('pillOrder').value();
  const listRef = useRef();

  return (
    <div className='search-list' style={style}>
      <Collapse in={!_.isEmpty(sortedCheckedItems)} timeout={200}>
        <div className='search-list-bar'>
          {sortedCheckedItems.map((item) => (
            <Chip
              className='search-list-bar-chip'
              icon={item.iconUrl && <img src={item.iconUrl} alt={`${item.id}-icon`} style={{ filter: iconFilter }} />}
              item={item}
              key={item.id}
              text={item.name}
              hasCloseIcon
              onCloseClick={() => onRemoveItem(item)}
            />
          ))}
        </div>
      </Collapse>
      <div />
      <TextField
        variant='outlined'
        margin='none'
        fullWidth
        value={query}
        placeholder={searchPlaceholderText}
        onChange={(e) => onSearch(e.target.value)}
        onFocus={onFocus}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchNormal1 />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {!_.isEmpty(query) && (
                <IconButton onClick={() => onSearch('')}>
                  <CloseCircle size={24} />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        inputProps={{
          'data-testid': 'searchInput'
        }}
      />
      <List
        variant='scroll'
        className='search-list-list'
        style={{
          '--list-top': `${_.get(listRef, ['current', 'offsetTop'], 0)}px`
        }}
        ref={listRef}
      >
        <p className='search-list-list-header'>{listHeaderText}</p>
        {_.map(items, (item) => {
          const id = _.get(item, 'id', '');
          const name = _.get(item, 'name', '');
          const iconUrl = _.get(item, 'iconUrl', '');
          const checked = _.get(item, 'checked', false);
          const showInList = _.get(item, 'showInList', false);

          return (
            showInList && (
              <ListItemButton
                className='flex'
                key={id}
                selected={checked}
                divider
                onClick={() => (checked ? onRemoveItem(item) : onAddItem(item))}
              >
                {iconUrl && (
                  <ListItemIcon>
                    <img src={iconUrl} alt={`${id}-icon`} style={{ filter: iconFilter }} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={name}
                  primaryTypographyProps={{ style: { fontWeight: checked ? 500 : 'inherit' } }}
                />
                {checked && <TickCircle color={colorSuccess} />}
              </ListItemButton>
            )
          );
        })}
      </List>
    </div>
  );
};

export default SearchList;
