import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { startSubscriptionNow, startSubscriptionLater } from '@app/src/taxflow/sections/submit/services/submitService';
import { isReactNative } from '@app/src/global/Helpers';
import {
  accountDetailsSelector,
  subscriptionPromptQuestionSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import { subscriptionPromptShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/taxflow/navigation/components/TaxFlowSubscriptionPrompt.scss';

const TaxFlowSubscriptionPrompt = ({
  accountDetails,
  startSubscriptionLater,
  startSubscriptionNow,
  subscriptionPromptQuestion,
  subscriptionPromptShow
}) => {
  const onStartNow = async () => {
    await startSubscriptionNow();
  };

  const onStartLater = async () => {
    await startSubscriptionLater();
  };

  const onSpringStart = (event) => {
    if (event.type === 'OPEN') {
      trackActivity('navigation: subscription prompt');
    }
  };

  return (
    <BottomSheet
      className='taxflow-subscription-prompt'
      open={subscriptionPromptShow}
      onDismiss={onStartLater}
      onSpringStart={onSpringStart}
      expandOnContentDrag
    >
      <div className='taxflow-subscription-prompt-content'>
        <h1 className='taxflow-subscription-prompt-header'>{_.get(subscriptionPromptQuestion, 'title')}</h1>
        <p className='taxflow-subscription-prompt-body'>{_.get(subscriptionPromptQuestion, 'summary')}</p>
        <Stack spacing={1}>
          {isReactNative() && (
            <Button variant='contained' size='large' onClick={onStartNow}>
              {_.get(accountDetails, 'interval') === 'month' ? 'Switch to' : 'Start'} annual plan now
            </Button>
          )}
          <Button variant={isReactNative() ? 'outlined' : 'contained'} size='large' onClick={onStartLater}>
            Start my taxes, {_.get(accountDetails, 'interval') === 'month' ? 'switch' : 'pay'} later
          </Button>
        </Stack>
      </div>
    </BottomSheet>
  );
};

const mapStateToProps = (state) => ({
  subscriptionPromptQuestion: subscriptionPromptQuestionSelector(state),
  subscriptionPromptShow: subscriptionPromptShowSelector(state),
  accountDetails: accountDetailsSelector(state)
});

const mapDispatchToProps = {
  startSubscriptionNow,
  startSubscriptionLater
};

const ConnectedTaxFlowSubscriptionPrompt = connect(mapStateToProps, mapDispatchToProps)(TaxFlowSubscriptionPrompt);

export default ConnectedTaxFlowSubscriptionPrompt;
