import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { updateReadyToFile } from '@app/src/taxflow/sections/submit/services/submitService';
import { updateCampaign } from '@app/src/services/workService';
import { trackQuestionAnswer, trackUiStageChange } from '@app/src/taxflow/main/services/mainService';
import {
  PATH_COMPONENT__SUBMIT_BLOCKED_FINAL_REVIEW,
  PATH_COMPONENT__SUBMIT_SENT_TO_IRS,
  SLUG__SUBMIT_CONFIRMATION_MODAL
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  TAXFLOW_BASE_URL,
  UI_STAGE__BLOCKED_FINAL_REVIEW,
  UI_STAGE__FINAL_REVIEW
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { setIsConfirmationModalOpen } from '@app/src/actions/taxFlowActions';
import { irsEnabledSelector } from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import { uiStageSelector } from '@app/src/selectors/workSelectors';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

class TaxFlowSubmitConfirmationModel extends Component {
  onClose = () => {
    this.props.setIsConfirmationModalOpen(false);
  };

  onClick = async () => {
    try {
      this.props.setIsConfirmationModalOpen(false);
      this.props.trackQuestionAnswer({ modalQuestion: this.props.modalQuestion, irsEnabled: this.props.irsEnabled });

      let nextUiStage = UI_STAGE__FINAL_REVIEW;
      let nextPath = PATH_COMPONENT__SUBMIT_SENT_TO_IRS;
      if (!this.props.irsEnabled) {
        nextUiStage = UI_STAGE__BLOCKED_FINAL_REVIEW;
        nextPath = PATH_COMPONENT__SUBMIT_BLOCKED_FINAL_REVIEW;
      }

      await this.props.updateCampaign({ ui_stage: nextUiStage });
      this.props.trackUiStageChange({
        prevUiStage: this.props.uiStage,
        newUiStage: nextUiStage,
        origin: 'tax filing ui (user confirmed amounts)'
      });
      this.props.history.push(`/${TAXFLOW_BASE_URL}/${nextPath}`);
      await this.props.updateReadyToFile(this.props.irsEnabled);
    } catch (e) {
      defaultCaptureException(e);
    }
  };

  render() {
    const { modalQuestion } = this.props;

    return (
      <Dialog maxWidth='xs' open={true} onClose={this.onClose}>
        <DialogTitle className='submit-confirmation-modal-title'>{modalQuestion.title}</DialogTitle>
        <DialogContent className='submit-confirmation-modal-body'>
          <DialogContentText>{modalQuestion.summary}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant='contained' onClick={this.onClick}>
            {modalQuestion.nextButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => ({
  modalQuestion: props.currentQuestion.sub_question.find((x) => x.slug === SLUG__SUBMIT_CONFIRMATION_MODAL),
  irsEnabled: irsEnabledSelector(state),
  uiStage: uiStageSelector(state)
});

const mapDispatchToProps = {
  updateReadyToFile,
  setIsConfirmationModalOpen,
  updateCampaign,
  trackQuestionAnswer,
  trackUiStageChange
};

const ConnectedTaxFlowSubmitConfirmationModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowSubmitConfirmationModel);

export default ConnectedTaxFlowSubmitConfirmationModel;
