import React from 'react';
import { connect } from 'react-redux';
import TaxFlowQuestion from '@app/src/Components/TaxFlow/Question/TaxFlowQuestion';
import {
  getQuestionAnswer,
  updateQuestionAnswer,
  advanceToNextUrl,
  trackQuestionAnswer,
  advanceToCurrentSectionPage
} from '@app/src/taxflow/main/services/mainService';
import { getNextButtonLabel, isPrev, getSlugByLocation } from '@app/src/taxflow/main/utils/mainUtils';
import _ from 'lodash';
import qs from 'qs';
import TaxFlowCard from '@app/src/Components/TaxFlow/Common/TaxFlowCard';
import TaxFlowNavigationPanel from '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel';
import LoginRedirect from '@app/src/Components/Login/LoginWithOTP/LoginRedirect';
import { scrollToTop } from '@app/src/utils';
import { isValidInputSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getErrors } from '@app/src/services/taxValidationService';
import { formErrorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { setIsNextButtonLoading } from '@app/src/taxflow/shared/actions/sharedActions';
import { getQuestionsAnswered } from '@app/src/taxflow/main/services/taxFlowDataService';
import TaxFlowInfoModalWindow from '@app/src/Components/TaxFlow/Common/TaxFlowInfoModalWindow';
import TaxFlowPremiumUpsellModal from '@app/src/Components/TaxFlow/Question/TaxFlowPremiumUpsellModal';
import { SLUG__BULK_UPLOAD_QUESTIONS } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { connectSocket, disconnectSocket } from '@app/src/actions/socketActions';
import PaymentModal from '@app/src/Components/Subscription/PaymentModal/PaymentModal';
import { isPaymentModalOpenSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import TaxFlowDesktopSellModal from '@app/src/Components/TaxFlow/Common/TaxFlowDesktopSellModal';
import { currentQuestionnaireQuestionSelector } from '@app/src/selectors/taxFlowSelectors';
import history from '@app/src/keeperHistory';

class TaxFlowQuestionContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socketConnected: false
    };
  }

  async componentDidMount() {
    this.getQuestionConditional();
  }

  async componentDidUpdate(prevProps) {
    if (this.shouldRefreshQuestionData(prevProps)) {
      this.getQuestionConditional();
    }
  }

  componentWillUnmount() {
    this.props.disconnectSocket();
  }

  shouldRefreshQuestionData = (prevProps) => {
    // Case: user has advanced to another questionnaire question
    if (
      this.props.slug === SLUG__BULK_UPLOAD_QUESTIONS &&
      !_.isEqual(this.props.currentQuestionnaireQuestion, prevProps.currentQuestionnaireQuestion)
    ) {
      return true;
    }
    // Case: user has advanced to another question
    else if (this.props.slug !== prevProps.slug || this.props.collectionId !== prevProps.collectionId) {
      return true;
    }
  };

  getQuestionConditional = async () => {
    if (!this.props.isAuthenticated) {
      return;
    }

    if (!this.props.slug) {
      await this.props.advanceToCurrentSectionPage({ history });
      return;
    }

    if (!this.state.socketConnected) {
      this.props.connectSocket();
      this.setState({ socketConnected: true });
    }

    await this.props.getQuestionAnswer({
      slug: this.props.slug,
      location: this.props.location,
      history: this.props.history
    });

    scrollToTop();
  };

  onNext = async () => {
    try {
      await this.props.updateQuestionAnswer({
        stripe: this.props.stripe,
        elements: this.props.elements,
        handleErrors: true
      });

      await this.props.advanceToNextUrl({ history: this.props.history, location: this.props.location });
    } catch (e) {
      if (e.message === 'Validation Error') {
        // Form invalid
        const errorElement = document.querySelector(
          '.form-group:has(.Mui-error), .form-group:has(.tax-validation-warning-blue)'
        );

        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        throw e;
      }
    }
  };

  render() {
    const { slug, isPrev, nextButtonLabel, location, isAuthenticated, isPaymentModalOpen } = this.props;

    if (!isAuthenticated) {
      return <LoginRedirect location={location} />;
    }

    if (!slug) {
      return null;
    }

    return (
      <div className='taxflow-wrapper'>
        <div className='taxflow-content'>
          <TaxFlowNavigationPanel />
          <div className='taxflow-content-center'>
            <TaxFlowCard location={location} slug={slug}>
              <TaxFlowQuestion onNext={this.onNext} isPrev={isPrev} NextButtonLabel={nextButtonLabel} />
            </TaxFlowCard>
            <TaxFlowInfoModalWindow />
            <TaxFlowPremiumUpsellModal />
            {isPaymentModalOpen && <PaymentModal history={this.props.history} />}
            <TaxFlowDesktopSellModal />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentQuestion: _.get(state, ['taxFlow', 'currentQuestion']),
  isPrev: isPrev({
    slug: _.get(state, ['taxFlow', 'currentQuestion', 'slug'])
  }),
  nextButtonLabel: getNextButtonLabel({
    question: _.get(state, ['taxFlow', 'currentQuestion'])
  }),
  slug: getSlugByLocation(props.location),
  isAuthenticated: _.get(state, ['auth', 'isAuthenticated']),
  collectionId: _.get(qs.parse(props.location.search.slice(1), { ignoreQueryPrefix: true }), 'collectionId'),
  isValidInput: isValidInputSelector(state, props),
  formErrors: formErrorsSelector(state, props),
  isPaymentModalOpen: isPaymentModalOpenSelector(state),
  currentQuestionnaireQuestion: currentQuestionnaireQuestionSelector(state)
});

const mapDispatchToProps = {
  getQuestionAnswer,
  updateQuestionAnswer,
  getQuestionsAnswered,
  advanceToNextUrl,
  getErrors,
  setIsNextButtonLoading,
  trackQuestionAnswer,
  connectSocket,
  disconnectSocket,
  advanceToCurrentSectionPage
};

const ConnectedTaxFlowQuestionContent = connect(mapStateToProps, mapDispatchToProps)(TaxFlowQuestionContent);

export default ConnectedTaxFlowQuestionContent;
