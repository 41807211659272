import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import IconButton from '@mui/material/IconButton';
import ArrowDownIcon from '@app/src/assets/arrow-down-chevron.svg?react';
import ChatPanel from '@app/src/Components/Assistant/ChatPanel';
import { colorSurface } from '@app/src/theme';
import { useVirtualizedItems } from '@app/src/utils/assistantUtils';
import AssistantVirtualizedMessages from '@app/src/Components/Assistant/AssistantVirtualizedMessages';

const AssistantChatPanel = ({ open, setOpen, toggleOpen, header, footer, isLoading, virtualizerProps, getImage }) => {
  const messagesRef = useRef(null);

  const [items, virtualizer] = useVirtualizedItems({
    getScrollElement: () => messagesRef.current,
    open,
    estimateSize: () => 35,
    ...virtualizerProps
  });

  useEffect(() => {
    if (isLoading) return;

    if (_.isNil(open)) {
      setOpen(true);
    }
  }, [isLoading, open, setOpen]);

  return (
    <ChatPanel
      className='assistant'
      header={header}
      footer={footer}
      open={open}
      onHeaderClick={() => {
        toggleOpen('header clicked');
      }}
    >
      <IconButton
        sx={{
          color: colorSurface,
          position: 'absolute',
          top: 16,
          right: 16,
          ...(!open ? { transform: 'scaleY(-1)' } : {}),
          transition: 'transform 0.3s'
        }}
        onClick={() => {
          toggleOpen('button clicked');
        }}
      >
        <ArrowDownIcon fill={colorSurface} width={16} height={16} />
      </IconButton>
      <AssistantVirtualizedMessages ref={messagesRef} virtualizer={virtualizer} items={items} getImage={getImage} />
    </ChatPanel>
  );
};

export default AssistantChatPanel;
