import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FuzzySearch from 'fuzzy-search';
import { CloseCircle, SearchNormal1 } from 'iconsax-react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setHolisticOnboardingState } from '@app/src/actions/holisticOnboardingActions';
import { stateSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { STATE_NAME_MAP } from '@app/src/taxflow/shared/constants/sharedConstants';

const OnboardingState = ({ state, setHolisticOnboardingState }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const states = Object.entries(STATE_NAME_MAP).filter(([abbreviation, name]) => {
    if (!searchQuery) {
      return true;
    }

    const searcher = new FuzzySearch([{ name, abbreviation }], ['name', 'abbreviation'], {
      caseSensitive: false,
      sort: true,
      threshold: 0.3
    });

    return searcher.search(searchQuery)?.length > 0;
  });

  const handleSelect = (state) => {
    setHolisticOnboardingState(state);
  };

  return (
    <Stack spacing={1}>
      <div>
        <div className='h-onboarding-content-question-label' />
        <TextField
          fullWidth
          variant='outlined'
          margin='none'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchNormal1 size={20} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                {searchQuery && (
                  <IconButton onClick={() => setSearchQuery('')}>
                    <CloseCircle size={24} />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
        />
      </div>
      <List variant='scroll'>
        {states.map(([abbreviation, name], index) => (
          <ListItemButton
            key={index}
            divider
            selected={state === abbreviation}
            onClick={() => handleSelect(abbreviation)}
          >
            {name}
          </ListItemButton>
        ))}
      </List>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  state: stateSelector(state)
});

const mapDispatchToProps = {
  setHolisticOnboardingState
};

const ConnectedOnboardingState = connect(mapStateToProps, mapDispatchToProps)(OnboardingState);

export default ConnectedOnboardingState;
