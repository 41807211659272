import Snackbar from '@mui/material/Snackbar';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom';

import { setSettingsError } from '@app/src/actions/settingsActions';
import SettingsModalContainer from '@app/src/Components/Settings/Modals/SettingsModalContainer';
import SettingsFooter from '@app/src/Components/Settings/SettingsFooter';
import SettingsLinkedAccounts from '@app/src/Components/Settings/SettingsLinkedAccounts';
import SettingsLoginCredentials from '@app/src/Components/Settings/SettingsLoginCredentials';
import SettingsNotifications from '@app/src/Components/Settings/SettingsNotifications';
import SettingsSubscription from '@app/src/Components/Settings/SettingsSubscription';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import { settingsErrorSelector } from '@app/src/selectors/settingsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getSettings } from '@app/src/services/authService';
import { getChargeableAccount } from '@app/src/services/bankService';
import { getAccountDetails } from '@app/src/services/pricingService';
import TaxFlowNavigationPanel from '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel';

import '@app/src/Components/Settings/SettingsContainer.scss';

const SettingsContainer = ({
  getSettings,
  getAccountDetails,
  getChargeableAccount,
  settingsError,
  setSettingsError
}) => {
  useEffect(() => {
    trackActivity('navigation: settings');

    const loadSettings = async () => {
      await getSettings();
      await getAccountDetails();
      await getChargeableAccount();
    };

    loadSettings();
  }, [getSettings, getAccountDetails, getChargeableAccount]);

  const clearError = () => {
    setSettingsError('');
  };

  return (
    <StripeProvider>
      <TaxFlowNavigationPanel />
      <div className='settings settings-container'>
        <div className='left-spacer' />
        <div className='settings-content'>
          <Switch>
            <Route path='/settings' component={SettingsLinkedAccounts} exact />
            <Route path='/settings/linked-accounts' component={SettingsLinkedAccounts} />
            <Route path='/settings/notifications' component={SettingsNotifications} />
            <Route path='/settings/login-credentials' component={SettingsLoginCredentials} />
            <Route path='/settings/subscription' component={SettingsSubscription} />
          </Switch>
          <SettingsFooter />
        </div>
        <SettingsModalContainer />
        <Snackbar open={settingsError} autoHideDuration={5000} onClose={clearError} message={settingsError} />
      </div>
    </StripeProvider>
  );
};

const mapStateToProps = (state) => ({
  settingsError: settingsErrorSelector(state)
});

const mapDispatchToProps = {
  getSettings,
  getAccountDetails,
  getChargeableAccount,
  setSettingsError
};

const ConnectedSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);

export default ConnectedSettingsContainer;
