import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { SLUG__BULK_UPLOAD_QUESTIONS_INTRO } from '@app/src/taxflow/sections/special/constants/specialConstants';
import ReturnInReviewIcon from '@app/src/assets/return-in-review.svg?react';
import { SLUG__SUBMIT_UNABLE_TO_FILE } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import TaxFlowSubmitWarnings from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitWarnings';

const TaxFlowInfoItem = ({ currentQuestion }) => {
  if (currentQuestion.slug === SLUG__BULK_UPLOAD_QUESTIONS_INTRO) {
    return <ReturnInReviewIcon style={{ width: '100%' }} />;
  }

  if (currentQuestion.slug === SLUG__SUBMIT_UNABLE_TO_FILE) {
    return <TaxFlowSubmitWarnings hideButtons={true}></TaxFlowSubmitWarnings>;
  }

  const link = _.get(currentQuestion, ['question_meta', 'link']);
  if (!link) {
    return null;
  }

  const path = _.get(link, 'path', '');
  if (!path) {
    throw new Error('Could not resolve path');
  }

  return (
    <Link
      to={path}
      component={({ navigate }) => {
        return (
          <div className='steps-body'>
            <Button fullWidth variant='contained' color='secondary' onClick={navigate}>
              {link.label}
            </Button>
          </div>
        );
      }}
    />
  );
};

export default TaxFlowInfoItem;
