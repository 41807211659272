import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { setAuthResponseError } from '@app/src/actions/authActions';
import { setTaxFlowError } from '@app/src/actions/taxFlowActions';
import LoginRedirect from '@app/src/Components/Login/LoginWithOTP/LoginRedirect';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

const TaxFlowCard = ({ currentQuestion, isAuthenticated, location, children }) => {
  if (!isAuthenticated && !_.isEmpty(currentQuestion) && !currentQuestion.preSignup) {
    return <LoginRedirect location={location} />;
  }

  return <div className='container'>{children}</div>;
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
  setTaxFlowError,
  setAuthResponseError
};

const ConnectedTaxFlowCard = connect(mapStateToProps, mapDispatchToProps)(TaxFlowCard);

export default ConnectedTaxFlowCard;
