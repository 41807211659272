import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  formatAutoFillAddress,
  getMappedAddressSlugs,
  getQuestionOcrAddressErrors
} from '@app/src/taxflow/shared/utils/sharedUtils';
import TaxValidationInput from '@app/src/Components/TaxValidation/TaxValidationInput';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationWarning from '@app/src/Components/TaxValidation/TaxValidationWarning';
import { updateCurrentAnswerBulk } from '@app/src/actions/taxFlowActions';
import '@app/src/Components/TaxFlow/Form/TaxFlowFormAddressItem.scss';
import { errorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { currentCollectionIdSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { dismissOcrError } from '@app/src/services/taxFlowService';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { ADDRESS_SLUG_SUFFIXES } from '@app/src/taxflow/sections/income/constants/incomeConstants';

const TaxFlowFormAddressItem = ({
  question,
  answer,
  onChange,
  onFocus,
  replaceStrings,
  updateCurrentAnswerBulk,
  errors,
  collectionId,
  dismissOcrError,
  setErrors
}) => {
  const addressValue = answer && !_.isNil(answer.value) ? answer.value : '';
  const searchOptions = {
    types: ['address'],
    componentRestrictions: { country: 'us' }
  };

  const onInputChange = (value) => {
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus({ endpoint_attr: question.endpoint_attr });
    }
  };

  const slugs = getMappedAddressSlugs({
    slug: question.slug
  });

  const handleSelect = async (address) => {
    const formattedAddress = await formatAutoFillAddress(address);

    updateCurrentAnswerBulk(
      Object.keys(slugs).reduce((acc, fieldType) => {
        const slug = slugs[fieldType];
        if (slug) {
          acc[slug] = {
            slug,
            value: _.get(formattedAddress, fieldType)
          };
        }
        return acc;
      }, {})
    );

    const ocrErrors = getQuestionOcrAddressErrors({
      errors,
      question,
      collectionId
    });
    Promise.all(ocrErrors.map((error) => dismissOcrError({ error })));

    setErrors(
      errors.filter(
        (error) =>
          !(
            error.coll_type === question.collectionType &&
            error.coll_id === collectionId &&
            ADDRESS_SLUG_SUFFIXES.some((suffix) => error.slug.endsWith(suffix))
          )
      )
    );
  };

  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  return (
    <div className='form-group'>
      <TaxValidationLabel
        question={question}
        answer={answer}
        title={replaceStrings(question.title)}
        htmlFor={question.slug}
      />
      <PlacesAutocomplete
        value={addressValue}
        onChange={onInputChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        onBlur={(_, clearSuggestions) => clearSuggestions()}
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className='tax-flow-input-wrap'>
            <TaxValidationInput
              question={question}
              answer={answer}
              type={question.question_meta.type}
              name={question.slug}
              id={question.slug}
              placeholder={question.question_meta.default}
              required={question.required}
              onChange={(e) => onInputChange(e.target.value)}
              onFocus={handleFocus}
              value={addressValue}
              {...getInputProps({
                placeholder: question.question_meta.default
              })}
            />
            {suggestions && suggestions.length ? (
              <div className='taxflow-form-address-dropdown-container'>
                <div className='taxflow-form-address-suggestion-title'>Suggestions</div>
                {suggestions.map((suggestion) => {
                  return (
                    <div
                      className={classNames('taxflow-form-address-suggestion-item', {
                        'taxflow-form-address-suggestion-item-active': suggestion.active
                      })}
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.placeId}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
      </PlacesAutocomplete>
      <TaxValidationWarning question={question} answer={answer} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: errorsSelector(state),
  collectionId: currentCollectionIdSelector(state)
});

const mapDispatchToProps = {
  updateCurrentAnswerBulk,
  dismissOcrError,
  setErrors
};

const ConnectedTaxFlowFormAddressItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormAddressItem);

export default ConnectedTaxFlowFormAddressItem;
