import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { SLUG__INCOME_FREELANCE_JOB_NAME } from '@app/src/taxflow/sections/income/constants/incomeConstants';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import { businessCodeSelector } from '@app/src/selectors/taxFlowSelectors';
import { isOnboardingQuestion } from '@app/src/services/taxFlow/isOnboardingQuestion';
import { setContextLoading, setBusinessCode } from '@app/src/actions/taxFlowActions';
import { getCurrentYear, getPreviousYear, replaceString } from '@app/src/global/Helpers';
import { getDerivedQuestion } from '@app/src/taxflow/main/utils/mainUtils';
import { initPlaid } from '@app/src/services/bankLinkService';
import BankLinkPlaid from '@app/src/Components/BankLinkPlaid/BankLinkPlaid';
import { trackActivity } from '@app/src/services/analyticsService';
import TaxFlowFormCustomJobItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormCustomJobItem';
import { jobOptionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import BusinessCenterIcon from '@app/src/assets/briefcase.svg?react';

const TaxFlowOptionItem = ({
  currentQuestion: originalCurrentQuestion,
  currentAnswer,
  jobOptions,
  initPlaid,
  isChildQuestion,
  onChange,
  onFocus,
  setCurrentAnswer,
  setBusinessCode,
  taxFlow,
  businessCode,
  history,
  resultLoading,
  jobCategories,
  replaceStrings
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(originalCurrentQuestion);
  const [filteredList, setFilteredList] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [items, setItems] = useState([]);
  const [isCustomJobSelected, setIsCustomJobSelected] = useState(false);
  const [timeoutId, setTimeoutId] = useState(undefined);
  const [plaidInitiated, setPlaidInitiated] = useState(false);

  /**
   * @desc filter options and set in filteredList to show only filterjob category
   */
  const filterOptions = useCallback(
    (filterString) => {
      if (
        !currentQuestion.question_meta ||
        (currentQuestion.question_meta && currentQuestion.question_meta.length === 0)
      )
        return;

      const cleanedFilterString = filterString.trim().toLowerCase();

      const newList =
        !cleanedFilterString || cleanedFilterString === ''
          ? currentQuestion.question_meta
          : currentQuestion.question_meta.filter(
              (x) => x.text && x.text.trim().toLowerCase().indexOf(cleanedFilterString) !== -1
            );
      setFilteredList(newList);
    },
    [currentQuestion]
  );

  // Add any defined custom jobs to list if freelance income "job name" question
  useEffect(() => {
    setCurrentQuestion(
      _.get(originalCurrentQuestion, 'slug') === SLUG__INCOME_FREELANCE_JOB_NAME
        ? getDerivedQuestion({ question: originalCurrentQuestion, jobOptions })
        : originalCurrentQuestion
    );
  }, [originalCurrentQuestion, jobOptions]);

  useEffect(() => {
    if (!plaidInitiated && _.some(items, { openPlaid: true })) {
      initPlaid({ type: 'payment' });
      setPlaidInitiated(true);
    }
  }, [initPlaid, items, plaidInitiated]);

  const filteredListNil = _.isNil(filteredList);
  useEffect(() => {
    if (filteredListNil && currentQuestion) {
      filterOptions('');
    }
  }, [currentQuestion, filterOptions, filteredListNil]);

  useEffect(() => {
    setItems(
      _.get(currentQuestion, 'question_meta', []).map((item) => ({
        ...item,
        value: replaceString(_.get(item, 'value', ''), {
          currentYear: getCurrentYear(),
          previousYear: getPreviousYear()
        })
      }))
    );
  }, [currentQuestion]);

  const onClick = async (item) => {
    const result = `${item.value}`;

    setIsCustomJobSelected(false);

    if (item.openPlaid) {
      setCurrentAnswer({ value: null });
    } else if (isChildQuestion) {
      // Component used as child question
      if (onChange) {
        onChange(result);
      }

      if (currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME && item.businessCode) {
        setBusinessCode(item.businessCode);
      }

      if (onFocus) {
        onFocus();
      }
    } else {
      if (currentQuestion) {
        // for single option items, don't toggle on click - clicking on the item again should just move us to the next page.
        const newAnswer = {
          value: result,
          response_text: item.response_text
        };

        setCurrentAnswer(newAnswer);
      }
    }
  };

  /**
   * @desc search options
   */
  const Search = (filterString) => {
    setSearchText(filterString);
    if (filterString) filterString = filterString.trim();
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        filterOptions(filterString);
      }, 50)
    );
  };

  const renderJobIcon = ({ iconUrl, jobSlug, className }) => {
    if (iconUrl) {
      return <img className={className} src={iconUrl} alt={`${jobSlug}-icon`} />;
    }

    return <BusinessCenterIcon className={className} alt={`${jobSlug} icon`} />;
  };

  const renderItem = (item) => {
    const info = _.get(taxFlow, ['normalizedInfoItems', 'byId', item.info]);

    const itemProps = {
      item,
      handleChange: onClick,
      checked:
        currentAnswer &&
        !_.isNil(currentAnswer.value) &&
        `${item.value}` === `${currentAnswer.value}` &&
        (currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME && item.businessCode
          ? businessCode === item.businessCode
          : true) &&
        !isCustomJobSelected,
      className: classNames({ onboarding: isOnboardingQuestion(currentQuestion) }),
      showRightIcon: !!info,
      rightIcon: <TaxFlowInfoButton currentQuestion={currentQuestion} item={item} />
    };

    if (item.openPlaid) {
      return (
        <BankLinkPlaid
          type='payment'
          key={item.value}
          preSuccess={() => {
            setContextLoading(true);
          }}
          postSuccess={({ metadata }) => {
            const accountId = _.get(metadata, ['accounts', 0, 'id']);

            if (accountId && item.plaidSuccessRedirect) {
              history.push(
                `${item.plaidSuccessRedirect}?${new URLSearchParams({ account_id: accountId, plaid: true })}`
              );
            } else {
              history.push(item.plaidSuccessRedirect);
            }
          }}
          renderButton={({ open, ready }) => (
            <TaxFlowListItem
              ready={ready}
              {...itemProps}
              handleChange={async (...props) => {
                await onClick(...props);
                trackActivity('question: plaid option selected', {
                  question: currentQuestion.slug
                });
                open();
              }}
            >
              <span>{replaceStrings(item.text)}</span>
            </TaxFlowListItem>
          )}
        />
      );
    }

    return (
      <TaxFlowListItem key={_.compact([item.value, item.businessCode]).join(' - ')} {...itemProps}>
        {currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME &&
          renderJobIcon({ iconUrl: item.iconUrl, jobSlug: item.value, className: 'tax-flow-list-item-job-icon' })}
        <span>{replaceStrings(item.text)}</span>
      </TaxFlowListItem>
    );
  };

  if (resultLoading) return null;

  const selectedAnswer =
    _.size(items) > 10 && currentAnswer ? items.find((m) => `${m.value}` === `${currentAnswer.value}`) : undefined;

  return (
    <div className={classNames({ 'steps-body': !isChildQuestion })}>
      <div className='checkbox-list'>
        {_.size(items) <= 10 || currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME ? (
          items.map((item) => renderItem(item))
        ) : (
          // big list
          <>
            {selectedAnswer && renderItem(selectedAnswer)}
            <div className='searchbox'>
              <TextField
                className='form-control'
                type='text'
                name='search'
                autoComplete='off'
                value={searchText}
                onChange={(e) => {
                  Search(e.target.value);
                }}
                onInput={(e) => {
                  Search(e.target.value);
                }}
                placeholder='Search...'
              />
            </div>

            {currentQuestion.question_meta &&
              filteredList &&
              currentQuestion.question_meta.length > 0 &&
              filteredList
                .filter((item) => !(selectedAnswer && `${selectedAnswer.value}` === `${item.value}`))
                .map((item) => renderItem(item))}
          </>
        )}
      </div>
      {currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB_NAME && (
        <TaxFlowFormCustomJobItem
          question={currentQuestion}
          answer={currentAnswer}
          setCurrentAnswer={setCurrentAnswer}
          onChange={onChange}
          onFocus={onFocus}
          jobCategories={jobCategories}
          jobOptions={jobOptions}
          isCustomJobSelected={isCustomJobSelected}
          setIsCustomJobSelected={(value) => {
            setIsCustomJobSelected(value);
          }}
          setBusinessCode={setBusinessCode}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    taxFlow: state.taxFlow,
    resultLoading: !_.isNil(props.resultLoading) ? props.resultLoading : state.taxFlow.resultLoading,
    jobOptions: jobOptionsSelector(state),
    businessCode: businessCodeSelector(state)
  };
};

const mapDispatchToProps = {
  initPlaid,
  setContextLoading,
  setBusinessCode
};

const ConnectedTaxFlowOptionItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowOptionItem);

export default ConnectedTaxFlowOptionItem;
