// path components
export const PATH_COMPONENT__SPECIAL_EARLY_EXIT = 'special-early-exit';
export const PATH_COMPONENT__SPECIAL_EXIT_BOUNCE = 'special-exit-bounce';
export const PATH_COMPONENT__SPECIAL_WHO = 'special-who';
export const PATH_COMPONENT__FIND_WRITE_OFFS = 'find-write-offs';
export const PATH_COMPONENT__FILING_QUALIFIED = 'filing-qualified';
export const PATH_COMPONENT__PREMIUM_START = 'premium-start';
export const PATH_COMPONENT__MATCHING = 'matching';
export const PATH_COMPONENT__MATCHING_COMPLETE = 'matching-complete';

export const PATH_COMPONENT__BULK_UPLOAD = 'bulk-upload';
export const PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_INTRO = 'bulk-upload-questions-intro';
export const PATH_COMPONENT__BULK_UPLOAD_QUESTIONS = 'bulk-upload-questions';
export const PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_ADD_FORMS = 'bulk-upload-questions-add-forms';
export const PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_SUMMARY = 'bulk-upload-questions-summary';
export const PATH_COMPONENT__BULK_UPLOAD_MULTI_IMAGE = 'bulk-upload-multi-image';
export const PATH_COMPONENT__BULK_UPLOAD_PHOTO_CAPTURE = 'bulk-upload-photo-capture';
export const PATH_COMPONENT__BULK_UPLOAD_MANUAL_ENTRY = 'bulk-upload-manual-entry';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_1099 = 'bulk-upload-has-1099';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_W2 = 'bulk-upload-has-w2';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_1099G = 'bulk-upload-has-1099g';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_INT = 'bulk-upload-has-int';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_DIV = 'bulk-upload-has-div';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_INVEST = 'bulk-upload-has-invest';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_RETIREMENT = 'bulk-upload-has-retirement';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_W2G = 'bulk-upload-has-w2g';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_HSA = 'bulk-upload-has-hsa';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_IRA = 'bulk-upload-has-ira';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_STUDENT_LOANS = 'bulk-upload-has-student-loans';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_MORTGAGE = 'bulk-upload-has-mortgage';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_ENERGY = 'bulk-upload-has-energy';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_TEACHING = 'bulk-upload-has-teaching';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_TUITION = 'bulk-upload-has-tuition';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_CHARITY = 'bulk-upload-has-charity';
export const PATH_COMPONENT__BULK_UPLOAD_HAS_CHILDCARE = 'bulk-upload-has-childcare';
export const PATH_COMPONENT__INCOME_ADD_MORE = 'income-add-more';
export const PATH_COMPONENT__CREDIT_ADD_MORE = 'credit-add-more';

// slugs
export const SLUG__SPECIAL_START = 'special-start';
export const SLUG__SPECIAL_EARLY_EXIT = 'special-early-exit';
export const SLUG__SPECIAL_EXIT_BOUNCE = 'special-exit-bounce';
export const SLUG__SPECIAL_WHO = 'special-who';
export const SLUG__FIND_WRITE_OFFS = 'find-write-offs';
export const SLUG__FILING_QUALIFIED = 'filing-qualified';
export const SLUG__PREMIUM_START = 'premium-start';
export const SLUG__MATCHING = 'matching';
export const SLUG__MATCHING_COMPLETE = 'matching-complete';

export const SLUG__BULK_UPLOAD = 'bulk-upload';
export const SLUG__BULK_UPLOAD_QUESTIONS_INTRO = 'bulk-upload-questions-intro';
export const SLUG__BULK_UPLOAD_QUESTIONS_ALL_FORMS_UPLOADED = 'bulk-upload-questions-all-forms-uploaded';
export const SLUG__BULK_UPLOAD_QUESTIONS_ADD_FORMS = 'bulk-upload-questions-add-forms';
export const SLUG__BULK_UPLOAD_QUESTIONS = 'bulk-upload-questions';
export const SLUG__BULK_UPLOAD_MULTI_IMAGE = 'bulk-upload-multi-image';
export const SLUG__BULK_UPLOAD_PHOTO_CAPTURE = 'bulk-upload-photo-capture';
export const SLUG__BULK_UPLOAD_MANUAL_ENTRY = 'bulk-upload-manual-entry';
export const SLUG__BULK_UPLOAD_QUESTIONS_SUMMARY = 'bulk-upload-questions-summary';
export const SLUG__BULK_UPLOAD_HAS_1099 = 'bulk-upload-has-1099';
export const SLUG__BULK_UPLOAD_HAS_W2 = 'bulk-upload-has-w2';
export const SLUG__BULK_UPLOAD_HAS_1099G = 'bulk-upload-has-1099g';
export const SLUG__BULK_UPLOAD_HAS_INT = 'bulk-upload-has-int';
export const SLUG__BULK_UPLOAD_HAS_DIV = 'bulk-upload-has-div';
export const SLUG__BULK_UPLOAD_HAS_INVEST = 'bulk-upload-has-invest';
export const SLUG__BULK_UPLOAD_HAS_RETIREMENT = 'bulk-upload-has-retirement';
export const SLUG__BULK_UPLOAD_HAS_W2G = 'bulk-upload-has-w2g';
export const SLUG__BULK_UPLOAD_HAS_HSA = 'bulk-upload-has-hsa';
export const SLUG__BULK_UPLOAD_HAS_IRA = 'bulk-upload-has-ira';
export const SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS = 'bulk-upload-has-student-loans';
export const SLUG__BULK_UPLOAD_HAS_MORTGAGE = 'bulk-upload-has-mortgage';
export const SLUG__BULK_UPLOAD_HAS_ENERGY = 'bulk-upload-has-energy';
export const SLUG__BULK_UPLOAD_HAS_TEACHING = 'bulk-upload-has-teaching';
export const SLUG__BULK_UPLOAD_HAS_TUITION = 'bulk-upload-has-tuition';
export const SLUG__BULK_UPLOAD_HAS_CHARITY = 'bulk-upload-has-charity';
export const SLUG__BULK_UPLOAD_HAS_CHILDCARE = 'bulk-upload-has-childcare';
export const SLUG__BULK_UPLOAD_CHARITY_OPTION = 'bulk-upload-charity-option';
export const SLUG__INCOME_ADD_MORE = 'income-add-more';
export const SLUG__CREDIT_ADD_MORE = 'credit-add-more';

// endpoint attributes
export const ENDPOINT_ATTRIBUTE__FILING_QUALIFIED = 'filing-qualified';
export const ENDPOINT_ATTRIBUTE__SPECIAL_START = 'special-start';
export const ENDPOINT_ATTRIBUTE__SPECIAL_DONE = 'special-done';
export const ENDPOINT_ATTRIBUTE__SPECIAL_WHO = 'special-who';

// collection types
export const COLLECTION_TYPE__SPECIAL = 'special';

export const BULK_UPLOAD_FORM_PREDICT_QUESTIONS = [
  'bulk-upload-has-1099',
  'bulk-upload-has-w2',
  'bulk-upload-has-1099g',
  'bulk-upload-has-int',
  'bulk-upload-has-div',
  'bulk-upload-has-invest',
  'bulk-upload-has-retirement',
  'bulk-upload-has-w2g',
  'bulk-upload-has-hsa',
  'bulk-upload-has-ira',
  'bulk-upload-has-student-loans',
  'bulk-upload-has-charity',
  'bulk-upload-has-mortgage',
  'bulk-upload-has-energy',
  'bulk-upload-has-teaching',
  'bulk-upload-has-tuition',
  'bulk-upload-has-childcare'
];
