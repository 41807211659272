import React from 'react';
import { connect } from 'react-redux';
import Icon from '@mui/material/Icon';
import _ from 'lodash';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionListItem.scss';
import { selectedCollectionTypesSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { requestInfoResponseMessage } from '@app/src/services/assistantService';
import { trackActivity } from '@app/src/services/analyticsService';

const TaxFlowMultiOptionListItem = ({
  currentQuestion,
  currentAnswer,
  collectionTypeItem,
  item,
  setCurrentAnswer,
  requestInfoResponseMessage,
  ...props
}) => {
  const onChange = async (item) => {
    if (currentQuestion) {
      if (props.onFocus) {
        props.onFocus();
      }
      const existingValue = currentAnswer && currentAnswer.value ? currentAnswer.value : [];
      let newValue = [];
      if (isChecked(item.value)) {
        newValue = existingValue.filter((x) => `${x}` !== `${item.value}`);
      } else {
        newValue = [...existingValue, item.value];
      }
      newValue = _.sortBy(_.uniq(newValue));
      const newAnswer = { value: newValue };

      if (props.isChildQuestion) {
        props.onChange(newValue);
      } else {
        setCurrentAnswer(newAnswer);
      }
    }
  };

  const isChecked = (value) => {
    return currentAnswer && _.isArray(currentAnswer.value) ? currentAnswer.value.indexOf(value) !== -1 : false;
  };

  const onRightIconPress = () => {
    const { message, response } = (() => {
      if (item.aboutYou) {
        return {
          message: _.get(collectionTypeItem, ['fields', 'modalTitle'], ''),
          response: _.get(collectionTypeItem, ['fields', 'modalBody'], '')
        };
      }

      if (item.info) {
        return {
          message: _.get(item, 'info.title', ''),
          response: _.get(item, 'info.description', '')
        };
      }

      return {};
    })();

    if (message && response) {
      trackActivity('tax filing multi option list item info clicked', {
        message: message,
        slug: currentQuestion.slug
      });

      requestInfoResponseMessage({
        message,
        response,
        slug: currentQuestion.slug,
        origin: 'tax filing multi option list item info clicked'
      });
    }
  };

  return (
    <TaxFlowListItem
      handleChange={() => onChange(item)}
      checked={isChecked(item.value)}
      showRightIcon={(item.aboutYou && !!_.get(collectionTypeItem, ['fields', 'modalTitle'])) || _.get(item, 'info')}
      handleRightIconPress={() => onRightIconPress()}
      {...props}
    >
      <div className='taxflow-multi-option-list-item'>
        {(() => {
          if (item.aboutYou) {
            return (
              <>
                <Icon color='info' className='tax-flow-list-item-icon'>
                  <img src={_.get(collectionTypeItem, ['fields', 'icon', 'fields', 'file', 'url'])} alt='' />
                </Icon>
                <span>{_.get(collectionTypeItem, ['fields', 'title'])}</span>
              </>
            );
          } else {
            return (
              <>
                <span>{item.text}</span>
              </>
            );
          }
        })()}
      </div>
    </TaxFlowListItem>
  );
};

const mapStateToProps = (state, props) => ({
  currentQuestion: props.currentQuestion,
  currentAnswer: props.currentAnswer || state.taxFlow.currentAnswer,
  queryResults: props.queryResults,
  selectedCollectionTypes: selectedCollectionTypesSelector(state)
});

const mapDispatchToProps = {
  requestInfoResponseMessage
};

const ConnectedTaxFlowMultiOptionListItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowMultiOptionListItem);

export default ConnectedTaxFlowMultiOptionListItem;
