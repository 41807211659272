import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setAssistantOpen } from '@app/src/actions/assistantActions';
import { setSidebarComponent } from '@app/src/actions/expenseReviewActions';
import CloseIcon from '@app/src/assets/close.svg?react';
import ExpenseReviewSidebarRules from '@app/src/Components/ExpenseReview/ExpenseReviewSidebarRules';
import ExpenseReviewSidebarSavingsInfo from '@app/src/Components/ExpenseReview/ExpenseReviewSidebarSavingsInfo';
import { sidebarComponentSelector } from '@app/src/selectors/expenseReviewSelectors';

import '@app/src/Components/ExpenseReview/ExpenseReviewSidebar.scss';

const ExpenseReviewSidebar = ({ component, setSidebarComponent, setAssistantOpen }) => {
  const getContent = () => {
    switch (component) {
      case 'SavingsInfo':
        return <ExpenseReviewSidebarSavingsInfo />;
      case 'Rules':
        return <ExpenseReviewSidebarRules />;
      default:
        return <ExpenseReviewSidebarSavingsInfo />;
    }
  };

  useEffect(() => {
    if (component) {
      setAssistantOpen(false);
    }
  }, [component, setAssistantOpen]);

  return (
    <Drawer
      anchor='right'
      open={Boolean(component)}
      onClose={() => setSidebarComponent(null)}
      variant='persistent'
      className='base-sidebar-panel'
      ModalProps={{
        keepMounted: true
      }}
      PaperProps={{
        style: {
          padding: '16px'
        }
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
        onClick={() => setSidebarComponent(null)}
      >
        <CloseIcon />
      </IconButton>
      <div className='base-sidebar-panel-content'>{getContent()}</div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  component: sidebarComponentSelector(state)
});

const mapDispatchToProps = {
  setSidebarComponent,
  setAssistantOpen
};

const ConnectedExpenseReviewSidebar = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewSidebar);

export default ConnectedExpenseReviewSidebar;
