import axios from 'axios';
import indefinite from 'indefinite';
import isOnline from 'is-online';
import _ from 'lodash';
import qs from 'qs';
import url from 'url';

import { setBankLinkSkipped } from '@app/src/actions/bankActions';
import {
  setCurrentAnswer,
  setCurrentContext,
  setCurrentQuestion,
  setInitialLoad,
  setOnboardingError,
  setOnboardingExperiments,
  setOnboardingLoading,
  setOnboardingMarketingContent,
  setOnboardingUpdating,
  setType
} from '@app/src/actions/onboardingActions';
import { setWorkDetails } from '@app/src/actions/workActions';
import { SKIP_BUTTON } from '@app/src/constants/bankLinkConstants';
import {
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  REFERRER_ID_STORAGE_KEY,
  REFERRER_STORAGE_KEY
} from '@app/src/constants/constants';
import {
  ONBOARDING_EXPERIMENTS_KEY,
  Url_FACEBOOK_CLICK_ID_STORAGE_KEY,
  Url_GOOGLE_CLICK_ID_STORAGE_KEY,
  Url_ONBOARDING_DURATION,
  Url_ONBOARDING_HOME,
  Url_ONBOARDING_INCOME_TYPE,
  Url_ONBOARDING_JOB_SELECT,
  Url_ONBOARDING_MEAL,
  Url_ONBOARDING_TRANSPORT,
  Url_ONBOARDING_TRAVEL,
  Url_ONBOARDING_VERIFY,
  Url_ONBOARDING_WRITE_OFFS,
  Url_PRESELECT_JOB_PARAM,
  Url_SIGNUP,
  Url_SIGNUP_LINK,
  Url_SIGNUP_PHONE_ONLY,
  Url_SIGNUP_PHONE_ONLY_TEST,
  Url_TIKTOK_STORAGE_KEY,
  Url_TUNE_TRANSACTION_STORAGE_KEY
} from '@app/src/constants/onboardingConstants';
import { incomeTypeQuestionContent } from '@app/src/constants/onboardingIncomeTypeContent';
import { CONTENTFUL_ONBOARDING_MARKETING_CONTENT, serverUrl } from '@app/src/global/Environment';
import { getCookie, getCurrentYear, getPreviousYear, replaceString } from '@app/src/global/Helpers';
import { isEmailUniqueSelector, isPhoneUniqueSelector } from '@app/src/selectors/authSelectors';
import { isW2OnlySelector } from '@app/src/selectors/dashboardSelectors';
import {
  currentAnswerSelector,
  currentQuestionSelector,
  jobCategoriesSelector,
  marketingContentIncomeTypesSelector,
  normalizedQuestionsSelector,
  onboardingEmailSelector,
  onboardingPhoneSelector,
  orderedJobCategoriesSelector
} from '@app/src/selectors/onboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { incomeTypeSelector, jobSlugsSelector, workDetailsSelector } from '@app/src/selectors/workSelectors';
import {
  generateClientDedupId,
  getAnalyticsTrait,
  setUserWithObj,
  trackActivity
} from '@app/src/services/analyticsService';
import {
  checkEmailUnique,
  checkPhoneUnique,
  requestLoginEmail,
  requestLoginOtp,
  signUpTaxFlow
} from '@app/src/services/authService';
import { getBankSummaryV2 } from '@app/src/services/bankService';
import {
  getContentfulEntries,
  getJobCategoryList,
  requireJobCategoryList,
  requireOnboardingQuestions
} from '@app/src/services/contentfulService';
import {
  sendOnboardingUtms,
  setAppAnalyticsLocalStorage,
  trackOnboardingQuestionAnswer,
  trackOnboardingQuestionView
} from '@app/src/services/onboardingAnalyticsService';
import {
  initOnboardingExperimentGroups,
  parseExperimentProperties,
  parseSignupExperiments
} from '@app/src/services/onboardingExperimentService';
import {
  getAnswerIncomeType,
  getAnswerLocalStorage,
  getWorkInfoLocalStorage,
  isW2OnlyPreSignup,
  updateAnswerJobSelection,
  updateAnswerLocalStorage
} from '@app/src/services/onboardingLocalStorageService';
import { createReferrerCookies, setMilesLocal, setMilesRemote } from '@app/src/services/onboardingReferrerService';
import { getFeatures, getWorkInfo, requireWorkCategories } from '@app/src/services/workService';
import { PATH_COMPONENT__SPECIAL_WHO } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

const handleError = (error) => async (dispatch) => {
  const message = _.get(error, 'message') || 'Something went wrong. Please try again.';
  dispatch(setOnboardingError(message));
  notify(message);

  if (message !== 'You already have an account!') {
    defaultCaptureException(error);
  }
};

const populateStrings = () => async (dispatch, getState) => {
  const currentQuestion = currentQuestionSelector(getState());
  const newQuestion = {
    ...currentQuestion,
    title: dispatch(replaceJobStrings(currentQuestion.titleOrig)),
    summary: dispatch(replaceJobStrings(currentQuestion.summaryOrig)),
    ...((currentQuestion.question_type === CATEGORY_TYPE_OPTIONS ||
      currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION) &&
    _.isArray(currentQuestion.question_meta)
      ? {
          question_meta: currentQuestion.question_meta.map((option) => ({
            ...option,
            text: dispatch(replaceJobStrings(option.text)),
            value: dispatch(replaceJobStrings(`${option.value}`))
          }))
        }
      : {}),
    ...(currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM && currentQuestion.sub_question
      ? {
          sub_question: currentQuestion.sub_question.map((question) => ({
            ...question,
            title: dispatch(replaceJobStrings(question.titleOrig)),
            summary: dispatch(replaceJobStrings(question.summaryOrig))
          }))
        }
      : {})
  };
  dispatch(setCurrentQuestion(newQuestion));
};

const populateQuestion =
  ({ questionSlug }) =>
  async (dispatch, getState) => {
    const normalizedQuestions = normalizedQuestionsSelector(getState());
    const currentQuestion = _.get(normalizedQuestions, ['byId', questionSlug], {});
    dispatch(setCurrentQuestion(currentQuestion));
    await dispatch(populateStrings());
  };

const goToUrlIfAuthenticated = (url, history) => async (dispatch, getState) => {
  const isAuthenticated = _.get(getState(), ['auth', 'isAuthenticated']);

  if (isAuthenticated) {
    history.push(url);
  }
};

const getQuestionData = () => async (dispatch) => {
  await Promise.all([
    dispatch(requireOnboardingQuestions()),
    dispatch(requireWorkCategories()),
    dispatch(requireJobCategoryList())
  ]);
};

export const getQuestion =
  ({ slug, history, params, jobNumberParam }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setOnboardingLoading(true));

      if (
        slug === Url_ONBOARDING_DURATION ||
        slug === Url_ONBOARDING_HOME ||
        slug === Url_ONBOARDING_INCOME_TYPE ||
        slug === Url_ONBOARDING_JOB_SELECT ||
        slug === Url_ONBOARDING_MEAL ||
        slug === Url_ONBOARDING_TRANSPORT ||
        slug === Url_ONBOARDING_TRAVEL ||
        slug === Url_ONBOARDING_WRITE_OFFS ||
        slug === Url_SIGNUP ||
        slug === Url_SIGNUP_PHONE_ONLY ||
        slug === Url_SIGNUP_PHONE_ONLY_TEST
      ) {
        const isAuthenticated = _.get(getState(), ['auth', 'isAuthenticated']);
        if (isAuthenticated) {
          await dispatch(getWorkInfo());
          const isW2Only = isW2OnlySelector(getState());
          if (isW2Only) {
            history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SPECIAL_WHO}`);
          } else {
            history.push('/onboarding/link');
          }
          return;
        }
      }

      await dispatch(getQuestionData());
      const workDetails = getWorkInfoLocalStorage();
      dispatch(setWorkDetails(workDetails));

      if (slug === Url_ONBOARDING_INCOME_TYPE) {
        return;
      }

      await dispatch(populateQuestion({ questionSlug: slug }));
      dispatch(setCurrentContext({}));
      await dispatch(getAnswerLocalStorage({ slug, jobNumberParam }));
      await dispatch(trackOnboardingQuestionView());

      if (slug === Url_SIGNUP || slug === Url_SIGNUP_PHONE_ONLY || slug === Url_SIGNUP_PHONE_ONLY_TEST) {
        dispatch(redirectIfMissingInfo({ history, slug }));
      }

      if (_.has(params, 'miles')) {
        dispatch(setMilesLocal({ miles: parseInt(_.get(params, 'miles')) }));
      }
    } catch (e) {
      await dispatch(handleError(e));
    } finally {
      dispatch(setOnboardingLoading(false));
    }
  };

const getIncomeTypeQuestion = () => async (dispatch) => {
  try {
    dispatch(setOnboardingLoading(true));
    dispatch(setCurrentQuestion(incomeTypeQuestionContent));
    const workDetails = getWorkInfoLocalStorage();
    await dispatch(setWorkDetails(workDetails));
    const answer = getAnswerIncomeType({ workDetails });
    dispatch(setCurrentAnswer(answer));
    await dispatch(trackOnboardingQuestionView());
  } catch (e) {
    await dispatch(handleError(e));
  } finally {
    dispatch(setOnboardingLoading(false));
  }
};

const redirectAnswer =
  ({ history }) =>
  async (dispatch, getState) => {
    const question = currentQuestionSelector(getState());
    const urls = {
      [Url_ONBOARDING_JOB_SELECT]: '/onboarding/job-duration/1',
      [Url_ONBOARDING_DURATION]: '/onboarding/transport',
      [Url_ONBOARDING_TRANSPORT]: '/onboarding/home',
      [Url_ONBOARDING_HOME]: '/onboarding/meal',
      [Url_ONBOARDING_MEAL]: '/onboarding/travel',
      [Url_ONBOARDING_TRAVEL]: '/onboarding/account'
    };
    const u = _.get(urls, question.slug);
    if (u) {
      history.push(u);
    }
  };

export const updateAnswer =
  ({ history, buttonClicked }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setOnboardingUpdating(true));
      const question = currentQuestionSelector(getState());
      await dispatch(updateAnswerLocalStorage());

      if (question.slug === Url_ONBOARDING_INCOME_TYPE) {
        trackActivity('onboarding: income type valid', { incomeType: incomeTypeSelector(getState()) });
      }

      if (question.slug === Url_SIGNUP) {
        await dispatch(updateAccountAnswer({ history }));
      } else if (question.slug === Url_SIGNUP_PHONE_ONLY) {
        await dispatch(updateAccountPhoneOnlyAnswer({ history }));
      }

      if (question.slug === Url_SIGNUP_LINK) {
        await dispatch(trackOnboardingQuestionAnswer(buttonClicked));
      } else {
        await dispatch(trackOnboardingQuestionAnswer());
      }

      await dispatch(redirectAnswer({ history }));
    } catch (e) {
      await dispatch(handleError(e));
    } finally {
      dispatch(setOnboardingUpdating(false));
    }
  };

const updateAccountAnswer =
  ({ history }) =>
  async (dispatch, getState) => {
    const email = onboardingEmailSelector(getState());
    await dispatch(checkEmailUnique(email));
    const isEmailUnique = isEmailUniqueSelector(getState());
    if (isEmailUnique) {
      history.push('/onboarding/account-phone-only');
    } else {
      await dispatch(
        requestLoginEmail({ email }, () => {
          history.push(
            url.format({
              pathname: '/onboarding/verify',
              query: {
                type: 'email'
              }
            })
          );
        })
      );
    }
  };

const updateAccountPhoneOnlyAnswer =
  ({ history }) =>
  async (dispatch, getState) => {
    const newWorkDetails = getWorkInfoLocalStorage();
    await dispatch(checkPhoneUnique(newWorkDetails.phone));
    const isPhoneUnique = isPhoneUniqueSelector(getState());
    if (isPhoneUnique) {
      await dispatch(createAccount({ workDetails: newWorkDetails }));
      const u = isW2OnlyPreSignup() ? `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SPECIAL_WHO}` : '/onboarding/link';
      await dispatch(goToUrlIfAuthenticated(u, history));
    } else {
      const phone = onboardingPhoneSelector(getState());
      await dispatch(
        requestLoginOtp({ phone }, () => {
          history.push(
            url.format({
              pathname: '/onboarding/verify',
              query: {
                type: 'phone'
              }
            })
          );
        })
      );
    }
  };

const createAccount =
  ({ workDetails }) =>
  async (dispatch, getState) => {
    const referrer = getCookie(REFERRER_STORAGE_KEY);
    const referrer_id = getCookie(REFERRER_ID_STORAGE_KEY);
    const fbc = getAnalyticsTrait(Url_FACEBOOK_CLICK_ID_STORAGE_KEY);
    const gclid = getAnalyticsTrait(Url_GOOGLE_CLICK_ID_STORAGE_KEY);
    const ttclid = getAnalyticsTrait(Url_TIKTOK_STORAGE_KEY);
    const client_dedup_id = generateClientDedupId();

    const experiments = _.get(getState(), ['onboarding', ONBOARDING_EXPERIMENTS_KEY]);
    const experimentProperties = parseExperimentProperties(experiments);
    const signupExperiments = parseSignupExperiments(experimentProperties.experiments);

    const data = {
      email: workDetails.email,
      phone: workDetails.phone,
      firstname: workDetails.firstname,
      lastname: workDetails.lastname,
      home: workDetails.home,
      meals: workDetails.meals,
      travel: workDetails.travel,
      car: workDetails.car,
      public_transport: 0,
      rideshare: 0,
      referrer,
      referrer_id,
      jobs: workDetails.jobs,
      job_dates: workDetails.job_dates,
      tax_responses: [],
      income_type: workDetails.income_type,
      ...(fbc && { fbc }),
      ...(ttclid && { ttclid }),
      ...(gclid && { gclid }),
      ...(client_dedup_id && { client_dedup_id }),
      experiments: signupExperiments
    };

    try {
      await dispatch(signUpTaxFlow(data));

      const user = userSelector(getState());
      trackActivity('signup (frontend)', {
        referrer,
        userId: user.id,
        phone: user.phone,
        client_dedup_id
      });

      if (localStorage.getItem('miles') !== null) {
        dispatch(setMilesRemote({ miles: parseInt(localStorage.getItem('miles')) }));
      }

      const userObj = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phone: data.phone,
        Affiliate_referral: data.referrer,
        jobs: workDetails.jobs,
        ...experimentProperties
      };

      const tuneTransactionId = getAnalyticsTrait(Url_TUNE_TRANSACTION_STORAGE_KEY);

      if (tuneTransactionId) {
        userObj[Url_TUNE_TRANSACTION_STORAGE_KEY] = tuneTransactionId;
      }

      setUserWithObj(data.phone, userObj);
    } catch (e) {
      trackActivity('onboarding: signup failed', { reason: e.message });
      throw e;
    }
  };

const redirectIfMissingInfo =
  ({ history, slug }) =>
  async (dispatch, getState) => {
    const workDetails = _.get(getState(), ['work', 'workDetails']);

    if (isW2OnlyPreSignup()) {
      return;
    }

    if (_.isEmpty(workDetails.job_dates)) {
      history.push('/onboarding/job-select');
    } else if (_.isNil(workDetails.car)) {
      history.push('/onboarding/transport');
    } else if (_.isNil(workDetails.home)) {
      history.push('/onboarding/home');
    } else if (_.isNil(workDetails.meals)) {
      history.push('/onboarding/meal');
    } else if (_.isNil(workDetails.travel)) {
      history.push('/onboarding/travel');
    } else if (
      (slug === Url_SIGNUP_PHONE_ONLY || slug === Url_SIGNUP_PHONE_ONLY_TEST) &&
      [workDetails.email, workDetails.firstname].some((property) => _.isEmpty(property))
    ) {
      history.push('/onboarding/account');
    }
  };

const updatePreselectJob =
  ({ params }) =>
  async (dispatch, getState) => {
    await dispatch(getJobCategoryList());
    const jobCategories = orderedJobCategoriesSelector(getState());
    const jobSlug = params[Url_PRESELECT_JOB_PARAM];
    const jobItem = jobSlug ? jobCategories.find((category) => category.slug === jobSlug) : null;
    if (jobItem) {
      const workDetails = getWorkInfoLocalStorage();
      updateAnswerJobSelection({ answer: { value: [jobSlug] }, workDetails });
    }
  };

export const getIncomeTypeLite = () => async (dispatch) => {
  await dispatch(getIncomeTypeQuestion());
};

export const getIncomeType =
  ({ slug, history }) =>
  async (dispatch) => {
    await dispatch(getQuestion({ slug, history }));
  };

export const updateIncomeType =
  ({ history }) =>
  async (dispatch) => {
    dispatch(updateAnswer({ history }));
  };

export const getLink =
  ({ history }) =>
  async (dispatch) => {
    await dispatch(getFeatures());
    await dispatch(getQuestion({ slug: Url_SIGNUP_LINK, history }));
    await dispatch(trackOnboardingQuestionView());
  };

export const updateLink =
  ({ history, buttonClicked }) =>
  async (dispatch) => {
    await dispatch(updateAnswer({ history, buttonClicked }));

    if (buttonClicked === SKIP_BUTTON) {
      await dispatch(setBankLinkSkipped(true));
    }
  };

export const getVerify =
  ({ history, location }) =>
  async (dispatch) => {
    const type = _.get(qs.parse(location.search, { ignoreQueryPrefix: true }), 'type') || 'phone';
    dispatch(setType(type));
    await dispatch(getQuestion({ slug: Url_ONBOARDING_VERIFY, history }));
  };

const getOnboardingMarketingContent = () => async (dispatch) => {
  try {
    const vals = await getContentfulEntries(CONTENTFUL_ONBOARDING_MARKETING_CONTENT);

    const formattedContentVals = vals.items.reduce(
      (acc, { fields }) => ({ ...acc, [fields.contentName]: fields.content }),
      {}
    );

    dispatch(setOnboardingMarketingContent(formattedContentVals));
  } catch (e) {
    dispatch(setOnboardingError('Something went wrong, please try again'));
  }
};

export const getOnboardingPrerequisites = () => async (dispatch, getState) => {
  try {
    const isInitialLoad = _.get(getState(), ['onboarding', 'initialLoad']);
    if (isInitialLoad) {
      await dispatch(getOnboardingMarketingContent());

      if (!_.isNil(localStorage.getItem(ONBOARDING_EXPERIMENTS_KEY))) {
        const userExperiments = JSON.parse(localStorage.getItem(ONBOARDING_EXPERIMENTS_KEY));
        dispatch(setOnboardingExperiments(userExperiments));
      }

      dispatch(setInitialLoad(false));
    }
  } catch (e) {
    dispatch(setOnboardingError('Something went wrong, please try again'));
  }
};

const initOnboardingParams =
  ({ params }) =>
  async (dispatch) => {
    await Promise.all([
      dispatch(getOnboardingPrerequisites()),
      dispatch(createReferrerCookies({ params })),
      dispatch(setAppAnalyticsLocalStorage({ params })),
      dispatch(updatePreselectJob({ params })),
      dispatch(sendOnboardingUtms({ params }))
    ]);
  };

export const initOnboarding =
  ({ history, location }) =>
  async (dispatch) => {
    try {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      await dispatch(initOnboardingExperimentGroups({ history, params }));
      await dispatch(initOnboardingParams({ params }));
    } catch (e) {
      dispatch(setOnboardingError('Something went wrong, please try again'));
      defaultCaptureException(e);
    }
  };

export const initJobSelect =
  ({ location }) =>
  async (dispatch) => {
    try {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      await dispatch(initOnboardingParams({ params }));
    } catch (e) {
      dispatch(setOnboardingError('Something went wrong, please try again'));
      defaultCaptureException(e);
    }
  };

export const updateCurrentAnswer =
  ({ slug, answer }) =>
  (dispatch, getState) => {
    const currentAnswer = currentAnswerSelector(getState());
    dispatch(
      setCurrentAnswer({
        ...currentAnswer,
        value: {
          ...currentAnswer.value,
          [slug]: answer
        }
      })
    );
  };

export const replaceJobStrings = (str) => (dispatch, getState) => {
  const incomeType = incomeTypeSelector(getState());
  const incomeTypeDescriptors = marketingContentIncomeTypesSelector(getState());
  const incomeTypeDescriptor = getIncomeTypeDescriptor(incomeType, incomeTypeDescriptors);
  const jobCategories = jobCategoriesSelector(getState());
  const jobSlugs = jobSlugsSelector(getState());
  const hasOnlyOneJob = jobSlugs.length === 1;

  const currentJobSlug = jobSlugs && hasOnlyOneJob ? jobSlugs[0] : 'other';
  const currentJob = jobCategories.find((job) => job.slug === currentJobSlug);
  const defaultJob = jobCategories.find((job) => job.slug === 'other');

  const jobNameWithIndefiniteArticle = indefinite(
    _.get(currentJob, 'name') && currentJobSlug !== 'other' && hasOnlyOneJob
      ? currentJob.name
      : getDefaultJobName(incomeType)
  ).toLowerCase();

  if (_.isEmpty(currentJob) && _.isEmpty(defaultJob)) return str;

  const { carRelatedDescription, homeOfficeDescription, travelHelpText, whyMightThisPersonGetABusinessMeal } =
    !_.isEmpty(currentJob) ? currentJob : defaultJob;

  const workDetails = workDetailsSelector(getState());

  return replaceString(str, {
    jobNameWithIndefiniteArticle,
    work: incomeTypeDescriptor,
    carRelatedDescription,
    homeOfficeDescription,
    travelHelpText,
    whyMightThisPersonGetABusinessMeal,
    currentYear: getCurrentYear(),
    previousYear: getPreviousYear(),
    firstname: _.get(workDetails, 'firstname')
  });
};

// Formats the income type content for onboarding flow.
function getIncomeTypeDescriptor(userIncomeTypes = [], incomeTypeMapping) {
  if (_.isEmpty(incomeTypeMapping)) return;

  const { salaried, contracting_business, contracting, business } = incomeTypeMapping;

  if (userIncomeTypes.includes('contractor')) {
    if (userIncomeTypes.includes('owner')) {
      return contracting_business;
    }
    return contracting;
  } else if (userIncomeTypes.includes('owner')) {
    return business;
  } else if (userIncomeTypes.includes('salaried')) {
    return salaried;
  } else {
    return contracting;
  }
}

function getDefaultJobName(userIncomeTypes = []) {
  if (userIncomeTypes.includes('contractor')) {
    if (userIncomeTypes.includes('owner')) {
      return 'freelancer / business owner';
    }
    return 'freelancer';
  } else if (userIncomeTypes.includes('owner')) {
    return 'business owner';
  } else if (userIncomeTypes.includes('salaried')) {
    return 'employee';
  } else {
    return 'freelancer';
  }
}

export const runRetroJob =
  (options = { force: false, retry: false, updatingStartDate: false }) =>
  async (dispatch, getState) => {
    dispatch(setOnboardingLoading(true));
    try {
      await dispatch(getBankSummaryV2());
      if (_.isEmpty(getState().bank.bankList)) {
        trackActivity('run retro: no bank linked');
        return;
      }

      await dispatch(getWorkInfo());
      const jobDuration = _.get(getState(), ['work', 'workDetails', 'job_dates', 0, 'dates'], {});

      const year = jobDuration.year ? jobDuration.year : 'all';
      const month = jobDuration.month ? jobDuration.month : '1';

      trackActivity('run retro: frontend triggered', {
        isOnline: await isOnline(),
        year,
        month
      });
      const res = await axios
        .post(`${baseUrl}api/profile/run-retro`, {
          force: options.force,
          year,
          month,
          retry: options.retry,
          updatingStartDate: options.updatingStartDate
        })
        .then((response) => response.data);
      const { status } = res;
      dispatch(setOnboardingLoading(false));
      return status;
    } catch (error) {
      trackActivity('run retro: frontend failed', { error: error.message, isOnline: await isOnline() });
      dispatch(setOnboardingError('Something went wrong, please try again'));
      dispatch(setOnboardingLoading(false));
      defaultCaptureException(error);
      return error;
    }
  };
