import React from 'react';
import TaxFlowBillElement from '@app/src/Components/TaxFlow/Question/TaxFlowBillElement';
import TaxFlowSubmitSummaryElement from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitSummaryElement';

const TaxFlowSubmitElement = (props) => {
  return (
    <div className='taxflow-submit-element'>
      <TaxFlowBillElement {...props} />
      <TaxFlowSubmitSummaryElement {...props} />
    </div>
  );
};

export default TaxFlowSubmitElement;
