import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import _ from 'lodash';
import { createClient } from 'contentful';
import {
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_HOST,
  CONTENTFUL_CONTENT_JOBCATEGORY_TYPE,
  CONTENTFUL_CONTENT_TYPE
} from '@app/src/global/Environment';
import {
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD,
  CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_TAXFLOW_SYSTEM,
  CATEGORY_TYPE_TEMPLATE,
  CATEGORY_TYPE_TEXT
} from '@app/src/constants/constants';
import { jobCategoriesSelector, normalizedQuestionsSelector } from '@app/src/selectors/onboardingSelectors';
import {
  setNormalizedQuestions,
  setOnboardingError,
  setOnboardingJobCategoryList
} from '@app/src/actions/onboardingActions';
import { setHolisticOnboardingJobCategories } from '@app/src/actions/holisticOnboardingActions';

let client;

export const getContentfulEntries = async (content_type, condition) => {
  client = createClient({
    space: CONTENTFUL_SPACE_ID,
    environment: CONTENTFUL_ENVIRONMENT,
    accessToken: CONTENTFUL_ACCESS_TOKEN,
    host: CONTENTFUL_HOST
  });
  return await client.getEntries({ content_type: content_type, ...condition });
};

export const getContentfulMappedItem = (item) => {
  const { sys, fields } = item;
  let newItem = {};
  let mappedSubQuestionList;
  if (Array.isArray(fields.subQuestion)) {
    mappedSubQuestionList = _.flatMap(fields.subQuestion, (item) => {
      return _.get(item, ['sys', 'type']) === 'Entry' ? [getContentfulMappedItem(item)] : [];
    });
  }
  newItem = {
    id: sys.id,
    title: fields.question || fields.title || '',
    summary: fields.description || '',
    question_type: fields.questionType ? getQuestionType(fields.questionType) : '',
    next_question: fields.nextQuestionOptions ? getNextQuestionId(fields.nextQuestionOptions) : undefined,
    required: fields.required !== false,
    selfUnlockEnabled: !!fields.selfUnlockEnabled,
    question_meta: fields.questionMeta || {},
    question_meta_ext: fields.questionMetaExtensions || {},
    endpoint_attr: fields.endpointAttribute,
    examples: fields.examples,
    header_style: fields.headerStyle ? fields.headerStyle.toLowerCase() : 'normal',
    sub_question: mappedSubQuestionList,
    slug: fields.slug,
    section_id: fields.section ? fields.section.sys.id : undefined,
    section: fields.section,
    sub_section: fields.subSection,
    collectionType: fields.collectionType,
    collectionId: fields.collectionId,
    navigationType: fields.navigationType,
    nextQuestionOptions: fields.nextQuestionOptions,
    preSignup: fields.preSignup,
    learnMoreInfo: fields.learnMoreInfo,
    learnMoreTitle: fields.learnMoreTitle,
    showWriteOffs: fields.showWriteOffs,
    nextButtonLabel: fields.nextButtonLabel,
    progressIndex: fields.progressIndex,
    multi: fields.multi,
    info: fields.info
      ? {
          default: fields.info
        }
      : null,
    infoItem: fields.info ? { info: 'default' } : null,
    flow: fields.flow,
    template: fields.questionType === CATEGORY_TYPE_TEMPLATE ? getContentfulMappedItem(fields.template) : null,
    started: fields.started,
    collectionTypeStarted: fields.collectionTypeStarted,
    done: fields.done
  };
  newItem.titleOrig = newItem.title;
  newItem.summaryOrig = newItem.summary;

  if (newItem.question_type === CATEGORY_TYPE_TAXFLOW_FORM || newItem.question_type === CATEGORY_TYPE_TAXFLOW_SYSTEM) {
    //Set sysId to show system component like tax writeoff/signup/connect bank
    //Default to slug.
    newItem.sysId = getQuestionSystemId(newItem.question_meta) || newItem.slug;
  }

  return newItem;
};

const getQuestionSystemId = (item) => {
  if (item && item.length > 0) {
    if (item[0] && item[0].id) return item[0].id;
  }
  return undefined;
};

const getQuestionType = (type) => {
  if (type === 'job') return CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY;
  else if (type === 'option' || type === 'multiple choice') return CATEGORY_TYPE_OPTIONS;
  else if (type === 'input') return CATEGORY_TYPE_TEXT;
  else if (type === 'multi-option') return CATEGORY_TYPE_TAXFLOW_MULTI_OPTION;
  else if (type === 'form') return CATEGORY_TYPE_TAXFLOW_FORM;
  else if (type === 'system') return CATEGORY_TYPE_TAXFLOW_SYSTEM;
  else if (type === 'form-upload') return CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD;
  return type;
};

const getNextQuestionId = (item) => {
  if (item && item.length > 0) {
    if (item[0] && item[0].sys) return item[0].sys.id;
  }
  return undefined;
};

export const getJobCategoryList = () => async (dispatch) => {
  try {
    const res = await getContentfulEntries(CONTENTFUL_CONTENT_JOBCATEGORY_TYPE, {
      'fields.hide[ne]': true,
      limit: 200
    });
    const { items } = res;
    const mappedJobList = items.map((item) => {
      const { sys, fields } = item;
      let newItem = {};
      newItem = {
        id: sys.id,
        icon_url: fields.icon ? fields.icon.fields.file.url : undefined,
        ...fields
      };
      return newItem;
    });

    dispatch(setOnboardingJobCategoryList(mappedJobList));
    dispatch(setHolisticOnboardingJobCategories(mappedJobList));
    return mappedJobList;
  } catch (error) {
    dispatch(setOnboardingError('Something went wrong, please try again'));
    defaultCaptureException(error);
    return error;
  }
};

export const requireJobCategoryList = () => async (dispatch, getState) => {
  if (_.isEmpty(jobCategoriesSelector(getState()))) {
    await dispatch(getJobCategoryList());
  }
};

const forceRefreshOnboardingQuestions = () => async (dispatch) => {
  try {
    const res = await getContentfulEntries(CONTENTFUL_CONTENT_TYPE, {
      limit: 1000,
      'fields.flow': 'onboarding'
    });
    const { items } = res;
    const mappedQuestionList = items.map((item) => getContentfulMappedItem(item));
    const questions = {
      allIds: mappedQuestionList.map((item) => item.slug),
      byId: _.assign({}, ...mappedQuestionList.map((item) => ({ [item.slug]: item })))
    };
    dispatch(setNormalizedQuestions(questions));
  } catch (error) {
    dispatch(setOnboardingError('Something went wrong. Please try again.'));
    defaultCaptureException(error);
  }
};

export const requireOnboardingQuestions = () => async (dispatch, getState) => {
  const normalizedQuestions = normalizedQuestionsSelector(getState());
  if (_.isEmpty(_.get(normalizedQuestions, 'allIds'))) {
    await dispatch(forceRefreshOnboardingQuestions());
    return;
  }
};
