import React, { useEffect } from 'react';
import classNames from 'classnames';
import isOnline from 'is-online';
import '@app/src/Components/GenericErrorScreen/GenericErrorScreen.scss';
import { trackActivity } from '@app/src/services/analyticsService';
import Link from '@mui/material/Link';
import getImageUrl from '@app/src/utils/getImageUrl';

const GenericErrorScreen = ({ className }) => {
  useEffect(() => {
    const run = async () => {
      trackActivity('generic error screen: view', { isOnline: await isOnline() });
    };
    run();
  }, []);

  const onRefresh = (e) => {
    e.preventDefault();
    trackActivity('generic error screen: refresh');
    window.location.reload();
  };

  const onContactSupport = () => {
    trackActivity('generic error screen: contact support');
  };

  return (
    <div className={classNames('generic-error-screen', className)}>
      <div className='generic-error-screen-container'>
        <img className='generic-error-screen-image' alt='Error occurred' src={getImageUrl('audit-risks.svg')} />
        <h1 className='generic-error-screen-title'>Our app encountered an error while processing that request.</h1>
        <div className='generic-error-screen-description'>
          Please{' '}
          <Link
            className='generic-error-screen-link'
            component='button'
            onClick={onRefresh}
            sx={{
              verticalAlign: 'baseline'
            }}
          >
            refresh
          </Link>{' '}
          or contact{' '}
          <Link className='generic-error-screen-link' href='mailto:support@keepertax.com' onClick={onContactSupport}>
            support@keepertax.com
          </Link>
          .
        </div>
      </div>
    </div>
  );
};

export default GenericErrorScreen;
