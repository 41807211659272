import _ from 'lodash';
import axios from 'axios';
import { serverUrl } from '@app/src/global/Environment';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { assistantSignedUrlSelector } from '@app/src/selectors/assistantSelectors';
import {
  addToSignedUrlCache,
  sendStreamMessage,
  setAssistantHasError,
  setAssistantOpen
} from '@app/src/actions/assistantActions';
import { setInfoModalItem, setInfoModalShow } from '@app/src/actions/taxFlowActions';
import { RESPONSE_TEXT_DELAY_MS } from '@app/src/taxflow/shared/constants/sharedConstants';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const baseUrl = serverUrl();

export const generatePageSummary = () => async (_dispatch, getState) => {
  if (!window.location.pathname.startsWith('/tax-filing') && !window.location.pathname.includes('/settings')) {
    return '';
  }

  const currentQuestion = currentQuestionSelector(getState());

  const header = document.querySelector('.steps-content .steps-head');
  const body = _.compact(
    Array.from(document.querySelectorAll('.steps-content > *:not(.steps-head)')).map((node) => node.innerText)
  ).join('\n');

  const infoTitle = _.get(currentQuestion, ['learnMoreInfo', 'fields', 'title']);
  const infoDescription = _.get(currentQuestion, ['learnMoreInfo', 'fields', 'description']);

  return _.compact([
    header && `Header: ${header.innerText}`,
    infoTitle && `Info Title (Hidden until clicked): ${infoTitle}`,
    infoDescription && `Info Description (Hidden until clicked): ${infoDescription}`,
    currentQuestion.question_type && `Question Type: ${currentQuestion.question_type}`,
    body && `Body: ${body}`
  ]).join('\n');
};

export const getMessageList = async () => {
  try {
    const res = await axios.get(`${baseUrl}api/message/get-message-list`);

    const messages = _.get(res, ['data', 'data', 'messages']);

    if (!_.isArray(messages)) {
      throw new Error('Expected messages to be an array');
    }

    return messages;
  } catch (e) {
    defaultCaptureException(e);
    return [];
  }
};

export const openInfoComponent = (item) => async (dispatch) => {
  dispatch(setInfoModalItem(item));
  dispatch(setInfoModalShow(true));
};

export const requestInfoResponseMessage = (item) => async (dispatch) => {
  const { message, response, slug, origin } = item;
  try {
    await dispatch(
      sendStreamMessage({
        text: message,
        origin,
        text_type: `info - ${slug}`,
        sub_type: `info - ${slug}`,
        response_text: response,
        response_delay: RESPONSE_TEXT_DELAY_MS
      })
    );
  } catch (e) {
    dispatch(
      openInfoComponent({
        ...item,
        title: message,
        source: response
      })
    );
    dispatch(setAssistantHasError(false));
    dispatch(setAssistantOpen(false));
  }
};

const getFileUrl = async (key) => {
  const res = await axios.get(`${baseUrl}api/message/files/${key}`);

  return _.get(res, 'data.data');
};

export const useSignedUrl = (key) => {
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const result = useSelector((state) => assistantSignedUrlSelector(state, { key }));
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const signedUrl = _.get(result, 'signedUrl');

    if (result || !key || signedUrl || error) {
      return;
    }

    (async () => {
      try {
        const fileUrlData = await getFileUrl(key);

        if (!isMounted.current) {
          return;
        }

        if (_.has(fileUrlData, 'signedUrl')) {
          dispatch(addToSignedUrlCache({ ...fileUrlData, key }));
        }
      } catch (e) {
        if (!isMounted.current) {
          return;
        }

        setError(e);
      }
    })();
  }, [dispatch, error, key, result]);

  return result || {};
};
