import React, { useEffect } from 'react';
import qs from 'qs';
import { parseImpersonationToken } from '@app/src/services/authService';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import classNames from 'classnames';
import '@app/src/Components/ImpersonationLoading/ImpersonationLoading.scss';
import _ from 'lodash';

const ImpersonationLoading = ({ className, location, parseImpersonationToken }) => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const token = _.get(query, 'token');

  useEffect(() => {
    const run = async () => {
      await parseImpersonationToken({ token });
    };
    run();
  }, [parseImpersonationToken, token]);

  return (
    <div className={classNames('impersonation-loading', className)}>
      <Loader type='page' />
    </div>
  );
};

const mapDispatchToProps = {
  parseImpersonationToken
};

const ConnectedImpersonationLoading = connect(null, mapDispatchToProps)(ImpersonationLoading);

export default ConnectedImpersonationLoading;
