import _ from 'lodash';
import {
  setCurrentQuestionnaireQuestion,
  setQuestionnaireProgress,
  setQuestionnaireSummaryPills
} from '@app/src/actions/taxFlowActions';
import { serverUrl } from '@app/src/global/Environment';
import axios from 'axios';
const baseUrl = serverUrl();

export const fetchCurrentQuestionnaireQuestion = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/questionnaire/get-current-question`);
  const question = _.get(res, ['data', 'data', 'question']);
  if (!_.isNil(question)) {
    dispatch(setCurrentQuestionnaireQuestion(question));
  }
  dispatch(fetchQuestionnaireProgress());
  return { question };
};

export const generateQuestionnaireQuestions = () => async (dispatch) => {
  await axios.post(`${baseUrl}api/questionnaire/generate-questions`);
  dispatch(fetchQuestionnaireProgress());
};

export const progressToNextQuestionnaireQuestion =
  ({ maybeFollowUpQuestion }) =>
  async (dispatch) => {
    const res = await axios.post(`${baseUrl}api/questionnaire/progress-to-next-question`, {
      maybeFollowUpQuestion
    });
    const currentQuestion = _.get(res, ['data', 'data', 'currentQuestion']);
    const questionnaireComplete = _.get(res, ['data', 'data', 'questionnaireComplete'], true);
    if (!_.isNil(currentQuestion) && !questionnaireComplete) {
      dispatch(setCurrentQuestionnaireQuestion(currentQuestion));
    }
    dispatch(fetchQuestionnaireProgress());
    return { questionnaireComplete };
  };

export const goBackToPreviousQuestionnaireQuestion = () => async (dispatch) => {
  const res = await axios.post(`${baseUrl}api/questionnaire/go-back-to-previous-question`);
  const currentQuestion = _.get(res, ['data', 'data', 'currentQuestion']);
  const questionnaireExited = _.get(res, ['data', 'data', 'questionnaireExited']);
  if (!_.isNil(currentQuestion)) {
    dispatch(setCurrentQuestionnaireQuestion(currentQuestion));
  }
  dispatch(fetchQuestionnaireProgress());
  return { questionnaireExited };
};

const fetchQuestionnaireProgress = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/questionnaire/get-progress`);
  const progressObject = _.get(res, ['data', 'data']);
  dispatch(setQuestionnaireProgress(progressObject));
};

export const fetchQuestionnaireSummaryPills = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/questionnaire-summary-pills`);
  const pills = _.get(res, ['data', 'data', 'questionnaireSummaryPills']);
  dispatch(setQuestionnaireSummaryPills(pills));
  return { pills };
};
