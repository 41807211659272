import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { ArrowDown2, Filter } from 'iconsax-react';
import _ from 'lodash';
import React, { useState } from 'react';

import ExpenseReviewFilterSelectCategory from '@app/src/Components/ExpenseReview/ExpenseReviewFilterSelectCategory';
import { colorPrimary, colorPrimaryAlt, colorSecondary, colorSurface } from '@app/src/theme';

const ExpenseReviewFilterSelect = ({
  className,
  value,
  filterSelected,
  onChange,
  renderValue,
  filterOptions,
  menuItemValueKey,
  menuItemDisplayKey,
  type
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasOptions = filterOptions.length > 0;
  const cursorType = hasOptions ? 'pointer' : 'default';

  const onClick = () => {
    if (hasOptions) {
      setIsOpen(!isOpen);
    }
  };

  const getFilterIcon = () => {
    if (!hasOptions) {
      return null;
    }
    if (filterSelected) {
      return (
        <IconButton onClick={onClick} style={{ padding: 4 }}>
          <Filter onClick={onClick} variant='Bold' size={20} style={{ color: colorSecondary, cursor: cursorType }} />
        </IconButton>
      );
    }

    return (
      <IconButton onClick={onClick} style={{ padding: 4 }}>
        <ArrowDown2 onClick={onClick} size={20} style={{ cursor: cursorType }} />
      </IconButton>
    );
  };

  if (type === 'category') {
    return (
      <ExpenseReviewFilterSelectCategory
        {...{
          className,
          value,
          filterSelected,
          onChange,
          renderValue,
          filterOptions,
          menuItemValueKey,
          menuItemDisplayKey
        }}
      />
    );
  }

  return (
    <Select
      multiple
      className={className}
      MenuProps={{
        autoFocus: false
      }}
      displayEmpty
      open={isOpen}
      style={{ padding: 0, fontSize: '14px', fontWeight: 700 }}
      value={value}
      onChange={onChange}
      onClick={onClick}
      sx={{
        boxShadow: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 0
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 0
        },
        '& .MuiSelect-select.MuiOutlinedInput-input.MuiInputBase-input': {
          paddingRight: '4px',
          cursor: cursorType
        },
        cursor: cursorType
      }}
      input={<OutlinedInput variant='table-input' />}
      IconComponent={getFilterIcon}
      renderValue={renderValue}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {filterOptions.map((option) => (
        <MenuItem
          key={_.get(option, menuItemValueKey)}
          value={_.get(option, menuItemValueKey)}
          sx={{
            '&.Mui-selected': { backgroundColor: colorPrimaryAlt, color: colorSurface },
            '&.Mui-selected:hover': { backgroundColor: colorPrimary, color: colorSurface }
          }}
        >
          {_.get(option, menuItemDisplayKey)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ExpenseReviewFilterSelect;
