import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Linkify from 'linkify-react';
import 'linkify-plugin-mention';
import AssistantMessageAvatar from '@app/src/Components/Assistant/AssistantMessageAvatar';
import AssistantSystemMessage from '@app/src/Components/Assistant/AssistantSystemMessage';
import '@app/src/Components/Assistant/AssistantMessage.scss';
import ReactMarkdown from 'react-markdown';
import { isReactNative } from '@app/src/global/Helpers';
import { Link } from 'react-router-dom';

const AssistantMessage = forwardRef(
  ({ message: { sender, content, type, hideAvatar, markdown, origin }, ...props }, ref) => {
    const role = sender === 'user' ? 'user' : 'assistant';

    const renderMention = ({ content: mention }) => {
      return <span className='assistant-message-mention'>{mention}</span>;
    };

    const renderLink = ({ attributes, content }) => {
      if (origin === 'ask my assistant button pressed') {
        const url = new URL(attributes.href);

        return (
          <Link to={`${url.pathname}${url.search}${url.hash}`} className='assistant-message-link' {...attributes}>
            {content}
          </Link>
        );
      }

      if (isReactNative()) {
        return (
          <a
            className={classNames('assistant-message-link', { 'assistant-message-long-link': content.length > 15 })}
            {...attributes}
          >
            {content}
          </a>
        );
      }

      return (
        <a
          target='_blank'
          rel='noopener noreferrer'
          className={classNames('assistant-message-link', { 'assistant-message-long-link': content.length > 15 })}
          {...attributes}
        >
          {content}
        </a>
      );
    };

    if (content?.trim() === '') {
      return <div ref={ref} {...props} />;
    }

    if (type === 'system') {
      return (
        <AssistantSystemMessage ref={ref} {...props}>
          {content}
        </AssistantSystemMessage>
      );
    }

    return (
      <div className={`assistant-message-group assistant-message-group-${role}`} ref={ref} {...props}>
        {!hideAvatar && <AssistantMessageAvatar sender={sender} />}
        <div
          className={classNames('assistant-message', `assistant-${role}`, {
            'assistant-message-no-avatar': role !== 'user' && hideAvatar
          })}
        >
          {markdown ? (
            <ReactMarkdown
              renderers={{
                link: ({ href, children }) => renderLink({ attributes: { href }, content: children })
              }}
            >
              {content}
            </ReactMarkdown>
          ) : (
            <Linkify options={{ render: { mention: renderMention, url: renderLink } }}>{content}</Linkify>
          )}
        </div>
      </div>
    );
  }
);

export default AssistantMessage;
