import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Notification1 from '@app/src/assets/notification-1.svg?react';
import Notification2 from '@app/src/assets/notification-2.svg?react';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import { notificationPlatformSelector } from '@app/src/selectors/authSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { updateNotificationPreference } from '@app/src/services/workService';

import '@app/src/Components/Settings/SettingsNotifications.scss';

const SettingsNotifications = ({ platform, updateNotificationPreference }) => {
  useEffect(() => {
    trackActivity('navigation: notification settings');
  }, []);

  const onUpdateNotificationPreference = async ({ target: { value } }) => {
    if (platform === value) return;

    await updateNotificationPreference(value, platform);
  };

  return (
    <>
      <div className='question-title'>Preferred notification method</div>
      <Divider />
      <div className='question-body'>
        Keeper will notify you of transactions that are likely to be deductions and any updates on your tax return
        filing.
      </div>
      <div className='flex column justify-center align-center mt-16 mb-16' style={{ gap: '8px' }}>
        <Notification1 />
        <Notification2 />
      </div>
      <div className='flex column' style={{ gap: '8px' }}>
        <ToggleButton value='stream' onClick={onUpdateNotificationPreference} selected={platform === 'stream'}>
          App notifications
        </ToggleButton>
        <ToggleButton fullWidth value='sms' onClick={onUpdateNotificationPreference} selected={platform === 'sms'}>
          SMS text messages
        </ToggleButton>
        <ToggleButton fullWidth value='none' onClick={onUpdateNotificationPreference} selected={platform === 'none'}>
          Don't notify me
        </ToggleButton>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  platform: notificationPlatformSelector(state)
});

const mapDispatchToProps = {
  updateNotificationPreference
};

const ConnectedNotifications = connect(mapStateToProps, mapDispatchToProps)(SettingsNotifications);

export default ConnectedNotifications;
