import React from 'react';
import { connect } from 'react-redux';
import TaxFlowFormTextItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormTextItem';
import TaxFlowFormNumberItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormNumberItem';
import TaxFlowFormMoneyItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormMoneyItem';
import TaxFlowFormPhoneItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormPhoneItem';
import TaxFlowFormSSNItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSSNItem';
import TaxFlowFormInitialItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormInitialItem';
import TaxFlowFormMilesItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormMilesItem';
import TaxFlowFormYearsItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormYearsItem';
import TaxFlowFormPercentItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormPercentItem';
import TaxFlowFormSquareFootageItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSquareFootageItem';
import TaxFlowFormEINItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormEINItem';
import TaxFlowFormSSNOrEINItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSSNOrEINItem';
import TaxFlowFormZipItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormZipItem';
import TaxFlowFormIntItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormIntItem';
import TaxFlowFormDateItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormDateItem';
import TaxFlowFormSliderItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSliderItem';
import TaxFlowFormDropdownItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormDropdownItem';
import TaxFlowFormTwelveAItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormTwelveAItem';
import TaxFlowFormBox14Item from '@app/src/Components/TaxFlow/Form/TaxFlowFormBox14Item';
import TaxFlowFormCalendarItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormCalendarItem';
import {
  CATEGORY_TYPE_TAXFLOW_FORM_TEXT,
  CATEGORY_TYPE_TAXFLOW_FORM_EMAIL,
  CATEGORY_TYPE_TAXFLOW_FORM_PHONE,
  CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD,
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_FORM_SSN,
  CATEGORY_TYPE_TAXFLOW_FORM_NUMBER,
  CATEGORY_TYPE_TAXFLOW_FORM_SLIDER,
  CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_INFO,
  CATEGORY_TYPE_MONEY,
  CATEGORY_TYPE_TWELVE_A,
  CATEGORY_TYPE_BOX_14,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_INITIAL,
  CATEGORY_TYPE_MILES,
  CATEGORY_TYPE_YEARS,
  CATEGORY_TYPE_PERCENT,
  CATEGORY_TYPE_SQUARE_FOOTAGE,
  CATEGORY_TYPE_EIN,
  CATEGORY_TYPE_SSN_OR_EIN,
  CATEGORY_TYPE_ZIP,
  CATEGORY_TYPE_INT,
  CATEGORY_TYPE_TAXFLOW_ADDRESS
} from '@app/src/constants/constants';
import TaxFlowFormOptionItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormOptionItem';
import TaxFlowFormMultiOptionItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormMultiOptionItem';
import TaxFlowFormInfoItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormInfoItem';
import TaxFlowFormAddressItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormAddressItem';
import { isW2OnlySelector } from '@app/src/selectors/dashboardSelectors';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';

class TaxFlowFormSubQuestionItem extends React.Component {
  render() {
    const { question, answer, onChange, onFocus, overrideCollectionId } = this.props;

    if (question && ((question.question_meta && question.question_meta.type) || question.question_type)) {
      switch (question.question_meta.type || question.question_type) {
        case CATEGORY_TYPE_TAXFLOW_FORM_TEXT:
          return (
            <TaxFlowFormTextItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_FORM_EMAIL:
          return (
            <TaxFlowFormTextItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_FORM_PHONE:
          return (
            <TaxFlowFormPhoneItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_FORM_NUMBER:
          return (
            <TaxFlowFormNumberItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD:
          return (
            <TaxFlowFormTextItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_MONEY:
          return (
            <TaxFlowFormMoneyItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
              overrideCollectionId={overrideCollectionId}
            />
          );
        case CATEGORY_TYPE_TWELVE_A:
          return (
            <TaxFlowFormTwelveAItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_BOX_14:
          return (
            <TaxFlowFormBox14Item
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_FORM_DATE:
          return (
            <TaxFlowFormDateItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_FORM_SSN:
          return (
            <TaxFlowFormSSNItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_INITIAL:
          return (
            <TaxFlowFormInitialItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_MILES:
          return (
            <TaxFlowFormMilesItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_YEARS:
          return (
            <TaxFlowFormYearsItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_PERCENT:
          return (
            <TaxFlowFormPercentItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_SQUARE_FOOTAGE:
          return (
            <TaxFlowFormSquareFootageItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_EIN:
          return (
            <TaxFlowFormEINItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_SSN_OR_EIN:
          return (
            <TaxFlowFormSSNOrEINItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_ZIP:
          return (
            <TaxFlowFormZipItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_INT:
          return (
            <TaxFlowFormIntItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_FORM_SLIDER:
          return (
            <TaxFlowFormSliderItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN:
          return (
            <TaxFlowFormDropdownItem
              {...this.props}
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_STATE:
          return (
            <TaxFlowFormDropdownItem
              {...this.props}
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_OPTIONS:
          return (
            <TaxFlowFormOptionItem
              {...this.props}
              question={{
                ...question,
                info: this.props.currentQuestion.info
              }}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
              setCurrentAnswer={this.props.setCurrentAnswer}
              fullAnswer={this.props.fullAnswer}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_MULTI_OPTION:
          return (
            <TaxFlowFormMultiOptionItem
              {...this.props}
              question={{
                ...question,
                info: this.props.currentQuestion.info
              }}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_INFO:
          return <TaxFlowFormInfoItem question={question} replaceStrings={this.props.replaceStrings} />;
        case CATEGORY_TYPE_TAXFLOW_CALENDAR:
          return (
            <TaxFlowFormCalendarItem
              {...this.props}
              question={question}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
              setCurrentAnswer={this.props.setCurrentAnswer}
            />
          );
        case CATEGORY_TYPE_TAXFLOW_ADDRESS:
          return (
            <TaxFlowFormAddressItem
              question={question}
              answer={answer}
              onFocus={(arg) => onFocus(arg)}
              onChange={(arg) => onChange(arg)}
              replaceStrings={this.props.replaceStrings}
            />
          );
        default:
          return null;
      }
    }
  }
}

const mapStateToProps = (state) => ({
  isW2Only: isW2OnlySelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowFormSubQuestionItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormSubQuestionItem);

export default ConnectedTaxFlowFormSubQuestionItem;
