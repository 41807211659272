import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {
  hideCollectionDeleteModal,
  deleteCollection,
  trackDeleteCollection
} from '@app/src/taxflow/collection/services/collectionService';
import { COLLECTION_TYPE__STATE_RETURN } from '@app/src/taxflow/sections/state/constants/stateConstants';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import { queryResultsSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { formUploadAttemptsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';

const TaxFlowCollectionDeleteModal = ({
  currentQuestion,
  collectionDeleteModalCollectionType,
  collectionDeleteModalCollectionId,
  hideCollectionDeleteModal,
  collectionDeleteModalSlug,
  queryResults,
  className,
  deleteCollection,
  onDelete = _.noop
}) => {
  const onClose = () => {
    hideCollectionDeleteModal();
  };

  const onCancelClick = () => {
    hideCollectionDeleteModal();
  };

  const onDeleteClick = async () => {
    if (collectionDeleteModalCollectionType === COLLECTION_TYPE__STATE_RETURN) {
      await deleteCollection({
        currentQuestion: currentQuestion,
        collectionType: collectionDeleteModalCollectionType,
        collectionId: collectionDeleteModalCollectionId,
        stateReturn: getQueryResultByEndpointAttribute({
          queryResults: queryResults,
          collectionType: collectionDeleteModalCollectionType,
          collectionId: collectionDeleteModalCollectionId,
          slug: collectionDeleteModalSlug
        })
      });
    } else {
      await deleteCollection({
        currentQuestion: currentQuestion,
        collectionType: collectionDeleteModalCollectionType,
        collectionId: collectionDeleteModalCollectionId
      });
    }
    hideCollectionDeleteModal();
    trackDeleteCollection({
      collectionType: collectionDeleteModalCollectionType,
      collectionId: collectionDeleteModalCollectionId
    });
    onDelete({ collectionType: collectionDeleteModalCollectionType, collectionId: collectionDeleteModalCollectionId });
  };

  return (
    <Dialog className={classNames(className)} onClose={onClose} open={true}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogActions>
        <Button variant='contained' type='button' onClick={onCancelClick}>
          Cancel
        </Button>
        <Button variant='outlined' type='button' onClick={onDeleteClick}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  queryResults: queryResultsSelector(state),
  collectionDeleteModalCollectionType: _.get(state, ['taxFlowModals', 'collectionDeleteModalCollectionType']),
  collectionDeleteModalCollectionId: _.get(state, ['taxFlowModals', 'collectionDeleteModalCollectionId']),
  collectionDeleteModalSlug: _.get(state, ['taxFlowModals', 'collectionDeleteModalSlug']),
  formUploadAttempts: formUploadAttemptsSelector(state)
});

const mapDispatchToProps = {
  hideCollectionDeleteModal,
  deleteCollection
};

const ConnectedTaxFlowCollectionDeleteModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowCollectionDeleteModal);

export default ConnectedTaxFlowCollectionDeleteModal;
