import _ from 'lodash';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import {
  Url_ONBOARDING_DURATION,
  Url_ONBOARDING_DURATION_DATE,
  Url_ONBOARDING_HOME,
  Url_ONBOARDING_INCOME_TYPE,
  Url_ONBOARDING_JOB_SELECT,
  Url_ONBOARDING_MEAL,
  Url_ONBOARDING_TRANSPORT,
  Url_ONBOARDING_TRAVEL,
  Url_SIGNUP,
  Url_SIGNUP_PHONE_ONLY
} from '@app/src/constants/onboardingConstants';
import { splitName } from '@app/src/global/Helpers';
import { workDetailsSelector } from '@app/src/selectors/workSelectors';
import {
  currentQuestionSelector,
  currentAnswerSelector,
  currentContextSelector
} from '@app/src/selectors/onboardingSelectors';
import { setCurrentAnswer } from '@app/src/actions/onboardingActions';
import parseNumberParam from '@app/src/utils/onboarding/parseNumberParam';

export const isW2OnlyPreSignup = () => {
  const workDetailsLocalStorage = getWorkInfoLocalStorage();
  const incomeTypesLocalStorage = _.get(workDetailsLocalStorage, 'income_type');
  return incomeTypesLocalStorage.length === 1 && incomeTypesLocalStorage[0] === 'salaried';
};

export const getWorkInfoLocalStorage = () => {
  try {
    const workInfo = JSON.parse(localStorage.getItem('workInfo'));
    const job_dates = _.get(workInfo, 'job_dates', []).map((job_date) => ({
      job: job_date.job,
      dates: job_date.dates
    }));
    const jobs = job_dates.map((job_date) => job_date.job);

    return {
      job_dates,
      jobs,
      income_type: _.get(workInfo, 'income_type', []),
      car: _.get(workInfo, 'car', null),
      home: _.get(workInfo, 'home', null),
      meals: _.get(workInfo, 'meals', null),
      travel: _.get(workInfo, 'travel', null),
      firstname: _.get(workInfo, 'firstname', ''),
      lastname: _.get(workInfo, 'lastname', ''),
      email: _.get(workInfo, 'email', ''),
      phone: _.get(workInfo, 'phone', '')
    };
  } catch (e) {
    defaultCaptureException(e);
    return {};
  }
};

const updateWorkInfoLocalStorage = (newWorkInfo) => {
  try {
    const workInfo = getWorkInfoLocalStorage();
    const fullWorkInfo = {
      ...workInfo,
      ...newWorkInfo
    };
    const computedWorkInfo = {
      ...fullWorkInfo,
      jobs: _.get(fullWorkInfo, 'job_dates', []).map((job_date) => job_date.job)
    };
    localStorage.setItem('workInfo', JSON.stringify(computedWorkInfo));
  } catch (e) {
    defaultCaptureException(e);
    localStorage.setItem('workInfo', JSON.stringify({}));
  }
};

export const getAnswerIncomeType = ({ workDetails }) => {
  const value = workDetails.income_type;
  return {
    value
  };
};

const getAnswerJobSelection = ({ workDetails }) => {
  const allJobs = workDetails.job_dates.map((job_date) => job_date.job);
  const jobs = allJobs.filter((job) => !/^other:/.test(job));
  const other = allJobs.filter((job) => /^other:/.test(job)).map((job) => job.slice('other:'.length))[0] || '';

  return {
    value: jobs,
    other
  };
};

const getAnswerJobNames = ({ workDetails }) => {
  const allJobs = workDetails.job_dates.map((job_date) => job_date.job);
  return allJobs;
};

const getAnswerJobDuration = ({ workDetails, jobIndex }) => {
  const job_date = workDetails.job_dates[jobIndex];

  if (_.isEmpty(_.get(job_date, 'dates'))) {
    return {};
  } else {
    const year = _.get(job_date, 'dates.year', '');
    const month = _.get(job_date, 'dates.month', '');

    return {
      value: {
        [Url_ONBOARDING_DURATION_DATE]: {
          slug: Url_ONBOARDING_DURATION_DATE,
          value: year + (month ? `-${month}` : '')
        }
      }
    };
  }
};

const getAnswerByName = ({ workDetails, name }) => {
  if (_.isNil(workDetails[name])) {
    return {};
  } else {
    return {
      value: workDetails[name] === 1 ? '1' : '0'
    };
  }
};

const getAnswerSignup = ({ workDetails }) => {
  return {
    value: {
      'signup-name': {
        slug: 'signup-name',
        value: (_.get(workDetails, 'firstname', '') + ' ' + _.get(workDetails, 'lastname', '')).trim()
      },
      'signup-email': {
        slug: 'signup-email',
        value: _.get(workDetails, 'email', '')
      }
    }
  };
};

const getAnswerSignupPhoneOnly = ({ workDetails }) => {
  return {
    value: {
      'signup-phone-only-phone': {
        slug: 'signup-phone-only-phone',
        value: _.get(workDetails, 'phone', '')
      }
    }
  };
};

const updateAnswerIncomeType = ({ answer }) => {
  updateWorkInfoLocalStorage({
    income_type: answer.value
  });
};

export const updateAnswerJobSelection = ({ answer, workDetails }) => {
  const defaultOrSelectedJobs = _.isEmpty(answer.value) ? ['other'] : answer.value;

  const allJobs = [...defaultOrSelectedJobs, ...(_.isEmpty(answer.other) ? [] : [`other:${answer.other}`])];

  const new_job_dates = allJobs.map((job) => {
    const index = workDetails.job_dates.findIndex((job_date) => job_date.job === job);
    if (index === -1) {
      return {
        job,
        dates: []
      };
    } else {
      return workDetails.job_dates[index];
    }
  });
  updateWorkInfoLocalStorage({
    job_dates: new_job_dates
  });
};

const updateAnswerJobDuration = ({ answer, workDetails }) => {
  const date = _.get(answer, ['value', Url_ONBOARDING_DURATION_DATE, 'value']);

  let year, month;
  if (date) {
    [year, month] = date.split('-');
  }

  const new_job_dates = workDetails.job_dates.map((job_date) => {
    return {
      ...job_date,
      dates: { year, month: year !== 'all' ? month : null }
    };
  });
  updateWorkInfoLocalStorage({
    job_dates: new_job_dates
  });
};

const updateAnswerByName = ({ answer, name }) => {
  updateWorkInfoLocalStorage({
    [name]: `${answer.value}` === '1' ? 1 : 0
  });
};

const updateAnswerSignup = ({ answer }) => {
  const [firstname, lastname] = splitName(_.get(answer, ['value', 'signup-name', 'value'], ''));

  updateWorkInfoLocalStorage({
    firstname,
    lastname,
    email: _.get(answer, ['value', 'signup-email', 'value'], '')
  });
};

const updateAnswerPhoneOnlySignup = ({ answer }) => {
  updateWorkInfoLocalStorage({
    phone: _.get(answer, ['value', 'signup-phone-only-phone', 'value'], '')
  });
};

export const getAnswerLocalStorage =
  ({ slug, jobNumberParam }) =>
  async (dispatch, getState) => {
    const workDetails = workDetailsSelector(getState());

    let answer = {};
    if (slug === Url_ONBOARDING_INCOME_TYPE) {
      answer = getAnswerIncomeType({ workDetails });
    } else if (slug === Url_ONBOARDING_JOB_SELECT) {
      answer = getAnswerJobSelection({ workDetails });
    } else if (slug === Url_ONBOARDING_DURATION) {
      const jobNames = getAnswerJobNames({ workDetails });
      const jobIndex = parseNumberParam({
        numberParam: jobNumberParam,
        numberParamCount: jobNames.length
      });
      answer = getAnswerJobDuration({ workDetails, jobIndex });
    } else if (slug === Url_ONBOARDING_TRANSPORT) {
      answer = getAnswerByName({ workDetails, name: 'car' });
    } else if (slug === Url_ONBOARDING_HOME) {
      answer = getAnswerByName({ workDetails, name: 'home' });
    } else if (slug === Url_ONBOARDING_MEAL) {
      answer = getAnswerByName({ workDetails, name: 'meals' });
    } else if (slug === Url_ONBOARDING_TRAVEL) {
      answer = getAnswerByName({ workDetails, name: 'travel' });
    } else if (slug === Url_SIGNUP) {
      answer = getAnswerSignup({ workDetails });
    } else if (slug === Url_SIGNUP_PHONE_ONLY) {
      answer = getAnswerSignupPhoneOnly({ workDetails });
    }
    dispatch(setCurrentAnswer(answer));
  };

export const updateAnswerLocalStorage = () => async (dispatch, getState) => {
  const question = currentQuestionSelector(getState());
  const answer = currentAnswerSelector(getState());
  const context = currentContextSelector(getState());
  const workDetails = workDetailsSelector(getState());

  if (question.slug === Url_ONBOARDING_INCOME_TYPE) {
    updateAnswerIncomeType({ answer, workDetails });
  } else if (question.slug === Url_ONBOARDING_JOB_SELECT) {
    updateAnswerJobSelection({ answer, workDetails });
  } else if (question.slug === Url_ONBOARDING_DURATION) {
    updateAnswerJobDuration({ answer, workDetails, jobIndex: context.jobIndex });
  } else if (question.slug === Url_ONBOARDING_TRANSPORT) {
    updateAnswerByName({ answer, name: 'car' });
  } else if (question.slug === Url_ONBOARDING_HOME) {
    updateAnswerByName({ answer, name: 'home' });
  } else if (question.slug === Url_ONBOARDING_MEAL) {
    updateAnswerByName({ answer, name: 'meals' });
  } else if (question.slug === Url_ONBOARDING_TRAVEL) {
    updateAnswerByName({ answer, name: 'travel' });
  } else if (question.slug === Url_SIGNUP) {
    updateAnswerSignup({ answer });
  } else if (question.slug === Url_SIGNUP_PHONE_ONLY) {
    updateAnswerPhoneOnlySignup({ answer });
  }
};
