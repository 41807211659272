import Skeleton from '@mui/material/Skeleton';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { contentSelector, initialLoadSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { getStringSubstitutions } from '@app/src/utils/holisticOnboardingUtils';
import { colorLightWhite } from '@app/src/theme';

const QuestionHeader = ({ content, getStringSubstitutions, initialLoad }) => {
  const { pathname } = useLocation();

  const title = _.get(content, `${pathname}.title`, '');
  const subtitle = _.get(content, `${pathname}.subtitle`, '');

  const formattedTitle = getStringSubstitutions(title);
  const formattedSubtitle = getStringSubstitutions(subtitle);

  return (
    <div style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: colorLightWhite }}>
      <div className='h-onboarding-content-question-title'>
        {initialLoad ? (
          <Skeleton variant='text' width={300} height={40} animation='pulse' />
        ) : (
          <div>{formattedTitle}</div>
        )}
      </div>
      <div className='h-onboarding-content-question-subtitle'>
        {initialLoad ? (
          <Skeleton variant='text' width={150} height={20} animation='pulse' />
        ) : (
          <>{subtitle && <div dangerouslySetInnerHTML={{ __html: formattedSubtitle }} />}</>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  content: contentSelector(state),
  initialLoad: initialLoadSelector(state)
});

const mapDispatchToProps = {
  getStringSubstitutions
};

const ConnectedQuestionHeader = connect(mapStateToProps, mapDispatchToProps)(QuestionHeader);

export default ConnectedQuestionHeader;
