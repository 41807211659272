import React, { forwardRef } from 'react';
import AssistantMessage from '@app/src/Components/Assistant/AssistantMessage';
import AssistantSystemMessage from '@app/src/Components/Assistant/AssistantSystemMessage';
import AssistantMessageAttachments from '@app/src/Components/Assistant/AssistantMessageAttachments';

const AssistantVirtualizedMessages = forwardRef(({ virtualizer, items, getImage }, ref) => {
  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div className='assistant-messages' ref={ref}>
      <div
        className='assistant-messages-items'
        style={{
          height: virtualizer.getTotalSize()
        }}
      >
        {virtualItems.map((virtualRow) => {
          const item = items[virtualRow.index];

          const style = {
            top: virtualRow.start
          };

          switch (item.type) {
            case 'message': {
              const { message } = item.payload;

              return (
                <AssistantMessage
                  key={virtualRow.index}
                  message={message}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                  style={style}
                />
              );
            }
            case 'attachments': {
              const { message } = item.payload;

              return (
                <AssistantMessageAttachments
                  key={virtualRow.index}
                  role={message.sender === 'user' ? 'user' : 'assistant'}
                  attachments={message.attachments}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                  style={style}
                  getImage={getImage}
                />
              );
            }
            case 'error': {
              const { error } = item.payload;

              return (
                <AssistantSystemMessage
                  key={virtualRow.index}
                  ref={virtualizer.measureElement}
                  data-index={virtualRow.index}
                  style={style}
                  error
                >
                  {error}
                </AssistantSystemMessage>
              );
            }
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
});

export default AssistantVirtualizedMessages;
