import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import TaxFlowQuestionItem from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionItem';
import { replaceStrings } from '@app/src/taxflow/common';
import { scrollToTop } from '@app/src/utils';
import { errorsSelector, isTaxFilingNextEnabledSelector } from '@app/src/selectors/taxValidationSelectors';
import {
  currentAnswerSelector,
  currentCollectionIdSelector,
  currentQuestionSelector,
  queryResultsSelector,
  statusSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import {
  PATH_COMPONENT__SUBMIT_FINISH,
  SLUG__SUBMIT_FINISH
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { showNextBtn } from '@app/src/taxflow/main/utils/mainUtils';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { isQuestionnaireFlowSelector } from '@app/src/selectors/taxFlowSelectors';
import { goBackToPreviousQuestionnaireQuestion } from '@app/src/services/questionnaireService';

class TaxFlowQuestion extends React.Component {
  onPrev = async () => {
    if (this.props.onPrev) {
      this.props.onPrev();
      return;
    }

    if (this.props.currentQuestion.slug === SLUG__SUBMIT_FINISH) {
      this.props.history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_FINISH}`);
      return;
    }

    // If "back" is clicked while in the questionnaire flow, navigate to the previous questionnaire question.
    // If the user exits the flow (occurs if they are on the first question), navigate back to bulk upload
    if (this.props.isQuestionnaireFlow) {
      const { questionnaireExited } = await this.props.goBackToPreviousQuestionnaireQuestion();
      if (!questionnaireExited) {
        return;
      }
    }

    this.props.history.goBack();
  };

  onNext = () => {
    const { onNext } = this.props;

    if (onNext) {
      onNext();
      return;
    }
  };

  onFocus = (question, collectionId) => {
    const collectionType = _.get(question, 'collectionType', _.get(this.props.currentQuestion, 'collectionType'));
    const endpoint_attr = _.get(question, 'endpoint_attr', _.get(this.props.currentQuestion, 'endpoint_attr'));

    const error = _.find(this.props.errors, {
      coll_type: collectionType,
      slug: endpoint_attr,
      coll_id: collectionId || this.props.currentCollectionId
    });

    if (error) {
      _.chain(this.props.errors)
        .filter(
          (err) => !(err.slug === error.slug && err.coll_type === error.coll_type && err.coll_id === error.coll_id)
        )
        .tap(this.props.setErrors)
        .value();
    }
  };

  componentDidMount() {
    scrollToTop();
  }

  render() {
    const { currentQuestion, isTaxFilingNextEnabled, ...props } = this.props;

    return (
      <div className={'taxflow-box-wrap'}>
        <div className='taxflow-box-wrap-left-spacer' />
        <TaxFlowQuestionItem
          isPrev
          {...props}
          urlObj={{}}
          isNext={showNextBtn({ slug: currentQuestion.slug })}
          onNextQuest={this.onNext}
          onPrev={this.onPrev}
          onNext={this.onNext}
          onFocus={this.onFocus}
          isNextDisabled={!isTaxFilingNextEnabled}
          replaceStrings={this.props.replaceStrings}
        />
        <div className='taxflow-box-wrap-right-spacer' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bank: state.bank,
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: currentAnswerSelector(state),
  queryResults: queryResultsSelector(state),
  currentCollectionId: currentCollectionIdSelector(state),
  isTaxFilingNextEnabled: isTaxFilingNextEnabledSelector(state),
  status: statusSelector(state),
  errors: errorsSelector(state),
  isQuestionnaireFlow: isQuestionnaireFlowSelector(state)
});

const mapDispatchToProps = {
  replaceStrings,
  setErrors,
  goBackToPreviousQuestionnaireQuestion
};

const ConnectedTaxFlowQuestion = connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxFlowQuestion));

export default ConnectedTaxFlowQuestion;
