import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import url from 'url';
import qs from 'qs';
import { CAPITAL_ONE_HOST, CAPITAL_ONE_CLIENT_ID, appUrl } from '@app/src/global/Environment';
import getImageUrl from '@app/src/utils/getImageUrl';
import '@app/src/Components/CapitalOnePrompt/CapitalOnePrompt.scss';

const CapitalOnePrompt = ({ className, location }) => {
  const type = qs.parse(location.search, { ignoreQueryPrefix: true }).type;
  const item_id = qs.parse(location.search, { ignoreQueryPrefix: true }).item_id;

  const onClick = () => {
    const redirectUrl = url.format({
      protocol: 'https:',
      host: CAPITAL_ONE_HOST,
      pathname: '/oauth2/authorize',
      query: {
        client_id: CAPITAL_ONE_CLIENT_ID,
        redirect_uri: `${appUrl()}/capital-one-redirect`,
        scope: 'read_transactions',
        response_type: 'code',
        state: JSON.stringify({
          type,
          ...(!_.isNil(item_id)
            ? {
                item_id
              }
            : {})
        })
      }
    });

    window.location.assign(redirectUrl);
  };

  return (
    <div className={classNames('capital-one-prompt', className)}>
      <img
        className='capital-one-prompt-logo'
        src={getImageUrl('capital-one-logo-white.svg')}
        alt='Capital One'
        width='130'
        height='47'
      />
      <div className='capital-one-prompt-title'>We’re redirecting you to the Capital One login page</div>
      <button type='button' className='btn btn-primary capital-one-prompt-button' onClick={onClick}>
        Continue
      </button>
    </div>
  );
};

export default CapitalOnePrompt;
