import React from 'react';
import { connect } from 'react-redux';
import WarningIcon from '@app/src/assets/warning.svg?react';
import CloseIcon from '@app/src/assets/close.svg?react';
import { dismissNonBlockingError } from '@app/src/services/taxFlowService';
import '@app/src/Components/TaxValidation/TaxValidationNonBlockingWarning.scss';

const TaxValidationNonBlockingWarning = ({
  warningMessage,
  validationSlug,
  coll_type,
  coll_id,
  dismissNonBlockingError
}) => {
  const onDismiss = () => {
    dismissNonBlockingError({ validationSlug, coll_type, coll_id });
  };

  return (
    <div className='validation-non-blocking-warning'>
      <div className='validation-non-blocking-warning-icon'>
        <WarningIcon width={20} height={20} />
      </div>
      <div className='validation-non-blocking-warning-message'>{warningMessage}</div>
      <div className='validation-non-blocking-warning-close' onClick={onDismiss}>
        <CloseIcon width={24} height={24} />
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  dismissNonBlockingError
};

const ConnectedTaxValidationNonBlockingWarning = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxValidationNonBlockingWarning);

export default ConnectedTaxValidationNonBlockingWarning;
