import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  currentCollectionIdSelector,
  currentCollectionTypeSelector,
  currentQuestionSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { summaryItemsSelector } from '@app/src/selectors/summarySelectors';
import { SLUG__BULK_UPLOAD } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  TAXFLOW__FORM_UPLOAD_COMPOSITE_SUMMARY_ITEM,
  TAXFLOW__FORM_UPLOAD_DELETED_STATES,
  TAXFLOW__FORM_UPLOAD_FAILED_STATES,
  TAXFLOW__FORM_UPLOAD_PENDING_STATES
} from '@app/src/taxflow/collection/constants/formUploadConstants';

export const bulkUploadItemsSelector = (state) => _.get(state, ['taxFlow', 'bulkUploadItems']);
export const bulkUploadPillsSelector = (state) => _.get(state, ['taxFlow', 'bulkUploadPills']);
export const questionnaireSummaryPillsSelector = (state) => _.get(state, ['taxFlow', 'questionnaireSummaryPills'], []);
export const reviewPillsSelector = (state) => _.get(state, ['taxFlow', 'reviewPills'], []);
export const addMoreItemsSelector = (state) => _.get(state, ['taxFlow', 'addMoreItems'], []);

export const formUploadAttemptsSelector = (state) => _.get(state, ['taxFlow', 'formUploadAttempts']);

export const flattenedUploadAttemptsSelector = createSelector([formUploadAttemptsSelector], (uploadAttemptsMap) =>
  _.flatMap(_.values(uploadAttemptsMap), (collectionType) => _.values(collectionType))
);

export const nonDeletedUploadAttemptsSelector = createSelector([flattenedUploadAttemptsSelector], (attempts) =>
  attempts.filter((x) => !TAXFLOW__FORM_UPLOAD_DELETED_STATES.includes(x.status))
);

const prefilledUploadAttemptsSelector = createSelector([flattenedUploadAttemptsSelector], (attempts) =>
  attempts.filter((x) => x.status === 'prefilled')
);

export const failedUploadAttemptsSelector = createSelector([flattenedUploadAttemptsSelector], (attempts) =>
  attempts.filter((x) => TAXFLOW__FORM_UPLOAD_FAILED_STATES.includes(x.status))
);

export const allCollectionTypesSelector = createSelector([summaryItemsSelector], (summaryItems) => {
  return [
    ..._.flatMap(_.values(summaryItems), (collectionType) => _.values(collectionType)),
    TAXFLOW__FORM_UPLOAD_COMPOSITE_SUMMARY_ITEM
  ];
});

export const isBulkUploadSelector = createSelector([currentQuestionSelector], (currentQuestion) => {
  return currentQuestion.slug === SLUG__BULK_UPLOAD;
});

export const isCollectionUploadedSelector = (collectionId, collectionType) =>
  createSelector([prefilledUploadAttemptsSelector], (prefilledAttempts) => {
    return _.some(prefilledAttempts, { collectionType, collectionId: parseInt(collectionId) });
  });

export const isCurrentCollectionUploadedSelector = createSelector(
  [prefilledUploadAttemptsSelector, currentCollectionTypeSelector, currentCollectionIdSelector],
  (prefilledAttempts, collectionType, collectionId) => {
    return _.some(prefilledAttempts, { collectionType, collectionId: parseInt(collectionId) });
  }
);

export const isCurrentCollectionAccessibleInBulkSelector = createSelector(
  [bulkUploadPillsSelector, currentCollectionTypeSelector, currentCollectionIdSelector],
  (bulkUploadPills, collectionType, collectionId) => {
    return _.some(bulkUploadPills, { collectionType, collectionId });
  }
);

export const bulkUploadErrorAttemptsSelector = createSelector(
  [allCollectionTypesSelector, failedUploadAttemptsSelector],
  (allCollectionTypes, failedUploadAttempts) => {
    const collectionTypeSlugs = allCollectionTypes.map((x) => x.collectionType);
    return failedUploadAttempts.filter((attempt) => collectionTypeSlugs.includes(attempt.collectionType));
  }
);

export const unclassifiedUploadsSelector = createSelector(
  [nonDeletedUploadAttemptsSelector],
  (nonDeletedUploadAttempts) => {
    return nonDeletedUploadAttempts.filter(
      (upload) => upload.isBulk && !upload.collectionType && TAXFLOW__FORM_UPLOAD_PENDING_STATES.includes(upload.status)
    );
  }
);

export const formUploadStatusSelector = (state) => _.get(state, ['taxFlow', 'formUploadStatus']);

/** Select upload files, keyed by a temp upload attempt id and then page */
export const formUploadKeyedFilesSelector = (state) => _.get(state, ['taxFlow', 'formUploadKeyedFiles']);

export const dropzoneLoadingSelector = (state) => _.get(state, ['taxFlow', 'dropzoneLoading']);

export const dropzoneFilesSelector = (state) => _.get(state, ['taxFlow', 'dropzoneFiles']);

export const dropzoneFilesReadyForUploadSelector = (state) => _.get(state, ['taxFlow', 'dropzoneFilesReadyForUpload']);

export const dropzoneFilesSameFormSelector = (state) => _.get(state, ['taxFlow', 'dropzoneFilesSameForm']);

export const formUploadModalHeaderSelector = (state) =>
  _.get(state, ['taxFlowModals', 'collectionFormUploadErrorModalHeader']);
