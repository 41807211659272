import _ from 'lodash';
import { geocodeByAddress } from 'react-places-autocomplete';
import {
  SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STREET,
  SLUG__INCOME_W2G_ADDRESS_CITY,
  SLUG__INCOME_W2G_ADDRESS_STATE,
  SLUG__INCOME_W2G_ADDRESS_ZIP,
  SLUG__INCOME_FREELANCE_ADDRESS_CITY,
  SLUG__INCOME_FREELANCE_ADDRESS_STATE,
  SLUG__INCOME_FREELANCE_ADDRESS_ZIP,
  SLUG__INCOME_W2_EMPLOYER_ADDRESS_ZIP,
  SLUG__INCOME_W2_EMPLOYER_ADDRESS_STATE,
  SLUG__INCOME_W2_EMPLOYER_ADDRESS_CITY,
  SLUG__INCOME_W2_EMPLOYER_ADDRESS_APT,
  SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_CITY,
  SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_ZIP,
  SLUG__INCOME_W2G_ADDRESS_STREET,
  SLUG__INCOME_FREELANCE_ADDRESS_STREET,
  SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STATE,
  SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_STREET,
  SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_APT,
  SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_CITY,
  SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_STATE,
  SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_ZIP,
  SLUG__INCOME_W2_EMPLOYER_ADDRESS_STREET,
  ADDRESS_SLUG_SUFFIXES
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  SLUG__HOME_ADDRESS_STREET,
  SLUG__HOME_ADDRESS_CITY,
  SLUG__HOME_ADDRESS_STATE,
  SLUG__HOME_ADDRESS_ZIP,
  SLUG__HOME_ADDRESS_CITY_MULTISTATE,
  SLUG__HOME_ADDRESS_ZIP_MULTISTATE,
  SLUG__HOME_ADDRESS_STATE_MULTISTATE,
  SLUG__HOME_ADDRESS_APT_MULTISTATE,
  SLUG__HOME_ADDRESS_STREET_MULTISTATE,
  SLUG__HOME_ADDRESS_APT,
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS,
  ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION,
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED,
  ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  SLUG__DC_RENT_CREDIT_LANDLORD_STREET,
  SLUG__DC_RENT_CREDIT_LANDLORD_ZIP,
  SLUG__HI_RENT_CREDIT_STREET,
  SLUG__HI_RENT_CREDIT_APT,
  SLUG__HI_RENT_CREDIT_CITY,
  SLUG__HI_RENT_CREDIT_ZIP,
  SLUG__HI_RENT_CREDIT_OWNER_STREET,
  SLUG__HI_RENT_CREDIT_OWNER_APT,
  SLUG__HI_RENT_CREDIT_OWNER_CITY,
  SLUG__HI_RENT_CREDIT_OWNER_ZIP
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__CREDIT_CHILD_CARE_APT,
  SLUG__CREDIT_CHILD_CARE_CITY,
  SLUG__CREDIT_CHILD_CARE_STATE,
  SLUG__CREDIT_CHILD_CARE_STREET,
  SLUG__CREDIT_CHILD_CARE_ZIP,
  SLUG__CREDIT_HOMEOWNER_LENDER_CITY,
  SLUG__CREDIT_HOMEOWNER_LENDER_STATE,
  SLUG__CREDIT_HOMEOWNER_LENDER_STREET,
  SLUG__CREDIT_HOMEOWNER_LENDER_ZIP,
  SLUG__CREDIT_HOMEOWNER_SECURING_CITY,
  SLUG__CREDIT_HOMEOWNER_SECURING_STATE,
  SLUG__CREDIT_HOMEOWNER_SECURING_STREET,
  SLUG__CREDIT_HOMEOWNER_SECURING_ZIP,
  SLUG__CREDIT_STUDENT_TUITION_CITY,
  SLUG__CREDIT_STUDENT_TUITION_STATE,
  SLUG__CREDIT_STUDENT_TUITION_STREET,
  SLUG__CREDIT_STUDENT_TUITION_ZIP
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { DEFAULT_COLLECTION_ID, STATES } from '@app/src/taxflow/shared/constants/sharedConstants';

export const getQueryResultByEndpointAttribute = ({ queryResults, collectionType, collectionId, slug }) => {
  return queryResults.find(
    (queryResult) =>
      queryResult.coll_type === collectionType && queryResult.coll_id === collectionId && queryResult.slug === slug
  );
};

export const getQueryResultValueByEndpointattribute = (...props) => {
  const result = getQueryResultByEndpointAttribute(...props);

  return _.get(result, 'answer.value');
};

export const getQueryResultsByEndpointAttribute = ({ queryResults, collectionType, slug }) => {
  return queryResults.filter((queryResult) => queryResult.coll_type === collectionType && queryResult.slug === slug);
};

export const isCalendarMonths = (question) => {
  return _.isEmpty(question.question_meta);
};

export const isPlatform = (platform) => {
  let detected;
  if (window.ReactNativeWebView) {
    if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
      detected = 'ios';
    } else {
      detected = 'android';
    }
  }

  return platform === detected;
};

export const formatAutoFillAddress = async (address) => {
  const geocodeAddress = await geocodeByAddress(address);
  const formattedAddress = _.get(geocodeAddress, [0, 'address_components']);

  const streetNumber = formattedAddress.find((x) => x.types.includes('street_number'));
  const streetName = formattedAddress.find((x) => x.types.includes('route'));
  const apt = formattedAddress.find((x) => x.types.includes('subpremise'));
  const city =
    formattedAddress.find((x) => x.types.includes('locality')) ||
    formattedAddress.find((x) => x.types.includes('neighborhood'));
  const state = formattedAddress.find((x) => x.types.includes('administrative_area_level_1'));
  const zip = formattedAddress.find((x) => x.types.includes('postal_code'));
  const validState = STATES.map((x) => x.code).includes(_.get(state, ['short_name']))
    ? _.get(state, ['short_name'])
    : null;
  const validZip = _.get(zip, ['long_name'], '').length === 5 ? _.get(zip, ['long_name']) : null;

  return {
    street: `${_.get(streetNumber, ['long_name'], '')} ${_.get(streetName, ['long_name'], '')}`,
    apt: _.get(apt, ['long_name']),
    city: _.get(city, ['long_name']),
    state: validState,
    zip: validZip
  };
};

export const isItin = (string) => {
  if (!string) return false;
  if (string[0] === '9') {
    return true;
  }
  return false;
};

export const getQuestionOcrError = ({ errors, question, collectionId }) => {
  return errors.find(
    (error) =>
      error.coll_type === question.collectionType &&
      error.coll_id === collectionId &&
      error.slug === question.endpoint_attr &&
      error.code === 'ocr_error'
  );
};

export const getCollectionOcrError = ({ errors, collectionType, collectionId }) => {
  return errors.find(
    (error) => error.coll_type === collectionType && error.coll_id === collectionId && error.code === 'ocr_error'
  );
};

export const getQuestionOcrAddressErrors = ({ errors, question, collectionId }) => {
  return errors.filter(
    (error) =>
      error.coll_type === question.collectionType &&
      error.coll_id === collectionId &&
      ADDRESS_SLUG_SUFFIXES.some((suffix) => error.slug.endsWith(suffix)) &&
      error.code === 'ocr_error'
  );
};

const streetToOtherAddressSlugs = {
  [SLUG__HOME_ADDRESS_STREET]: {
    apt: SLUG__HOME_ADDRESS_APT,
    city: SLUG__HOME_ADDRESS_CITY,
    state: SLUG__HOME_ADDRESS_STATE,
    zip: SLUG__HOME_ADDRESS_ZIP
  },
  [SLUG__HOME_ADDRESS_STREET_MULTISTATE]: {
    city: SLUG__HOME_ADDRESS_CITY_MULTISTATE,
    apt: SLUG__HOME_ADDRESS_APT_MULTISTATE,
    state: SLUG__HOME_ADDRESS_STATE_MULTISTATE,
    zip: SLUG__HOME_ADDRESS_ZIP_MULTISTATE
  },
  [SLUG__INCOME_W2G_ADDRESS_STREET]: {
    city: SLUG__INCOME_W2G_ADDRESS_CITY,
    state: SLUG__INCOME_W2G_ADDRESS_STATE,
    zip: SLUG__INCOME_W2G_ADDRESS_ZIP
  },
  [SLUG__INCOME_FREELANCE_ADDRESS_STREET]: {
    city: SLUG__INCOME_FREELANCE_ADDRESS_CITY,
    state: SLUG__INCOME_FREELANCE_ADDRESS_STATE,
    zip: SLUG__INCOME_FREELANCE_ADDRESS_ZIP
  },
  [SLUG__INCOME_W2_EMPLOYER_ADDRESS_STREET]: {
    apt: SLUG__INCOME_W2_EMPLOYER_ADDRESS_APT,
    city: SLUG__INCOME_W2_EMPLOYER_ADDRESS_CITY,
    state: SLUG__INCOME_W2_EMPLOYER_ADDRESS_STATE,
    zip: SLUG__INCOME_W2_EMPLOYER_ADDRESS_ZIP
  },
  [SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STREET]: {
    city: SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_CITY,
    state: SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_STATE,
    zip: SLUG__INCOME_UNEMPLOYMENT_PAYER_ADDRESS_ZIP
  },
  [SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_STREET]: {
    apt: SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_APT,
    city: SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_CITY,
    state: SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_STATE,
    zip: SLUG__INCOME_RETIREMENT_PENSION_PAYER_ADDRESS_ZIP
  },
  [SLUG__CREDIT_CHILD_CARE_STREET]: {
    apt: SLUG__CREDIT_CHILD_CARE_APT,
    city: SLUG__CREDIT_CHILD_CARE_CITY,
    state: SLUG__CREDIT_CHILD_CARE_STATE,
    zip: SLUG__CREDIT_CHILD_CARE_ZIP
  },
  [SLUG__CREDIT_STUDENT_TUITION_STREET]: {
    city: SLUG__CREDIT_STUDENT_TUITION_CITY,
    state: SLUG__CREDIT_STUDENT_TUITION_STATE,
    zip: SLUG__CREDIT_STUDENT_TUITION_ZIP
  },
  [SLUG__CREDIT_HOMEOWNER_LENDER_STREET]: {
    city: SLUG__CREDIT_HOMEOWNER_LENDER_CITY,
    state: SLUG__CREDIT_HOMEOWNER_LENDER_STATE,
    zip: SLUG__CREDIT_HOMEOWNER_LENDER_ZIP
  },
  [SLUG__CREDIT_HOMEOWNER_SECURING_STREET]: {
    city: SLUG__CREDIT_HOMEOWNER_SECURING_CITY,
    state: SLUG__CREDIT_HOMEOWNER_SECURING_STATE,
    zip: SLUG__CREDIT_HOMEOWNER_SECURING_ZIP
  },
  [SLUG__DC_RENT_CREDIT_LANDLORD_STREET]: {
    zip: SLUG__DC_RENT_CREDIT_LANDLORD_ZIP
  },
  [SLUG__HI_RENT_CREDIT_STREET]: {
    apt: SLUG__HI_RENT_CREDIT_APT,
    city: SLUG__HI_RENT_CREDIT_CITY,
    zip: SLUG__HI_RENT_CREDIT_ZIP
  },
  [SLUG__HI_RENT_CREDIT_OWNER_STREET]: {
    apt: SLUG__HI_RENT_CREDIT_OWNER_APT,
    city: SLUG__HI_RENT_CREDIT_OWNER_CITY,
    zip: SLUG__HI_RENT_CREDIT_OWNER_ZIP
  }
};

export const getMappedAddressSlugs = ({ slug: streetSlug }) => {
  const types = ['apt', 'city', 'state', 'zip'];
  return types.reduce(
    (acc, type) => {
      const value = _.get(streetToOtherAddressSlugs, [streetSlug, type]);
      if (value) acc[type] = value;
      return acc;
    },
    { street: streetSlug }
  );
};

export const getKnownJobInfo = (jobCategories, jobName) => {
  const jobValue = (jobName || '').toLowerCase();
  return jobCategories.find((j) => j.slug.toLowerCase() === jobValue || j.name.toLowerCase() === jobValue);
};

export const getFilingStatus = ({ updates = [], queryResults }) => {
  const married = getMostRecentValue({
    updates,
    queryResults,
    collectionType: COLLECTION_TYPE__SELF,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS
  });

  if (_.isEmpty(married)) return null;

  if (married === '1') {
    return getMarriedStatus({ updates, queryResults });
  } else {
    return getUnmarriedStatus({ updates, queryResults });
  }
};

const getMostRecentValue = ({ updates, queryResults, collectionType, collectionId, slug }) => {
  const update = _.find(updates, { slug, coll_type: collectionType, coll_id: collectionId });

  if (update) {
    return _.get(update, 'value');
  }

  return getQueryResultValueByEndpointattribute({
    queryResults,
    collectionType,
    collectionId,
    slug
  });
};

const getMarriedStatus = ({ updates, queryResults }) => {
  const filingOption = getMostRecentValue({
    updates,
    queryResults,
    collectionType: COLLECTION_TYPE__SELF,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION
  });

  switch (filingOption) {
    case 'filing-jointly':
      return 'married';
    case 'filing-separately':
      return 'married_separate';
    default:
      return null;
  }
};

const getUnmarriedStatus = ({ updates, queryResults }) => {
  const isHeadOfHousehold = [queryResults, updates].some(anyDependentNotDisqualified({ updates, queryResults }));

  return isHeadOfHousehold ? 'head' : 'single';
};

const anyDependentNotDisqualified =
  ({ updates, queryResults }) =>
  (inputs) => {
    const startedDependents = _.filter(inputs, {
      coll_type: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED
    });

    return startedDependents.some(isStartedDependentNotDisqualified({ updates, queryResults }));
  };

const isStartedDependentNotDisqualified =
  ({ updates, queryResults }) =>
  (input) => {
    const dependentQualified = getMostRecentValue({
      updates,
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      collectionId: input.coll_id,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED
    });

    return dependentQualified !== '0';
  };
