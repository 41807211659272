import LoginHeader from '@app/src/Components/Login/LoginWithOTP/LoginHeader';
import LoginOtp from '@app/src/Components/Login/LoginWithOTP/LoginOtp';
import LoginResend from '@app/src/Components/Login/LoginWithOTP/LoginResend';
import '@app/src/Components/Login/LoginWithOTP/LoginVerify.scss';
import LoginWindow from '@app/src/Components/Login/LoginWithOTP/LoginWindow';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { validateLoginOtp } from '@app/src/services/workService';
import LoadingButton from '@mui/lab/LoadingButton';
import classNames from 'classnames';
import _ from 'lodash';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import url from 'url';

const LoginVerify = ({ className, location, loading, validateLoginOtp }) => {
  const [otp, setOtp] = useState('');

  const type = _.get(qs.parse(location.search, { ignoreQueryPrefix: true }), 'type');
  const id = _.get(qs.parse(location.search, { ignoreQueryPrefix: true }), 'id');

  useEffect(() => {
    trackActivity('navigation: sign in verify');
  }, []);

  const validateOtp = async (otp) => {
    const otpInt = parseInt(otp, 10);
    const validateObj = type === 'phone' ? { phone: id } : { email: id };

    await validateLoginOtp({
      ...validateObj,
      token: otpInt
    });
  };

  const onContinue = async () => {
    await validateOtp(otp);
  };

  const onAltSignInClick = (type) => {
    history.push(url.format({ pathname: type === 'phone' ? '/login-email' : '/login' }));
  };

  return (
    <LoginWindow className={classNames('login-verify', className)}>
      <LoginHeader
        className='login-verify-header'
        title='Enter security code'
        subtitle={`We've sent you a 6-digit code to your ${
          type === 'phone' ? 'phone number' : 'email'
        }. Please enter it to confirm your sign in.`}
      />
      <div className='login-verify-form'>
        <LoginOtp className='login-verify-otp' type={type} id={id} otp={otp} onOtpChange={setOtp} />
        <LoadingButton
          loading={loading}
          fullWidth
          variant='contained'
          size='large'
          type='button'
          className='btn btn-primary btn-block'
          onClick={onContinue}
        >
          Continue
        </LoadingButton>
        <LoadingButton
          fullWidth
          variant='passive'
          size='large'
          type='button'
          className='btn btn-primary btn-block'
          onClick={() => onAltSignInClick(type)}
        >
          Sign in another way
        </LoadingButton>
        <LoginResend className='login-verify-resend' type={type} id={id} onResendSuccess={() => setOtp('')} />
      </div>
    </LoginWindow>
  );
};

const mapStateToProps = (state) => ({
  loading: _.get(state, ['auth', 'loading']),
  resError: _.get(state, ['auth', 'resError'])
});

const mapDispatchToProps = {
  validateLoginOtp
};

const ConnectedLoginVerify = connect(mapStateToProps, mapDispatchToProps)(LoginVerify);

export default ConnectedLoginVerify;
