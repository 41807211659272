import { serverUrl } from '@app/src/global/Environment';
import {
  setCollectionDeleteModalShow,
  setCollectionDeleteModalCollectionType,
  setCollectionDeleteModalCollectionId,
  setCollectionFormUploadErrorModalShow,
  setCollectionFormUploadErrorModalCollectionId,
  setCollectionFormUploadErrorModalCollectionType,
  setCollectionDeleteModalSlug,
  setCurrentTaxState,
  setCollectionFormUploadErrorModalAttemptId,
  setCollectionFormUploadErrorModalHeader
} from '@app/src/taxflow/collection/actions/collectionActions';
import {
  getFormUploadEnabledMap,
  getFormUploadErrorMessage,
  getFormUploadNextPathComponentMap,
  getPrefillPathComponentMap,
  getUploadAttemptFileNames
} from '@app/src/taxflow/collection/utils/collectionUtils';
import {
  setQueryResults,
  setUploadError,
  updateFormUploadAttempts
} from '@app/src/taxflow/shared/actions/sharedActions';
import _ from 'lodash';
import axios from 'axios';
import {
  COLLECTION_TYPE__STATE_RETURN,
  COLLECTION_TYPE__STATE_RESIDENCY,
  COLLECTION_TYPE__STATE_INCOME,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  COLLECTION_TYPE__STATE_EXPENSES
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  COLLECTION_TYPE__CREDIT_CHARITY,
  COLLECTION_TYPE__CREDIT_STANDARD,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { DEFAULT_COLLECTION_ID, STATE_TAX_COLL_TYPE_MAP } from '@app/src/taxflow/shared/constants/sharedConstants';
import { getManualInputStarted } from '@app/src/taxflow/common/services/forms';
import { queryResultsSelector, currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import {
  COLLECTION_TYPE__INCOME_COMPOSITE,
  COLLECTION_TYPE__INCOME_FREELANCE,
  COLLECTION_TYPE__INCOME_INVEST,
  SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
  SLUG__INCOME_INVEST_UNIFICATION
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { trackActivity } from '@app/src/services/analyticsService';
import { collectionUploadStatusSelector, formUploadCollectionTypesSelector } from '@app/src/selectors/taxFlowSelectors';
import { getFilingStatus } from '@app/src/taxflow/shared/utils/sharedUtils';
import {
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { removeAboutYouItem } from '@app/src/taxflow/main/services/taxFlowDataService';
import { currentNavigationItemSelector } from '@app/src/selectors/navigationListSelectors';
import {
  allCollectionTypesSelector,
  flattenedUploadAttemptsSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  COLLECTION_TYPE__SPECIAL,
  SLUG__BULK_UPLOAD
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW__FORM_UPLOAD_OCR_FAILED_STATES } from '@app/src/taxflow/collection/constants/formUploadConstants';
import { clearFormUploadAlert } from '@app/src/actions/socketActions';
import { deleteTaxData, updateTaxData } from '@app/src/api/taxDataApi';

const baseUrl = serverUrl();

export const showCollectionDeleteModal =
  ({ collectionType, collectionId, slug }) =>
  (dispatch) => {
    dispatch(setCollectionDeleteModalShow(true));
    dispatch(setCollectionDeleteModalCollectionType(collectionType));
    dispatch(setCollectionDeleteModalCollectionId(collectionId));
    dispatch(setCollectionDeleteModalSlug(slug));
  };

export const hideCollectionDeleteModal = () => (dispatch) => {
  dispatch(setCollectionDeleteModalShow(false));
  dispatch(setCollectionDeleteModalCollectionType(null));
  dispatch(setCollectionDeleteModalCollectionId(null));
  dispatch(setCollectionDeleteModalSlug(null));
};

export const deleteCollection =
  ({ currentQuestion, collectionType, collectionId, stateReturn }) =>
  async (dispatch, getState) => {
    const queryResults = queryResultsSelector(getState());
    const allCollectionTypes = allCollectionTypesSelector(getState());
    const currentCollTypeItem = allCollectionTypes.find((item) => item.collectionType === collectionType);

    let unificationRecord;
    let unifiedCollIds = [];
    if (
      (collectionType === COLLECTION_TYPE__INCOME_INVEST || collectionType === COLLECTION_TYPE__INCOME_COMPOSITE) &&
      currentQuestion.slug === SLUG__BULK_UPLOAD
    ) {
      unificationRecord = queryResults.find((queryResult) => {
        return (
          queryResult.coll_type === COLLECTION_TYPE__INCOME_INVEST &&
          queryResult.coll_id === collectionId &&
          queryResult.slug === SLUG__INCOME_INVEST_UNIFICATION
        );
      });
      unifiedCollIds = (_.get(unificationRecord, ['answer', 'value']) || []).filter(
        (collId) => collId !== Number(collectionId)
      );
      for (const collId of unifiedCollIds) {
        await Promise.all([
          dispatch(deleteTaxData({ coll_type: collectionType, coll_id: `${collId}` })),
          axios.delete(`${baseUrl}api/form-upload/ocr-results`, {
            data: {
              coll_type: collectionType,
              coll_id: `${collId}`
            }
          })
        ]);
      }
      const collIds = _.get(unificationRecord, ['answer', 'value']) || [Number(collectionId)];
      const newQueryResults = queryResults.filter((queryResult) => {
        return !(queryResult.coll_type === collectionType && collIds.includes(Number(queryResult.coll_id)));
      });
      dispatch(setQueryResults(newQueryResults));
    } else if (collectionType === COLLECTION_TYPE__STATE_RETURN) {
      // delete multistate collections
      await Promise.all([
        dispatch(
          deleteTaxData({ coll_type: COLLECTION_TYPE__STATE_RESIDENCY }),
          deleteTaxData({ coll_type: COLLECTION_TYPE__STATE_INCOME }),
          deleteTaxData({ coll_type: COLLECTION_TYPE__STATE_EXPENSES })
        )
      ]);

      let newQueryResults = queryResults.filter((queryResult) => {
        return !(
          (queryResult.coll_type === collectionType && queryResult.coll_id === collectionId) ||
          queryResult.coll_type === COLLECTION_TYPE__STATE_RESIDENCY ||
          queryResult.coll_type === COLLECTION_TYPE__STATE_INCOME ||
          queryResult.coll_type === COLLECTION_TYPE__STATE_EXPENSES
        );
      });

      // delete state return collection if last state return was deleted
      const stateReturns = newQueryResults.filter((queryResult) => {
        return queryResult.coll_type === collectionType && queryResult.slug === ENDPOINT_ATTRIBUTE__STATE_RETURN;
      });
      if (stateReturns.length === 0) {
        await dispatch(deleteTaxData({ coll_type: COLLECTION_TYPE__STATE_RETURN }));
        newQueryResults = newQueryResults.filter(
          (queryResult) => queryResult.coll_type !== COLLECTION_TYPE__STATE_RETURN
        );
      }

      // update query results
      dispatch(setQueryResults(newQueryResults));

      // delete individual state return collections
      dispatch(deleteStateReturn(stateReturn));
    } else if (collectionType === COLLECTION_TYPE__CREDIT_CHARITY) {
      await dispatch(
        updateTaxData({
          taxData: [
            {
              coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
              coll_id: collectionId,
              slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY,
              value: '0'
            }
          ],
          generateSharedCollectionId: false
        })
      );
      const newQueryResults = queryResults.filter((queryResult) => {
        return !(queryResult.coll_type === collectionType && queryResult.coll_id === collectionId);
      });
      dispatch(setQueryResults(newQueryResults));
    } else {
      const newQueryResults = queryResults.filter((queryResult) => {
        return !(queryResult.coll_type === collectionType && queryResult.coll_id === collectionId);
      });
      dispatch(setQueryResults(newQueryResults));
    }

    const filingStatus = getFilingStatus({ queryResults: queryResultsSelector(getState()) });
    if (_.isNil(filingStatus)) {
      await dispatch(deleteTaxData({ coll_type: COLLECTION_TYPE__SELF, slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS }));
    } else {
      await dispatch(
        updateTaxData({
          taxData: [
            {
              coll_type: COLLECTION_TYPE__SELF,
              coll_id: '0',
              slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
              value: filingStatus
            }
          ],
          generateSharedCollectionId: false
        })
      );
    }

    // update preselected about you collection types
    let removeSummaryItem = false;
    let collTypeStartedQuery;
    if (currentCollTypeItem && !currentCollTypeItem.required && !currentCollTypeItem.suppressed) {
      if (currentCollTypeItem.plural) {
        const collectionStartedSlug = currentCollTypeItem.slug;
        const hasOtherCollections = queryResults.some(
          (result) =>
            result.slug === collectionStartedSlug &&
            result.coll_id !== collectionId &&
            !unifiedCollIds.includes(Number(result.coll_id))
        );

        if (!hasOtherCollections) {
          removeSummaryItem = true;
          const navSectionItem = currentNavigationItemSelector(getState());
          collTypeStartedQuery = _.get(
            _.find(
              _.get(navSectionItem, ['meta', 'collectionTypeStartedQueries']),
              (query) => query.coll_type === collectionType
            ),
            'slug'
          );
        }
      } else {
        removeSummaryItem = true;
      }
    }
    if (removeSummaryItem) {
      await dispatch(removeAboutYouItem(collectionType));
    }

    await Promise.all([
      dispatch(deleteTaxData({ coll_type: collectionType, coll_id: collectionId })),
      axios.delete(`${baseUrl}api/form-upload/ocr-results`, {
        data: {
          coll_type: collectionType,
          coll_id: collectionId
        }
      }),
      ...(collTypeStartedQuery
        ? [
            dispatch(
              deleteTaxData({ coll_type: collectionType, coll_id: DEFAULT_COLLECTION_ID, slug: collTypeStartedQuery })
            )
          ]
        : [])
    ]);
  };

export const updateTaxState = (stateCode) => (dispatch) => {
  dispatch(setCurrentTaxState(stateCode));
};

export const showCollectionFormUploadErrorModal =
  ({ collectionType, collectionId, header = null, attemptId }) =>
  (dispatch) => {
    dispatch(setCollectionFormUploadErrorModalShow(true));
    if (!_.isNil(attemptId)) {
      dispatch(setCollectionFormUploadErrorModalAttemptId(attemptId));
    }
    dispatch(setCollectionFormUploadErrorModalCollectionId(collectionId));
    dispatch(setCollectionFormUploadErrorModalCollectionType(collectionType));
    dispatch(setCollectionFormUploadErrorModalHeader(header));
  };

export const hideCollectionFormUploadErrorModal =
  ({ collectionType }) =>
  (dispatch, getState) => {
    dispatch(setCollectionFormUploadErrorModalShow(false));
    dispatch(setCollectionFormUploadErrorModalCollectionType(null));
    dispatch(setCollectionFormUploadErrorModalCollectionId(null));
    dispatch(setCollectionFormUploadErrorModalHeader(null));

    if (collectionType === COLLECTION_TYPE__SPECIAL) {
      let uploadAttempts = flattenedUploadAttemptsSelector(getState());

      const errorAttemptId = _.get(getState(), ['taxFlowModals', 'collectionFormUploadErrorModalAttemptId']);
      if (errorAttemptId) {
        uploadAttempts = uploadAttempts.map((a) => {
          if (a.id === errorAttemptId) {
            return {
              ...a,
              isPendingAlert: 0
            };
          }
          return a;
        });
        dispatch(updateFormUploadAttempts({ attempts: uploadAttempts }));
        dispatch(clearFormUploadAlert(errorAttemptId));
      }

      const failedOcrBulkAttempts = uploadAttempts.filter(
        (a) => a.isBulk && a.isPendingAlert && TAXFLOW__FORM_UPLOAD_OCR_FAILED_STATES.includes(a.status)
      );
      if (failedOcrBulkAttempts.length) {
        dispatch(setBulkUploadOcrError({ attempt: failedOcrBulkAttempts[0] }));
      } else {
        dispatch(setCollectionFormUploadErrorModalAttemptId(null));
        dispatch(setUploadError(null));
      }
    }
  };

export const setBulkUploadOcrError =
  ({ attempt }) =>
  (dispatch, getState) => {
    const filesDisplayName = getUploadAttemptFileNames({ attempt });
    const formUploadCollectionTypes = formUploadCollectionTypesSelector(getState());
    if (attempt.collectionType && !formUploadCollectionTypes.includes(attempt.collectionType)) {
      const errorMsg = `Error uploading ${filesDisplayName}.`;
      dispatch(setUploadError(errorMsg));
    } else {
      const defaultErrorMsg = getFormUploadErrorMessage({ errorMessage: attempt.errorMessage });
      const fullErrorMsg = `Error uploading ${filesDisplayName}. ${defaultErrorMsg}`;
      dispatch(setUploadError(fullErrorMsg));
    }

    dispatch(
      showCollectionFormUploadErrorModal({
        collectionType: COLLECTION_TYPE__SPECIAL,
        collectionId: DEFAULT_COLLECTION_ID,
        attemptId: attempt.id
      })
    );
  };

const deleteStateReturn = (stateReturn) => async (dispatch) => {
  const stateCode = _.get(stateReturn, ['answer', 'value']);
  const stateTaxCollectionTypes = STATE_TAX_COLL_TYPE_MAP[stateCode];
  for (const collectionType of stateTaxCollectionTypes) {
    await dispatch(deleteTaxData({ coll_type: collectionType }));
  }
};

export const getNextPathComponentWithUpload =
  ({ pathComponent }) =>
  async (dispatch, getState) => {
    const currentQuestion = currentQuestionSelector(getState());
    if (!_.keys(getFormUploadNextPathComponentMap()).includes(currentQuestion.slug)) {
      return pathComponent;
    }

    const uploadStatus = collectionUploadStatusSelector(getState());
    if (!uploadStatus) {
      return pathComponent;
    }

    if (uploadStatus.status === 'pending') {
      return getPrefillPathComponentMap()[currentQuestion.slug];
    } else if (uploadStatus.status === 'success') {
      return getFormUploadNextPathComponentMap()[currentQuestion.slug];
    }
  };

export const getNextPathComponentWithSkip =
  ({ pathComponent, collectionType, collectionId, isCollectionUploaded }) =>
  async (dispatch) => {
    if (!_.keys(getFormUploadNextPathComponentMap()).includes(pathComponent)) {
      return pathComponent;
    }

    // If form upload not enabled or collection was uploaded already, don't prompt user to choose between manual entry and upload
    const formUploadEnabled = getFormUploadEnabledMap()[pathComponent];
    if (!formUploadEnabled || isCollectionUploaded) {
      return getFormUploadNextPathComponentMap()[pathComponent];
    }

    // slower check involving back-end call
    if (
      _.isNil(collectionType) &&
      [SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD, SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD].includes(
        pathComponent
      )
    ) {
      collectionType = COLLECTION_TYPE__INCOME_FREELANCE;
    }
    const manualInputStarted = await dispatch(
      getManualInputStarted({
        collectionType,
        collectionId
      })
    );
    if (manualInputStarted) {
      return getFormUploadNextPathComponentMap()[pathComponent];
    }

    // check if mobile
    if (isReactNative()) {
      sentMsgToReactNative('get_os');
    }
    return pathComponent;
  };

export const trackDeleteCollection = ({ collectionType, collectionId }) => {
  trackActivity('collection: removed', { collectionType, collectionId });
};

export const isEverySectionItemIncomplete = ({ slug, currentSummaryItems }) => {
  return (
    currentSummaryItems &&
    currentSummaryItems
      .filter((item) => {
        return !item.suppressed;
      })
      .every((item) => {
        if (slug === 'credit' && item.collectionType === 'credit-standard') {
          return true;
        } else {
          return item.incomplete;
        }
      })
  );
};
