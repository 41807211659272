import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import TaxFlowPillsSection from '@app/src/Components/TaxFlow/Common/TaxFlowPillsSection';
import { connect } from 'react-redux';
import { questionnaireSummaryPillsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import url from 'url';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import { trackActivity } from '@app/src/services/analyticsService';
import { PATH_COMPONENT__SUBMIT_FINISH } from '@app/src/taxflow/sections/submit/constants/submitConstants';

const TaxFlowQuestionnaireSummary = ({ questionnaireSummaryPills, history }) => {
  const [pills, setPills] = useState([]);

  useEffect(() => {
    if (_.isEmpty(questionnaireSummaryPills)) {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_FINISH}`);
    }
  }, [questionnaireSummaryPills, history]);

  const navigateToLinkedQuestion = useCallback(
    ({ startSlug, collectionType, collectionId, text }) => {
      trackActivity('questionnaire: pill clicked', { collectionType, collectionId, pillName: text });
      const nextPathComponent = getPathComponentBySlug(startSlug);
      if (_.isNil(nextPathComponent)) {
        defaultCaptureMessage('Could not advance to questionnaire review question', {
          coll_type: collectionType,
          coll_id: collectionId,
          slug: startSlug
        });
        return;
      }
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            origin: 'questionnaire-summary',
            collectionId: collectionId === '0' ? undefined : collectionId
          }
        })
      );
    },
    [history]
  );

  useEffect(() => {
    setPills(
      _.map(questionnaireSummaryPills, ({ text, startSlug, collectionType, collectionId, validationFailed }) => ({
        text,
        variant: validationFailed ? 'warning' : 'default',
        loading: false,
        onClick: () => navigateToLinkedQuestion({ startSlug, collectionType, collectionId, text })
      }))
    );
  }, [questionnaireSummaryPills, setPills, navigateToLinkedQuestion]);
  return (
    !_.isEmpty(pills) && <TaxFlowPillsSection sectionHeader={'Questions answered'} pills={pills}></TaxFlowPillsSection>
  );
};

const mapStateToProps = (state) => ({
  questionnaireSummaryPills: questionnaireSummaryPillsSelector(state)
});

const mapDispatchToProps = {};

const ConnectedTaxFlowQuestionnaireSummary = connect(mapStateToProps, mapDispatchToProps)(TaxFlowQuestionnaireSummary);

export default ConnectedTaxFlowQuestionnaireSummary;
