import React from 'react';
import { useWindowSize } from 'react-use';

import ExpenseReviewExpenses from '@app/src/Components/ExpenseReview/ExpenseReviewExpenses';
import ExpenseReviewSidebar from '@app/src/Components/ExpenseReview/ExpenseReviewSidebar';
import TaxFlowQuestionFooter from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionFooter';

import '@app/src/Components/ExpenseReview/ExpenseReview.scss';

const ExpenseReview = () => {
  const { width: windowWidth } = useWindowSize();
  const isDesktop = windowWidth >= 1024;

  return (
    <div className='expense-review-box-wrap'>
      <div className='expense-review-box-wrap-left-spacer' />
      <div className='expense-review-center-content'>
        <ExpenseReviewExpenses />
        <ExpenseReviewSidebar />
        {!isDesktop && <TaxFlowQuestionFooter />}
      </div>
    </div>
  );
};

export default ExpenseReview;
