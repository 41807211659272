import _ from 'lodash';
import moment from 'moment';
import { getCollectionTypeItems, getTaxFilingContentfulEntries } from '@app/src/services/taxFlowContentfulService';
import { REACT_APP_ENV, serverUrl } from '@app/src/global/Environment';
import { setCarPriorDepreciation, setIsNextButtonLoading } from '@app/src/taxflow/shared/actions/sharedActions';
import {
  setCurrentQuestion,
  setCurrentAnswer,
  resetCurrentQuestion as resetCurrentQuestionOrig,
  setUpdating,
  setTaxFlowError,
  mergeCurrentQuestion,
  setIsConfirmationModalOpen,
  setContextLoading,
  setBusinessCode,
  setTaxFlowLoader,
  setIsQuestionnaireFlow,
  setQuestionWrapper,
  setDropzoneFilesSameForm,
  setDropzoneLoading,
  setDropzoneFilesReadyForUpload,
  setCurrentGetQuestionAnswerLoadingSlug,
  setPremiumUpsellModalShow,
  setDesktopSellModalShow
} from '@app/src/actions/taxFlowActions';
import {
  clearPaymentType,
  getPaid,
  showSubscriptionPromptConditional,
  subscribeWithToken
} from '@app/src/taxflow/sections/submit/services/submitService';
import {
  TAXFLOW_BASE_URL,
  DEFAULT_COLLECTION_ID,
  PATH_COMPONENT__DASHBOARD,
  STATE_DROPDOWN_OPTIONS,
  STATE_TAX_COLL_TYPE_MAP,
  SLUG__SETTINGS,
  PATH_COMPONENT__SETTINGS,
  UI_STAGE__PURGATORY,
  UI_STAGE__OPS_REVIEW,
  SLUG__CONTACT_SUPPORT,
  SLUG__PAST_RETURNS,
  SLUG__SWITCH_TO_DESKTOP,
  PATH_COMPONENT__SWITCH_TO_DESKTOP,
  PATH_COMPONENT__PAST_RETURNS,
  PATH_COMPONENT__CONTACT_SUPPORT,
  STATE_NAME_MAP,
  SLUG__LINKED_ACCOUNTS,
  PATH_COMPONENT__LINKED_ACCOUNTS,
  UI_STAGE__REJECTED_ESC,
  UI_STAGE__USER_ESC
} from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  PATH_COMPONENT__SELF_EXIT_BOUNCE,
  PATH_COMPONENT__SPOUSE_EXIT_BOUNCE,
  PATH_COMPONENT__HOME_ADDRESS_EXIT_BOUNCE,
  PATH_COMPONENT__DEPENDENT_EXIT_BOUNCE,
  COLLECTION_TYPE__HOME_ADDRESS,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE,
  SLUG__SELF_DETAIL,
  SLUG__HOME_ADDRESS_DETAIL
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  PATH_COMPONENT__SPECIAL_EXIT_BOUNCE,
  SLUG__SPECIAL_START,
  SLUG__FIND_WRITE_OFFS,
  SLUG__SPECIAL_WHO,
  SLUG__PREMIUM_START,
  SLUG__BULK_UPLOAD,
  SLUG__BULK_UPLOAD_QUESTIONS,
  SLUG__BULK_UPLOAD_QUESTIONS_INTRO,
  PATH_COMPONENT__BULK_UPLOAD,
  SLUG__BULK_UPLOAD_MULTI_IMAGE,
  SLUG__BULK_UPLOAD_PHOTO_CAPTURE,
  SLUG__FILING_QUALIFIED,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_SUMMARY
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import { HOME_PATH_COMPONENTS } from '@app/src/taxflow/sections/home/constants/homeConstants';
import {
  PATH_COMPONENT__CAR_EXIT_BOUNCE,
  SLUG__CAR_DEPRECIATION_CONFIRMATION,
  COLLECTION_TYPE__CAR,
  ENDPOINT_ATTRIBUTE__CAR_PERCENT,
  ENDPOINT_ATTRIBUTE__CAR_YEARS_DEPRECIATION,
  ENDPOINT_ATTRIBUTE__CAR_COST
} from '@app/src/taxflow/sections/car/constants/carConstants';
import {
  SLUG__IL_TUITION_DEPENDENT,
  COLLECTION_TYPE__STATE_INCOME,
  SLUG__STATE_INCOME,
  SLUG__STATE_RETURN,
  SLUG__STATE_DEFAULT,
  SLUG__STATE_EXPENSES,
  COLLECTION_TYPE__STATE_EXPENSES,
  SLUG__STATE_NO_INCOME_TAX,
  SLUG__TEMPLATE_STATE_DONE
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import url from 'url';
import qs from 'qs';
import axios from 'axios';
import {
  getQuestionUpdates,
  getQuestionDelete,
  getQuestionById,
  getAllowedParams,
  getStartedUpdates,
  getDoneUpdates,
  getQuestionWithSubstitutions,
  getCurrentSectionPathComponent
} from '@app/src/taxflow/main/utils/mainUtils';
import {
  getQueryResultByEndpointAttribute,
  getQueryResultsByEndpointAttribute
} from '@app/src/taxflow/shared/utils/sharedUtils';
import {
  getNextPathComponentMap as getNextPathComponentMapCar,
  getOptionPathComponentMap as getOptionPathComponentMapCar,
  getNextPathComponent as getNextPathComponentCar,
  getSlugMap as getSlugMapCar
} from '@app/src/taxflow/sections/car/utils/carUtils';
import {
  getNextPathComponentMap as getNextPathComponentMapCredit,
  getOptionPathComponentMap as getOptionPathComponentMapCredit,
  getNextPathComponent as getNextPathComponentCredit,
  getSlugMap as getSlugMapCredit,
  getNextQuery as getNextQueryCredit
} from '@app/src/taxflow/sections/credit/utils/creditUtils';
import {
  getNextPathComponentMap as getNextPathComponentMapHome,
  getOptionPathComponentMap as getOptionPathComponentMapHome,
  getNextPathComponent as getNextPathComponentHome,
  getSlugMap as getSlugMapHome
} from '@app/src/taxflow/sections/home/utils/homeUtils';
import {
  getNextPathComponentMap as getNextPathComponentMapIncome,
  getNextPathComponent as getNextPathComponentIncome,
  getOptionPathComponentMap as getOptionPathComponentMapIncome,
  getSlugMap as getSlugMapIncome
} from '@app/src/taxflow/sections/income/utils/incomeUtils';
import {
  getNextQuery as getNextQueryPersonal,
  getNextPathComponentMap as getNextPathComponentMapPersonal,
  getOptionPathComponentMap as getOptionPathComponentMapPersonal,
  getNextPathComponent as getNextPathComponentPersonal,
  getSlugMap as getSlugMapPersonal
} from '@app/src/taxflow/sections/personal/utils/personalUtils';
import {
  getNextPathComponentMap as getNextPathComponentMapSpecial,
  getOptionPathComponentMap as getOptionPathComponentMapSpecial,
  getSlugMap as getSlugMapSpecial
} from '@app/src/taxflow/sections/special/utils/specialUtils';
import {
  getNextPathComponentMap as getNextPathComponentMapSubmit,
  getNextPathComponent as getNextPathComponentSubmit,
  getSlugMap as getSlugMapSubmit
} from '@app/src/taxflow/sections/submit';
import {
  getNextQuery as getNextQueryState,
  getNextPathComponentMap as getNextPathComponentMapState,
  getOptionPathComponentMap as getOptionPathComponentMapState,
  getNextPathComponent as getNextPathComponentState,
  getSlugMap as getSlugMapState
} from '@app/src/taxflow/sections/state';
import {
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_NAVIGATION,
  CATEGORY_TYPE_SUMMARY,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_BUSINESS_CODE
} from '@app/src/constants/constants';
import { setCurrentCollectionId, setCurrentTaxState } from '@app/src/taxflow/collection/actions/collectionActions';
import { getAccountDetails } from '@app/src/services/pricingService';
import { serializeQuestionAnswer, deserializeQuestionAnswer } from '@app/src/taxflow/mapping/utils/mappingUtils';
import {
  PATH_COMPONENT__INCOME_W2_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_INTEREST_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_UNEMPLOYMENT_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_DIV_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_INVEST_EXIT_BOUNCE,
  PATH_COMPONENT__INCOME_RETIREMENT_EXIT_BOUNCE,
  SLUG__INCOME_INVEST_INFO,
  SLUG__INCOME_INVEST_UNIFICATION,
  PATH_COMPONENT__INCOME_W2G_EXIT_BOUNCE,
  SLUG__INCOME_FREELANCE_JOB,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE,
  COLLECTION_TYPE__INCOME_FREELANCE,
  PATH_COMPONENT__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE,
  SLUG__INCOME_FREELANCE_INDUSTRY,
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { setTaxFilingError } from '@app/src/taxflow/common/services/errors';
import {
  SLUG__CREDIT_HEALTH_PLAN_FAMILY_MEMBERS,
  SLUG__CREDIT_STUDENT_TUITION_WHO,
  SLUG__CREDIT_CHILD_CARE_WHO,
  PATH_COMPONENT__CREDIT_QUARTERLY_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HEALTH_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HSA_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_STUDENT_LOAN_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_CHILD_CARE_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_COGS_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_CHARITY_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HOMEOWNER_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_CAPITAL_LOSS_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_STANDARD_EXIT_BOUNCE,
  SLUG__CREDIT_STANDARD_ITEMIZED,
  PATH_COMPONENT__CREDIT_CHILD_TAX_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_ENERGY_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_TEACHING_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_QBI_EXIT_BOUNCE,
  ITEMIZED_DEDUCTION_DEFAULTS_SLUGS,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_DISALLOWED_EXIT_BOUNCE,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  PATH_COMPONENT__SUBMIT_EXIT_BOUNCE,
  SLUG__SUBMIT_ACCEPTED_BY_IRS,
  SLUG__SUBMIT_SENT_TO_IRS,
  SLUG__SUBMIT_FINISH,
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_BYE,
  SLUG__SUBMIT_AUDIT_ISSUE,
  SLUG__SUBMIT_IRS_ISSUE,
  SLUG__SUBMIT_PAY_NOW,
  SLUG__SUBMIT_DEBIT_MANUAL,
  SLUG__SUBMIT_DEBIT_ROUTING,
  SLUG__SUBMIT_UNABLE_TO_FILE,
  SLUG__SUBMIT_BYE_MANUAL,
  SLUG__SUBMIT_EMAIL_OUTBOX,
  SLUG__SUBMIT_BLOCKED,
  SLUG__SUBMIT_BYE_MANUAL_ESC,
  SLUG__SUBMIT_BLOCKED_FINAL_REVIEW,
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_SWITCH_TO_ANNUAL,
  SLUG__SUBMIT_PAY_TEST,
  SLUG__SUBMIT_CONFIRM_ID,
  SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED,
  PATH_COMPONENT__SUBMIT_FINISH,
  SLUG__SUBMIT_TAX_AMOUNT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { trackActivity, setUserWithTaxStage, setUserWithObj } from '@app/src/services/analyticsService';
import { getErrors } from '@app/src/services/taxValidationService';
import { updateCampaign, postTaxSubmission, updateMilestones, getCampaign } from '@app/src/services/workService';
import {
  savedDefaultAnswerSelector,
  substitutionsWithQuestionSelector,
  allDerivedQuestionsSelector,
  taxFilingPaidSelector,
  accountDetailsSelector,
  submitEnabledSelector,
  isPremiumSubscriberSelector,
  idVerificationResultSelector,
  zeroTaxOwedSelector,
  isPreSubmitSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  bulkUploadItemsSelector,
  flattenedUploadAttemptsSelector,
  formUploadAttemptsSelector,
  isCollectionUploadedSelector,
  isCurrentCollectionAccessibleInBulkSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  submitIssueTypeSelector,
  uncheckedSubmitIssueMessagesSelector
} from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import {
  isReviewModeSelector,
  stateTaxSlugsSelector,
  businessCodeSelector,
  isQuestionnaireFlowSelector,
  currentGetQuestionAnswerLoadingSlugSelector,
  questionnairePassedQuestionsSelector,
  questionnaireTotalQuestionsSelector
} from '@app/src/selectors/taxFlowSelectors';
import {
  taxProgressStageSelector,
  percentCompleteSelector,
  supportAccessOriginSelector,
  aboutYouEnabledSelector,
  nextNavigationItemSelector,
  currentNavigationSectionSelector
} from '@app/src/selectors/navigationListSelectors';
import {
  taxFilingEnabledSelector,
  developerEnabledSelector,
  campaignSelector,
  uiStageSelector,
  idVerificationEnabledSelector,
  aboutYouStatusPrefillSelector,
  incomeTypeSelector,
  idVerificationDisabledSelector,
  humanReviewEnabledSelector
} from '@app/src/selectors/workSelectors';
import {
  aboutYouPredictionsSelector,
  collectionTypeItemsSelector,
  currentAnswerSelector,
  currentCollectionIdSelector,
  currentCollectionTypeSelector,
  currentQuestionSelector,
  deductionsSelector,
  irsPaymentAccountSelector,
  isSpecialDoneSelector,
  persistErrorSelector,
  queryResultsMapSelector,
  queryResultsSelector,
  resErrorSelector,
  statusSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import {
  getNextPathComponentWithSkip,
  isEverySectionItemIncomplete,
  getNextPathComponentWithUpload
} from '@app/src/taxflow/collection/services/collectionService';
import { userSelector } from '@app/src/selectors/userSelectors';
import { logout } from '@app/src/services/authService';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { filteredErrorsSelector, formErrorsSelector } from '@app/src/selectors/taxValidationSelectors';
import {
  getDeductions,
  getQueryResults,
  getCurrentQuestionData,
  getQuestionsAnswered,
  updateAboutYouInfo,
  updateItemizedDeduction,
  getQuestionnaireQuestionAsQuestion,
  resetCurrentAnswer,
  dispatchHomeOfficeUpdates
} from '@app/src/taxflow/main/services/taxFlowDataService';
import {
  getNextUrlNavigation,
  getNextUrlWithBulkUpload,
  requireNavigationData
} from '@app/src/services/taxFlowNavigationService';
import { getNavigationLockedRedirects, getOriginParam } from '@app/src/taxflow/navigation/utils/navigationUtils';
import { currentSummaryItemsSelector } from '@app/src/selectors/summarySelectors';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import {
  getUploadAttemptsStatuses,
  getIdVerificationQuestions,
  confirmId,
  resetSubmitSsnMatched,
  getIdVerificationResult
} from '@app/src/services/taxFlowService';
import { setSupportAccessOrigin } from '@app/src/taxflow/navigation/actions/navigationActions';
import { getPaymentPathComponent } from '@app/src/taxflow/sections/submit/utils/submitUtils';
import { PAYMENT_ERROR_MESSAGE } from '@app/src/constants/pricingConstants';
import { formatIdVerificationQuestions, mergeQuestions } from '@app/src/taxflow/main/utils/idVerificationUtils';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { desktopSellModalVisitedSelector } from '@app/src/selectors/assistantSelectors';
import {
  fetchCurrentQuestionnaireQuestion,
  fetchQuestionnaireSummaryPills,
  generateQuestionnaireQuestions,
  progressToNextQuestionnaireQuestion
} from '@app/src/services/questionnaireService';
import {
  deleteTaxData,
  getNavigationSections,
  getTaxData,
  markNavigationSectionAsSeen,
  updateTaxData,
  getSubmitWarnings,
  getIdMatch,
  getSubmitIssues,
  ssnMatchedSelector,
  submitWarningsSelector
} from '@app/src/api/taxDataApi';

const baseUrl = serverUrl();

const resetQuestionAnswer = () => (dispatch, getState) => {
  const persistError = persistErrorSelector(getState());
  dispatch(resetCurrentQuestionOrig());
  if (!persistError) {
    dispatch(setTaxFlowError(''));
  }
  dispatch(setCurrentCollectionId(null));
};

/**
 * Populate dropdown options dynamically (only works for dropdown options that aren't dependent on other inputs
 * currently on the screen)
 * @param {*} question
 */
const getQuestionMetaWithSubstitutions = (question) => (dispatch) => {
  if (question.question_type === CATEGORY_TYPE_STATE) {
    return STATE_DROPDOWN_OPTIONS;
  }

  if (question.slug === SLUG__CREDIT_HEALTH_PLAN_FAMILY_MEMBERS || question.slug === SLUG__CREDIT_STUDENT_TUITION_WHO) {
    return dispatch(getDependentOptionsIncludingParents());
  }

  if (question.slug === SLUG__CREDIT_CHILD_CARE_WHO || question.slug === SLUG__IL_TUITION_DEPENDENT) {
    return dispatch(getDependentOptions({ includeDefault: true }));
  }

  return question.question_meta;
};

const getDependentOptions =
  ({ includeDefault }) =>
  (dispatch, getState) => {
    const queryResults = queryResultsSelector(getState());
    const dependentQueryResults = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
    });

    return dependentQueryResults.map((queryResult, i) => ({
      text: _.get(queryResult, ['answer', 'value']),
      value: queryResult.coll_id,
      ...(includeDefault && i === 0 ? { default: 1 } : {})
    }));
  };

const getDependentOptionsIncludingParents = () => (dispatch, getState) => {
  const status = statusSelector(getState());

  return [
    {
      text: 'Me',
      value: 'me',
      default: 1
    },
    ...(status === 'married'
      ? [
          {
            text: 'My spouse',
            value: 'my_spouse'
          }
        ]
      : []),
    ...dispatch(getDependentOptions({}))
  ];
};

const getQuestionMetaWithSubstitutionsRecursive = (question) => (dispatch) => {
  return {
    ...question,
    question_meta: dispatch(getQuestionMetaWithSubstitutions(question)),
    ...(question.sub_question
      ? {
          sub_question: question.sub_question.map((subQuestion) => ({
            ...subQuestion,
            question_meta: dispatch(getQuestionMetaWithSubstitutions(subQuestion))
          }))
        }
      : {})
  };
};

const getCarPriorDepreciation = (queryResults, currentCollectionId) => async (dispatch) => {
  const queryResultCarPercent = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId: currentCollectionId,
    slug: ENDPOINT_ATTRIBUTE__CAR_PERCENT
  });

  const queryResultCarCost = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId: currentCollectionId,
    slug: ENDPOINT_ATTRIBUTE__CAR_COST
  });

  const queryResultCarYears = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId: currentCollectionId,
    slug: ENDPOINT_ATTRIBUTE__CAR_YEARS_DEPRECIATION
  });

  const businessPercent = _.get(queryResultCarPercent, ['answer', 'value']);
  const cost = _.get(queryResultCarCost, ['answer', 'value']);
  const yearsDepreciation = _.get(queryResultCarYears, ['answer', 'value']);

  const res = await axios.post(`${baseUrl}api/taxes/car-depreciation-calc`, {
    yearsDepreciation,
    businessPercent,
    cost
  });
  const { priorDepreciation } = res.data.data;
  dispatch(setCarPriorDepreciation(priorDepreciation));
};

const setCurrentQuestionnaireQuestionAsTheCurrentQuestion =
  ({ currentQuestionnaireQuestion }) =>
  async (dispatch, getState) => {
    const currentQuestion = await dispatch(
      getQuestionnaireQuestionAsQuestion({
        questionnaireQuestion: currentQuestionnaireQuestion
      })
    );
    if (!currentQuestion) {
      return null;
    }
    const substitutions = substitutionsWithQuestionSelector(getState(), {
      question: currentQuestion
    });
    const questionWithSubstitutions = getQuestionWithSubstitutions({
      question: currentQuestion,
      substitutions
    });
    const questionWithNewMeta = dispatch(getQuestionMetaWithSubstitutionsRecursive(questionWithSubstitutions));

    dispatch(setCurrentQuestion(questionWithNewMeta));
    dispatch(resetCurrentAnswer({ questionWithNewMeta }));

    return questionWithNewMeta;
  };

export const getQuestionAnswer =
  ({ slug, location, history }) =>
  async (dispatch, getState) => {
    const start = performance.now();
    const timeout = setTimeout(() => {
      trackActivity('timeout', { type: 'getQuestionAnswer', question: slug });
    }, 60000);

    try {
      dispatch(resetQuestionAnswer({ slug }));

      let currentCollectionId = DEFAULT_COLLECTION_ID;
      const query = qs.parse(location.search.slice(1), { ignoreQueryPrefix: true });
      if (_.has(query, ['collectionId'])) {
        currentCollectionId = query.collectionId;
      }
      dispatch(setCurrentCollectionId(currentCollectionId));

      await dispatch(getTaxFilingContentfulEntries());
      const allQuestions = allDerivedQuestionsSelector(getState());
      const currentQuestion = getQuestionById({ allQuestions, slug });

      dispatch(setCurrentGetQuestionAnswerLoadingSlug(_.get(currentQuestion, 'slug')));

      if (slug === SLUG__BULK_UPLOAD_QUESTIONS) {
        dispatch(setQuestionWrapper(currentQuestion));
        await dispatch(setIsQuestionnaireFlow(true));
        const { question: currentQuestionnaireQuestion } = await dispatch(fetchCurrentQuestionnaireQuestion());
        if (_.isNil(currentQuestionnaireQuestion)) {
          history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_FINISH}`);
          return;
        }
        if (_.isEmpty(flattenedUploadAttemptsSelector(getState()))) {
          await dispatch(getUploadAttemptsStatuses());
        }
        if (_.get(currentQuestionnaireQuestion, 'type') === 'form-review') {
          await dispatch(getCollectionTypeItems());
        }
        await dispatch(setCurrentQuestionnaireQuestionAsTheCurrentQuestion({ currentQuestionnaireQuestion }));
        dispatch(trackQuestionView(currentQuestionnaireQuestion));
        dispatch(setContextLoading(false));
        dispatch(setTaxFlowLoader(false));
        return;
      }

      if (currentQuestion.slug === SLUG__BULK_UPLOAD_QUESTIONS_INTRO) {
        const { question } = await dispatch(fetchCurrentQuestionnaireQuestion());
        const { pills: questionnaireSummaryPills } = await dispatch(fetchQuestionnaireSummaryPills());
        if (_.isNil(question) && _.isEmpty(questionnaireSummaryPills)) {
          history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_FINISH}`);
          return;
        } else if (_.isNil(question)) {
          history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_SUMMARY}`);
          return;
        }
      }

      await dispatch(setIsQuestionnaireFlow(false));

      await Promise.all([
        dispatch(getQuestionsAnswered()),
        dispatch(getCurrentQuestionData({ currentQuestion })),
        dispatch(getQueryResults({ currentQuestion })),
        dispatch(requireNavigationData({ currentQuestion })),
        dispatch(getErrors({ slug })),
        dispatch(getUploadAttemptsStatuses())
      ]);

      const queryResults = queryResultsSelector(getState());
      const queryResultsMap = queryResultsMapSelector(getState());
      const taxFilingEnabled = taxFilingEnabledSelector(getState());
      const developerEnabled = developerEnabledSelector(getState());
      const isStagingProdDb = REACT_APP_ENV === 'staging-prod-db';
      const uiStage = uiStageSelector(getState()) || '';
      const aboutYouEnabled = aboutYouEnabledSelector(getState());
      const taxFilingPaid = taxFilingPaidSelector(getState());
      const humanReviewEnabled = humanReviewEnabledSelector(getState());
      const navigationSections = await dispatch(getNavigationSections());

      const currentGetQuestionAnswerLoadingSlug = currentGetQuestionAnswerLoadingSlugSelector(getState());

      const maybeStartedNavigationSectionSlug = _.chain(navigationSections)
        .find({ linkedQuestionSlug: currentQuestion.slug })
        .get('slug')
        .value();
      if (!_.isNil(maybeStartedNavigationSectionSlug)) {
        trackActivity('navigation: progress to section', { slug: maybeStartedNavigationSectionSlug });
        await dispatch(markNavigationSectionAsSeen({ sectionSlug: maybeStartedNavigationSectionSlug }));
      }

      await dispatchHomeOfficeUpdates({
        dispatch,
        slug: currentQuestion.slug,
        queryResults,
        collectionId: currentCollectionId
      });

      if (currentQuestion.slug === SLUG__SETTINGS) {
        if (currentGetQuestionAnswerLoadingSlug === SLUG__SETTINGS) {
          history.push(`/${PATH_COMPONENT__SETTINGS}`);
        }
      } else if (currentQuestion.slug === SLUG__LINKED_ACCOUNTS) {
        if (currentGetQuestionAnswerLoadingSlug === SLUG__LINKED_ACCOUNTS) {
          history.push(`/${PATH_COMPONENT__LINKED_ACCOUNTS}`);
        }
      } else if (currentQuestion.slug === SLUG__SWITCH_TO_DESKTOP) {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SWITCH_TO_DESKTOP}`);
      } else if (taxFilingEnabled) {
        // locked ui stage redirects
        const submitLockedRedirect = getNavigationLockedRedirects({
          uiStage,
          slug: currentQuestion.slug,
          isStagingProdDb,
          humanReviewEnabled
        });
        if (submitLockedRedirect) {
          history.push(`/${TAXFLOW_BASE_URL}/${submitLockedRedirect}`);
          return;
        }
        // submit flow payment redirects
        if (
          !taxFilingPaid &&
          (currentQuestion.slug === SLUG__SUBMIT_EMAIL_INFO ||
            currentQuestion.slug === SLUG__SUBMIT_SIGNATURE ||
            currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION)
        ) {
          const paymentPath = getPaymentPathComponent({ paid: taxFilingPaid });
          history.push(`/${TAXFLOW_BASE_URL}/${paymentPath}`);
          return;
        }
      } else if (currentQuestion.slug === SLUG__PAST_RETURNS) {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__PAST_RETURNS}`);
      } else if (currentQuestion.slug === SLUG__CONTACT_SUPPORT) {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__CONTACT_SUPPORT}`);
      } else if (
        (currentQuestion.slug !== SLUG__CONTACT_SUPPORT || currentQuestion.slug !== SLUG__PAST_RETURNS) &&
        !developerEnabled
      ) {
        const specialSection = [SLUG__FIND_WRITE_OFFS, SLUG__SPECIAL_WHO];
        if (!aboutYouEnabled || !specialSection.includes(currentQuestion.slug)) {
          // redirect tax filing routes to nav if user not enabled
          await dispatch(advanceToCurrentSectionPage({ history }));
          return;
        }
      }

      if (currentQuestion.slug === SLUG__CREDIT_STANDARD_DEDUCTION && _.isNil(statusSelector(getState()))) {
        history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE}`);
      }

      // update current tax flow state
      if (currentQuestion.slug === SLUG__STATE_DEFAULT || currentQuestion.slug === SLUG__STATE_NO_INCOME_TAX) {
        const currentState = queryResults.find((queryResult) => {
          return queryResult.slug === SLUG__STATE_RETURN && queryResult.coll_id === currentCollectionId;
        });
        await dispatch(setCurrentTaxState(_.get(currentState, ['answer', 'value'])));
      } else if (_.get(currentQuestion, ['template', 'slug']) === SLUG__TEMPLATE_STATE_DONE) {
        const currentState = currentQuestion?.slug?.substring(0, 2).toUpperCase();
        if (currentState && STATE_NAME_MAP[currentState]) {
          await dispatch(setCurrentTaxState(currentState));
        }
      } else {
        const stateTaxCollectionTypes = new Set(
          Object.values(STATE_TAX_COLL_TYPE_MAP).flatMap((collTypes) => collTypes)
        );
        if (currentQuestion.collectionType && stateTaxCollectionTypes.has(currentQuestion.collectionType)) {
          const currentState = currentQuestion.collectionType.substring(0, 2).toUpperCase();
          await dispatch(setCurrentTaxState(currentState));
        } else {
          const primaryState = queryResults.find(
            (result) =>
              result.coll_type === COLLECTION_TYPE__HOME_ADDRESS &&
              result.coll_id === DEFAULT_COLLECTION_ID &&
              result.slug === ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE
          );
          const currentState = _.get(primaryState, ['answer', 'value']);
          if (currentState) {
            await dispatch(setCurrentTaxState(currentState));
          }
        }
      }

      if (currentQuestion.slug === SLUG__PREMIUM_START) {
        await axios.post(`${baseUrl}api/taxes/premium-start-email`, {});
      }

      const substitutions = substitutionsWithQuestionSelector(getState(), {
        question: currentQuestion
      });
      const questionWithSubstitutions = getQuestionWithSubstitutions({
        question: currentQuestion,
        substitutions
      });
      dispatch(setCurrentQuestion(questionWithSubstitutions));

      await Promise.all([
        ...(questionWithSubstitutions.slug === SLUG__CAR_DEPRECIATION_CONFIRMATION
          ? [dispatch(getCarPriorDepreciation(queryResults, currentCollectionId))]
          : [])
      ]);

      const questionWithNewMeta = dispatch(getQuestionMetaWithSubstitutionsRecursive(questionWithSubstitutions));
      dispatch(mergeCurrentQuestion(questionWithNewMeta));

      let currentAnswer = {};
      if (questionWithNewMeta.slug === SLUG__INCOME_INVEST_INFO) {
        const unificationRecord = queryResults.find(
          (result) => result.slug === SLUG__INCOME_INVEST_UNIFICATION && result.coll_id === currentCollectionId
        );
        let collectionIds;
        if (unificationRecord) {
          collectionIds = _.get(unificationRecord, ['answer', 'value'], [currentCollectionId]);
        } else {
          collectionIds = [currentCollectionId];
        }

        const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
          collectionIds.forEach((collId) => {
            const queryResult = queryResults.find(
              (qr) =>
                qr.coll_type === subQuestion.collectionType &&
                collId === Number(qr.coll_id) &&
                qr.slug === subQuestion.endpoint_attr
            );

            const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null });
            result = {
              ...result,
              [collId]: {
                ..._.get(result, collId, {}),
                [subQuestion.slug]: _.get(queryResult, 'answer', defaultAnswer)
              }
            };
          });

          return result;
        }, {});
        currentAnswer = { value: newValue };
      } else if (questionWithNewMeta.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
        const businessCode = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
          collectionId: currentCollectionId,
          slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE
        });
        const businessIndustry = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
          collectionId: currentCollectionId,
          slug: SLUG__INCOME_FREELANCE_INDUSTRY
        });
        currentAnswer = {
          value: {
            [SLUG__INCOME_FREELANCE_BUSINESS_CODE]: _.get(businessCode, ['answer']),
            [SLUG__INCOME_FREELANCE_INDUSTRY]: _.get(businessIndustry, ['answer'])
          }
        };
      } else if (questionWithNewMeta.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
        const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
          const queryResult = getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: subQuestion.collectionType,
            collectionId: currentCollectionId,
            slug: subQuestion.endpoint_attr
          });
          const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: subQuestion });
          const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null });
          return {
            ...result,
            [subQuestion.slug]: _.get(
              queryResult,
              ['answer'],
              !_.isNil(savedDefaultAnswer) ? savedDefaultAnswer : defaultAnswer
            )
          };
        }, {});
        currentAnswer = { value: newValue };
      } else if (questionWithNewMeta.slug === SLUG__STATE_INCOME || questionWithNewMeta.slug === SLUG__STATE_EXPENSES) {
        const defaultAnswer = deserializeQuestionAnswer({ question: questionWithNewMeta, value: null, queryResults });
        const collectionType =
          questionWithNewMeta.slug === SLUG__STATE_INCOME
            ? COLLECTION_TYPE__STATE_INCOME
            : COLLECTION_TYPE__STATE_EXPENSES;
        const savedAnswers = queryResults.filter(
          (queryResult) =>
            queryResult.coll_type === collectionType &&
            !queryResult.slug.endsWith('started') &&
            !queryResult.slug.endsWith('done')
        );
        const savedAnswerVals = savedAnswers.reduce((values, savedAnswer) => {
          return {
            ...values,
            [savedAnswer.slug]: {
              value: parseFloat(_.get(savedAnswer, ['answer', 'value']))
            }
          };
        }, {});

        currentAnswer = {
          value: {
            ...defaultAnswer.value,
            ...savedAnswerVals
          }
        };
      } else if (questionWithNewMeta.slug === SLUG__STATE_RETURN) {
        currentAnswer = deserializeQuestionAnswer({ question: questionWithNewMeta, value: null });
      } else {
        const aboutYouStatusPredictions = aboutYouStatusPrefillSelector(getState());

        const queryResult = queryResults.find(
          (queryResult) =>
            queryResult.slug === questionWithNewMeta.endpoint_attr && queryResult.coll_id === currentCollectionId
        );
        const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: questionWithNewMeta });
        const defaultAnswer = deserializeQuestionAnswer({
          question: questionWithNewMeta,
          value: null,
          aboutYouStatusPredictions
        });
        const prefilledAnswer = _.isNil(savedDefaultAnswer) ? defaultAnswer : savedDefaultAnswer;
        currentAnswer = _.get(queryResult, ['answer']) || prefilledAnswer;
      }

      if (currentQuestion.slug === SLUG__INCOME_FREELANCE_JOB) {
        dispatch(
          setBusinessCode(
            _.get(queryResultsMap, [
              COLLECTION_TYPE__INCOME_FREELANCE,
              currentCollectionId,
              SLUG__INCOME_FREELANCE_BUSINESS_CODE
            ])
          )
        );
      } else {
        dispatch(setBusinessCode(null));
      }

      dispatch(setCurrentAnswer(currentAnswer));

      if (currentQuestion.slug === SLUG__SUBMIT_CONFIRM_ID) {
        const idVerificationQuestions = await dispatch(getIdVerificationQuestions());
        if (idVerificationQuestions === 'error') {
          await dispatch(advanceToNextUrl({ history, location }));
        } else {
          const mergedObj = mergeQuestions(currentQuestion, formatIdVerificationQuestions(idVerificationQuestions));
          dispatch(setCurrentQuestion(mergedObj));
        }
      }

      if (currentQuestion.slug !== SLUG__PAST_RETURNS) {
        dispatch(trackQuestionView(currentQuestion));
      }
      await dispatch(setUserWithTaxMilestone());
      await dispatch(setUserWithTaxFlowTaxStage());
      dispatch(showSubscriptionPromptConditional());

      if (
        currentQuestion.slug === SLUG__SPECIAL_WHO &&
        !desktopSellModalVisitedSelector(getState()) &&
        !isReactNative()
      ) {
        dispatch(setDesktopSellModalShow(true));
      }

      dispatch(setContextLoading(false));
      dispatch(setTaxFlowLoader(false));
    } catch (e) {
      dispatch(setContextLoading(false));
      dispatch(setTaxFlowLoader(false));
      await dispatch(setTaxFilingError(e));
    } finally {
      const end = performance.now();
      trackActivity('load time: getQuestionAnswer', { loadTime: end - start, question: slug });
      clearTimeout(timeout);
      dispatch(setCurrentGetQuestionAnswerLoadingSlug(null));
    }
  };

const setUserWithTaxFlowTaxStage = () => async (_dispatch, getState) => {
  const user = userSelector(getState());
  const currentQuestion = currentQuestionSelector(getState());
  const currentNavigationSection = currentNavigationSectionSelector(getState());

  const currentSectionSlug = _.get(currentQuestion, ['section', 'fields', 'slug']);
  const navigationSectionSlug = _.get(currentNavigationSection, 'slug');

  const taxStage = navigationSectionSlug === currentSectionSlug ? navigationSectionSlug : null;

  const screen =
    _.get(currentQuestion, 'flow') === 'export' || _.get(currentQuestion, 'flow') === 'quarterly taxes'
      ? null
      : _.get(currentQuestion, 'slug');
  const percent = percentCompleteSelector(getState());

  setUserWithTaxStage({
    phone: user.phone,
    stage: taxStage,
    screen,
    percent
  });

  await editDataTags({
    ...(!_.isNil(taxStage) ? { taxstagety23: taxStage } : {}),
    ...(!_.isNil(screen) ? { taxscreen: screen } : {})
  });
};

const setUserWithTaxMilestone = () => async (dispatch, getState) => {
  const campaign = campaignSelector(getState());
  const currentQuestion = currentQuestionSelector(getState());
  const nextNavigationItem = nextNavigationItemSelector(getState());
  const accountDetails = accountDetailsSelector(getState());
  const isSpecialDone = isSpecialDoneSelector(getState());
  const currentSummaryItems = currentSummaryItemsSelector(getState());

  const milestones = [
    SLUG__SPECIAL_WHO,
    SLUG__SUBMIT_BYE,
    SLUG__SUBMIT_SENT_TO_IRS,
    SLUG__SUBMIT_ACCEPTED_BY_IRS,
    SLUG__SUBMIT_AUDIT_ISSUE,
    SLUG__SUBMIT_IRS_ISSUE
  ];

  if (!milestones.includes(currentQuestion.slug)) return;

  if (
    (currentQuestion.slug === SLUG__SPECIAL_WHO && !isSpecialDone) ||
    (currentQuestion.question_type === CATEGORY_TYPE_SUMMARY &&
      `${nextNavigationItem.id}-summary` === currentQuestion.slug &&
      isEverySectionItemIncomplete({
        slug: nextNavigationItem.id,
        currentSummaryItems
      })) ||
    currentQuestion.slug === SLUG__SUBMIT_BYE ||
    currentQuestion.slug === SLUG__SUBMIT_SENT_TO_IRS ||
    currentQuestion.slug === SLUG__SUBMIT_ACCEPTED_BY_IRS ||
    currentQuestion.slug === SLUG__SUBMIT_AUDIT_ISSUE ||
    currentQuestion.slug === SLUG__SUBMIT_IRS_ISSUE ||
    currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
  ) {
    const milestone = currentQuestion.slug === SLUG__SPECIAL_WHO ? SLUG__SPECIAL_START : currentQuestion.slug;

    trackActivity('filing: progress', {
      milestone,
      plan: accountDetails.interval,
      price: accountDetails.interval === 'year' ? accountDetails.annualPrice : accountDetails.pricing,
      trial: accountDetails.status === 'pre free trial' || accountDetails.status === 'free trial',
      first: !_.get(campaign, ['milestones', milestone])
    });

    await dispatch(updateMilestones({ [milestone]: true }));
  }
};

export const trackQuestionView = (currentQuestion, properties) => (dispatch, getState) => {
  const isReviewMode = isReviewModeSelector(getState());
  const isQuestionnaireFlow = isQuestionnaireFlowSelector(getState());
  const uiStage = uiStageSelector(getState());
  const taxProgressStage = taxProgressStageSelector(getState());
  const errors = filteredErrorsSelector(getState());
  const currentCollectionId = currentCollectionIdSelector(getState());
  const uniqueValidationErrors = _.uniqBy(errors, 'coll_type').map((error) => {
    return error.coll_type;
  });

  const submitIssues = uncheckedSubmitIssueMessagesSelector(getState());
  const submitWarnings = submitWarningsSelector(getState());
  const blockingErrors = submitWarnings.filter((e) => e.blocking);

  let supportAccessOrigin = supportAccessOriginSelector(getState());
  if (supportAccessOrigin && currentQuestion.slug !== SLUG__CONTACT_SUPPORT) {
    supportAccessOrigin = null;
    dispatch(setSupportAccessOrigin(null));
  }

  const question = isQuestionnaireFlow ? SLUG__BULK_UPLOAD_QUESTIONS : currentQuestion.slug;
  let title = currentQuestion.title;

  trackActivity('question: view', {
    flow: currentQuestion.flow,
    question,
    title,
    isReviewMode,
    taxProgressStage,
    uiStage,
    collectionTypeErrors: uniqueValidationErrors,
    totalErrors: errors.length,
    currentCollectionId,
    ...(isQuestionnaireFlow && {
      clarifyingQuestionSlug: _.get(currentQuestion, 'slug'),
      clarifyingQuestionProgress: questionnairePassedQuestionsSelector(getState()),
      clarifyingQuestionsCount: questionnaireTotalQuestionsSelector(getState())
    }),
    ...(supportAccessOrigin && { origin: supportAccessOrigin }),
    ...(question === SLUG__SUBMIT_FINISH && {
      escType: 'submit_warning',
      escErrors: _.map(submitWarnings, 'slug'),
      totalBlockingEsc: blockingErrors.length,
      totalWarningEsc: submitWarnings.length - blockingErrors.length,
      submitIssues
    }),
    ...(currentQuestion.slug === SLUG__SUBMIT_FINISH ? { submitEnabled: submitEnabledSelector(getState()) } : {}),
    ...properties
  });

  // used by product for YoY analytics
  if (currentQuestion.slug === SLUG__SPECIAL_WHO) {
    trackActivity('question: view', {
      flow: 'tax file',
      question: SLUG__SPECIAL_START
    });
  } else if (currentQuestion.slug === SLUG__SUBMIT_AUDIT_ISSUE) {
    trackActivity('tax file: audit issue', {});
  } else if (currentQuestion.slug === SLUG__SUBMIT_IRS_ISSUE) {
    trackActivity('tax file: IRS issue', {});
  }
};

export const trackQuestionAnswer =
  ({ type, submitType, modalQuestion, irsEnabled, properties, answer, skipped = false } = {}) =>
  (_dispatch, getState) => {
    const currentQuestion = modalQuestion || currentQuestionSelector(getState());
    const currentAnswer = answer || currentAnswerSelector(getState());
    const currentCollectionId = currentCollectionIdSelector(getState());
    const isQuestionnaireFlow = isQuestionnaireFlowSelector(getState());

    let serializedAnswer = serializeQuestionAnswer({ question: currentQuestion, answer: currentAnswer });
    if (currentQuestion.slug === SLUG__SUBMIT_DEBIT_MANUAL) {
      serializedAnswer = _.get(currentAnswer, ['value', SLUG__SUBMIT_DEBIT_ROUTING, 'value']);
    } else if (currentQuestion.slug === SLUG__SUBMIT_DEBIT) {
      if (_.get(currentAnswer, ['value']) === 'ach') {
        const paymentAccount = irsPaymentAccountSelector(getState());
        serializedAnswer = _.get(paymentAccount, 'institution_name');
      } else {
        serializedAnswer = _.get(currentAnswer, ['value']);
      }
    } else if (currentQuestion.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
      // Don't serialize
      serializedAnswer = _.chain(currentAnswer)
        .get('value')
        .mapValues((subQuestionAnswer) => {
          const answer = _.get(subQuestionAnswer, 'value');
          if (_.isNil(answer) || answer === '') {
            return null;
          }

          return Number(answer);
        })
        .value();
    } else if (currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
      serializedAnswer = _.flatMap(currentQuestion.sub_question, (subQuestion) => {
        const subAnswer = _.get(currentAnswer, ['value', subQuestion.slug]);
        const serializedSubAnswer = serializeQuestionAnswer({ question: subQuestion, answer: subAnswer });
        return [
          {
            slug: subQuestion.endpoint_attr,
            value: !_.isNil(serializedSubAnswer) ? serializedSubAnswer : ''
          }
        ];
      });
    } else if (currentQuestion.slug === SLUG__SUBMIT_AUDIT_ISSUE) {
      if (type === 'isEditButton') {
        serializedAnswer = 'edit-return';
      } else {
        serializedAnswer = 're-submit';
      }
    }

    const analyticsProperties = {
      flow: currentQuestion.flow,
      question: isQuestionnaireFlow ? SLUG__BULK_UPLOAD_QUESTIONS : currentQuestion.slug,
      title: currentQuestion.title,
      answer: serializedAnswer,
      currentCollectionId,
      ...(isQuestionnaireFlow && {
        clarifyingQuestionSlug: currentQuestion.slug,
        clarifyingQuestionProgress: questionnairePassedQuestionsSelector(getState())
      }),
      ...(submitType ? { submitType } : {}),
      ...(_.isNil(irsEnabled) ? {} : { irsEnabled }),
      ...properties
    };

    if (type === 'invalid') {
      const formErrors = formErrorsSelector(getState());

      trackActivity('question: validation error', {
        ...analyticsProperties,
        errorSlugs: Object.keys(formErrors),
        errorCount: Object.keys(formErrors).length
      });
    } else {
      trackActivity('question: answer', { ...analyticsProperties, skipped });
    }
  };

export const trackUiStageChange =
  ({ prevUiStage, newUiStage, origin }) =>
  () => {
    trackActivity('tax filing ui stage changed', {
      prevUiStage,
      uiStage: newUiStage,
      timestamp: moment().format(),
      origin
    });
  };

export const updateQuestionAnswer =
  ({ stripe, elements, type, handleErrors }) =>
  async (dispatch, getState) => {
    const start = performance.now();

    const currentQuestion = currentQuestionSelector(getState());
    const isQuestionnaireFlow = isQuestionnaireFlowSelector(getState());

    const timeout = setTimeout(() => {
      trackActivity('timeout', {
        type: 'updateQuestionAnswer',
        question: isQuestionnaireFlow ? SLUG__BULK_UPLOAD_QUESTIONS : currentQuestion.slug
      });
    }, 60000);
    const persistError = persistErrorSelector(getState());

    try {
      dispatch(setUpdating(true));
      if (!persistError) {
        dispatch(setTaxFlowError(''));
      }

      const user = userSelector(getState());
      const collectionType = currentCollectionTypeSelector(getState());
      const currentCollectionId = currentCollectionIdSelector(getState());
      const currentAnswer = currentAnswerSelector(getState());
      const queryResults = queryResultsSelector(getState());
      const jobCategories = jobCategoriesSelector(getState());
      const businessCode = businessCodeSelector(getState());
      const stateTaxSlugs = stateTaxSlugsSelector(getState());
      const isPremiumSubscriber = isPremiumSubscriberSelector(getState());
      const bulkUploadItems = bulkUploadItemsSelector(getState());
      const idVerificationEnabled = idVerificationEnabledSelector(getState());
      const idVerificationDisabled = idVerificationDisabledSelector(getState());
      const verifyIdEnabled = !(idVerificationDisabled || !idVerificationEnabled);

      // track invalid collection id issue
      if (currentCollectionId === 'NaN' || currentCollectionId === 'null') {
        defaultCaptureException('invalid collection id');
      }

      const taxData = [
        ...getStartedUpdates({
          question: currentQuestion,
          collectionId: currentCollectionId,
          stateTaxSlugs,
          queryResults
        }),
        ...getQuestionUpdates({
          question: currentQuestion,
          answer: currentAnswer,
          queryResults,
          collectionType,
          collectionId: currentCollectionId,
          user,
          jobCategories,
          businessCode,
          bulkUploadItems
        }),
        ...getDoneUpdates({
          question: currentQuestion,
          collectionId: currentCollectionId
        })
      ];

      if (!_.isEmpty(taxData)) {
        await dispatch(updateTaxData({ taxData, generateSharedCollectionId: false }));
      }

      const deletions = getQuestionDelete({
        question: currentQuestion,
        collectionId: currentCollectionId,
        answer: currentAnswer,
        updates: taxData,
        queryResults,
        businessCode
      });

      for (const deletion of deletions) {
        await dispatch(deleteTaxData(deletion));
      }

      if (currentQuestion.slug === SLUG__BULK_UPLOAD) {
        await dispatch(generateQuestionnaireQuestions());
      } else if (
        currentQuestion.slug === SLUG__FILING_QUALIFIED &&
        currentAnswer.value === '1' &&
        !isPremiumSubscriber
      ) {
        if (isReactNative()) {
          dispatch(setPremiumUpsellModalShow(true));
        } else {
          dispatch(setPremiumUpsellModalShow(true));
        }
      } else if (currentQuestion.slug === SLUG__SELF_DETAIL || currentQuestion.slug === SLUG__HOME_ADDRESS_DETAIL) {
        const submitSsnMatched = ssnMatchedSelector(getState());
        if (submitSsnMatched !== true) {
          dispatch(resetSubmitSsnMatched());
        }
      } else if (ITEMIZED_DEDUCTION_DEFAULTS_SLUGS.includes(currentQuestion.slug)) {
        await dispatch(updateItemizedDeduction());
      } else if (currentQuestion.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
        await dispatch(getDeductions());
      } else if (currentQuestion.slug === SLUG__SUBMIT_PAY_NOW || currentQuestion.slug === SLUG__SUBMIT_PAY_TEST) {
        dispatch(setIsNextButtonLoading(true));
        await dispatch(getAccountDetails());
        await dispatch(subscribeWithCurrentAnswer({ stripe, elements }));
      } else if (currentQuestion.slug === SLUG__SUBMIT_FINISH) {
        await dispatch(getIdVerificationResult());
        const idVerificationResult = idVerificationResultSelector(getState());
        const ssnMatched = await ssnMatchedSelector(getState());
        if (verifyIdEnabled && idVerificationResult === null && ssnMatched === null) {
          await dispatch(getIdMatch());
        }
      } else if (
        currentQuestion.slug === SLUG__SUBMIT_BYE ||
        currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
      ) {
        setUserWithObj(user.phone, { email: user.email, '2019DONE': 'TRUE' });
      } else if (currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION) {
        await dispatch(setIsConfirmationModalOpen(true));
      } else if (currentQuestion.slug === SLUG__SUBMIT_CONFIRM_ID) {
        const questions = currentQuestionSelector(getState());
        const answers = currentAnswerSelector(getState());
        await dispatch(confirmId(questions, answers));

        const res = idVerificationResultSelector(getState());
        if (res === 'timeout') {
          const idVerificationQuestions = await dispatch(getIdVerificationQuestions());
          const mergedObj = mergeQuestions(currentQuestion, formatIdVerificationQuestions(idVerificationQuestions));

          await dispatch(setCurrentQuestion(mergedObj));
        }
      } else if (
        currentQuestion.slug === SLUG__SUBMIT_BYE ||
        currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL ||
        currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL_ESC ||
        currentQuestion.slug === SLUG__SUBMIT_EMAIL_OUTBOX ||
        currentQuestion.slug === SLUG__SUBMIT_BLOCKED ||
        currentQuestion.slug === SLUG__SUBMIT_BLOCKED_FINAL_REVIEW ||
        currentQuestion.slug === SLUG__SUBMIT_SENT_TO_IRS ||
        currentQuestion.slug === SLUG__SUBMIT_ACCEPTED_BY_IRS ||
        currentQuestion.slug === SLUG__SUBMIT_UNABLE_TO_FILE ||
        currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
      ) {
        if (isReactNative()) {
          sentMsgToReactNative('home');
        } else {
          dispatch(logout());
        }
      } else if (currentQuestion.slug === SLUG__SPECIAL_WHO) {
        await dispatch(mergeAboutYouInfo());
      } else if (currentQuestion.slug === SLUG__BULK_UPLOAD_MULTI_IMAGE) {
        await dispatch(setDropzoneFilesSameForm(currentAnswer.value === '1'));
        await dispatch(setDropzoneFilesReadyForUpload(true));
        await dispatch(setDropzoneLoading(true));
      } else if (currentQuestion.slug === SLUG__BULK_UPLOAD_PHOTO_CAPTURE) {
        await dispatch(setDropzoneFilesReadyForUpload(true));
        await dispatch(setDropzoneLoading(true));
      }

      if ((!handleErrors && currentQuestion.slug !== SLUG__SUBMIT_FINISH) || (handleErrors && _.isEmpty(taxData))) {
        dispatch(trackQuestionAnswer({ type }));
      } else if (handleErrors && !_.isEmpty(taxData)) {
        await dispatch(handleFormErrors());
      }
    } catch (e) {
      if (e.message === 'Validation Error') {
        throw e;
      }

      await dispatch(setTaxFilingError(e));
    } finally {
      const end = performance.now();
      trackActivity('load time: updateQuestionAnswer', {
        loadTime: end - start,
        question: _.get(currentQuestion, ['slug'])
      });
      clearTimeout(timeout);
      dispatch(setUpdating(false));
      dispatch(setIsNextButtonLoading(false));
    }
  };

const mergeAboutYouInfo = () => async (dispatch, getState) => {
  // python backend predictions (all coll types)
  const aboutYouPredictions = aboutYouPredictionsSelector(getState());

  // onboarding answers/tax profile/tax calc predictions (income type)
  const selectedIncomeTypes = incomeTypeSelector(getState());
  const selectedIncomeCollTypes = (selectedIncomeTypes || [])
    .filter((type) => ['owner', 'contractor', 'salaried'].includes(type))
    .map((type) => (type === 'salaried' ? 'income-w2' : 'income-freelance'));

  const selectedCollTypes = [...selectedIncomeCollTypes, ...aboutYouPredictions];
  await dispatch(updateAboutYouInfo(selectedCollTypes));
};

const handleFormErrors = () => async (dispatch, getState) => {
  const isFormValid = () => _.chain(getState()).thru(formErrorsSelector).isEmpty().value();

  if (!isFormValid()) {
    // Skip form
    dispatch(trackQuestionAnswer({ skipped: true }));
    return;
  }

  try {
    dispatch(setIsNextButtonLoading(true));

    const slug = _.chain(getState()).thru(currentQuestionSelector).get('slug').value();

    await dispatch(getErrors({ slug }));

    await dispatch(getQuestionsAnswered());
  } finally {
    dispatch(setIsNextButtonLoading(false));
  }

  if (isFormValid()) {
    dispatch(trackQuestionAnswer());
    return;
  }

  dispatch(trackQuestionAnswer({ type: 'invalid' }));

  throw new Error('Validation Error');
};

const subscribeWithCurrentAnswer =
  ({ stripe, elements }) =>
  async (dispatch, getState) => {
    if (!stripe || !elements) {
      throw new Error(PAYMENT_ERROR_MESSAGE);
    }
    const user = _.get(getState(), ['auth', 'user']);
    const currentAnswer = currentAnswerSelector(getState());
    const paymentType = _.get(currentAnswer, ['value', 'paymentType']);
    if (paymentType === 'paymentRequest') {
      const token = _.get(currentAnswer, ['value', 'token']);
      await dispatch(subscribeWithToken({ token: { id: token } }));
    } else {
      const cardElement = elements.getElement('card');
      if (!cardElement) {
        throw new Error(PAYMENT_ERROR_MESSAGE);
      }
      const { token } = await stripe.createToken(cardElement, {
        name: `${user.firstname} ${user.lastname}`
      });
      await dispatch(subscribeWithToken({ token }));
    }
    dispatch(clearPaymentType());
  };

export const updateQuestionStarted = () => async (_dispatch, getState) => {
  const question = currentQuestionSelector(getState());
  const collectionId = currentCollectionIdSelector(getState());
  const stateTaxSlugs = stateTaxSlugsSelector(getState());
  const queryResults = queryResultsSelector(getState());

  const updates = getStartedUpdates({
    question,
    collectionId,
    stateTaxSlugs,
    queryResults
  });

  if (!_.isEmpty(updates)) {
    await _dispatch(updateTaxData({ taxData: updates, generateSharedCollectionId: false }));
  }
};

const getNextUrlWithBounce =
  ({ location }) =>
  async (dispatch, getState) => {
    const { urlStr, nextCollectionId, questionnaireEarlyExit } = await dispatch(getNextUrl({ location }));
    if (!urlStr) {
      return { urlStr, nextCollectionId, questionnaireEarlyExit };
    }
    const urlObj = url.parse(urlStr);
    const pathComponents = urlObj.pathname.split('/');
    if (
      pathComponents.length >= 3 &&
      [
        PATH_COMPONENT__SPECIAL_EXIT_BOUNCE,
        HOME_PATH_COMPONENTS.EXIT_BOUNCE,
        PATH_COMPONENT__CAR_EXIT_BOUNCE,
        PATH_COMPONENT__SELF_EXIT_BOUNCE,
        PATH_COMPONENT__SPOUSE_EXIT_BOUNCE,
        PATH_COMPONENT__HOME_ADDRESS_EXIT_BOUNCE,
        PATH_COMPONENT__DEPENDENT_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_FREELANCE_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_W2_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_W2G_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_UNEMPLOYMENT_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_INTEREST_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_DIV_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_INVEST_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_CAPITAL_LOSS_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_RETIREMENT_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_STANDARD_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_QUARTERLY_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_HEALTH_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_HSA_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_STUDENT_LOAN_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_STUDENT_TUITION_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_CHILD_CARE_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_CHARITY_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_COGS_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_HOMEOWNER_EXIT_BOUNCE,
        PATH_COMPONENT__SUBMIT_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_CHILD_TAX_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_ENERGY_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_TEACHING_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_QBI_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE,
        PATH_COMPONENT__INCOME_PERSONAL_ITEMS_EXIT_BOUNCE,
        PATH_COMPONENT__CREDIT_DISALLOWED_EXIT_BOUNCE
      ].indexOf(pathComponents[2]) !== -1
    ) {
      const slugMap = {
        ...getSlugMapSpecial(),
        ...getSlugMapHome(),
        ...getSlugMapCar(),
        ...getSlugMapPersonal(),
        ...getSlugMapIncome(),
        ...getSlugMapCredit(),
        ...getSlugMapSubmit()
      };
      const slug = slugMap[pathComponents[2]];
      const question = getQuestionById({ allQuestions: allDerivedQuestionsSelector(getState()), slug });

      const taxData = [
        {
          coll_type: question.collectionType,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: question.endpoint_attr,
          value: '1'
        }
      ];
      await dispatch(updateTaxData({ taxData, generateSharedCollectionId: false }));

      const nextPathComponentMap = {
        ...getNextPathComponentMapSpecial(),
        ...getNextPathComponentMapHome(),
        ...getNextPathComponentMapCar(),
        ...getNextPathComponentMapPersonal(),
        ...getNextPathComponentMapIncome(),
        ...getNextPathComponentMapCredit(),
        ...getNextPathComponentMapSubmit()
      };
      const nextPathComponent = _.get(nextPathComponentMap, [slug, 'nextPathComponent']);
      return {
        urlStr: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
        questionnaireEarlyExit: _.get(nextPathComponentMap, [slug, 'questionnaireEarlyExit'])
      };
    }

    return { urlStr, nextCollectionId, questionnaireEarlyExit };
  };

const getNextUrlWithValidParams =
  ({ location }) =>
  async (dispatch, getState) => {
    const { urlStr, nextCollectionId, questionnaireEarlyExit } = await dispatch(getNextUrlWithBounce({ location }));
    if (!urlStr) {
      return { urlStr, nextSlug: null, questionnaireEarlyExit: true };
    }
    const urlObj = url.parse(urlStr, true);
    const pathComponents = urlObj.pathname.split('/');

    if (pathComponents.length === 2 && pathComponents[1] === PATH_COMPONENT__DASHBOARD) {
      return {
        urlStr: url.format({
          pathname: urlObj.pathname,
          query: _.pick(urlObj.query, ['taxYear'])
        }),
        nextSlug: null,
        nextCollectionId,
        questionnaireEarlyExit: true
      };
    }

    const slugMap = {
      ...getSlugMapSpecial(),
      ...getSlugMapHome(),
      ...getSlugMapCar(),
      ...getSlugMapPersonal(),
      ...getSlugMapIncome(),
      ...getSlugMapCredit(),
      ...getSlugMapSubmit(),
      ...getSlugMapState()
    };
    const slug = slugMap[pathComponents[2]];
    const currentSlug = _.get(currentQuestionSelector(getState()), 'slug');
    return {
      urlStr: url.format({
        pathname: urlObj.pathname,
        query: _.pick(urlObj.query, getAllowedParams({ slug, currentSlug }))
      }),
      nextSlug: slug,
      nextCollectionId,
      questionnaireEarlyExit
    };
  };

const getNextUrl =
  ({ location }) =>
  async (dispatch, getState) => {
    const currentQuestion = currentQuestionSelector(getState());
    const currentAnswer = currentAnswerSelector(getState());
    const queryResults = queryResultsSelector(getState());
    const currentNavigationSectionPathComponent = await dispatch(fetchCurrentSectionPathComponent());

    const clarifyingQuestionsUrl = await dispatch(getNextUrlWithBulkUpload({ currentQuestion }));
    if (clarifyingQuestionsUrl) {
      return { urlStr: clarifyingQuestionsUrl };
    }

    if (currentQuestion.question_type === CATEGORY_TYPE_NAVIGATION) {
      const navigationItem = nextNavigationItemSelector(getState());
      const navigationUrl = dispatch(getNextUrlNavigation({ navigationItem }));
      return { urlStr: navigationUrl };
    }

    if (currentQuestion.slug === SLUG__FIND_WRITE_OFFS) {
      return { urlStr: `/${TAXFLOW_BASE_URL}/${currentNavigationSectionPathComponent}` };
    }

    if (currentQuestion.slug === SLUG__SUBMIT_FINISH) {
      const submitWarnings = await dispatch(getSubmitWarnings());
      if (!_.isEmpty(submitWarnings)) {
        return { urlStr: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_FINISH}` };
      }
    }

    if (
      [
        SLUG__SUBMIT_DEBIT,
        SLUG__SUBMIT_DEBIT_MANUAL,
        SLUG__SUBMIT_PAY_NOW,
        SLUG__SUBMIT_PAY_TEST,
        SLUG__SUBMIT_TAX_AMOUNT
      ].includes(currentQuestion.slug)
    ) {
      await dispatch(getPaid());
    }

    const currentQuery = qs.parse(location.search.slice(1), { ignoreQueryPrefix: true });

    let currentCollectionId = currentCollectionIdSelector(getState());
    const taxFilingPaid = taxFilingPaidSelector(getState());
    const deductions = deductionsSelector(getState());
    const collectionTypeItems = collectionTypeItemsSelector(getState());
    const businessCode = businessCodeSelector(getState());
    const stateTaxSlugs = stateTaxSlugsSelector(getState());
    const formUploadAttempts = formUploadAttemptsSelector(getState());
    const uiStage = uiStageSelector(getState());
    const submitIssueType = submitIssueTypeSelector(getState());
    const uncheckedSubmitIssues = uncheckedSubmitIssueMessagesSelector(getState());
    const irsPaymentAccount = irsPaymentAccountSelector(getState());
    const isPremiumSubscriber = isPremiumSubscriberSelector(getState());
    const idVerificationResult = idVerificationResultSelector(getState());
    const idVerificationEnabled = idVerificationEnabledSelector(getState());
    const idVerificationDisabled = idVerificationDisabledSelector(getState());
    const verifyIdEnabled = !(idVerificationDisabled || !idVerificationEnabled);
    const humanReviewEnabled = humanReviewEnabledSelector(getState());
    const zeroTaxOwed = zeroTaxOwedSelector(getState());
    const useQuestionnaireNavigationLogic =
      isQuestionnaireFlowSelector(getState()) || _.get(currentQuery, 'origin') === 'questionnaire-summary';
    const isPreSubmit = isPreSubmitSelector(getState());
    const isCurrentCollectionAccessibleInBulk = isCurrentCollectionAccessibleInBulkSelector(getState());

    const isCollectionUploaded = isCollectionUploadedSelector(
      currentCollectionId,
      currentQuestion.collectionType
    )(getState());

    let nextPathComponent = null;
    // Should we ignore nextPathComponent if in the questionnaire flow, and instead advance to the next pre-generated question?
    let questionnaireEarlyExit = false;

    // Simple cases wherein one page always navigates to a fixed next page
    const nextPathComponentMap = {
      ...getNextPathComponentMapCar(),
      ...getNextPathComponentMapCredit({ isCollectionUploaded }),
      ...getNextPathComponentMapHome(),
      ...getNextPathComponentMapIncome(),
      ...getNextPathComponentMapPersonal(),
      ...getNextPathComponentMapSpecial(),
      ...getNextPathComponentMapSubmit(),
      ...getNextPathComponentMapState()
    };
    if (_.has(nextPathComponentMap, [currentQuestion.slug])) {
      const questionnaireNextPathComponent = _.get(nextPathComponentMap, [
        currentQuestion.slug,
        'questionnaireNextPathComponent'
      ]);
      nextPathComponent =
        (useQuestionnaireNavigationLogic && questionnaireNextPathComponent) ||
        _.get(nextPathComponentMap, [currentQuestion.slug, 'nextPathComponent']);
      questionnaireEarlyExit = _.get(nextPathComponentMap, [currentQuestion.slug, 'questionnaireEarlyExit']);
    }

    // Simple cases wherein one page always navigates to a fixed next page as a function of a selected option on the page
    const optionPathComponentMap = {
      ...getOptionPathComponentMapSpecial({ isPremiumSubscriber }),
      ...getOptionPathComponentMapCar(),
      ...getOptionPathComponentMapCredit({ isCollectionUploaded }),
      ...getOptionPathComponentMapHome(),
      ...getOptionPathComponentMapIncome(),
      ...getOptionPathComponentMapPersonal(),
      ...getOptionPathComponentMapState()
    };

    if (_.has(optionPathComponentMap, [currentQuestion.slug])) {
      const questionnaireNextPathComponent = _.get(optionPathComponentMap, [
        currentQuestion.slug,
        _.get(currentAnswer, ['value']),
        'questionnaireNextPathComponent'
      ]);
      nextPathComponent =
        (useQuestionnaireNavigationLogic && questionnaireNextPathComponent) ||
        _.get(optionPathComponentMap, [currentQuestion.slug, _.get(currentAnswer, ['value']), 'nextPathComponent']);
      questionnaireEarlyExit = _.get(optionPathComponentMap, [
        currentQuestion.slug,
        _.get(currentAnswer, ['value']),
        'questionnaireEarlyExit'
      ]);
    }

    // Special cases requiring more than a simple state transition model
    if (!nextPathComponent) {
      const {
        nextPathComponent: updatedNextPathComponent,
        questionnaireEarlyExit: updatedQuestionnaireEarlyExit,
        questionnaireNextPathComponent
      } = _.reduce(
        [
          getNextPathComponentCar,
          getNextPathComponentHome,
          getNextPathComponentCredit,
          getNextPathComponentIncome,
          getNextPathComponentSubmit,
          getNextPathComponentState,
          getNextPathComponentPersonal
        ],
        ({ nextPathComponent, questionnaireEarlyExit, questionnaireNextPathComponent }, getNextPathComponent) => {
          if (_.isNil(nextPathComponent)) {
            return getNextPathComponent({
              question: currentQuestion,
              answer: currentAnswer,
              collectionId: currentCollectionId,
              queryResults,
              deductions,
              businessCode,
              currentCollectionId,
              uploadAttempts: formUploadAttempts,
              uiStage,
              submitIssueType,
              paid: taxFilingPaid,
              uncheckedSubmitIssues,
              irsPaymentAccount,
              idVerificationResult,
              verifyIdEnabled,
              humanReviewEnabled,
              zeroTaxOwed,
              stateTaxSlugs,
              collectionTypeItems,
              currentNavigationSectionPathComponent,
              isPreSubmit,
              isCurrentCollectionAccessibleInBulk
            });
          }
          return { nextPathComponent, questionnaireEarlyExit, questionnaireNextPathComponent };
        },
        { nextPathComponent, questionnaireEarlyExit }
      );
      nextPathComponent =
        (useQuestionnaireNavigationLogic && questionnaireNextPathComponent) || updatedNextPathComponent;
      questionnaireEarlyExit = updatedQuestionnaireEarlyExit;
    }

    if (nextPathComponent) {
      nextPathComponent = await dispatch(
        getNextPathComponentWithSkip({
          pathComponent: nextPathComponent,
          collectionType: currentQuestion.collectionType,
          collectionId: currentCollectionId,
          isCollectionUploaded
        })
      );
    }

    if (nextPathComponent) {
      nextPathComponent = await dispatch(
        getNextPathComponentWithUpload({
          pathComponent: nextPathComponent
        })
      );
    }

    let basePathComponent = TAXFLOW_BASE_URL;

    let nextQuery = {
      collectionId: currentCollectionId,
      ...(_.has(currentQuery, 'origin') ? { origin: currentQuery.origin } : {}),
      ...getNextQueryPersonal({ question: currentQuestion, answer: currentAnswer }),
      ...getNextQueryCredit({
        question: currentQuestion,
        nextPathComponent
      }),
      ...getNextQueryState({
        question: currentQuestion,
        queryResults,
        collectionId: currentCollectionId
      })
    };

    if (nextPathComponent) {
      return {
        urlStr: url.format({
          pathname:
            nextPathComponent === PATH_COMPONENT__DASHBOARD
              ? `/${PATH_COMPONENT__DASHBOARD}`
              : `/${basePathComponent}/${nextPathComponent}`,
          query: nextQuery
        }),
        questionnaireEarlyExit,
        nextCollectionId: _.get(nextQuery, 'collectionId')
      };
    }

    return { urlStr: null };
  };

const advanceToNextQuestionnaireQuestion =
  ({ nextSlug, nextCollectionId, questionnaireEarlyExit, history }) =>
  async (dispatch, getState) => {
    const currentCollectionId = currentCollectionIdSelector(getState());
    const currentQuestion = currentQuestionSelector(getState());
    if (currentQuestion.slug === SLUG__FILING_QUALIFIED && nextSlug === SLUG__FILING_QUALIFIED) {
      return;
    }
    // If nav logic provides a next question and we want to include it in the questionnaire flow (not early exit), advance to such "follow-up" question
    // Else, advance to the next generated questionnaire question
    const maybeFollowUpQuestion = !_.isNil(nextSlug) &&
      !questionnaireEarlyExit && {
        type: 'more-info',
        coll_id: currentCollectionId !== nextCollectionId ? nextCollectionId : undefined,
        slug: nextSlug
      };
    const { questionnaireComplete } = await dispatch(progressToNextQuestionnaireQuestion({ maybeFollowUpQuestion }));
    if (questionnaireComplete) {
      dispatch(setIsQuestionnaireFlow(false));
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_SUMMARY}`);
    }
  };

export const advanceToNextUrl =
  ({ history, location }) =>
  async (dispatch, getState) => {
    const currentQuestion = currentQuestionSelector(getState());
    const slug = _.get(currentQuestion, 'slug');

    const { urlStr, nextSlug, nextCollectionId, questionnaireEarlyExit } = await dispatch(
      getNextUrlWithValidParams({ location })
    );

    const isQuestionnaireFlow = isQuestionnaireFlowSelector(getState());
    if (isQuestionnaireFlow) {
      await dispatch(
        advanceToNextQuestionnaireQuestion({
          nextSlug,
          nextCollectionId,
          questionnaireEarlyExit,
          history
        })
      );
      return;
    }
    const originParam = getOriginParam({ location });
    if (questionnaireEarlyExit && originParam === 'questionnaire-summary') {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_SUMMARY}`);
      return;
    }

    if (!nextSlug || (originParam === 'bulk' && nextSlug === SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO)) {
      if (originParam === 'bulk') {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__BULK_UPLOAD}`);
      } else {
        await dispatch(advanceToCurrentSectionPage({ history }));
      }
      return;
    }

    const resError = resErrorSelector(getState());
    const persistError = persistErrorSelector(getState());
    if (!persistError && resError) {
      return;
    }
    if (isReactNative()) {
      const urlObj = url.parse(urlStr);
      if (urlObj.pathname === `/${PATH_COMPONENT__DASHBOARD}`) {
        sentMsgToReactNative('home');
        return;
      }

      const paid = taxFilingPaidSelector(getState());
      if (slug === SLUG__SUBMIT_SWITCH_TO_ANNUAL && !paid) {
        sentMsgToReactNative('open_subscribe_modal');
        return;
      }
    }

    // Temporary questions which the users should not be able to return to
    if ([SLUG__BULK_UPLOAD_MULTI_IMAGE, SLUG__BULK_UPLOAD_PHOTO_CAPTURE].includes(slug)) {
      history.goBack();
      return;
    }

    if (nextSlug === SLUG__SUBMIT_BYE) {
      const currentUiStage = uiStageSelector(getState());
      let submitType = 'initial_submit';
      let updatedUiStage = UI_STAGE__PURGATORY;
      if (currentUiStage === UI_STAGE__USER_ESC) {
        submitType = 'audit_resubmit';
        updatedUiStage = UI_STAGE__OPS_REVIEW;
      } else if (currentUiStage === UI_STAGE__REJECTED_ESC) {
        submitType = 'irs_resubmit';
        updatedUiStage = UI_STAGE__OPS_REVIEW;
      }

      // update ui
      await dispatch(updateCampaign({ ui_stage: updatedUiStage }));
      dispatch(
        trackUiStageChange({
          prevUiStage: currentUiStage,
          newUiStage: updatedUiStage,
          origin: `tax filing ui (user submission - ${submitType})`
        })
      );

      // track
      dispatch(trackQuestionAnswer({ submitType }));

      // send tax submission to backend
      await dispatch(postTaxSubmission(submitType));
    }

    history.push(urlStr);
  };

export const advanceToCurrentSectionPage =
  ({ history, replaceLocation = false }) =>
  async (dispatch) => {
    const nextPathComponent = await dispatch(fetchCurrentSectionPathComponent());

    replaceLocation
      ? history.replace(`/${TAXFLOW_BASE_URL}/${nextPathComponent}`)
      : history.push(`/${TAXFLOW_BASE_URL}/${nextPathComponent}`);
  };

const fetchCurrentSectionPathComponent = () => async (dispatch, getState) => {
  const [specialTaxData, navigationSections, submitIssues] = await Promise.all([
    dispatch(getTaxData({ collectionType: 'special' })),
    dispatch(getNavigationSections()),
    dispatch(getSubmitIssues()),
    // TODO - move campaign logic into rtk-query
    ...(_.isNil(campaignSelector(getState())) || _.isEmpty(campaignSelector(getState()))
      ? [dispatch(getCampaign())]
      : [])
  ]);
  const uiStage = uiStageSelector(getState());
  return getCurrentSectionPathComponent({
    specialTaxData,
    navigationSections,
    uiStage,
    submitIssues
  });
};

const editDataTags = async (dataTags) => {
  try {
    await axios.post(`${baseUrl}api/profile/edit-data-tags`, {
      dataTags
    });
  } catch (e) {
    defaultCaptureException(e);
  }
};
