import React from 'react';
import { Route } from 'react-router-dom';
import TaxFlowQuestionTypeItem from '@app/src/taxflow/main/components/TaxFlowQuestionTypeItem';

class TaxFlowRoutes extends React.Component {
  render() {
    return <Route component={TaxFlowQuestionTypeItem} />;
  }
}

export default TaxFlowRoutes;
