import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import SearchList from '@app/src/Components/Common/SearchList/SearchList';
import { selectedBulkManualEntryItemSelector } from '@app/src/selectors/taxFlowSelectors';
import { bulkUploadItemsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import FuzzySearch from 'fuzzy-search';
import { setSelectedBulkManualEntryItem } from '@app/src/actions/taxFlowActions';
import { colorFilterSecondary } from '@app/src/theme';

const TaxFlowFormInputDropdown = ({ bulkUploadItems, selectedListItem, setSelectedListItem }) => {
  const [listItems, setListItems] = useState([]);
  const [fuzzySearcher, setFuzzySearcher] = useState(new FuzzySearch([], ['listDisplayName'], { sort: true }));
  const [query, setQuery] = useState('');

  useEffect(() => {
    setSelectedListItem(undefined);
  }, [setSelectedListItem]);

  useEffect(() => {
    const itemsMatchingQuery = fuzzySearcher.search(query);
    setListItems(
      _.map(bulkUploadItems, ({ listDisplayName, iconUrl, collectionType, subType, supportManualEntry }, idx) => ({
        id: idx,
        name: listDisplayName,
        iconUrl,
        checked:
          _.get(selectedListItem, 'collectionType') === collectionType &&
          _.get(selectedListItem, 'subType') === subType,
        showInList: supportManualEntry && !selectedListItem && _.some(itemsMatchingQuery, { collectionType, subType }),
        collectionType,
        subType
      }))
    );
  }, [bulkUploadItems, selectedListItem, query, fuzzySearcher]);

  useEffect(() => {
    setFuzzySearcher(new FuzzySearch(bulkUploadItems, ['listDisplayName'], { sort: true }));
  }, [bulkUploadItems, setFuzzySearcher]);

  const onSearch = (query) => {
    setQuery(query);
    setSelectedListItem(undefined);
  };

  const onAddItem = (item) => {
    setSelectedListItem(_.find(listItems, { collectionType: item.collectionType, subType: item.subType }));
    setQuery(item.name);
  };

  const onRemoveItem = () => {
    setSelectedListItem(undefined);
  };

  return (
    <div className='steps-body'>
      <SearchList
        items={listItems}
        query={query}
        onAddItem={onAddItem}
        onRemoveItem={onRemoveItem}
        onSearch={onSearch}
        iconFilter={colorFilterSecondary}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bulkUploadItems: bulkUploadItemsSelector(state),
  selectedListItem: selectedBulkManualEntryItemSelector(state)
});

const mapDispatchToProps = {
  setSelectedListItem: setSelectedBulkManualEntryItem
};

const ConnectedTaxFlowFormInputDropdown = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormInputDropdown);

export default ConnectedTaxFlowFormInputDropdown;
