import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { LampOn } from 'iconsax-react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setExpenseReviewCurrentRule, setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import IconCheckGreen from '@app/src/assets/icon-check-green.svg?react';
import IconClose from '@app/src/assets/icon-close.svg?react';
import ExpenseReviewCategoryIcon from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryIcon';
import ExpenseReviewMerchantSearchBar from '@app/src/Components/ExpenseReview/ExpenseReviewMerchantSearchBar';
import KeeperSelect from '@app/src/Components/Common/KeeperSelect/KeeperSelect';
import {
  allCategoriesSelector,
  currentRuleSelector,
  validRuleSelector,
  merchantSearchResultsSelector,
  loadingSelector,
  rulesSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { createExpenseRule, updateExpenseRule, deleteExpenseRule } from '@app/src/services/expenseReviewService';
import { colorNeutralGraphite, colorWarning } from '@app/src/theme';

import '@app/src/Components/ExpenseReview/ExpenseReviewModalEditRule.scss';

const ExpenseReviewModalEditRule = ({
  rule,
  rules,
  categories,
  setExpenseReviewCurrentRule,
  setExpenseReviewModalType,
  merchants,
  validRule,
  createExpenseRule,
  updateExpenseRule,
  deleteExpenseRule,
  loading
}) => {
  const ruleId = _.get(rule, 'rule_id', null);
  const ruleName = _.get(rule, 'name', '');
  const rulePrediction = _.get(rule, 'prediction', '');
  const ruleCategory = _.get(rule, 'category_update', '');

  const existingRules = rules.map(({ name }) => name);

  const merchant = merchants.find((merchant) => merchant.clean_name === ruleName);

  useEffect(() => {
    trackActivity(`navigation: ${ruleId ? 'edit' : 'create'} rule`);
  }, [ruleId, setExpenseReviewCurrentRule]);

  useEffect(() => {
    return () => {
      setExpenseReviewCurrentRule({});
    };
  }, [setExpenseReviewCurrentRule]);

  const handleStatusChange = ({ target: { value } }) => {
    setExpenseReviewCurrentRule({ ...rule, prediction: value });
  };

  const handleCategoryChange = ({ target: { value } }) => {
    setExpenseReviewCurrentRule({ ...rule, category_update: value });
  };

  const handleMerchantChange = (value) => {
    if (existingRules.includes(value)) {
      setExpenseReviewCurrentRule(rules.find(({ name }) => name === value));
    } else {
      setExpenseReviewCurrentRule({ ...rule, name: value });
    }
  };

  const handleSave = async () => {
    await createExpenseRule();
    setExpenseReviewModalType('');
  };

  const handleUpdate = async () => {
    await updateExpenseRule();
    setExpenseReviewModalType('');
  };

  const handleDelete = async () => {
    await deleteExpenseRule();
    setExpenseReviewModalType('');
  };

  const toggleApplyToPreviousTransactions = () => {
    setExpenseReviewCurrentRule({ ...rule, update_expenses: !rule.update_expenses });
  };

  return (
    <div className='rule-modal'>
      <div className='text-xl bold'>{ruleId ? 'Edit rule' : 'Create a rule'}</div>
      <Divider />
      <Stack spacing={1} className='rules'>
        <div className='text-lg bold'>IF</div>
        <div>
          <InputLabel className='text-base'>Merchant name</InputLabel>
          {ruleId ? (
            <OutlinedInput value={ruleName} fullWidth readOnly />
          ) : (
            <ExpenseReviewMerchantSearchBar onSelect={handleMerchantChange} origin='rules-web' />
          )}
          <Collapse in={!!merchant}>
            <div className='rule-modal-text-count text-xs'>{merchant?.count} transactions found</div>
          </Collapse>
        </div>
        <div className='bold' style={{ fontSize: '20px' }}>
          THEN
        </div>
        <div>
          <InputLabel className='text-base'>Status</InputLabel>
          <KeeperSelect value={rulePrediction} defaultValue='' onChange={handleStatusChange}>
            <MenuItem value=''>
              <div style={{ color: colorNeutralGraphite }}>Select a status</div>
            </MenuItem>
            <MenuItem value='yes'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconCheckGreen />
                <div style={{ marginLeft: '4px' }}>Deduction</div>
              </div>
            </MenuItem>
            <MenuItem value='no'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconClose />
                <div style={{ marginLeft: '4px' }}>Not deductible</div>
              </div>
            </MenuItem>
            <MenuItem value='maybe'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LampOn color={colorWarning} />
                <div style={{ marginLeft: '4px' }}>Ask me</div>
              </div>
            </MenuItem>
          </KeeperSelect>
        </div>
        <div>
          <InputLabel className='text-base'>Category</InputLabel>
          <KeeperSelect value={ruleCategory} defaultValue='' onChange={handleCategoryChange}>
            <MenuItem value=''>
              <div style={{ color: colorNeutralGraphite }}>Select a category</div>
            </MenuItem>
            {categories.map(({ id, display_name_without_emoji }, idx) => (
              <MenuItem key={idx} value={id}>
                <div className='flex align-center'>
                  <ExpenseReviewCategoryIcon id={id} className='expense-review-category-cell-icon' size={20} />
                  <div style={{ marginLeft: '4px' }}>{_.capitalize(display_name_without_emoji)}</div>
                </div>
              </MenuItem>
            ))}
          </KeeperSelect>
        </div>
        <Collapse in={!ruleId && !!merchant}>
          <div className='flex row space-between align-center'>
            <div>Apply rule to past transactions ({merchant?.count})</div>
            <Switch onChange={toggleApplyToPreviousTransactions} defaultChecked={false} />
          </div>
        </Collapse>
        <div>
          <LoadingButton
            variant='contained'
            color='secondary'
            fullWidth
            onClick={ruleId ? handleUpdate : handleSave}
            disabled={!validRule}
            loading={loading}
          >
            {ruleId ? 'Update' : 'Save'}
          </LoadingButton>
        </div>
        {ruleId && !loading && (
          <Button fullWidth onClick={handleDelete} sx={{ color: 'danger.main' }}>
            Delete rule
          </Button>
        )}
      </Stack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  rule: currentRuleSelector(state),
  rules: rulesSelector(state),
  categories: allCategoriesSelector(state),
  merchants: merchantSearchResultsSelector(state),
  validRule: validRuleSelector(state),
  loading: loadingSelector(state)
});

const mapDispatchToProps = {
  setExpenseReviewCurrentRule,
  deleteExpenseRule,
  setExpenseReviewModalType,
  createExpenseRule,
  updateExpenseRule
};

const ConnectedExpenseReviewModalEditRule = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalEditRule);

export default ConnectedExpenseReviewModalEditRule;
