import React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { BANK_LINK_SKIP_TITLE } from '@app/src/constants/loadingPageConstants';
import { getQuestion } from '@app/src/services/onboardingService';
import { bankListSelector } from '@app/src/selectors/bankSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import getImageUrl from '@app/src/utils/getImageUrl';
import { useWindowSize } from 'react-use';

const OnboardingAppDownload = ({ bankList }) => {
  const isMobile = useWindowSize().width < 1024;
  const bankLinkSkipped = bankList.length === 0;

  const onDownloadClick = () => {
    trackActivity('app store clicked', { store: 'download' });
  };

  const onAppStoreClick = (store) => {
    trackActivity('app store clicked', { store });
    if (store === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.keepertax&hl=en&gl=US', '_blank');
    } else {
      window.open('https://itunes.apple.com/app/id1471090941', '_blank');
    }
  };

  return (
    <div className='h-onboarding-content-question-download'>
      <div className='h-onboarding-content-question-download-header'>
        <img
          src={getImageUrl('desktop.svg')}
          alt='Computer'
          className='h-onboarding-content-question-download-header-computer'
        />
        <img
          src={getImageUrl('onboarding-phone.svg')}
          alt='Phone'
          className='h-onboarding-content-question-download-header-phone'
        />
      </div>
      <div>
        <div>
          <h1>{bankLinkSkipped ? BANK_LINK_SKIP_TITLE : 'To see your results on mobile, please download the app'}</h1>
          {isMobile ? (
            <div style={{ margin: '16px 0' }}>
              <Button
                variant='contained'
                color='secondary'
                fullWidth
                type='button'
                href='https://keeper.tax/get-the-app'
                onClick={onDownloadClick}
              >
                Download the app
              </Button>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={getImageUrl('apple-store-logo.svg')}
                    alt='Apple App Store'
                    onClick={() => onAppStoreClick('apple')}
                    role='button'
                  />
                  <img
                    src={getImageUrl('google-store-logo.svg')}
                    alt='Google Play Store'
                    onClick={() => onAppStoreClick('android')}
                    role='button'
                  />
                </div>
                <img src={getImageUrl('get-the-app-qr.svg')} alt='QR code' height={130} width={130} />
              </div>
              <div className=''>To download, scan QR code with your phone's camera</div>
            </div>
          )}
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            Or go to <a href='https://dashboard.keepertax.com/expenses'>keepertax.com</a>
            {isMobile && ' on your computer'}
          </div>
        </div>
      </div>
      <div className='h-onboarding-content-question-download-reviews'>
        <img src={getImageUrl('download-rating.svg')} alt='Rating' />
        <p style={{}}>20,000+ Reviews</p>
        <div style={{ width: '60%', display: 'flex', justifyContent: 'center' }}>
          <img src={getImageUrl('download-featured.svg')} alt='Featured' />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bankList: bankListSelector(state)
});

const mapDispatchToProps = {
  getQuestion
};

const ConnectedOnboardingAppDownload = connect(mapStateToProps, mapDispatchToProps)(OnboardingAppDownload);

export default ConnectedOnboardingAppDownload;
