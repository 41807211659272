import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import React, { useState } from 'react';

import KeeperSelect from '@app/src/Components/Common/KeeperSelect/KeeperSelect';
import { colorPrimary, colorPrimaryAlt, colorSurface } from '@app/src/theme';

const ExpenseReviewEditPanelDropdown = ({ displayName, value, options, disabled, onChange, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <KeeperSelect
      disabled={disabled}
      label='Status'
      open={isOpen}
      value={!_.isNil(value) ? value : ''}
      onChange={onChange}
      onClick={onClick}
      renderValue={() => _.upperFirst(displayName)}
      inputProps={{ 'aria-label': 'Without label' }}
      {...props}
    >
      {options.map((option) => (
        <MenuItem
          key={_.get(option, 'value')}
          value={_.get(option, 'value')}
          sx={{
            '&.Mui-selected': { backgroundColor: colorPrimaryAlt, color: colorSurface },
            '&.Mui-selected:hover': { backgroundColor: colorPrimary, color: colorSurface }
          }}
        >
          {_.get(option, 'displayName')}
        </MenuItem>
      ))}
    </KeeperSelect>
  );
};

export default ExpenseReviewEditPanelDropdown;
