import _ from 'lodash';
import { createSelector } from 'reselect';

const bankLinksSelector = (state) => _.get(state, ['pricing2', 'bankLinks']);
export const loadingSelector = (state) => _.get(state, ['pricing2', 'loading']);
export const updatingSelector = (state) => _.get(state, ['pricing2', 'updating']);
export const walletTypeSelector = (state) => _.get(state, ['pricing2', 'walletType']);
const isCardValidSelector = (state) => _.get(state, ['pricing2', 'isCardValid']);
export const bankSelector = (state) => _.get(state, ['pricing2', 'bank']);
export const originSelector = (state) => _.get(state, ['pricing2', 'origin']);
export const subscriptionTypeSelector = (state) => _.get(state, ['pricing2', 'subscriptionType']);

const userPricingSelector = (state) => _.get(state, ['auth', 'settings', 'pricing'], {});

export const chargeableAccountsSelector = createSelector([bankLinksSelector], (bankLinks) => {
  return bankLinks
    .filter((bankLink) => bankLink.linked)
    .flatMap((bankLink) => {
      return bankLink.subAccounts
        .filter((subAccount) => subAccount.chargeable)
        .map((subAccount) => {
          const mask = _.get(subAccount, 'mask') ? _.get(subAccount, 'mask').padStart(4, 0) : null;

          return {
            institution_name: bankLink.institution_name,
            institution_id: bankLink.institution_id,
            logo: bankLink.logo,
            account_id: subAccount.account_id,
            name: subAccount.name,
            mask
          };
        });
    });
});

export const isUpdateEnabledSelector = createSelector([bankSelector, isCardValidSelector], (bank, isCardValid) => {
  return !!bank || isCardValid;
});

export const hasDividerSelector = createSelector(
  [walletTypeSelector, chargeableAccountsSelector],
  (walletType, chargeableAccounts) => {
    return !!walletType || !!chargeableAccounts.length;
  }
);

export const trialLengthSelector = (state) => {
  const accountDetails = _.get(state, ['pricing2', 'accountDetails']);
  return _.get(accountDetails, 'free_trial_length');
};

export const currentSubscriptionTypeSelector = createSelector([userPricingSelector], (pricing) => {
  if (_.includes(pricing, 'premium')) {
    return 'premium';
  } else if (_.includes(pricing, 'annual')) {
    return 'annual';
  } else {
    return 'monthly';
  }
});

export const currentSubscriptionPriceSelector = createSelector([userPricingSelector], (pricing) =>
  String(pricing).match(/\d/g)?.join('')
);
