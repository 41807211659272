import React from 'react';
import { connect } from 'react-redux';
import StarOutlineIcon from '@app/src/assets/star.svg?react';
import StarFilledIcon from '@app/src/assets/star-filled.svg?react';
import { trackActivity, setUserWithObj } from '@app/src/services/analyticsService';
import { setTaxFlowRating, setTaxFlowHoverRating } from '@app/src/actions/taxFlowActions';
import { userSelector } from '@app/src/selectors/userSelectors';
import { ratingSelector, hoverRatingSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import classNames from 'classnames';

const TaxFlowRatingFeedbackStar = ({
  className,
  isFilled,
  starValue,
  user,
  setTaxFlowRating,
  setTaxFlowHoverRating
}) => {
  const phone = user.phone;

  const updateRating = (newRating) => {
    trackActivity('filing feedback: submitted', { rating: newRating });
    setUserWithObj(phone, { rating: newRating });
    setTaxFlowRating(newRating);
  };

  return (
    <div
      className={classNames('taxflow-rating-feedback-star', className)}
      onClick={() => updateRating(starValue)}
      onMouseEnter={() => setTaxFlowHoverRating(starValue)}
      role='button'
    >
      {isFilled ? <StarFilledIcon /> : <StarOutlineIcon />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  rating: ratingSelector(state),
  hoverRating: hoverRatingSelector(state)
});

const mapDispatchToProps = {
  setTaxFlowRating,
  setTaxFlowHoverRating
};

const ConnectedTaxFlowRatingFeedbackStar = connect(mapStateToProps, mapDispatchToProps)(TaxFlowRatingFeedbackStar);

export default ConnectedTaxFlowRatingFeedbackStar;
