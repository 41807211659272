import Stack from '@mui/material/Stack';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setHolisticOnboardingMeal } from '@app/src/actions/holisticOnboardingActions';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import ResponseText from '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText';
import { mealSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';
import { autoAdvance } from '@app/src/utils/holisticOnboardingUtils';

const OnboardingMeal = ({ meal, setHolisticOnboardingMeal, onContinue }) => {
  const {
    location: { pathname },
    push
  } = useHistory();

  const handleSelect = (value) => {
    setHolisticOnboardingMeal(value);

    if (value === 0) {
      autoAdvance({ f: onContinue, push, pathname });
      return;
    }
  };

  return (
    <Stack spacing={1}>
      <ToggleButton selected={meal === 1} onClick={() => handleSelect(1)}>
        Yes, sometimes work comes up
      </ToggleButton>
      <ToggleButton selected={meal === 0} onClick={() => handleSelect(0)}>
        Not really, no
      </ToggleButton>
      <ResponseText pathname={pathname} visible={meal === 1} />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  meal: mealSelector(state)
});

const mapDispatchToProps = {
  setHolisticOnboardingMeal,
  onContinue
};

const ConnectedOnboardingMeal = connect(mapStateToProps, mapDispatchToProps)(OnboardingMeal);

export default ConnectedOnboardingMeal;
