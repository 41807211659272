import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  currentQuestionnaireQuestionSelector,
  questionnairePassedSectionsSelector,
  questionnaireTotalSectionsSelector
} from '@app/src/selectors/taxFlowSelectors';
import {
  SLUG__BULK_UPLOAD_QUESTIONS_ADD_FORMS,
  SLUG__BULK_UPLOAD_QUESTIONS_ALL_FORMS_UPLOADED
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import ArrowRightIcon from '@app/src/assets/arrow-right.svg?react';
import SectionedProgressBar from '@app/src/Components/Common/SectionedProgressBar/SectionedProgressBar';
import '@app/src/Components/TaxFlow/Common/TaxFlowQuestionnaireProgressBar.scss';

function TaxFlowQuestionnaireProgressBar({ currentQuestionnaireQuestion, passedSections, totalSections }) {
  if (_.isNil(totalSections) || _.isNil(passedSections)) {
    return null;
  }

  return (
    <>
      <SectionedProgressBar totalSections={totalSections} currentSection={passedSections + 1} className='mb-16' />
      <div className='questionnaire-progress-bar-breadcrumb'>
        <div className='questionnaire-progress-bar-breadcrumb-parent'>Questionnaire</div>
        <ArrowRightIcon width={16} height={16} />
        <div className='questionnaire-progress-bar-breadcrumb-child'>
          {currentQuestionnaireQuestion.type === 'form-review' ||
          _.includes(
            [SLUG__BULK_UPLOAD_QUESTIONS_ADD_FORMS, SLUG__BULK_UPLOAD_QUESTIONS_ALL_FORMS_UPLOADED],
            currentQuestionnaireQuestion.slug
          )
            ? 'Review forms'
            : 'Follow up questions'}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentQuestionnaireQuestion: currentQuestionnaireQuestionSelector(state),
  passedSections: questionnairePassedSectionsSelector(state),
  totalSections: questionnaireTotalSectionsSelector(state)
});

const ConnectedTaxFlowQuestionnaireProgressBar = connect(mapStateToProps)(TaxFlowQuestionnaireProgressBar);

export default ConnectedTaxFlowQuestionnaireProgressBar;
