import axios from 'axios';

import { setSendingLinkType } from '@app/src/actions/mobileRedirectActions';
import { setTaxFlowError } from '@app/src/actions/taxFlowActions';
import { serverUrl } from '@app/src/global/Environment';
import { formatPhoneNumberToDisplay } from '@app/src/global/Helpers';
import { userSelector } from '@app/src/selectors/userSelectors';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const sendDownloadText =
  ({ linkType }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setSendingLinkType(linkType));
      await axios.post(`${baseUrl}api/misc/send-download-text`, {});

      if (linkType === 'write-offs-link') {
        const user = userSelector(getState());
        const message = `The link has been sent to ${formatPhoneNumberToDisplay(user.phone)}!`;
        dispatch(setTaxFlowError(message));
        notify(message);
      }

      dispatch(setSendingLinkType(null));
    } catch (e) {
      defaultCaptureException(e);
      dispatch(setSendingLinkType(null));
    }
  };
