import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ArrowLeft from '@app/src/assets/arrow-left.svg?react';
import LoadingButton from '@mui/lab/LoadingButton';
import '@app/src/Components/Onboarding/Question/OnboardingFoot.scss';

const OnboardingFoot = ({
  isPrev,
  isNext,
  isSkipEnabled,
  isDisabled,
  isNextButtonLoading,
  buttonLabel,
  onPrev,
  onNext
}) => (
  <div className='onboarding-foot'>
    <div className='onboarding-foot-content'>
      <Stack spacing={1} direction='row' className='onboarding-foot-actions'>
        {isPrev && (
          <Button variant='outlined' disableElevation className='onboarding-foot-button' onClick={onPrev}>
            <ArrowLeft />
          </Button>
        )}
        {isNext && (
          <div>
            <LoadingButton
              variant={isSkipEnabled && !isDisabled ? 'outlined' : 'contained'}
              color='primary'
              disableElevation
              className='onboarding-foot-button'
              disabled={isDisabled}
              loading={isNextButtonLoading}
              onClick={onNext}
            >
              <div className='onboarding-foot-button-content'>{isSkipEnabled ? 'Skip' : buttonLabel || 'Next'}</div>
            </LoadingButton>
          </div>
        )}
      </Stack>
    </div>
  </div>
);

export default OnboardingFoot;
