import axios from 'axios';
import _ from 'lodash';

import { clearFormUploadAlert, setFormUploadCloudStatus } from '@app/src/actions/socketActions';
import {
  setFormUploadStatus,
  setIdVerificationResult,
  setSsnMatched,
  setTaxFlowError,
  setTaxFlowLoader
} from '@app/src/actions/taxFlowActions';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { setCampaign } from '@app/src/actions/workActions';
import { serverUrl } from '@app/src/global/Environment';
import { currentUploadsSelector } from '@app/src/selectors/taxFlowSelectors';
import { taxValidationErrorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { campaignSelector } from '@app/src/selectors/workSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { getErrors } from '@app/src/services/taxValidationService';
import { TAXFLOW__FORM_UPLOAD_OCR_FAILED_STATES } from '@app/src/taxflow/collection/constants/formUploadConstants';
import {
  setBulkUploadOcrError,
  showCollectionFormUploadErrorModal
} from '@app/src/taxflow/collection/services/collectionService';
import { getUploadAttemptFileNames } from '@app/src/taxflow/collection/utils/collectionUtils';
import { setTaxFilingError } from '@app/src/taxflow/common/services/errors';
import { formUploadKeyedFilesSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  COLLECTION_TYPE__INCOME_DIV,
  COLLECTION_TYPE__INCOME_FREELANCE,
  COLLECTION_TYPE__INCOME_INTEREST,
  COLLECTION_TYPE__INCOME_INVEST
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { COLLECTION_TYPE__SPECIAL } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { setIRSPaymentAccount } from '@app/src/taxflow/sections/submit/actions/submitActions';
import {
  setBulkUploadPills,
  setUploadError,
  updateFormUploadAttempts
} from '@app/src/taxflow/shared/actions/sharedActions';
import { DEFAULT_COLLECTION_ID, TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector, irsPaymentAccountSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const setIRSAccount = () => async (dispatch, getState) => {
  const irsPaymentAccount = irsPaymentAccountSelector(getState());
  dispatch(setTaxFlowLoader(true));
  const res = await axios.post(`${baseUrl}api/taxes/set-irs-account`, {
    access_token: irsPaymentAccount.access_token,
    account_id: irsPaymentAccount.account_id,
    institution_name: irsPaymentAccount.institution_name
  });

  const { status, msg } = res.data;
  if (status === 'ok') {
    dispatch(setTaxFlowError(null));
    dispatch(setTaxFlowLoader(false));
  } else {
    await dispatch(setTaxFilingError({ message: msg }));
    dispatch(setIRSPaymentAccount({}));
    dispatch(setCurrentAnswer({ value: null }));
    dispatch(setTaxFlowLoader(false));
  }
};

export const createFormUploadAttempts = async (uploadAttemptObjects) => {
  const result = await axios.post(`${baseUrl}api/form-upload/create-form-upload-attempts`, {
    forms: uploadAttemptObjects
  });
  return _.get(result, ['data', 'data']);
};

const uploadFilesToS3 =
  ({ forms, keyedFiles, collectionType }) =>
  async (dispatch) => {
    // For each form, upload all form images to S3
    const formUploadResults = await Promise.all(
      _.map(forms, async (form) => {
        const { id: attemptId, collection_type, collection_id } = form;
        const fileFormats = _(form.files)
          .map((file) => file.file_format)
          .uniq()
          .value();
        const fileNames = _.map(form.files, (file) => file.file_name);
        trackActivity('form upload: begin upload', {
          file_format: fileFormats,
          collection_type,
          collection_id,
          attempt_id: attemptId
        });
        const { result, file_names } = await dispatch(uploadSingleForm(form, keyedFiles));
        trackActivity('form upload: upload result', {
          result: result === 'success' ? 'success' : 'failure',
          file_format: fileFormats,
          file_name: fileNames,
          collection_type,
          collection_id,
          attempt_id: attemptId
        });
        return { result, file_names };
      })
    );

    dispatch(getUploadAttemptsStatuses());
    const failedUploads = formUploadResults.filter(({ result }) => result !== 'success');

    // bulk upload error msg
    if (!_.isEmpty(failedUploads) && collectionType === COLLECTION_TYPE__SPECIAL) {
      dispatch(
        setUploadError(
          `Error uploading the following files: ${_(failedUploads)
            .flatMap(({ file_names }) => file_names)
            .join(', ')}. Please try again or enter the information manually.`
        )
      );
      dispatch(
        showCollectionFormUploadErrorModal({
          collectionType,
          collectionId: DEFAULT_COLLECTION_ID
        })
      );
    }
    return _.isEmpty(failedUploads) ? 'success' : 'failure';
  };

const uploadSingleForm = (form, keyedFiles) => async (dispatch) => {
  const fileNames = _.map(form.files, (file) => file.file_name);
  try {
    const results = await Promise.all(
      _.map(form.files, async (formFile) => {
        const file = _.get(keyedFiles, [form.temp_id, formFile.page]);
        const res = await fetch(formFile.upload_url, {
          body: file,
          method: 'PUT'
        });

        if (res.status === 200) {
          return { result: 'success' };
        } else {
          const responseText = await res.text();
          return {
            result: 'aws_failure',
            error: `Upload rejected by AWS: ${responseText}`
          };
        }
      })
    );
    const result = _.some(results, ({ result }) => result === 'aws_failure') ? 'aws_failure' : 'success';
    const status = result === 'aws_failure' ? 'cloud_upload_failed' : 'in_cloud_storage';
    dispatch(setFormUploadCloudStatus({ recordId: form.id, status }));
    return { file_names: fileNames, result };
  } catch (e) {
    dispatch(
      setFormUploadCloudStatus({
        recordId: form.id,
        status: 'cloud_upload_failed',
        error: `Upload request failed: ${e.message}`
      })
    );
    return { file_names: fileNames, result: 'aws_failure' };
  }
};

export const getFileFormat = (file) => {
  const extension = file.name.split('.').pop();
  return extension && _.lowerCase(extension);
};

export const getUploadAttemptsStatuses = () => async (dispatch, getState) => {
  const [attemptsResponse, pillsResponse] = await Promise.all([
    axios.get(`${baseUrl}api/form-upload/get-upload-attempts-statuses`),
    axios.get(`${baseUrl}api/taxes/bulk-upload-pills`)
  ]);

  const bulkUploadPills = _.get(pillsResponse, ['data', 'data', 'bulkUploadPills'], []);
  dispatch(setBulkUploadPills(bulkUploadPills));

  let attempts = _.get(attemptsResponse, ['data', 'data', 'attemptStatuses']);
  if (_.isNil(attempts)) {
    return;
  }

  const prefillAlert = attempts.filter((a) => a.status === 'prefilled' && a.isPendingAlert);
  if (prefillAlert.length) {
    const slugToTextMap = {
      [COLLECTION_TYPE__INCOME_INVEST]: 'Investment earnings',
      [COLLECTION_TYPE__INCOME_DIV]: 'Dividends',
      [COLLECTION_TYPE__INCOME_INTEREST]: 'Interest',
      [COLLECTION_TYPE__INCOME_FREELANCE]: 'Freelance'
    };
    let formTypesText = '';
    const max = Math.min(prefillAlert.length, 2);
    for (let i = 0; i < max; i++) {
      formTypesText += `${slugToTextMap[prefillAlert[i].collectionType]}`;
      if (i < max - 1) {
        formTypesText += ' and ';
      }
    }
    const msg = `Heads up! Your form ${getUploadAttemptFileNames({
      attempt: prefillAlert[0]
    })} reported ${formTypesText}, so we added ${_.size(prefillAlert) === 1 ? 'that section' : 'those sections'} for you.`;
    dispatch(setTaxFlowError(msg));
    notify(msg);

    const prefillAlertRecords = prefillAlert.map((a) => a.id);
    prefillAlertRecords.forEach((recordId) => dispatch(clearFormUploadAlert(recordId)));

    attempts = attempts.map((a) => {
      if (prefillAlertRecords.includes(a.id)) {
        return {
          ...a,
          isPendingAlert: 0
        };
      }
      return a;
    });
  }
  dispatch(updateFormUploadAttempts({ attempts }));

  const failedOcrBulkAttempt = attempts.find(
    (a) => a.isBulk && a.isPendingAlert && TAXFLOW__FORM_UPLOAD_OCR_FAILED_STATES.includes(a.status)
  );
  const formUploadErrorModalShow = _.get(getState(), ['taxFlowModals', 'collectionFormUploadErrorModalShow']);
  if (failedOcrBulkAttempt && !formUploadErrorModalShow) {
    dispatch(setBulkUploadOcrError({ attempt: failedOcrBulkAttempt }));
  }
  return attempts;
};

/**
 * @desc Upload pending upload files to s3, and tell the backend to kick off classification
 */
export const uploadPendingUploadFilesToS3 =
  ({ temp_id_mappings }) =>
  async (dispatch, getState) => {
    const attempts = await dispatch(getUploadAttemptsStatuses());
    const currentUploads = currentUploadsSelector(getState());
    const updatedPendingUploads = _(currentUploads)
      .filter((upload) => upload.status === 'pending')
      .map((upload) => {
        const uploadId = _.get(temp_id_mappings, upload.temp_id);
        const matchingAttempt = _.find(attempts, (attempt) => attempt.id === uploadId);
        return matchingAttempt
          ? {
              ...upload,
              id: matchingAttempt.id,
              files: matchingAttempt.files
            }
          : upload;
      })
      .value();

    const res = await dispatch(
      uploadFilesToS3({
        forms: _.filter(updatedPendingUploads, (upload) => _.has(temp_id_mappings, upload.temp_id)),
        keyedFiles: formUploadKeyedFilesSelector(getState()),
        collectionType: currentQuestionSelector(getState()).collectionType
      })
    );
    dispatch(setFormUploadStatus(res));
  };

export const sendRatingNotification = async (text, rating, phone, email) => {
  try {
    const slackMsg = {
      attachments: [
        {
          title: phone,
          text: email,
          fields: [
            {
              title: 'User Rating',
              value: String(rating)
            },
            {
              title: 'Comment',
              value: String(text)
            }
          ]
        }
      ]
    };

    await axios.post(`${baseUrl}api/taxes/tax-feedback-notification`, { slackMsg });
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const extractDrakePdf =
  (year = TAX_FILING_YEAR) =>
  async (dispatch, getState) => {
    try {
      const user = userSelector(getState());
      const currentQuestion = currentQuestionSelector(getState());

      const response = await axios.post(`${baseUrl}api/taxes/extract-drake-pdf`, { year });
      const msg = `A copy of your ${year} tax return is being emailed to ${user.email}`;
      trackActivity('export: tax return', { year, origin: currentQuestion.slug });
      dispatch(setTaxFlowError(msg));
      notify(msg);

      return _.get(response, ['data', 'data']);
    } catch (e) {
      dispatch(setTaxFlowError('There was an error emailing your tax return'));
      notify('There was an error emailing your tax return');
      defaultCaptureException(e);
    }
  };

export const dismissOcrError =
  ({ error }) =>
  async () => {
    try {
      trackActivity(`form upload: dismiss ocr error`, {
        slug: error.slug
      });
      await axios.patch(`${baseUrl}api/form-upload/dismiss-ocr-error`, {
        error
      });
    } catch (e) {
      defaultCaptureException(e);
    }
  };

export const dismissNonBlockingError =
  ({ validationSlug, coll_id, coll_type }) =>
  async (dispatch, getState) => {
    try {
      const errors = taxValidationErrorsSelector(getState());
      const matchesError = _.matches({ coll_type, coll_id, validationSlug });
      const [[matchingError] = [], otherErrors] = _.partition(errors, matchesError);
      if (_.isUndefined(matchingError)) {
        return;
      }
      dispatch(setErrors(otherErrors));
      trackActivity('dismiss non-blocking validation', {
        collectionType: coll_type,
        collectionId: coll_id,
        validationSlug
      });
      await axios.post(`${baseUrl}api/tax-validation/dismiss-validation`, {
        coll_type,
        coll_id,
        validationSlug
      });
    } catch (e) {
      defaultCaptureException(e);
    }
  };

export const dismissUncommonOcrErrors =
  ({ slug, collType, collId }) =>
  async (dispatch) => {
    try {
      trackActivity(`form upload: dismiss ocr errors for uncommon fields`, {
        slug
      });
      await axios.patch(`${baseUrl}api/form-upload/dismiss-uncommon-ocr-error`, {
        collId,
        collType
      });
    } catch (e) {
      defaultCaptureException(e);
    } finally {
      dispatch(getErrors({ slug }));
    }
  };

export const moveToNotStarted = async () => {
  try {
    await axios.post(`${baseUrl}api/taxes/move-to-not-started`);
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const getPastReturns = async () => {
  try {
    const res = await axios.get(`${baseUrl}api/taxes/past-returns`);
    return _.get(res, ['data', 'data', 'pastReturns']);
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const getPastReturnByYear = async (year) => {
  const res = await axios.get(`${baseUrl}api/taxes/past-returns/${year}`);
  return _.get(res, ['data', 'data']);
};

export const downloadFileFromURL = (url, fileName) => async (dispatch) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      await dispatch(
        setTaxFilingError(
          new Error('Something went wrong while downloading the file. Please refresh the page and try again.')
        )
      );
      return;
    }

    const blob = await response.blob();

    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;

    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const getReturnStatus = async () => {
  try {
    const res = await axios.get(`${baseUrl}api/taxes/return-status`);
    return _.get(res, ['data', 'data']);
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const updateHideAssistantBadgeCampaign = () => async (dispatch, getState) => {
  await axios.post(`${baseUrl}api/profile/update-test-campaigns`, {
    testCampaigns: {
      hide_assistant_badge: 1
    }
  });

  const campaign = campaignSelector(getState());

  dispatch(
    setCampaign({
      ...campaign,
      test_campaigns: {
        ...campaign.test_campaigns,
        hide_assistant_badge: 1
      }
    })
  );
};

export const getIdVerificationResult = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/get-id-verification-result`);
  return dispatch(setIdVerificationResult(_.get(res, ['data', 'data', 'response'])));
};

export const getIdVerificationQuestions = () => async () => {
  const res = await axios.post(`${baseUrl}api/taxes/get-id-verification-questions`, {});
  return JSON.parse(_.get(res, ['data', 'data', 'response']));
};

export const confirmId = (questions, answers) => async (dispatch) => {
  const res = await axios.post(`${baseUrl}api/taxes/confirm-id`, {
    questions,
    answers
  });
  return dispatch(setIdVerificationResult(_.get(res, ['data', 'data', 'response'])));
};

export const resetSubmitSsnMatched = () => async (dispatch) => {
  await axios.post(`${baseUrl}api/taxes/reset-submit-ssn-matched`, {});
  return dispatch(setSsnMatched(null));
};

export const updateDesktopSellCampaign = () => async (dispatch, getState) => {
  await axios.post(`${baseUrl}api/profile/update-test-campaigns`, {
    testCampaigns: {
      desktop_sell_modal_visited: 1
    }
  });

  const campaign = campaignSelector(getState());

  dispatch(
    setCampaign({
      ...campaign,
      test_campaigns: {
        ...campaign.test_campaigns,
        desktop_sell_modal_visited: 1
      }
    })
  );
};
