import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { getOnboardingPrerequisites } from '@app/src/services/onboardingService';
import { initPage, initOnboarding } from '@app/src/services/holisticOnboardingService';
import HolisticOnboardingLayout from '@app/src/Components/HolisticOnboarding/containers/HolisticOnboardingLayout';
import Oauth from '@app/src/Components/Common/Oauth/Oauth';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import OnboardingAppDownload from '@app/src/Components/HolisticOnboarding/pages/OnboardingAppDownload';
import OnboardingBankLink from '@app/src/Components/HolisticOnboarding/pages/OnboardingBankLink';
import OnboardingDeductionEstimate from '@app/src/Components/HolisticOnboarding/pages/OnboardingDeductionEstimate';
import OnboardingDeductionEstimateW2 from '@app/src/Components/HolisticOnboarding/pages/OnboardingDeductionEstimateW2';
import OnboardingDeductionsList from '@app/src/Components/HolisticOnboarding/pages/OnboardingDeductionsList';
import OnboardingDeductions from '@app/src/Components/HolisticOnboarding/pages/OnboardingDeductions';
import OnboardingDrive from '@app/src/Components/HolisticOnboarding/pages/OnboardingDrive';
import OnboardingHome from '@app/src/Components/HolisticOnboarding/pages/OnboardingHome';
import OnboardingIncomeEstimate from '@app/src/Components/HolisticOnboarding/pages/OnboardingIncomeEstimate';
import OnboardingInit from '@app/src/Components/Onboarding/pages/OnboardingInit';
import OnboardingJobDuration from '@app/src/Components/HolisticOnboarding/pages/OnboardingJobDuration';
import OnboardingJobSelect from '@app/src/Components/HolisticOnboarding/pages/OnboardingJobSelect';
import OnboardingLoadingInitial from '@app/src/Components/HolisticOnboarding/pages/OnboardingLoadingInitial';
import OnboardingMarriageStatus from '@app/src/Components/HolisticOnboarding/pages/OnboardingMarriageStatus';
import OnboardingMeal from '@app/src/Components/HolisticOnboarding/pages/OnboardingMeal';
import OnboardingPhone from '@app/src/Components/HolisticOnboarding/pages/OnboardingPhone';
import OnboardingRedirect from '@app/src/Components/Onboarding/pages/OnboardingRedirect';
import OnboardingScan from '@app/src/Components/HolisticOnboarding/pages/OnboardingScan';
import OnboardingSignup from '@app/src/Components/HolisticOnboarding/pages/OnboardingSignup';
import OnboardingSituations from '@app/src/Components/HolisticOnboarding/pages/OnboardingSituations';
import OnboardingState from '@app/src/Components/HolisticOnboarding/pages/OnboardingState';
import OnboardingTravel from '@app/src/Components/HolisticOnboarding/pages/OnboardingTravel';
import { contentSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const routes = [
  { path: '/onboarding', Component: OnboardingInit },
  { path: '/h/onboarding/app-download', Component: OnboardingAppDownload },
  { path: '/h/onboarding/deduction-estimate', Component: OnboardingDeductionEstimate },
  { path: '/h/onboarding/deduction-estimate-w2', Component: OnboardingDeductionEstimateW2 },
  { path: '/h/onboarding/deductions', Component: OnboardingDeductions },
  { path: '/h/onboarding/deductions-list', Component: OnboardingDeductionsList },
  { path: '/h/onboarding/drive', Component: OnboardingDrive },
  { path: '/h/onboarding/home', Component: OnboardingHome },
  { path: '/h/onboarding/income-estimate', Component: OnboardingIncomeEstimate },
  { path: '/h/onboarding/job-duration', Component: OnboardingJobDuration },
  { path: '/h/onboarding/job-select', Component: OnboardingJobSelect },
  { path: '/h/onboarding/link', Component: OnboardingBankLink },
  { path: '/h/onboarding/loading-initial', Component: OnboardingLoadingInitial },
  { path: '/h/onboarding/marriage-status', Component: OnboardingMarriageStatus },
  { path: '/h/onboarding/meal', Component: OnboardingMeal },
  { path: '/h/onboarding/phone', Component: OnboardingPhone },
  { path: '/h/onboarding/scan', Component: OnboardingScan },
  { path: '/h/onboarding/signup', Component: OnboardingSignup },
  { path: '/h/onboarding/situations', Component: OnboardingSituations },
  { path: '/h/onboarding/state', Component: OnboardingState },
  { path: '/h/onboarding/travel', Component: OnboardingTravel },
  { path: '/onboarding/oauth', Component: (props) => <Oauth {...props} defaultRoute='/h/onboarding/link' /> },
  { path: '*', Component: OnboardingRedirect }
];

const HolisticOnboardingApp = ({ getOnboardingPrerequisites, initPage, initOnboarding, content }) => {
  const location = useLocation();

  useEffect(() => {
    const run = async () => {
      if (_.isEmpty(content)) {
        initOnboarding();
      }

      initPage({ url: location.pathname });
    };

    run();
  }, [content, initPage, location.pathname, initOnboarding]);

  useEffect(() => {
    getOnboardingPrerequisites();
  }, [getOnboardingPrerequisites]);

  const renderRoute = useCallback(
    () => (
      <StripeProvider>
        <HolisticOnboardingLayout location={location}>
          <Switch location={location}>
            {routes.map(({ path, Component }) => (
              <Route key={path} exact path={path} component={Component} />
            ))}
          </Switch>
        </HolisticOnboardingLayout>
      </StripeProvider>
    ),
    [location]
  );

  return <Route render={renderRoute} />;
};

const mapStateToProps = (state) => ({
  content: contentSelector(state)
});

const mapDispatchToProps = {
  getOnboardingPrerequisites,
  initOnboarding,
  initPage
};

const ConnectedHolisticOnboardingApp = connect(mapStateToProps, mapDispatchToProps)(HolisticOnboardingApp);

export default ConnectedHolisticOnboardingApp;
