import _ from 'lodash';
import { createSelector } from 'reselect';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

export const summaryItemsSelector = (state) => _.get(state, ['taxFlowNavigation', 'summaryItems']);

export const currentSummaryItemsSelector = createSelector(
  [currentQuestionSelector, summaryItemsSelector],
  (currentQuestion, summaryItems) => {
    const currentSection = _.get(currentQuestion, ['section', 'fields', 'slug']);
    return _.get(summaryItems, [currentSection]);
  }
);
