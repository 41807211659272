import _ from 'lodash';
import React from 'react';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import OnboardingLabel from '@app/src/Components/Onboarding/Question/OnboardingLabel';

const OnboardingTextItem = ({ className, question, answer, onChange }) => {
  return (
    <div className={classNames('form-group', className)}>
      <OnboardingLabel question={question} />
      <TextField
        className='form-control'
        fullWidth
        type='text'
        name={question.slug}
        placeholder={question.question_meta.default}
        value={answer && !_.isNil(answer.value) ? answer.value : ''}
        onChange={(e) => onChange({ slug: question.slug, value: e.target.value })}
      />
    </div>
  );
};

export default OnboardingTextItem;
