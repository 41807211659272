import React from 'react';
import _ from 'lodash';
import TaxValidationDatePicker from '@app/src/Components/TaxValidation/TaxValidationDatePicker';
import TaxValidationWarning from '@app/src/Components/TaxValidation/TaxValidationWarning';

const TaxFlowFormDateItem = ({ question, answer, onChange, onFocus }) => {
  const handleChange = ({ value }) => {
    onChange({
      slug: question.slug,
      value,
      endpoint_attr: question.endpoint_attr
    });
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus({ endpoint_attr: question.endpoint_attr });
    }
  };

  if (!question) {
    return null;
  }

  return (
    <div className='form-group'>
      <TaxValidationDatePicker
        question={question}
        answer={answer}
        name={question.slug}
        placeholder={question.question_meta.default}
        required={question.required}
        onChange={handleChange}
        onFocus={handleFocus}
        value={_.get(answer, 'value')}
      />
      <TaxValidationWarning question={question} answer={answer} />
    </div>
  );
};

export default TaxFlowFormDateItem;
