import classNames from 'classnames';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { initOnboarding } from '@app/src/services/onboardingService';
import Loader from '@app/src/Components/Common/Loader/Loader';
import '@app/src/Components/Onboarding/pages/OnboardingInit.scss';

const OnboardingInit = ({ className, history, location, initOnboarding }) => {
  useEffect(() => {
    initOnboarding({ history, location });
  }, [history, location, initOnboarding]);

  return (
    <div className={classNames('onboarding-2-init', className)}>
      <Loader />
    </div>
  );
};

const mapDispatchToProps = {
  initOnboarding
};

const ConnectedOnboardingInit = connect(null, mapDispatchToProps)(OnboardingInit);

export default ConnectedOnboardingInit;
