import React from 'react';
import { connect } from 'react-redux';

import ExpenseReviewModalAdd from '@app/src/Components/ExpenseReview/ExpenseReviewModalAdd';
import ExpenseReviewModalAutoRule from '@app/src/Components/ExpenseReview/ExpenseReviewModalAutoRule';
import ExpenseReviewModalEdit from '@app/src/Components/ExpenseReview/ExpenseReviewModalEdit';
import ExpenseReviewModalEditRule from '@app/src/Components/ExpenseReview/ExpenseReviewModalEditRule';
import ExpenseReviewModalPayment from '@app/src/Components/ExpenseReview/ExpenseReviewModalPayment';
import ExpenseReviewModalPaywall from '@app/src/Components/ExpenseReview/ExpenseReviewModalPaywall';
import SubscriptionTypeModal from '@app/src/Components/Subscription/SubscriptionTypeModal/SubscriptionTypeModal';
import { expenseReviewModalTypeSelector } from '@app/src/selectors/expenseReviewSelectors';

const ExpenseReviewModalContent = ({ modalType }) => {
  switch (modalType) {
    case 'add':
      return <ExpenseReviewModalAdd />;
    case 'edit':
      return <ExpenseReviewModalEdit />;
    case 'paywall':
      return <ExpenseReviewModalPaywall />;
    case 'payment':
      return <ExpenseReviewModalPayment />;
    case 'subscription':
      return <SubscriptionTypeModal />;
    case 'rule':
      return <ExpenseReviewModalEditRule />;
    case 'auto-rule':
      return <ExpenseReviewModalAutoRule />;
    default:
      return null;
  }
};
const mapStateToProps = (state) => ({
  modalType: expenseReviewModalTypeSelector(state)
});

const ConnectedExpenseReviewModalContent = connect(mapStateToProps)(ExpenseReviewModalContent);

export default ConnectedExpenseReviewModalContent;
