import axios from 'axios';
import _ from 'lodash';
import { serverUrl } from '@app/src/global/Environment';

const baseUrl = serverUrl();

export const getOrCreateChannel = async () => {
  const res = await axios.post(`${baseUrl}api/message/channel`);

  return _.get(res, ['data', 'data', 'channel']);
};
