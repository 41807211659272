import React, { useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import { useFilePicker } from 'use-file-picker';
import { useDropzone } from 'react-dropzone';
import CircularProgress from '@mui/material/CircularProgress';
import MoonLoader from 'react-spinners/MoonLoader';
import Button from '@mui/material/Button';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import WarningIcon from '@app/src/assets/warning.svg?react';
import UploadIcon from '@app/src/assets/document-upload.svg?react';
import EditIcon from '@app/src/assets/edit.svg?react';
import TaxValidationWarning from '@app/src/Components/TaxValidation/TaxValidationWarning';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import { DEFAULT_COLLECTION_ID, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  FORM_UPLOAD_UI_EVENTS_DEBOUNCE_TIME_MS,
  FORM_UPLOAD_VALID_FILE_FORMATS,
  TAXFLOW__FORM_UPLOAD_FAILED_STATES,
  TAXFLOW__FORM_UPLOAD_VALID_STATES,
  TAXFLOW__FORM_UPLOAD_WRONG_FILE_FORMAT_TEXT
} from '@app/src/taxflow/collection/constants/formUploadConstants';
import {
  COLLECTION_TYPE__SPECIAL,
  PATH_COMPONENT__BULK_UPLOAD_MANUAL_ENTRY,
  PATH_COMPONENT__BULK_UPLOAD_MULTI_IMAGE,
  PATH_COMPONENT__BULK_UPLOAD_PHOTO_CAPTURE,
  SLUG__BULK_UPLOAD
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { COLLECTION_TYPE__CREDIT_HEALTHCARE } from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  createFormUploadAttempts,
  getFileFormat,
  uploadPendingUploadFilesToS3
} from '@app/src/services/taxFlowService';
import { trackActivity } from '@app/src/services/analyticsService';
import { updateQuestionStarted } from '@app/src/taxflow/main/services/mainService';
import {
  getNextPathComponentWithUpload,
  showCollectionFormUploadErrorModal
} from '@app/src/taxflow/collection/services/collectionService';
import {
  currentCollectionIdSelector,
  currentQuestionSelector,
  isAlabamaOrMinnesotaSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import {
  setCurrentUploads,
  setDropzoneFiles,
  setDropzoneFilesReadyForUpload,
  setDropzoneFilesSameForm,
  setDropzoneLoading,
  setFormUploadKeyedFiles,
  setFormUploadStatus
} from '@app/src/actions/taxFlowActions';
import { setUploadError } from '@app/src/taxflow/shared/actions/sharedActions';
import { setFormUploadCloudStatus } from '@app/src/actions/socketActions';
import { getFormUploadNextPathComponentMap } from '@app/src/taxflow/collection/utils/collectionUtils';
import { getNextPathComponentMap as getNextPathComponentMapIncome } from '@app/src/taxflow/sections/income/utils/incomeUtils';
import { getNextPathComponentMap as getNextPathComponentMapCredit } from '@app/src/taxflow/sections/credit/utils/creditUtils';
import {
  bulkUploadErrorAttemptsSelector,
  dropzoneFilesReadyForUploadSelector,
  dropzoneFilesSameFormSelector,
  dropzoneFilesSelector,
  dropzoneLoadingSelector,
  formUploadAttemptsSelector,
  formUploadKeyedFilesSelector,
  formUploadStatusSelector,
  isBulkUploadSelector,
  unclassifiedUploadsSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { currentUploadsSelector, isReviewModeSelector, uploadErrorSelector } from '@app/src/selectors/taxFlowSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowFormUploadItem.scss';
import { isReactNative } from '@app/src/global/Helpers';
import { useDebounce } from 'react-use';
import { collectionFormUploadErrorModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getOriginParam } from '@app/src/taxflow/navigation/utils/navigationUtils';
import url from 'url';

const TaxFlowFormUploadItem = ({
  currentAnswer,
  currentQuestion,
  currentCollectionId,
  history,
  isAlabamaOrMinnesota,
  uploadError,
  uploadAttempts,
  uploadStatus,
  currentUploads,
  unclassifiedUploads,
  isReviewMode,
  isBulkUpload,
  bulkUploadErrorAttempts,
  setCurrentUploads,
  setUploadStatus,
  uploadPendingUploadFilesToS3,
  setFormUploadCloudStatus,
  getNextPathComponentWithUpload,
  setUploadError,
  updateQuestionStarted,
  collectionFormUploadErrorModalShow,
  // Files keyed by [upload temp_id, page]
  tempIdKeyedFiles,
  setTempIdKeyedFiles,
  setDropzoneLoading,
  dropzoneLoading,
  dropzoneFiles,
  setDropzoneFiles,
  dropzoneFilesReadyForUpload,
  setDropzoneFilesReadyForUpload,
  dropzoneFilesSameForm,
  setDropzoneFilesSameForm,
  showCollectionFormUploadErrorModal
}) => {
  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    accept: ['.pdf', '.jpg', '.jpeg', '.png', '.tif', '.tiff'],
    readAs: 'BinaryString',
    maxFileSize: 10,
    multiple: isBulkUpload
  });
  const location = useLocation();

  const collectionType = _.get(currentQuestion, 'collectionType');
  const collectionUpload = _.get(uploadAttempts, [collectionType, currentCollectionId]);
  const existingUploadStatus = _.get(collectionUpload, 'status');
  const hasRetryError = TAXFLOW__FORM_UPLOAD_FAILED_STATES.includes(existingUploadStatus);
  const isBulkClassificationPending = isBulkUpload && uploadStatus === 'success' && !_.isEmpty(unclassifiedUploads);
  const isDropzoneDisabled = isBulkClassificationPending || uploadStatus === 'pending';
  const isChecked = (value) => value === 'file' && uploadStatus === 'success';
  const isFailed = (value) => value === 'file' && (uploadStatus === 'failure' || hasRetryError);
  const isPending = (value) => value === 'file' && uploadStatus === 'pending';
  const hasFailureMessage = (value) => isFailed(value) && uploadError;

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    acceptedFiles
  } = useDropzone({
    disabled: isDropzoneDisabled,
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.jpg', '.jpeg', '.png', '.tif', '.tiff']
    },
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: () => {
      trackActivity('form upload: button clicked', {
        button: 'drag and drop',
        collection_type: currentQuestion.collectionType,
        collection_id: currentCollectionId
      });
    }
  });

  const createUploadAttemptObjects = useCallback(
    (files = []) => {
      // Case: Single file upload - create one upload attempt with one file
      if (currentQuestion.slug !== SLUG__BULK_UPLOAD) {
        const temp_id = _.uniqueId();
        const file = files[0];
        const uploadAttemptObject = {
          collection_type: currentQuestion.collectionType,
          collection_id: currentCollectionId,
          media_type: 'file',
          is_doc_only: 0,
          files: [{ file_name: _.get(file, 'name'), file_format: getFileFormat(file), page: 0 }],
          temp_id
        };

        const subTypeMap = {
          [SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD]: 'ssa',
          [SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD]: 'pension',
          [SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD]: 'misc',
          [SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD]: 'nec'
        };
        if (_.has(subTypeMap, currentQuestion.slug)) {
          uploadAttemptObject.sub_type = subTypeMap[currentQuestion.slug];
        }

        return { attempts: [uploadAttemptObject], files: { [temp_id]: [file] } };
      }

      // Case: Bulk upload, all files part of the same form - create one upload with all selected files, sorted in order of modification time
      if (dropzoneFilesSameForm) {
        const temp_id = _.uniqueId();
        const orderedFiles = _(files)
          .sortBy((file) => file.lastModified)
          .value();
        return {
          attempts: [
            {
              collection_type: null,
              collection_id: null,
              media_type: 'file',
              is_doc_only: 0,
              is_bulk: 1,
              files: _.map(orderedFiles, (file, page) => ({
                file_name: _.get(file, 'name'),
                file_format: getFileFormat(file),
                page
              })),
              temp_id
            }
          ],
          files: { [temp_id]: orderedFiles }
        };
      }

      // Case: Bulk upload, each file a different form - create an upload for every selected file
      const attempts = files.map((file) => ({
        collection_type: null,
        collection_id: null,
        media_type: 'file',
        is_doc_only: 0,
        is_bulk: 1,
        files: [{ file_name: _.get(file, 'name'), file_format: getFileFormat(file), page: 0 }],
        temp_id: _.uniqueId()
      }));
      return {
        attempts,
        files: _(attempts)
          .map((attempt) => attempt.temp_id)
          .zipWith(files)
          .map(([temp_id, file]) => [temp_id, [file]])
          .fromPairs()
          .value()
      };
    },
    [currentCollectionId, currentQuestion, dropzoneFilesSameForm]
  );

  const advanceToNextPage = useCallback(
    async (manualEntry = false) => {
      let nextPath;
      const originParam = getOriginParam({ location });
      if (manualEntry) {
        const nextComponentPathMap = { ...getFormUploadNextPathComponentMap() };
        nextPath = _.get(nextComponentPathMap, [currentQuestion.slug, 'nextPathComponent']);
      } else {
        const nextComponentPathMap = { ...getNextPathComponentMapCredit(), ...getNextPathComponentMapIncome() };
        nextPath = await getNextPathComponentWithUpload({
          pathComponent: _.get(nextComponentPathMap, [currentQuestion.slug, 'nextPathComponent'])
        });
        trackActivity('form upload: auto advance', {
          collection_type: currentQuestion.collectionType,
          collection_id: currentCollectionId,
          nextPath
        });
      }
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPath}`,
          query: {
            collectionId: currentCollectionId,
            origin: originParam
          }
        })
      );
    },
    [currentCollectionId, currentQuestion, history, getNextPathComponentWithUpload, location]
  );

  useEffect(() => {
    if (acceptedFiles.length) {
      const isEachFileAnImage = acceptedFiles.every((file) => _.startsWith(file.type, 'image'));
      setDropzoneFiles(acceptedFiles);
      setUploadStatus(null);
      setUploadError(null);
      const wasImageRecentlyCaptured = (image) => Date.now() - image.lastModified < 10000;
      // If user uploads a single captured photo, ask user if they want to capture additional form pages
      if (
        acceptedFiles.length === 1 &&
        wasImageRecentlyCaptured(_.get(acceptedFiles, 0)) &&
        isEachFileAnImage &&
        isReactNative()
      ) {
        setDropzoneFilesReadyForUpload(false);
        setDropzoneFilesSameForm(true);
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__BULK_UPLOAD_PHOTO_CAPTURE}`);
      }
      // If each upload is an image, ask user if they are all of the same form (need to figure if to classify each individually or all together)
      else if (acceptedFiles.length > 1 && isEachFileAnImage) {
        setDropzoneFilesReadyForUpload(false);
        setDropzoneFilesSameForm(undefined);
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__BULK_UPLOAD_MULTI_IMAGE}`);
      } else {
        setDropzoneFilesSameForm(false);
        setDropzoneFilesReadyForUpload(true);
      }
    }
  }, [
    acceptedFiles,
    history,
    setDropzoneFiles,
    setDropzoneFilesSameForm,
    setDropzoneLoading,
    setDropzoneFilesReadyForUpload,
    setUploadError,
    setUploadStatus
  ]);

  useEffect(() => {
    const isFileFormatValid = (file) => _.includes(FORM_UPLOAD_VALID_FILE_FORMATS, _.toLower(file.file_format));
    const areAttemptFileFormatsValid = (attempt) => _.every(attempt.files, isFileFormatValid);
    const getInvalidFileFormatModalHeader = (invalidAttempts) =>
      `${_(invalidAttempts)
        .flatMap(({ files }) => files)
        .filter((file) => !isFileFormatValid(file))
        .map(({ file_name }) => file_name)
        .join(', ')} file type is not supported.`;

    const createUploadAttempts = async (files = []) => {
      setDropzoneLoading(true);
      setUploadStatus('pending');

      // set current uploads data
      const { attempts, files: keyedFiles } = createUploadAttemptObjects(files);
      const [validAttempts, invalidAttempts] = _.partition(attempts, areAttemptFileFormatsValid);
      const currentUploads = validAttempts.map((obj) => ({
        ..._.pick(obj, ['collection_type', 'collection_id', 'is_bulk', 'files', 'temp_id']),
        status: 'pending'
      }));
      setCurrentUploads(currentUploads);
      setTempIdKeyedFiles({ ...tempIdKeyedFiles, ...keyedFiles });
      if (!_.isEmpty(validAttempts)) {
        // generate s3 signed URLs for current uploads
        const { temp_id_mappings } = await createFormUploadAttempts(validAttempts);
        uploadPendingUploadFilesToS3({ temp_id_mappings });
      } else {
        setDropzoneFiles([]);
        setUploadStatus('');
        setDropzoneLoading(false);
      }

      if (!_.isEmpty(invalidAttempts)) {
        const errorModalHeader = getInvalidFileFormatModalHeader(invalidAttempts);
        setUploadError(TAXFLOW__FORM_UPLOAD_WRONG_FILE_FORMAT_TEXT);
        showCollectionFormUploadErrorModal({
          collectionType: currentQuestion.collectionType || COLLECTION_TYPE__SPECIAL,
          collectionId: currentCollectionId || DEFAULT_COLLECTION_ID,
          header: errorModalHeader
        });
        trackActivity('form upload: selected unsupported files', {
          file_format: _(invalidAttempts)
            .flatMap(({ files }) => files)
            .map(({ file_format }) => file_format)
            .uniq()
            .value()
        });
      }
    };

    if (uploadStatus === 'pending') {
      return;
    }

    if (plainFiles.length && !uploadStatus) {
      createUploadAttempts(plainFiles);
      clear();
    }

    if (!dropzoneFilesReadyForUpload) {
      return;
    }

    if (!_.isEmpty(dropzoneFiles) && !uploadStatus) {
      createUploadAttempts(dropzoneFiles);
    }
  }, [
    plainFiles,
    uploadStatus,
    currentQuestion,
    currentCollectionId,
    setCurrentUploads,
    createUploadAttemptObjects,
    clear,
    dropzoneFiles,
    history,
    dropzoneFilesReadyForUpload,
    setUploadStatus,
    uploadPendingUploadFilesToS3,
    tempIdKeyedFiles,
    setTempIdKeyedFiles,
    setUploadError,
    showCollectionFormUploadErrorModal,
    setDropzoneFiles,
    setDropzoneLoading
  ]);

  // Once everything has been classified, stop the dropzone loading animation
  // If not bulk (specific form upload), advance to the prefill screen for that form
  useEffect(() => {
    if (!_.isEmpty(unclassifiedUploads) || uploadStatus !== 'success') {
      return;
    }
    setDropzoneLoading(false);
    setDropzoneFiles([]);
    setCurrentUploads([]);
    if (isBulkUpload) {
      return;
    }
    const collectionType = _.get(currentQuestion, 'collectionType');
    const collectionUpload = _.get(uploadAttempts, [collectionType, currentCollectionId]);
    const existingUploadStatus = _.get(collectionUpload, 'status');
    if (TAXFLOW__FORM_UPLOAD_VALID_STATES.includes(existingUploadStatus)) {
      advanceToNextPage();
    }
  }, [
    unclassifiedUploads,
    advanceToNextPage,
    currentQuestion,
    currentCollectionId,
    uploadAttempts,
    isBulkUpload,
    setDropzoneLoading,
    uploadStatus,
    setDropzoneFiles,
    setCurrentUploads
  ]);

  useEffect(() => {
    if (uploadStatus === 'failure') {
      setDropzoneLoading(false);
      setDropzoneFiles([]);
      setCurrentUploads([]);
    }
  }, [uploadStatus, setDropzoneLoading, setDropzoneFiles, setCurrentUploads]);

  // Track classification attempts in the context of the UI surfaced to the user
  // Need to debounce, as all required info (and respective updates) occur in an async fashion
  const previousIsBulkClassificationPending = useRef(false);
  useDebounce(
    () => {
      if (isBulkClassificationPending && !previousIsBulkClassificationPending.current) {
        trackActivity('bulk upload FE: classification waiting', {});
      }
      if (!isBulkClassificationPending && previousIsBulkClassificationPending.current) {
        trackActivity('bulk upload FE: classification complete', { success: !collectionFormUploadErrorModalShow });
      }
      previousIsBulkClassificationPending.current = isBulkClassificationPending;
    },
    FORM_UPLOAD_UI_EVENTS_DEBOUNCE_TIME_MS,
    [collectionFormUploadErrorModalShow, isBulkClassificationPending, previousIsBulkClassificationPending]
  );

  const onClick = async (value) => {
    if (uploadStatus === 'pending') return;

    if (value === 'file') {
      trackActivity('form upload: button clicked', {
        button: 'upload',
        collection_type: currentQuestion.collectionType,
        collection_id: currentCollectionId
      });
      setCurrentUploads([]);
      setUploadStatus('');

      if (
        !isBulkUpload &&
        (!currentCollectionId ||
          isNaN(currentCollectionId) ||
          (currentCollectionId === '0' && currentQuestion.collection_type !== COLLECTION_TYPE__CREDIT_HEALTHCARE))
      ) {
        setUploadStatus('failure');
        return;
      }

      trackActivity('form upload: open web filepicker attempt', {
        collection_type: currentQuestion.collectionType,
        collection_id: currentCollectionId
      });
      openFileSelector();

      await updateQuestionStarted();
    } else if (value === 'manual') {
      await updateQuestionStarted();
      if (!_.isNil(existingUploadStatus) && existingUploadStatus !== 'deleted') {
        setCurrentUploads([
          {
            status: 'deleted',
            collection_type: currentQuestion.collectionType,
            collection_id: currentCollectionId
          }
        ]);
        setFormUploadCloudStatus({ recordId: collectionUpload.id, status: 'deleted' });
      } else {
        const existingUpload = currentUploads.find(
          (upload) =>
            upload.collection_type === currentQuestion.collectionType &&
            upload.collection_id === currentCollectionId &&
            upload.id
        );
        if (existingUpload) {
          setCurrentUploads([
            {
              status: 'deleted',
              collection_type: currentQuestion.collectionType,
              collection_id: currentCollectionId
            }
          ]);
          setFormUploadCloudStatus({ recordId: existingUpload.id, status: 'deleted' });
        }
      }

      trackActivity('form upload: button clicked', {
        button: 'manual',
        collection_type: currentQuestion.collectionType
      });
      advanceToNextPage(true);
    }
  };

  const renderItem = (item) => {
    if (item.slug === 'manual-entry' && isAlabamaOrMinnesota && REACT_APP_ENV !== 'staging-prod-db') {
      return null;
    }

    return (
      <div key={item.value}>
        <TaxFlowListItem
          className={classNames({ 'tax-flow-list-item-warning': isFailed(item.value) })}
          item={item}
          checked={isChecked(item.value)}
          handleChange={() => onClick(item.value)}
          showRightIcon={isPending(item.value) || isFailed(item.value)}
          isWarning={isReviewMode && isFailed(item.value)}
          rightIcon={
            isPending(item.value) ? (
              <div className='tax-flow-list-item-icon'>
                <MoonLoader color={'#00945e'} size={20} css={'box-sizing: content-box;'} />
              </div>
            ) : isFailed(item.value) ? (
              <WarningIcon className='tax-flow-list-item-icon-warning' />
            ) : null
          }
        >
          {item.slug === 'file-upload' ? (
            <UploadIcon className='tax-flow-form-upload-item-icon' alt='File upload icon' width={20} height={20} />
          ) : (
            <EditIcon className='tax-flow-form-upload-item-icon' alt='Edit icon' width={20} height={20} />
          )}
          <span className='tax-flow-form-upload-item-text'>
            {isFailed(item.value) ? (hasFailureMessage(item.value) ? uploadError : 'Retry upload') : item.text}
          </span>
        </TaxFlowListItem>
        {isFailed(item.value) && <TaxValidationWarning question={currentQuestion} answer={currentAnswer} />}
      </div>
    );
  };

  const navigateToManualEntry = () => {
    history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__BULK_UPLOAD_MANUAL_ENTRY}`);
  };

  return isBulkUpload ? (
    <div className='tax-flow-form-upload-item-bulk-container'>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button
          variant='dashed'
          disableTouchRipple={isDropzoneDisabled}
          onClick={openDropzone}
          className={classNames({
            'tax-flow-form-upload-item-drag-drop-button': true,
            'tax-flow-form-upload-item-drag-drop-button-disabled': isDropzoneDisabled
          })}
        >
          <div className='tax-flow-form-upload-item-drag-drop-button-container'>
            <div className='tax-flow-form-upload-item-drag-drop-icon-container'>
              {isBulkClassificationPending || dropzoneLoading ? (
                <CircularProgress color='secondary' size={20} />
              ) : (
                <UploadIcon
                  className='tax-flow-form-upload-item-drag-drop-icon'
                  alt='File upload icon'
                  width={20}
                  height={20}
                />
              )}
            </div>
            {isDropzoneDisabled && (
              <>
                <div className='tax-flow-form-upload-item-drag-drop-button-text'>
                  <div>Uploading...</div>
                  <div>
                    {_(dropzoneFiles)
                      .map(({ name }) => name)
                      .join(', ')}
                  </div>
                </div>
              </>
            )}
            {!isDropzoneDisabled && isReactNative() && (
              <div className='tax-flow-form-upload-item-drag-drop-button-text'>
                <div>
                  <span className='tax-flow-form-upload-item-drag-drop-button-choose-files'>Take photo</span>
                  {' or '}
                  <span className='tax-flow-form-upload-item-drag-drop-button-choose-files'>choose files</span>
                </div>
              </div>
            )}
            {!isDropzoneDisabled && !isReactNative() && (
              <div className='tax-flow-form-upload-item-drag-drop-button-text'>
                <div>
                  {'Drag and drop, '}
                  <span className='tax-flow-form-upload-item-drag-drop-button-choose-files'>choose file</span>
                  {' or '}
                  <span className='tax-flow-form-upload-item-drag-drop-button-choose-files'>upload photo</span>
                </div>
              </div>
            )}

            <div
              className={classNames(
                'tax-flow-form-upload-item-drag-drop-button-text',
                'tax-flow-form-upload-item-drag-drop-button-hint-text'
              )}
            >
              Suggested: W-2, 1099-NEC, 1099-K, Prior year return
            </div>
          </div>
        </Button>
      </div>
      <Link variant='page-link' component='button' underline='none' onClick={navigateToManualEntry}>
        Enter manually
      </Link>
      {bulkUploadErrorAttempts.length > 0 && <TaxValidationWarning question={currentQuestion} answer={currentAnswer} />}
    </div>
  ) : (
    <div className='steps-body'>
      <div className='checkbox-list'>{currentQuestion.question_meta.map((item) => renderItem(item))}</div>
    </div>
  );
};

const mapDispatchToProps = {
  setCurrentUploads,
  getNextPathComponentWithUpload,
  uploadPendingUploadFilesToS3,
  setUploadError,
  setFormUploadCloudStatus,
  updateQuestionStarted,
  setDropzoneLoading,
  setDropzoneFiles,
  setDropzoneFilesReadyForUpload,
  setDropzoneFilesSameForm,
  setUploadStatus: setFormUploadStatus,
  setTempIdKeyedFiles: setFormUploadKeyedFiles,
  showCollectionFormUploadErrorModal
};

const mapStateToProps = (state, props) => ({
  history: props.history,
  currentAnswer: state.taxFlow.currentAnswer,
  currentQuestion: currentQuestionSelector(state),
  currentCollectionId: currentCollectionIdSelector(state),
  isAlabamaOrMinnesota: isAlabamaOrMinnesotaSelector(state),
  uploadAttempts: formUploadAttemptsSelector(state),
  currentUploads: currentUploadsSelector(state),
  unclassifiedUploads: unclassifiedUploadsSelector(state),
  uploadError: uploadErrorSelector(state),
  isReviewMode: isReviewModeSelector(state),
  isBulkUpload: isBulkUploadSelector(state),
  bulkUploadErrorAttempts: bulkUploadErrorAttemptsSelector(state),
  collectionFormUploadErrorModalShow: collectionFormUploadErrorModalShowSelector(state),
  dropzoneLoading: dropzoneLoadingSelector(state),
  dropzoneFiles: dropzoneFilesSelector(state),
  dropzoneFilesReadyForUpload: dropzoneFilesReadyForUploadSelector(state),
  dropzoneFilesSameForm: dropzoneFilesSameFormSelector(state),
  uploadStatus: formUploadStatusSelector(state),
  tempIdKeyedFiles: formUploadKeyedFilesSelector(state)
});

const ConnectedTaxFlowFormUploadItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormUploadItem);

export default ConnectedTaxFlowFormUploadItem;
