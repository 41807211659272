import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CloseIcon from '@app/src/assets/close.svg?react';
import BankList from '@app/src/Components/Bank/BankList/BankList';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import history from '@app/src/keeperHistory';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getExpenseWaiting } from '@app/src/services/expenseReviewService';
import { moveToNotStarted } from '@app/src/services/taxFlowService';
import { isPremiumSubscriberSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';

import '@app/src/Components/ExpenseReview/ExpenseReviewExpensesEmpty.scss';
import { advanceToCurrentSectionPage } from '@app/src/taxflow/main/services/mainService';

const SettingsLinkedAccounts = ({
  isPremiumSubscriber,
  selfUnlockModalOpen,
  setSelfUnlockModalOpen,
  advanceToCurrentSectionPage
}) => {
  useEffect(() => {
    trackActivity('navigation: connected accounts');
  }, []);

  return (
    <>
      <div className='question-title'>Linked Accounts</div>
      <Divider />
      <BankList type='settings' />
      {isPremiumSubscriber ? (
        <Dialog
          open={selfUnlockModalOpen}
          onClose={() => {
            setSelfUnlockModalOpen(false);
          }}
        >
          <DialogTitle sx={{ marginTop: 1.5, marginRight: 1.5, marginLeft: 1.5 }}>
            <div>{TAX_FILING_YEAR} deductions lock for editing</div>
            <IconButton
              aria-label='close'
              onClick={() => {
                setSelfUnlockModalOpen(false);
              }}
              sx={{
                color: 'primary.main',
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ paddingRight: 1.5, paddingLeft: 1.5 }}>
            <DialogContentText>
              If you need to make changes, contact your assigned tax pro to unlock your return
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        <TaxFlowConfirmModal
          open={selfUnlockModalOpen}
          setOpen={setSelfUnlockModalOpen}
          header='Edit your return and re-submit?'
          body="You'll need to re-submit for review after editing your return."
          // non breaking hyphen
          confirmText='Yes, edit and re&#8209;submit'
          onConfirm={async () => {
            trackActivity('self unlock: modal confirmed');
            await moveToNotStarted();
            await advanceToCurrentSectionPage({ history });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state),
  isPremiumSubscriber: isPremiumSubscriberSelector(state)
});

const mapDispatchToProps = {
  getExpenseWaiting,
  setSelfUnlockModalOpen,
  advanceToCurrentSectionPage
};

const ConnectedSettingsLinkedAccounts = connect(mapStateToProps, mapDispatchToProps)(SettingsLinkedAccounts);

export default ConnectedSettingsLinkedAccounts;
