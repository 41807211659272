import axios from 'axios';
import _ from 'lodash';

import { setSubscriptionPromptShow, setTaxFlowError } from '@app/src/actions/taxFlowActions';
import { updateTaxData } from '@app/src/api/taxDataApi';
import { PAYMENT_ERROR_MESSAGE } from '@app/src/constants/pricingConstants';
import { serverUrl } from '@app/src/global/Environment';
import { sentMsgToReactNative } from '@app/src/global/Helpers';
import { nextNavigationItemSelector } from '@app/src/selectors/navigationListSelectors';
import { annualPriceSelector, premiumPriceSelector } from '@app/src/selectors/userSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { createSubscription, generateStripeCustomer } from '@app/src/services/pricingService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { getCampaign } from '@app/src/services/workService';
import { isPremiumSubscriberSelector, taxFilingPaidSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { SLUG__SPECIAL_WHO } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { setPaid, setSubmitTimestamp, setTaxAmounts } from '@app/src/taxflow/sections/submit/actions/submitActions';
import {
  COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
  ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT,
  SLUG__SUBMIT_SUBSCRIPTION_PROMPT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { setIsPaymentValid, setQueryResults } from '@app/src/taxflow/shared/actions/sharedActions';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector, queryResultsSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const clearPaymentType = () => (dispatch) => {
  dispatch(setCurrentAnswer({ value: { paymentType: null } }));
};

export const subscribeWithToken =
  ({ token }) =>
  async (dispatch, getState) => {
    const annualPrice = annualPriceSelector(getState());
    const premiumPrice = premiumPriceSelector(getState());
    const isPremiumSubscriber = isPremiumSubscriberSelector(getState());
    const price = isPremiumSubscriber ? premiumPrice : annualPrice;

    if (!_.get(token, 'id')) {
      dispatch(setTaxFlowError('Please enter your credit card details'));
      trackActivity('tax file: add payment method fail', { price });
      return;
    }
    await dispatch(
      generateStripeCustomer({
        stripeToken: token.id,
        skipEvent: true,
        product: 'bookkeeping',
        callback: async (error) => {
          const errorMsg = error || PAYMENT_ERROR_MESSAGE;
          notify(errorMsg);
          await dispatch(setTaxFlowError(error));
        },
        successCallback: async () => {
          dispatch(setTaxFlowError(null));
          const account_id = _.get(getState(), ['bank', 'chargeableAccount', 'account_id']);
          const planType = isPremiumSubscriber ? 'premium' : 'annual';

          const res = await dispatch(createSubscription({ account_id, origin: 'tax filing', planType }));
          if (_.get(res, ['data', 'status']) === 'error') {
            const errorMsg = _.get(res, ['data', 'data', 'error']);
            trackActivity('Purchase Failed', {
              product: 'bookkeeping',
              currency: 'USD',
              price: annualPrice,
              error: errorMsg
            });
            notify(errorMsg || PAYMENT_ERROR_MESSAGE);
            await dispatch(setTaxFlowError(_.get(res, ['data', 'data', 'error'])));
          }
        }
      })
    );
  };

export const getTaxAmounts = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/get-tax-amounts`);
  const taxAmounts = _.get(res, ['data', 'data', 'taxAmounts'], {});
  dispatch(setTaxAmounts(taxAmounts));
};

export const getPaid = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/plaid/get-tax-filing-purchase-status`);
  const paid = _.get(res, ['data', 'data', 'paid'], null);
  await dispatch(setPaid(paid));
};

export const updateReadyToFile = (irsEnabled) => async (dispatch) => {
  await axios.post(`${baseUrl}api/taxes/update-ready-to-file`, { irsEnabled });
  await dispatch(getCampaign());
};

export const getSubmitTimestamp = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/submit-timestamp`);
  const submitTimestamp = _.get(res, ['data', 'data', 'submitTimestamp'], {});
  dispatch(setSubmitTimestamp(submitTimestamp));
};

export const showSubscriptionPromptConditional = () => (dispatch, getState) => {
  const currentQuestion = currentQuestionSelector(getState());
  const nextNavigationItem = nextNavigationItemSelector(getState());
  const queryResults = queryResultsSelector(getState());
  const subscriptionPromptQueryResult = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT
  });
  const paid = taxFilingPaidSelector(getState());
  if (
    currentQuestion.slug === SLUG__SPECIAL_WHO &&
    nextNavigationItem.id === 'personal' &&
    _.get(subscriptionPromptQueryResult, ['answer', 'value']) !== '1' &&
    !paid
  ) {
    dispatch(setSubscriptionPromptShow(true));
  }
};

const dismissSubscriptionPrompt = () => async (dispatch, getState) => {
  dispatch(setSubscriptionPromptShow(false));

  const queryResults = queryResultsSelector(getState());
  const newQueryResults = queryResults.map((queryResult) =>
    queryResult.coll_type === COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT &&
    queryResult.coll_id === DEFAULT_COLLECTION_ID &&
    queryResult.slug === SLUG__SUBMIT_SUBSCRIPTION_PROMPT
      ? {
          ...queryResult,
          answer: {
            ...queryResult.answer,
            value: '1'
          }
        }
      : queryResult
  );
  dispatch(setQueryResults(newQueryResults));
  await dispatch(
    updateTaxData({
      taxData: [
        {
          coll_type: COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: SLUG__SUBMIT_SUBSCRIPTION_PROMPT,
          value: '1'
        }
      ]
    })
  );
};

const openSubscribeModal = () => async () => {
  sentMsgToReactNative('open_subscribe_modal', { origin: 'subscription prompt' });
};

export const startSubscriptionNow = () => async (dispatch) => {
  trackActivity('subscription prompt: start now');
  await Promise.all([dispatch(dismissSubscriptionPrompt()), dispatch(openSubscribeModal())]);
};

export const startSubscriptionLater = () => async (dispatch) => {
  trackActivity('subscription prompt: start later');
  await dispatch(dismissSubscriptionPrompt());
};

export const updateIsPaymentValid = (isPaymentValid) => (dispatch) => {
  dispatch(setIsPaymentValid(isPaymentValid));
};
