import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getIncomeType, getIncomeTypeLite, updateIncomeType } from '@app/src/services/onboardingService';
import { Url_ONBOARDING_INCOME_TYPE } from '@app/src/constants/onboardingConstants';
import CompanyInfo from '@app/src/Components/Common/CompanyInfo/CompanyInfo';
import OnboardingQuestion from '@app/src/Components/Onboarding/Question/OnboardingQuestion';
import { getWorkInfoLocalStorage, isW2OnlyPreSignup } from '@app/src/services/onboardingLocalStorageService';

class OnboardingIncomeType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      didFullCall: false
    };
  }

  async componentDidMount() {
    await this.props.getIncomeTypeLite();
  }

  async componentDidUpdate() {
    if (!this.state.didFullCall) {
      this.setState({ didFullCall: true });
      await this.props.getIncomeType({
        slug: Url_ONBOARDING_INCOME_TYPE,
        history: this.props.history
      });
    }
  }

  onNext = async () => {
    await this.props.updateIncomeType({ history: this.props.history });
    const nextUrl = isW2OnlyPreSignup() ? '/onboarding/account' : '/onboarding/job-select';
    this.props.history.push(nextUrl);

    const workDetails = getWorkInfoLocalStorage();

    window.analytics.identify({
      income_type: _.get(workDetails, 'income_type')
    });
  };

  render() {
    return (
      <>
        <OnboardingQuestion onNext={this.onNext} isPrev={false} />
        <CompanyInfo />
      </>
    );
  }
}

const mapDispatchToProps = {
  getIncomeType,
  getIncomeTypeLite,
  updateIncomeType
};

const ConnectedOnboardingIncomeType = connect(null, mapDispatchToProps)(OnboardingIncomeType);

export default ConnectedOnboardingIncomeType;
