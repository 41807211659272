import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import ExpenseReviewFreeTrialContent from '@app/src/Components/ExpenseReview/ExpenseReviewFreeTrialContent';
import { INACTIVE_USER_STATUSES } from '@app/src/constants/constants';
import { PAYWALLED_STATES_MAP } from '@app/src/constants/subscriptionConstants';
import { trackActivity } from '@app/src/services/analyticsService';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

import '@app/src/Components/ExpenseReview/ExpenseReviewModalPaywall.scss';

const ExpenseReviewModalPaywall = ({ subscriptionStatus, setExpenseReviewModalType, className }) => {
  useEffect(() => {
    trackActivity('Expense review: paywall', { subscriptionStatus });
  });

  if (!INACTIVE_USER_STATUSES.includes(subscriptionStatus)) {
    return null;
  }

  const title = _.get(PAYWALLED_STATES_MAP, [subscriptionStatus, 'title']);
  const message = _.get(PAYWALLED_STATES_MAP, [subscriptionStatus, 'message']);
  const image = _.get(PAYWALLED_STATES_MAP, [subscriptionStatus, 'image']);
  const cta = _.get(PAYWALLED_STATES_MAP, [subscriptionStatus, 'cta']);

  const handleContinue = () => {
    setExpenseReviewModalType('payment');
  };

  return (
    <div className={classNames('expense-review-modal-paywall', className)}>
      <DialogTitle>{title}</DialogTitle>
      {subscriptionStatus === 'pre free trial' ? (
        <ExpenseReviewFreeTrialContent />
      ) : (
        <>
          <div className='expense-review-modal-paywall-item' style={{ margin: '16px 0' }}>
            <img src={image} alt='Subscription image' style={{ width: '50%' }} />
          </div>
          <DialogContentText style={{ textAlign: 'left' }}>{message}</DialogContentText>
        </>
      )}
      <Divider style={{ height: '1px', backgroundColor: '#aaaaab' }} />
      <div className='expense-review-modal-paywall-item'>
        <Button variant='contained' color='secondary' fullWidth onClick={handleContinue}>
          {cta}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subscriptionStatus: _.get(accountDetailsSelector(state), 'status')
});

const mapDispatchToProps = { setExpenseReviewModalType };

const ConnectedExpenseReviewModalPaywall = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalPaywall);

export default ConnectedExpenseReviewModalPaywall;
