import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import TaxFlowCollectionFormUploadErrorModal from '@app/src/taxflow/collection/components/TaxFlowCollectionFormUploadErrorModal';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { bulkUploadItemsSelector, bulkUploadPillsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { getQueryResults } from '@app/src/taxflow/main/services/taxFlowDataService';
import { getErrors } from '@app/src/services/taxValidationService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { collectionFormUploadErrorModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import TaxFlowSubscriptionPrompt from '@app/src/taxflow/navigation/components/TaxFlowSubscriptionPrompt';
import { showCollectionDeleteModal } from '@app/src/taxflow/collection/services/collectionService';
import { getBulkUploadFormUrl } from '@app/src/taxflow/collection/utils/collectionUtils';
import TaxFlowPillsSection from '@app/src/Components/TaxFlow/Common/TaxFlowPillsSection';
import { trackActivity } from '@app/src/services/analyticsService';
import { getUploadAttemptsStatuses } from '@app/src/taxFlowMessageEntrypoint';
import { setBulkUploadPills } from '@app/src/taxflow/shared/actions/sharedActions';
import { usePrevious } from 'react-use';

const TaxFlowBulkUploadItem = ({
  history,
  currentQuestion,
  collectionDeleteModalShow,
  collectionFormUploadErrorModalShow,
  bulkUploadPills,
  showCollectionDeleteModal,
  getUploadAttemptsStatuses,
  setBulkUploadPills
}) => {
  const previousBulkUploadPills = usePrevious(bulkUploadPills);
  const [pills, setPills] = useState([]);

  const navigateToLinkedQuestion = useCallback(
    ({ collectionType, collectionId, clickable, nextSlug, text }) => {
      trackActivity('bulk upload: pill clicked', { collectionType, collectionId, pillName: text });
      if (!clickable || _.isNil(nextSlug)) {
        return;
      }
      history.push(getBulkUploadFormUrl({ nextPathComponent: nextSlug, collectionId }));
    },
    [history]
  );
  const confirmPillDeletion = useCallback(
    ({ collectionType, collectionId, text }) => {
      trackActivity('bulk upload: pill removal requested', { collectionType, collectionId, pillName: text });
      showCollectionDeleteModal({
        collectionType: collectionType,
        collectionId
      });
    },
    [showCollectionDeleteModal]
  );

  const removePillAndRefresh = useCallback(
    ({ collectionType, collectionId }) => {
      setBulkUploadPills(_.filter(bulkUploadPills, _.negate(_.matches({ collectionType, collectionId }))));
      getUploadAttemptsStatuses();
    },
    [bulkUploadPills, setBulkUploadPills, getUploadAttemptsStatuses]
  );

  // Form upload UI analytics - report parse behavior as seen by the user
  useEffect(() => {
    if (_.isNil(previousBulkUploadPills)) {
      return;
    }
    const pillsWithLoadingChange = _.differenceWith(bulkUploadPills, previousBulkUploadPills, (pill, previousPill) =>
      _.isMatch(pill, _.pick(previousPill, ['collectionType', 'collectionId', 'loading']))
    );

    _.map(pillsWithLoadingChange, ({ collectionType, collectionId, loading, parseFailed, validationFailed }) => {
      if (loading) {
        trackActivity('bulk upload FE: parsing waiting', { collectionType, collectionId });
      } else {
        trackActivity('bulk upload FE: parsing complete', {
          success: !parseFailed && !validationFailed,
          uploadStatus: parseFailed ? 'failure' : 'success',
          collectionType,
          collectionId
        });
      }
    });
  }, [bulkUploadPills, previousBulkUploadPills]);

  useEffect(() => {
    setPills(
      _.map(
        bulkUploadPills,
        ({
          collectionType,
          collectionId,
          loading,
          text,
          validationFailed,
          parseFailed,
          clickable,
          formInputQuestionSlug
        }) => ({
          text,
          variant: validationFailed || parseFailed ? 'warning' : 'default',
          loading,
          removable: true,
          onClick: () =>
            navigateToLinkedQuestion({
              collectionType,
              collectionId,
              clickable,
              nextSlug: formInputQuestionSlug,
              text
            }),
          onRemove: () => confirmPillDeletion({ collectionType, collectionId, text })
        })
      )
    );
  }, [bulkUploadPills, setPills, navigateToLinkedQuestion, confirmPillDeletion]);

  return (
    <>
      {!_.isEmpty(bulkUploadPills) && (
        <TaxFlowPillsSection sectionHeader={'Your forms'} pills={pills}></TaxFlowPillsSection>
      )}
      <TaxFlowSubscriptionPrompt />
      {collectionDeleteModalShow && (
        <TaxFlowCollectionDeleteModal currentQuestion={currentQuestion} onDelete={removePillAndRefresh} />
      )}
      {collectionFormUploadErrorModalShow && <TaxFlowCollectionFormUploadErrorModal />}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  collectionDeleteModalShow: _.get(state, ['taxFlowModals', 'collectionDeleteModalShow']),
  collectionFormUploadErrorModalShow: collectionFormUploadErrorModalShowSelector(state),
  bulkUploadItems: bulkUploadItemsSelector(state),
  bulkUploadPills: bulkUploadPillsSelector(state)
});

const mapDispatchToProps = {
  getQueryResults,
  getErrors,
  setCurrentAnswer,
  showCollectionDeleteModal,
  getUploadAttemptsStatuses,
  setBulkUploadPills
};

const ConnectedTaxFlowBulkUploadItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowBulkUploadItem);

export default ConnectedTaxFlowBulkUploadItem;
