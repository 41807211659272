import { createCookie, getDomain } from '@app/src/global/Helpers';
import {
  REFERRER_STORAGE_KEY,
  REFERRER_ID_STORAGE_KEY,
  REFERRER_PARAM_KEY,
  REFERRER_ID_PARAM_KEY
} from '@app/src/constants/constants';
import { updateTaxData } from '@app/src/api/taxDataApi';

export const createReferrerCookies =
  ({ params }) =>
  () => {
    //check does have referral code then set in session storage and use when signup
    const referrerCode = params[REFERRER_PARAM_KEY] || params.invite;
    if (referrerCode) {
      createCookie(REFERRER_STORAGE_KEY, referrerCode, 1, '/', getDomain());
    }

    //check does have referral Id code then set in session storage and use when signup
    const referrerIdCode = params[REFERRER_ID_PARAM_KEY];
    if (referrerIdCode) {
      createCookie(REFERRER_ID_STORAGE_KEY, referrerIdCode, 1, '/', getDomain());
    }
  };

export const setMilesLocal =
  ({ miles }) =>
  () => {
    localStorage.setItem('miles', `${miles}`);
  };

export const setMilesRemote =
  ({ miles }) =>
  async (dispatch) => {
    await dispatch(
      updateTaxData({
        taxData: [
          {
            coll_type: 'car',
            coll_id: '1',
            slug: 'car-work-miles',
            value: `${miles}`
          }
        ],
        generateSharedCollectionId: false
      })
    );
  };
