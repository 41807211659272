import Autocomplete from '@mui/material/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Fuse from 'fuse.js';
import { ArrowDown2 } from 'iconsax-react';
import _ from 'lodash';
import React, { useMemo } from 'react';

import { FUSE_CATEGORY_OPTIONS } from '@app/src/constants/constants';
import { colorPrimaryAlt, colorSurface } from '@app/src/theme';

const CategorySearch = ({ open, anchorEl, value, onChange, handleClose, filterOptions }) => {
  const fuse = useMemo(() => new Fuse(filterOptions, FUSE_CATEGORY_OPTIONS), [filterOptions]);

  return (
    <Popper open={open} anchorEl={anchorEl} style={{ width: 200, zIndex: 9000 }} placement='bottom-start'>
      <ClickAwayListener onClickAway={handleClose}>
        <Autocomplete
          open
          onClose={(_, reason) => {
            if (reason === 'escape') {
              handleClose();
            }
          }}
          onChange={(event, newValue, reason) => {
            if (
              event.type === 'keydown' &&
              (event.key === 'Backspace' || event.key === 'Delete') &&
              reason === 'removeOption'
            ) {
              return;
            }
            onChange({ event, newValue, reason });
          }}
          disableCloseOnSelect
          renderTags={() => null}
          options={filterOptions}
          popupIcon={<ArrowDown2 />}
          filterOptions={(options, { inputValue }) => {
            if (inputValue === '') {
              const selectedOption = options.find((option) => option.value === value[0]);

              if (selectedOption) {
                return [selectedOption, ...options.filter((option) => option.value !== value[0])];
              }

              return options;
            }

            const searchResult = fuse.search(inputValue);

            return _.map(searchResult, 'item');
          }}
          renderOption={(props, option) => (
            <MenuItem
              aria-selected={value[0] === option.value}
              style={{
                backgroundColor: value[0] === option.value && colorPrimaryAlt,
                color: value[0] === option.value && colorSurface
              }}
              {...props}
            >
              {option.displayName}
            </MenuItem>
          )}
          getOptionLabel={(option) => option.displayName}
          renderInput={(params) => (
            <TextField
              {...params}
              color='secondary'
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              placeholder='e.g. Supplies'
              sx={{ zIndex: 9999 }}
            />
          )}
        />
      </ClickAwayListener>
    </Popper>
  );
};

export default CategorySearch;
