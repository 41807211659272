// path components
export const HOME_PATH_COMPONENTS = {
  ACQUIRED: 'home-acquired',
  DEPRECIATION: 'home-depreciation',
  DEPRECIATION_CONFIRMATION: 'home-depreciation-confirmation',
  DEPRECIATION_TOTAL: 'home-depreciation-total',
  DONE: 'home-done',
  DURATION: 'home-duration',
  EXIT_BOUNCE: 'home-exit-bounce',
  EXPENSES_OWN: 'home-expenses-own',
  EXPENSES_RENT: 'home-expenses-rent',
  OFFICE_SIZE: 'home-office-size',
  OPTIONS: 'home-options',
  PAST: 'home-past',
  TYPE: 'home-type',
  VALUE: 'home-value'
};

// slugs
export const HOME_SLUGS = {
  ACQUIRED: 'home-acquired',
  DEPRECIATION: 'home-depreciation',
  DEPRECIATION_CONFIRMATION: 'home-depreciation-confirmation',
  DEPRECIATION_TOTAL: 'home-depreciation-total',
  DONE: 'home-done',
  DURATION: 'home-duration',
  EXIT_BOUNCE: 'home-exit-bounce',
  EXPENSES_MONTHLY_HOME_INSURANCE: 'home-expenses-monthly-home-insurance',
  EXPENSES_MONTHLY_MISCELLANEOUS: 'home-expenses-monthly-miscellaneous',
  EXPENSES_MONTHLY_MORTGAGE_INTEREST: 'home-expenses-monthly-mortgage-interest',
  EXPENSES_MONTHLY_RENTERS_INSURANCE: 'home-expenses-monthly-renters-insurance',
  EXPENSES_MONTHLY_RENT: 'home-expenses-monthly-rent',
  EXPENSES_MONTHLY_UTILITIES: 'home-expenses-monthly-utilities',
  EXPENSES_OWN: 'home-expenses-own',
  EXPENSES_RENT: 'home-expenses-rent',
  OFFICE_SIZE: 'home-office-size',
  OPTIONS: 'home-options',
  PAST: 'home-past',
  TYPE: 'home-type',
  VALUE: 'home-value'
};

export const HOME_ENDPOINT_ATTRIBUTES = {
  DATES: 'home-dates',
  OFFICE_SIZE_TOTAL: 'home-office-size-total',
  OFFICE_SIZE_WORK_SPACE: 'home-office-size-work-space',
  OPTIONS: 'home-options',
  VALUE_IMPROVEMENTS: 'home-value-improvements',
  VALUE_LAND: 'home-value-land',
  VALUE_PURCHASE_PRICE: 'home-value-purchase-price',
  YEARS_DEPRECIATION: 'home-years-depreciation'
};

// collection types
export const COLLECTION_TYPE__HOME = 'home';
