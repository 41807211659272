import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { setHolisticOnboardingJob } from '@app/src/actions/holisticOnboardingActions';
import { jobsSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import JobSelect from '@app/src/Components/Common/JobSelect/JobSelect';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';

const OnboardingJobSelect = ({ allJobs, setHolisticOnboardingJob, selectedJobs }) => {
  const handleAddJob = (job) => {
    setHolisticOnboardingJob([_.find(allJobs, { slug: job.slug }), ...selectedJobs]);
  };

  const handleRemoveJob = (job) => {
    setHolisticOnboardingJob(selectedJobs.filter((selectedJob) => selectedJob.slug !== job.slug));
  };

  return (
    <JobSelect
      onAdd={handleAddJob}
      onRemove={handleRemoveJob}
      selectedJobs={selectedJobs}
      origin='holistic onboarding'
    />
  );
};

const mapStateToProps = (state) => ({
  allJobs: jobCategoriesSelector(state),
  selectedJobs: jobsSelector(state)
});

const mapDispatchToProps = {
  setHolisticOnboardingJob
};

const ConnectedOnboardingJobSelect = connect(mapStateToProps, mapDispatchToProps)(OnboardingJobSelect);

export default ConnectedOnboardingJobSelect;
