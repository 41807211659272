import Button from '@mui/material/Button';
import { HambergerMenu } from 'iconsax-react';
import React from 'react';
import { connect } from 'react-redux';

import { setIsNavigationPanelOpen } from '@app/src/taxflow/navigation/actions/navigationActions';

import '@app/src/Components/Settings/SettingsFooter.scss';

const SettingsFooter = ({ setIsNavigationPanelOpen }) => {
  return (
    <div className='footer-container'>
      <div className='footer-content'>
        <Button variant='outlined' onClick={() => setIsNavigationPanelOpen(true)}>
          <HambergerMenu />
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setIsNavigationPanelOpen
};

const ConnectedSettingsFooter = connect(null, mapDispatchToProps)(SettingsFooter);

export default ConnectedSettingsFooter;
