import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  SLUG__CAR_PERCENT,
  SLUG__CAR_OPTIONS,
  SLUG__CAR_MILES_CONFIRMATION,
  COLLECTION_TYPE__CAR,
  ENDPOINT_ATTRIBUTE__CAR_PERCENT,
  ENDPOINT_ATTRIBUTE__CAR_TOTAL_MILES,
  SLUG__CAR_DEPRECIATION_CONFIRMATION
} from '@app/src/taxflow/sections/car/constants/carConstants';
import {
  COLLECTION_TYPE__HOME,
  HOME_ENDPOINT_ATTRIBUTES,
  HOME_SLUGS
} from '@app/src/taxflow/sections/home/constants/homeConstants';
import {
  SLUG__HOME_ADDRESS_STATE,
  SLUG__SELF_FIRST_NAME,
  SLUG__SELF_LAST_NAME,
  SLUG__SELF_TAX_STATUS
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  getSubstitutions,
  getDerivedQuestion,
  getQuestionById,
  getStringWithSubstitutions
} from '@app/src/taxflow/main/utils/mainUtils';
import {
  queryResultsSelector,
  currentCollectionIdSelector,
  taxFilePriceSelector,
  cogsSummarySelector,
  currentQuestionSelector,
  deductionsSelector,
  currentTaxStateSelector,
  statusSelector,
  collectionTypeItemsSelector,
  queryResultsMapSelector,
  currentAnswerSelector,
  homeOfficeAmountsSelector,
  homeOfficeExpensesSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import {
  SLUG__CREDIT_COGS_NEW_INVENTORY,
  SLUG__CREDIT_COGS_END_INVENTORY,
  SLUG__CREDIT_STANDARD_HEALTH_INSURANCE,
  SLUG__CREDIT_STANDARD_MORTGAGE_INTEREST,
  COLLECTION_TYPE__CREDIT_HOMEOWNER,
  ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST,
  COLLECTION_TYPE__CREDIT_CHARITY,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_STANDARD_CHARITY,
  COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED,
  SLUG__CREDIT_HEALTH_HAS_1095A
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { getOtherJobName } from '@app/src/taxflow/collection/utils/collectionUtils';
import {
  workDetailsSelector,
  taxFilingEnabledSelector,
  freeTaxFilingSelector,
  idVerificationEnabledSelector,
  uiStageSelector
} from '@app/src/selectors/workSelectors';
import {
  DEFAULT_COLLECTION_ID,
  UI_STAGE_METADATA,
  UI_STAGE__CONFIRM_AMOUNTS
} from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  SLUG__SUBMIT_SUBSCRIPTION_PROMPT_ANNUAL,
  SLUG__SUBMIT_SUBSCRIPTION_PROMPT_MONTHLY
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { userSelector } from '@app/src/selectors/userSelectors';
import { savingsSelector } from '@app/src/selectors/dashboardSelectors';
import {
  SLUG__INCOME_FREELANCE_BUSINESS_CODE,
  SLUG__INCOME_FREELANCE_JOB_NAME
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import isValidInput from '@app/src/services/taxFlow/isValidInput';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { hasQuestionSpecialChars } from '@app/src/global/Helpers';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import {
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { submitWarningsSelector } from '@app/src/api/taxDataApi';

export const questionSelector = (state, props) => props.question;
export const allQuestionsSelector = (state) => _.get(state, ['taxFlow', 'allQuestions']);
const carDepreciationSelector = (state) => _.get(state, ['taxFlow', 'carPriorDepreciation']);
const homeDepreciationSelector = (state) => _.get(state, ['taxFlow', 'homePriorDepreciation']);
export const accountDetailsSelector = (state) => _.get(state, ['pricing2', 'accountDetails'], {});
const paidSelector = (state) => _.get(state, ['taxFlow', 'paid']);
export const idVerificationResultSelector = (state) => _.get(state, ['taxFlow', 'idVerificationResult']);

export const taxFilingPaidSelector = createSelector(
  [paidSelector, freeTaxFilingSelector],
  (paid, freeTaxFiling) => paid || freeTaxFiling
);

export const savedDefaultAnswerSelector = createSelector(
  [
    questionSelector,
    workDetailsSelector,
    userSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    cogsSummarySelector,
    carDepreciationSelector,
    homeDepreciationSelector,
    deductionsSelector,
    homeOfficeExpensesSelector
  ],
  (
    question,
    workDetails,
    user,
    queryResults,
    currentCollectionId,
    cogsSummary,
    carPriorDepreciation,
    homePriorDepreciation,
    deductions,
    homeOfficeExpenses
  ) => {
    const getDefaultValue = () => {
      if (question.slug === SLUG__CAR_OPTIONS) {
        return workDetails.car ? null : 'no_car';
      }
      if (question.slug === SLUG__CAR_PERCENT) {
        return workDetails.percent_car;
      }
      if (question.slug === HOME_SLUGS.OPTIONS) {
        return workDetails.home ? null : 'no_home';
      }
      if (question.slug === SLUG__SELF_FIRST_NAME) {
        return user.firstname;
      }
      if (question.slug === SLUG__SELF_LAST_NAME) {
        return user.lastname;
      }
      if (question.slug === SLUG__HOME_ADDRESS_STATE) {
        return workDetails.state_residence;
      }
      if (question.slug === SLUG__SELF_TAX_STATUS) {
        return workDetails.filing_status;
      }
      if (question.slug === SLUG__CAR_MILES_CONFIRMATION) {
        const percentQueryResult = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: COLLECTION_TYPE__CAR,
          collectionId: currentCollectionId,
          slug: ENDPOINT_ATTRIBUTE__CAR_PERCENT
        });
        const percent = _.get(percentQueryResult, ['answer', 'value']);
        const totalMilesQueryResult = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: COLLECTION_TYPE__CAR,
          collectionId: currentCollectionId,
          slug: ENDPOINT_ATTRIBUTE__CAR_TOTAL_MILES
        });
        const totalMiles = _.get(totalMilesQueryResult, ['answer', 'value']);
        const workMiles = Math.round((percent / 100) * totalMiles);
        return workMiles;
      }
      if (question.slug === SLUG__CAR_DEPRECIATION_CONFIRMATION) {
        return carPriorDepreciation;
      }
      if (question.slug === HOME_SLUGS.DEPRECIATION_CONFIRMATION) {
        return homePriorDepreciation;
      }
      if (question.slug === SLUG__CREDIT_COGS_NEW_INVENTORY || question.slug === SLUG__CREDIT_COGS_END_INVENTORY) {
        return _.get(cogsSummary, 'inventory');
      }
      if (question.slug === SLUG__CREDIT_HEALTH_HAS_1095A) {
        const attempted1095AInput = _.get(
          getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
            collectionId: DEFAULT_COLLECTION_ID,
            slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED
          }),
          ['answer', 'value']
        );
        return attempted1095AInput ? '1' : '0';
      }
      if (question.slug === SLUG__CREDIT_STANDARD_HEALTH_INSURANCE) {
        return _.get(deductions, ['itemizedDefaults', 'yearlyPremium']);
      }
      if (question.slug === SLUG__CREDIT_STANDARD_MORTGAGE_INTEREST) {
        const mortgageInterest = _.chain(queryResults)
          .filter({
            coll_type: COLLECTION_TYPE__CREDIT_HOMEOWNER,
            slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
          })
          .map((result) => _.get(result, ['answer', 'value']))
          .sumBy(Number)
          .value();

        if (mortgageInterest) {
          return mortgageInterest;
        }
      }
      if (question.slug === HOME_SLUGS.EXPENSES_MONTHLY_MORTGAGE_INTEREST) {
        const mortgageInterest = _.chain(queryResults)
          .filter({
            coll_type: COLLECTION_TYPE__CREDIT_HOMEOWNER,
            slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
          })
          .map((result) => _.get(result, ['answer', 'value']))
          .sumBy(Number)
          .value();

        const duration = _.get(
          getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: COLLECTION_TYPE__HOME,
            collectionId: currentCollectionId,
            slug: HOME_ENDPOINT_ATTRIBUTES.DATES
          }),
          ['answer']
        );

        const numberOfMonths = Number(duration.value) === 1 ? duration.months.length : 12;

        if (mortgageInterest) {
          return mortgageInterest / numberOfMonths;
        }
      }
      if (question.slug === SLUG__CREDIT_STANDARD_CHARITY) {
        const donations = _.get(
          getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: COLLECTION_TYPE__CREDIT_CHARITY,
            collectionId: DEFAULT_COLLECTION_ID,
            slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT
          }),
          ['answer', 'value']
        );

        if (donations) {
          return donations;
        }
      }
      if (question.slug === HOME_SLUGS.EXPENSES_MONTHLY_UTILITIES) {
        return homeOfficeExpenses.utilities;
      }
      if (
        [HOME_SLUGS.EXPENSES_MONTHLY_RENTERS_INSURANCE, HOME_SLUGS.EXPENSES_MONTHLY_HOME_INSURANCE].includes(
          question.slug
        )
      ) {
        return homeOfficeExpenses.insurance;
      }
      if (question.slug === HOME_SLUGS.EXPENSES_MONTHLY_MISCELLANEOUS) {
        return homeOfficeExpenses.miscellaneous;
      }
      if (question.slug === HOME_SLUGS.EXPENSES_MONTHLY_RENT) {
        return homeOfficeExpenses.rent;
      }
      return null;
    };
    const defaultValue = getDefaultValue();
    if (defaultValue === null) {
      return null;
    }
    // Don't suggest default if value not valid;
    if (hasQuestionSpecialChars(defaultValue, question)) {
      return null;
    }
    return { value: defaultValue };
  }
);

export const taxAmountsSelector = (state) => _.get(state, ['taxFlow', 'taxAmounts']);

export const submitEnabledSelector = (state) => {
  const navigationItems = _.get(state, ['taxFlowNavigation', 'navigationItems']);
  const queryResults = _.get(state, ['taxFlow', 'queryResults']);

  if (REACT_APP_ENV === 'staging-prod-db' || REACT_APP_ENV === 'development') {
    return true;
  }

  const submitNavItem = navigationItems.find((item) => item.id === 'submit');
  const submitEnabledStatus = _.get(submitNavItem, ['meta', 'submitEnabled']);

  const states = queryResults
    .filter(
      (queryResult) =>
        queryResult.coll_type === COLLECTION_TYPE__STATE_RETURN && queryResult.slug === SLUG__STATE_RETURN
    )
    .map((state) => _.get(state, ['answer', 'value']));

  return states.every((state) => _.get(submitEnabledStatus, state));
};

export const substitutionsSelector = createSelector(
  [
    currentQuestionSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    taxFilePriceSelector,
    workDetailsSelector,
    taxAmountsSelector,
    jobCategoriesSelector,
    deductionsSelector,
    currentTaxStateSelector,
    savingsSelector,
    submitWarningsSelector,
    submitEnabledSelector,
    idVerificationResultSelector,
    idVerificationEnabledSelector,
    homeOfficeAmountsSelector,
    uiStageSelector
  ],
  (
    currentQuestion,
    queryResults,
    currentCollectionId,
    taxFilePrice,
    workDetails,
    taxAmounts,
    jobCategories,
    deductions,
    currentTaxState,
    savings,
    submitWarnings,
    submitEnabled,
    idVerificationResult,
    idVerificationEnabled,
    homeOfficeAmounts,
    uiStage
  ) =>
    getSubstitutions({
      question: currentQuestion,
      queryResults,
      collectionId: currentCollectionId,
      taxFilePrice,
      workDetails,
      taxAmounts,
      jobCategories,
      deductions,
      taxState: currentTaxState,
      savings,
      submitWarnings,
      submitEnabled,
      idVerificationResult,
      idVerificationEnabled,
      homeOfficeAmounts,
      uiStage
    })
);

export const substitutionsWithQuestionSelector = createSelector(
  [
    questionSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    taxFilePriceSelector,
    workDetailsSelector,
    taxAmountsSelector,
    jobCategoriesSelector,
    taxFilingEnabledSelector,
    deductionsSelector,
    currentTaxStateSelector,
    submitWarningsSelector,
    submitEnabledSelector,
    idVerificationResultSelector,
    homeOfficeAmountsSelector,
    uiStageSelector
  ],
  (
    question,
    queryResults,
    currentCollectionId,
    taxFilePrice,
    workDetails,
    taxAmounts,
    jobCategories,
    taxFilingEnabled,
    deductions,
    currentTaxState,
    submitWarnings,
    submitEnabled,
    idVerificationResult,
    homeOfficeAmounts,
    uiStage
  ) =>
    getSubstitutions({
      question,
      queryResults,
      collectionId: currentCollectionId,
      taxFilePrice,
      workDetails,
      taxAmounts,
      jobCategories,
      taxFilingEnabled,
      deductions,
      taxState: currentTaxState,
      submitWarnings,
      submitEnabled,
      idVerificationResult,
      homeOfficeAmounts,
      uiStage
    })
);

const customJobsSelector = createSelector([queryResultsMapSelector], (queryResultsMap) => {
  const incomeFreelanceForms = _.get(queryResultsMap, 'income-freelance', []);

  const jobs = incomeFreelanceForms.map((form) => {
    const started = _.get(form, 'income-freelance-started');
    const name = _.get(form, SLUG__INCOME_FREELANCE_JOB_NAME);
    const businessCode = _.get(form, SLUG__INCOME_FREELANCE_BUSINESS_CODE);

    if (!form || !started || !name || !businessCode) {
      return null;
    }

    return {
      text: name,
      value: name,
      businessCode
    };
  });

  return _.compact(jobs);
});

export const jobOptionsSelector = createSelector(
  [workDetailsSelector, jobCategoriesSelector, customJobsSelector],
  (workDetails, jobCategories, customJobs) => {
    // don't allow users to select generic jobs in income freelance
    const jobIds = _.get(workDetails, 'jobs', []).filter((jobId) => jobId !== 'business-owner' && jobId !== 'other');

    const keeperJobs = jobIds.map((jobId) => {
      const jobCategory = jobCategories.find((jobCategory) => jobCategory.slug === jobId);
      if (jobCategory) {
        return {
          text: jobCategory.name,
          value: jobCategory.slug,
          iconUrl: jobCategory.icon_url,
          businessCode: jobCategory.businessCode
        };
      } else {
        return {
          text: getOtherJobName(jobId),
          value: jobId
        };
      }
    });

    return _.uniqWith(
      [...keeperJobs, ...customJobs],
      (a, b) => a.value === b.value && a.businessCode === b.businessCode
    );
  }
);

export const allDerivedQuestionsSelector = createSelector(
  [allQuestionsSelector, jobOptionsSelector],
  (allQuestions, jobOptions) => {
    return allQuestions.map((question) => getDerivedQuestion({ question, jobOptions }));
  }
);

export const subscriptionPromptQuestionSelector = createSelector(
  [allQuestionsSelector, accountDetailsSelector],
  (allQuestions, accountDetails) => {
    const slug =
      _.get(accountDetails, 'interval') === 'month'
        ? SLUG__SUBMIT_SUBSCRIPTION_PROMPT_MONTHLY
        : SLUG__SUBMIT_SUBSCRIPTION_PROMPT_ANNUAL;
    return getQuestionById({ allQuestions, slug });
  }
);

export const collectionTypeItemsWithSubstitutionsSelector = createSelector(
  [collectionTypeItemsSelector, substitutionsSelector],
  (collectionTypeItems, substitutions) =>
    collectionTypeItems.map((item) => ({
      ...item,
      fields: {
        ...item.fields,
        modalTitle: _.get(item, ['fields', 'modalTitle'])
          ? getStringWithSubstitutions({ str: item.fields.modalTitle, substitutions })
          : null,
        modalBody: _.get(item, ['fields', 'modalBody'])
          ? getStringWithSubstitutions({ str: item.fields.modalBody, substitutions })
          : null
      }
    }))
);

export const isValidInputSelector = createSelector(
  [currentQuestionSelector, currentAnswerSelector, statusSelector, queryResultsSelector, currentCollectionIdSelector],
  (question, answer, status, queryResults, currentCollectionId) =>
    isValidInput({ question, answer, status, queryResults, currentCollectionId })
);

export const hasActiveSubscriptionSelector = createSelector([accountDetailsSelector], (accountDetails) => {
  return ['active', 'free trial'].includes(_.get(accountDetails, 'status'));
});

export const isPremiumSubscriberSelector = createSelector(accountDetailsSelector, (accountDetails) =>
  _.get(accountDetails, 'isPremiumSubscriber', false)
);

export const zeroTaxOwedSelector = createSelector([taxAmountsSelector], (taxAmounts) => {
  const allMultistateZero = taxAmounts?.multistate?.every(({ amount }) => amount === 0) ?? true;
  return Number(taxAmounts?.federal_amount) === 0 && allMultistateZero;
});

export const selfUnlockEnabledSelector = createSelector(
  [uiStageSelector, currentQuestionSelector, isPremiumSubscriberSelector],
  (uiStage, currentQuestion, isPremiumSubscriber) =>
    uiStage === UI_STAGE__CONFIRM_AMOUNTS && currentQuestion?.selfUnlockEnabled && !isPremiumSubscriber
);

export const isPreSubmitSelector = createSelector([uiStageSelector], (uiStage) => {
  if (_.isNil(uiStage) || _.isEmpty(uiStage)) {
    return true;
  }
  return _.some(UI_STAGE_METADATA, { stage: uiStage, preSubmit: true });
});
