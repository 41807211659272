import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Briefcase, Graph, Heart, Home, Personalcard, Profile2User } from 'iconsax-react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  setHolisticOnboardingDependents,
  setHolisticOnboardingHomeOwner,
  setHolisticOnboardingIncomeType,
  setHolisticOnboardingInitialLoad,
  setHolisticOnboardingMarried,
  setHolisticOnboardingRent,
  setHolisticOnboardingStudentLoans
} from '@app/src/actions/holisticOnboardingActions';
import EducationIcon from '@app/src/assets/expenseCategoryIcons/Education.svg?react';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import VerticalIconButton from '@app/src/Components/HolisticOnboarding/components/VerticalIconButton/VerticalIconButton';
import {
  dependentsSelector,
  homeOwnerSelector,
  incomeTypeSelector,
  initialLoadSelector,
  marriedSelector,
  rentSelector,
  studentLoansSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { colorPrimary } from '@app/src/theme';

const OnboardingSituations = ({
  dependents,
  homeOwner,
  incomeTypes,
  married,
  rent,
  studentLoans,
  loading,
  setHolisticOnboardingDependents,
  setHolisticOnboardingIncomeType,
  setHolisticOnboardingMarried,
  setHolisticOnboardingHomeOwner,
  setHolisticOnboardingRent,
  setHolisticOnboardingStudentLoans,
  setHolisticOnboardingInitialLoad
}) => {
  const options = [
    {
      name: "I'm married",
      icon: Heart,
      onClick: () => {
        setHolisticOnboardingMarried(married === 'married' ? 'single' : 'married');
      },
      selected: married === 'married'
    },
    {
      name: 'I have dependents',
      icon: Profile2User,
      onClick: () => {
        setHolisticOnboardingDependents(dependents > 0 ? 0 : 1);
      },
      selected: dependents > 0
    },
    {
      name: 'Salaried (W-2) income',
      icon: Personalcard,
      onClick: () => {
        const isSelected = incomeTypes?.includes('salaried');
        const updatedValues = isSelected
          ? incomeTypes?.filter((type) => type !== 'salaried')
          : [...incomeTypes, 'salaried'];
        setHolisticOnboardingIncomeType(updatedValues);
      },
      selected: incomeTypes?.includes('salaried')
    },
    {
      name: 'Freelance (1099) income',
      icon: Graph,
      onClick: () => {
        const isSelected = incomeTypes?.includes('contractor');
        const updatedValues = isSelected
          ? incomeTypes?.filter((type) => type !== 'contractor')
          : [...incomeTypes, 'contractor'];
        setHolisticOnboardingIncomeType(updatedValues);
      },
      selected: incomeTypes?.includes('contractor')
    },
    {
      name: 'I own a business',
      icon: Briefcase,
      onClick: () => {
        const isSelected = incomeTypes?.includes('owner');
        const updatedValues = isSelected ? incomeTypes?.filter((type) => type !== 'owner') : [...incomeTypes, 'owner'];
        setHolisticOnboardingIncomeType(updatedValues);
      },
      selected: incomeTypes?.includes('owner')
    },
    {
      name: 'Student loans',
      icon: () => <EducationIcon stroke={colorPrimary} style={{ width: 24, height: 24 }} />,
      onClick: () => {
        setHolisticOnboardingStudentLoans(!studentLoans);
      },
      selected: studentLoans
    },
    {
      name: 'I own a home',
      icon: Home,
      onClick: () => {
        setHolisticOnboardingHomeOwner(!homeOwner);
      },
      selected: homeOwner
    },
    {
      name: 'I pay rent',
      icon: Profile2User,
      onClick: () => {
        setHolisticOnboardingRent(!rent);
      },
      selected: rent
    }
  ];

  useEffect(() => {
    setTimeout(() => {
      setHolisticOnboardingInitialLoad(false);
    }, 500);
  }, [setHolisticOnboardingInitialLoad]);

  return (
    <>
      <div className='h-onboarding-options-container'>
        {options.map(({ name, icon, onClick, selected }, idx) =>
          loading ? (
            <Skeleton key={idx} width='46%' height={100} variant='rounded' animation='pulse' />
          ) : (
            <VerticalIconButton
              key={idx}
              Icon={icon}
              text={name}
              color='primary'
              selected={selected}
              onClick={() => onClick(name)}
            />
          )
        )}
      </div>
      <Fade in={dependents > 0}>
        <Stack direction='column'>
          <div className='h-onboarding-content-question-title'>How many dependents do you have?</div>
          <Stack spacing={1} direction='row'>
            {[1, 2, 3, 4, 5].map((value) => (
              <ToggleButton
                key={value}
                selected={dependents === value}
                onClick={() => setHolisticOnboardingDependents(value)}
                center
              >
                {value}
              </ToggleButton>
            ))}
            <ToggleButton center selected={dependents > 5} onClick={() => setHolisticOnboardingDependents(6)}>
              6+
            </ToggleButton>
          </Stack>
        </Stack>
      </Fade>
    </>
  );
};

const mapStateToProps = (state) => ({
  married: marriedSelector(state),
  dependentsSelector: dependentsSelector(state),
  incomeTypes: incomeTypeSelector(state),
  studentLoans: studentLoansSelector(state),
  homeOwner: homeOwnerSelector(state),
  rent: rentSelector(state),
  loading: initialLoadSelector(state),
  dependents: dependentsSelector(state)
});

const mapDispatchToProps = {
  setHolisticOnboardingDependents,
  setHolisticOnboardingMarried,
  setHolisticOnboardingIncomeType,
  setHolisticOnboardingHomeOwner,
  setHolisticOnboardingRent,
  setHolisticOnboardingStudentLoans,
  setHolisticOnboardingInitialLoad
};

const ConnectedOnboardingSituations = connect(mapStateToProps, mapDispatchToProps)(OnboardingSituations);

export default ConnectedOnboardingSituations;
