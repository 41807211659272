import _ from 'lodash';
import axios from 'axios';
import { serverUrl } from '@app/src/global/Environment';
import shortid from 'shortid';
import { overrideGetData, overrideGetQueries } from '@app/src/taxflow/common/services/overrides';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { redirectIfUnauthorized } from '@app/src/services/authService';

const baseUrl = serverUrl();

export const getAnswersArray = () => {
  const itemStr = localStorage.getItem('taxFlowAnswerItems');
  const items = itemStr ? JSON.parse(itemStr) : [];
  return items;
};

export const updateAnswersArray = (items) => {
  localStorage.setItem('taxFlowAnswerItems', JSON.stringify(items));
};

export const getAnswersFrontend = async ({ queries }) => {
  try {
    return {
      data: {
        status: 'ok',
        data: getAnswersArray().filter((item) => {
          return queries.some((q) => {
            let condition = true;
            if (q.coll_type) {
              condition = condition && q.coll_type === item.coll_type;
            }
            if (q.coll_id) {
              condition = condition && q.coll_id === item.coll_id;
            }
            if (q.slug) {
              condition = condition && q.slug === item.slug;
            }
            return condition;
          });
        })
      }
    };
  } catch (e) {
    defaultCaptureException(e);
    return {
      data: {
        status: 'ok',
        data: []
      }
    };
  }
};

export const getAnswersCollections = (items) => {
  const collections = {};
  items.forEach((item) => {
    _.set(collections, [item.coll_id || 'default', item.slug], item);
  });
  return collections;
};

export const updateAnswersFrontend = async ({ taxData }) => {
  try {
    const collections = getAnswersCollections(getAnswersArray());

    const generateId = (item) => {
      if (!item.coll_type) {
        return 'default';
      }
      if (!item.coll_id) {
        return shortid.generate();
      }
      return 'default';
    };

    taxData.forEach((item) => {
      _.unset(collections, [generateId(item), item.slug]);
    });

    const collectionArray = _.flatMap(Object.keys(collections), (collectionId) => {
      return Object.keys(collections[collectionId]).map((slug) => {
        return collections[collectionId][slug];
      });
    });

    updateAnswersArray([...collectionArray, ...taxData]);
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const getAnswersBackend =
  ({ queries }) =>
  async (dispatch) => {
    const res = await axios.post(`${baseUrl}api/taxes/user-tax-data/batch-get`, {
      queries: overrideGetQueries({ queries })
    });
    if (res.data.status !== 'ok') {
      dispatch(redirectIfUnauthorized(res));
      return {};
    }

    return {
      ...res,
      data: {
        ...res.data,
        data: await dispatch(overrideGetData({ queries, taxData: res.data.data }))
      }
    };
  };
