import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { setSettingsModalType } from '@app/src/actions/settingsActions';
import { deleteSubscription } from '@app/src/services/pricingService';
import { cancelMembership, sendChurnFeedback } from '@app/src/services/settingsService';
import { getAccountDetails } from '@app/src/services/workService';

const ChurnExplanation = ({ setSettingsModalType, cancelMembership, sendChurnFeedback }) => {
  const [feedbackComment, setFeedbackComment] = useState('');

  const onFeedbackCommentChange = (event) => {
    setFeedbackComment(event.target.value);
  };

  const onSendFeedbackAndCancel = async () => {
    setSettingsModalType('');

    await cancelMembership({ comment: feedbackComment });
    await sendChurnFeedback(feedbackComment);
  };

  return (
    <div className='churn-explanation'>
      <DialogTitle>Please tell us how we can do better!</DialogTitle>
      <textarea
        className='form-control'
        rows='10'
        maxLength='1000'
        style={{ height: '100px' }}
        value={feedbackComment}
        onChange={onFeedbackCommentChange}
      />
      <Button
        type='button'
        variant='contained'
        className='btn btn-primary btn-block'
        disabled={false}
        onClick={onSendFeedbackAndCancel}
      >
        Cancel subscription
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  settings: state.settings
});

const mapDispatchToProps = {
  setSettingsModalType,
  getAccountDetails,
  deleteSubscription,
  cancelMembership,
  sendChurnFeedback
};

const ConnectedChurnExplanation = connect(mapStateToProps, mapDispatchToProps)(ChurnExplanation);

export default ConnectedChurnExplanation;
