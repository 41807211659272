import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import { setSubscriptionType } from '@app/src/actions/pricingActions';
import SubscriptionTypeElement from '@app/src/Components/Subscription/Common/SubscriptionTypeElement';
import { SUBSCRIPTION_FEATURES } from '@app/src/constants/subscriptionConstants';
import {
  currentSubscriptionTypeSelector,
  originSelector,
  subscriptionTypeSelector,
  trialLengthSelector,
  updatingSelector
} from '@app/src/selectors/pricingSelectors';
import { settingsLoadingSelector } from '@app/src/selectors/settingsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { updateSubscription } from '@app/src/services/settingsService';
import { colorSecondary } from '@app/src/theme';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

import '@app/src/Components/Subscription/SubscriptionTypeModal/SubscriptionTypeModal.scss';

const SubscriptionTypeModal = ({
  origin,
  loading,
  accountDetails,
  subscriptionType,
  currentSubscriptionType,
  setSubscriptionType,
  updateSubscription
}) => {
  useEffect(() => {
    trackActivity('Subscription type modal', { origin });

    setSubscriptionType(currentSubscriptionType);
  }, [origin, setSubscriptionType, currentSubscriptionType]);

  const handleContinue = async () => {
    await updateSubscription();
  };

  const isTrialing = _.get(accountDetails, 'status') === 'free trial';

  return (
    <>
      <DialogTitle>Pick a plan</DialogTitle>
      <DialogContent className='payment-modal-body'>
        <Stack spacing={2} direction='column' style={{ marginTop: 32 }}>
          {Object.keys(SUBSCRIPTION_FEATURES).map((val) => (
            <SubscriptionTypeElement
              key={val}
              type={val}
              onClick={setSubscriptionType}
              focused={subscriptionType === val}
              selected={subscriptionType === val}
              disabled={currentSubscriptionType === val && !isTrialing}
              style={{ width: '343px' }}
            >
              {val === 'premium' && (
                <div
                  style={{
                    position: 'absolute',
                    top: -20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: colorSecondary,
                    padding: '4px 10px',
                    borderRadius: 20
                  }}
                >
                  <div className='bold' style={{ color: 'white' }}>
                    BEST VALUE
                  </div>
                </div>
              )}
            </SubscriptionTypeElement>
          ))}
        </Stack>
      </DialogContent>
      <Stack spacing={1} direction='column'>
        <Divider />
        <LoadingButton
          variant='contained'
          color='secondary'
          loading={loading}
          fullWidth
          onClick={handleContinue}
          disabled={subscriptionType === currentSubscriptionType && !isTrialing}
          loadingIndicator='Updating subscription…'
        >
          Continue
        </LoadingButton>
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({
  updating: updatingSelector(state),
  origin: originSelector(state),
  trialLength: trialLengthSelector(state),
  subscriptionType: subscriptionTypeSelector(state),
  currentSubscriptionType: currentSubscriptionTypeSelector(state),
  loading: settingsLoadingSelector(state),
  accountDetails: accountDetailsSelector(state)
});

const mapDispatchToProps = {
  setSubscriptionType,
  setExpenseReviewModalType,
  updateSubscription
};

const ConnectedSubscriptionTypeModal = connect(mapStateToProps, mapDispatchToProps)(SubscriptionTypeModal);

export default ConnectedSubscriptionTypeModal;
