import Select from '@mui/material/Select';
import { ArrowDown2 } from 'iconsax-react';
import React from 'react';

const KeeperSelect = ({ children, ...props }) => (
  <Select
    color='secondary'
    displayEmpty
    fullWidth
    IconComponent={ArrowDown2}
    notched={false}
    sx={{ '.MuiSelect-icon': { right: '16px', top: 'unset' } }}
    {...props}
  >
    {children}
  </Select>
);

export default KeeperSelect;
