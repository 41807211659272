// @ts-check

import { capitalize } from '@mui/material/utils';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import '@app/src/Components/TaxFlow/Question/TaxFlowSettlementStatusContainer.scss';

/**
 * @typedef TaxFlowSettlementStatusContainerProps
 * @property {string} entity
 * @property {number} amount
 * @property {moment.Moment} [estimatedDate]
 * @property {React.ReactNode} children
 * @property {string} [description]
 * @property {boolean} [completed]
 * @property {string} status
 */

/**
 * @type {React.FC<TaxFlowSettlementStatusContainerProps>}
 */
const TaxFlowSettlementStatusContainer = ({
  entity,
  amount,
  estimatedDate,
  children,
  description,
  completed,
  status
}) => {
  if (_.isNil(completed)) {
    completed = estimatedDate && estimatedDate.isSameOrBefore(moment().startOf('day'));
  }

  const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      signDisplay: 'never',
      maximumFractionDigits: 0
    });

    return formatter.format(value);
  };

  const isRefund = amount < 0;

  return (
    <section className='settlement-status'>
      <header>
        <div className='primary'>
          <div className='amount'>
            {capitalize(entity)} {isRefund ? 'refund' : 'tax bill'}: {formatCurrency(amount)}
          </div>
          <div className={classNames('status', { pending: !completed })}>{status}</div>
        </div>
        {description ? (
          <div>{description}</div>
        ) : (
          estimatedDate && (
            <div>
              {!completed && 'Est. '}
              {estimatedDate.format('MMM D, YYYY')}
            </div>
          )
        )}
      </header>
      <div>{children}</div>
    </section>
  );
};

export default TaxFlowSettlementStatusContainer;
