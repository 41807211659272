import {
  SET_TAXFILING_COLLECTION_INFO_MODAL_SHOW,
  SET_TAXFILING_SELECTED_COLLECTION_TYPE_ITEM,
  SET_TAXFILING_IS_CONFIRMATION_MODAL_OPEN,
  SET_TAXFILING_IS_PAYMENT_MODAL_OPEN,
  SET_TAXFILING_SUBSCRIPTION_PROMPT_SHOW,
  SET_TAXFILING_DELETE_MODAL_SLUG,
  SET_TAXFILING_DELETE_MODAL_COLLECTION_ID,
  SET_TAXFILING_DELETE_MODAL_COLLECTION_TYPE,
  SET_TAXFILING_DELETE_MODAL_SHOW,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_SHOW,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_COLLECTION_TYPE,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_COLLECTION_ID,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_ATTEMPT_ID,
  SET_TAXFILING_INFO_MODAL_SHOW,
  SET_TAXFILING_INFO_MODAL_ITEM,
  SET_TAXFILING_COLLECTION_MODAL_TITLE,
  SET_TAXFILING_COLLECTION_MODAL_DESCRIPTION,
  SET_PREMIUM_UPSELL_MODAL_SHOW,
  SET_DESKTOP_SELL_MODAL_SHOW,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_HEADER
} from '@app/src/actions/types';

const initialState = {
  infoModalShow: false,
  infoModalItem: {},
  collectionModalTitle: null,
  collectionModalDescription: null,
  collectionInfoModalShow: false,
  selectedCollectionTypeItem: '',
  isConfirmationModalOpen: false,
  isPaymentModalOpen: false,
  subscriptionPromptShow: false,
  collectionDeleteModalShow: false,
  collectionDeleteModalCollectionType: null,
  collectionDeleteModalCollectionId: null,
  collectionDeleteModalSlug: null,
  collectionFormUploadErrorModalShow: false,
  collectionFormUploadErrorModalHeader: null,
  collectionFormUploadErrorModalCollectionType: null,
  collectionFormUploadErrorModalCollectionId: null,
  collectionFormUploadErrorModalAttemptId: null,
  premiumUpsellModalShow: false,
  desktopSellModalShow: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TAXFILING_INFO_MODAL_SHOW:
      return {
        ...state,
        infoModalShow: action.payload
      };

    case SET_TAXFILING_INFO_MODAL_ITEM:
      return {
        ...state,
        infoModalItem: action.payload
      };

    case SET_TAXFILING_COLLECTION_MODAL_TITLE:
      return {
        ...state,
        infoModalTitle: action.payload
      };

    case SET_TAXFILING_COLLECTION_MODAL_DESCRIPTION:
      return {
        ...state,
        infoModalDescription: action.payload
      };

    case SET_TAXFILING_COLLECTION_INFO_MODAL_SHOW:
      return {
        ...state,
        collectionInfoModalShow: action.payload
      };

    case SET_TAXFILING_SELECTED_COLLECTION_TYPE_ITEM:
      return {
        ...state,
        selectedCollectionTypeItem: action.payload
      };

    case SET_TAXFILING_IS_CONFIRMATION_MODAL_OPEN: {
      return {
        ...state,
        isConfirmationModalOpen: action.payload
      };
    }

    case SET_TAXFILING_IS_PAYMENT_MODAL_OPEN: {
      return {
        ...state,
        isPaymentModalOpen: action.payload
      };
    }

    case SET_TAXFILING_SUBSCRIPTION_PROMPT_SHOW: {
      return {
        ...state,
        subscriptionPromptShow: action.payload
      };
    }

    case SET_TAXFILING_DELETE_MODAL_SHOW: {
      return {
        ...state,
        collectionDeleteModalShow: action.payload
      };
    }

    case SET_TAXFILING_DELETE_MODAL_COLLECTION_TYPE: {
      return {
        ...state,
        collectionDeleteModalCollectionType: action.payload
      };
    }

    case SET_TAXFILING_DELETE_MODAL_COLLECTION_ID: {
      return {
        ...state,
        collectionDeleteModalCollectionId: action.payload
      };
    }

    case SET_TAXFILING_DELETE_MODAL_SLUG: {
      return {
        ...state,
        collectionDeleteModalSlug: action.payload
      };
    }

    case SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_SHOW: {
      return {
        ...state,
        collectionFormUploadErrorModalShow: action.payload
      };
    }

    case SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_HEADER: {
      return {
        ...state,
        collectionFormUploadErrorModalHeader: action.payload
      };
    }

    case SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_COLLECTION_TYPE: {
      return {
        ...state,
        collectionFormUploadErrorModalCollectionType: action.payload
      };
    }

    case SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_ATTEMPT_ID: {
      return {
        ...state,
        collectionFormUploadErrorModalAttemptId: action.payload
      };
    }

    case SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_COLLECTION_ID: {
      return {
        ...state,
        collectionFormUploadErrorModalCollectionId: action.payload
      };
    }

    case SET_PREMIUM_UPSELL_MODAL_SHOW: {
      return {
        ...state,
        premiumUpsellModalShow: action.payload
      };
    }

    case SET_DESKTOP_SELL_MODAL_SHOW: {
      return {
        ...state,
        desktopSellModalShow: action.payload
      };
    }
    default:
      return state;
  }
}
