import Button from '@mui/material/Button';
import classNames from 'classnames';
import React from 'react';
import '@app/src/Components/Common/BasePill/BasePill.scss';

const BasePill = ({ children, onClick, icon, className, loading, disabled }) => {
  return (
    <>
      {onClick ? (
        <Button
          color='secondary'
          className={classNames('base-pill', className)}
          disabled={loading || disabled}
          onClick={onClick}
          size='small'
          startIcon={icon && icon}
          variant='contained'
        >
          {children}
        </Button>
      ) : (
        <div className={classNames('base-pill', className)}>
          {icon && <div className='base-pill-icon'>{icon}</div>}
          {children}
        </div>
      )}
    </>
  );
};

export default BasePill;
