import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  currentQuestionSelector,
  cachedNavigationItemsSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { substitutionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getStringWithSubstitutions } from '@app/src/taxflow/main/utils/mainUtils';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';

export const currentNavigationSectionSelector = (state) =>
  _.get(state, ['taxFlowNavigation', 'currentNavigationSection']);
const currentNavigationSectionIndexSelector = (state) =>
  _.get(state, ['taxFlowNavigation', 'currentNavigationSection', 'navigationIndex']);
export const navSectionsStartedSelector = (state) =>
  _.get(state, ['taxFlowNavigation', 'currentNavigationSection', 'sectionsStarted']);
export const taxProgressStageSelector = (state) =>
  _.get(state, ['taxFlowNavigation', 'currentNavigationSection', 'stage']);

export const isFilingQualifiedSelector = (state) => _.get(state, ['taxFlowNavigation', 'isFilingQualified']);
export const isNavigationPanelOpenSelector = (state) => _.get(state, ['taxFlowNavigation', 'isNavigationPanelOpen']);
export const supportAccessOriginSelector = (state) => _.get(state, ['taxFlowNavigation', 'supportAccessOrigin']);
export const selfUnlockModalOpenSelector = (state) => _.get(state, ['taxFlowNavigation', 'selfUnlockModalOpen']);

const navigationItemsSelector = createSelector(
  [cachedNavigationItemsSelector, substitutionsSelector],
  (items, substitutions) =>
    items.map((item) => {
      item = {
        ...item,
        title: item.title ? getStringWithSubstitutions({ str: item.title, substitutions }) : null,
        description: item.description ? getStringWithSubstitutions({ str: item.description, substitutions }) : null,
        url: item.url ? getStringWithSubstitutions({ str: item.url, substitutions }) : null
      };
      return item;
    })
);

export const aboutYouEnabledSelector = createSelector([cachedNavigationItemsSelector], (navigationItems) => {
  const dashboardNavItem = navigationItems.find((item) => item.id === 'dashboard');
  const aboutYouEnabled = _.get(dashboardNavItem, ['meta', 'aboutYouEnabled']);
  return !!aboutYouEnabled;
});

export const currentNavigationItemSelector = createSelector(
  [navigationItemsSelector, currentQuestionSelector],
  (navigationItems, currentQuestion) => {
    const currentSection = _.get(currentQuestion, ['section', 'fields', 'slug']);
    return navigationItems.find((item) => item.id === currentSection);
  }
);

export const nextNavigationItemSelector = createSelector(
  [currentNavigationSectionIndexSelector, navigationItemsSelector],
  (navigationSectionIndex, navigationItems) => {
    return navigationItems[navigationSectionIndex];
  }
);

export const isNavigationPanelEnabledSelector = createSelector([currentQuestionSelector], (currentQuestion) => {
  return _.get(currentQuestion, 'flow') === 'tax file';
});

export const percentCompleteSelector = createSelector(
  [currentNavigationSectionSelector],
  (currentNavigationSection) => {
    return _.round(_.get(currentNavigationSection, ['taxProgressPercentage'], 0) * 100);
  }
);

export const navigationStartedQueriesSelector = createSelector([navigationItemsSelector], (items) =>
  _.flatMap(items, (item) =>
    _.get(item, ['meta', 'startedQueries'], []).map((query) => _.pick(query, ['slug', 'coll_type']))
  )
);

export const navigationQueriesSelector = createSelector([navigationItemsSelector], (items) =>
  _.flatMap(items, (item) =>
    _.get(item, ['meta', 'collectionTypeStartedQueries'], []).map((query) => ({
      coll_type: query.coll_type,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: query.slug
    }))
  )
);

export const navigationDoneQueriesSelector = createSelector([navigationItemsSelector], (items) =>
  _.flatMap(items, (item) =>
    _.get(item, ['meta', 'doneQueries'], []).map((query) => ({
      coll_type: query.coll_type,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: query.slug
    }))
  )
);
