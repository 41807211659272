import React, { useEffect, useState } from 'react';
import ReactOdometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import '@app/src/Components/Common/AnimatedNumber/AnimatedNumber.scss';

const AnimatedNumber = ({ value }) => {
  const [odometerValue, setOdometerValue] = useState(0);

  useEffect(() => {
    setOdometerValue(value);
  }, [value]);

  return <ReactOdometer value={odometerValue} format='(,ddd)' duration={500} />;
};

export default AnimatedNumber;
