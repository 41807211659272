import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import TaxFlowQuestionStripe from '@app/src/taxflow/main/components/TaxFlowQuestionStripe';
import '@app/src/taxflow/main/components/TaxFlowQuestionTypeItem.scss';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';

const TaxFlowQuestionTypeItem = ({ history, location }) => {
  return (
    <StripeProvider>
      <TaxFlowQuestionStripe history={history} location={location} />
    </StripeProvider>
  );
};

const mapStateToProps = (state) => ({
  questionSlug: _.get(state, ['taxFlow', 'currentQuestion', 'slug'])
});

const ConnectedTaxFlowQuestionTypeItem = connect(mapStateToProps)(TaxFlowQuestionTypeItem);

export default ConnectedTaxFlowQuestionTypeItem;
