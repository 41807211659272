import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from '@mui/material/Icon';
import TimerIcon from '@app/src/assets/timer.svg?react';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitProgressTimer.scss';
import { instantReviewCountdownSelector } from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import TaxFlowNavigationProgressBar from '@app/src/taxflow/navigation/components/TaxFlowNavigationProgressBar';
import { humanReviewEnabledSelector } from '@app/src/selectors/workSelectors';

const TaxFlowSubmitProgressTimer = ({ currentQuestion, timerTarget, humanReviewEnabled }) => {
  // calculate time left
  const timeLeft = timerTarget.valueOf() - moment().valueOf();
  const [timer, setTimer] = useState(timeLeft > 0 ? timeLeft : 0);
  const timerDone = timer === 0;

  // convert time left from ms to hours / minutes / seconds
  const hours = Math.floor(timer / (1000 * 60 * 60));
  const minutes = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timer % (1000 * 60)) / 1000);

  const percentageElapsed = ((90 * 60 * 1000 - timeLeft) / (90 * 60 * 1000)) * 100;

  // refresh timer
  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = timerTarget.valueOf() - moment().valueOf();
      setTimer(timeLeft > 0 ? timeLeft : 0);
    }, 1000);

    if (timerDone) {
      trackActivity('review timer: expired', { screen: currentQuestion.slug });
    }

    return () => clearInterval(interval);
  }, [currentQuestion, timerTarget, timerDone]);

  return (
    <div>
      <div className='taxflow-submit-progress-timer'>
        <Icon className='taxflow-submit-progress-timer-icon'>
          <TimerIcon width={12} height={12} />
        </Icon>
        <span className='taxflow-submit-progress-timer-label'>
          {timerDone ? 'Any moment now...' : 'Estimated finish: '}
        </span>

        {!timerDone && (
          <span className='taxflow-submit-progress-timer-countdown'>
            {hours > 0
              ? hours +
                ' hr ' +
                minutes.toString().padStart(2, '0') +
                ' min ' +
                seconds.toString().padStart(2, '0') +
                ' sec'
              : minutes + ' min ' + seconds.toString().padStart(2, '0') + ' sec'}
          </span>
        )}
      </div>
      {humanReviewEnabled && (
        <div className='taxflow-submit-progress-panel-title'>
          <TaxFlowNavigationProgressBar percentComplete={percentageElapsed} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  timerTarget: instantReviewCountdownSelector(state),
  humanReviewEnabled: humanReviewEnabledSelector(state)
});

const ConnectedTaxFlowSubmitProgressTimer = connect(mapStateToProps, {})(TaxFlowSubmitProgressTimer);

export default ConnectedTaxFlowSubmitProgressTimer;
