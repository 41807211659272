import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import '@app/src/Components/TaxFlow/Common/TaxFlowDesktopSellModal.scss';
import { setDesktopSellModalShow } from '@app/src/actions/taxFlowActions';
import { desktopSellModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import DragAndDropGif from '@app/src/assets/desktop-sell-drag-and-drop.gif';
import ExpertReviewGif from '@app/src/assets/desktop-sell-expert-review.gif';
import TaxQuestionGif from '@app/src/assets/desktop-sell-tax-question.gif';
import CloseIcon from '@app/src/assets/close.svg?react';
import { updateDesktopSellCampaign } from '@app/src/services/taxFlowService';
import { trackActivity } from '@app/src/services/analyticsService';

const slides = [
  {
    subtitle: "Upload your forms, we'll do the rest",
    gif: DragAndDropGif
  },
  {
    subtitle: 'Reviewed and signed by a tax professional',
    gif: ExpertReviewGif
  },
  {
    subtitle: 'Tax experts on standby',
    gif: TaxQuestionGif
  }
];

const TaxFlowDesktopSellModal = ({ desktopSellModalShow, setDesktopSellModalShow, updateDesktopSellCampaign }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (desktopSellModalShow) {
      trackActivity('tax filing: desktop sell modal opened');
    }
    const timer = setTimeout(() => {
      if (currentSlide === slides.length - 1) {
        return;
      }
      setCurrentSlide(currentSlide + 1);
    }, 7000);

    return () => clearTimeout(timer);
  }, [currentSlide, desktopSellModalShow]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const onClose = () => {
    setDesktopSellModalShow(false);
    trackActivity('tax filing: desktop sell modal closed');
    updateDesktopSellCampaign();
  };

  return (
    <Dialog
      sx={{ '.MuiPaper-root': { maxWidth: '100%', width: '535px', borderRadius: '8px' } }}
      open={!!desktopSellModalShow}
      onClose={onClose}
    >
      <div className='taxflow-desktop-sell-modal'>
        <div className='taxflow-desktop-sell-modal-header'>
          <CloseIcon
            onClick={onClose}
            className='taxflow-desktop-sell-modal-close-icon'
            style={{ cursor: 'pointer' }}
          />
          <div className='taxflow-desktop-sell-modal-title'>Tax filing just got way easier</div>
        </div>
        <div className='taxflow-desktop-sell-modal-subtitle'>{slides[currentSlide].subtitle}</div>
        <img src={slides[currentSlide].gif} className='taxflow-desktop-sell-modal-image' />
        <div className='taxflow-desktop-sell-modal-carousel'>
          {slides.map((_, index) => (
            <div
              key={index}
              className={`taxflow-desktop-sell-modal-carousel-dots ${currentSlide === index ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
        <Button
          className='taxflow-desktop-sell-modal-button'
          size='large'
          variant='contained'
          color='secondary'
          onClick={onClose}
        >
          Get Started
        </Button>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  desktopSellModalShow: desktopSellModalShowSelector(state)
});

const mapDispatchToProps = {
  setDesktopSellModalShow,
  updateDesktopSellCampaign
};

const ConnectedTaxFlowDesktopSellModal = connect(mapStateToProps, mapDispatchToProps)(TaxFlowDesktopSellModal);

export default ConnectedTaxFlowDesktopSellModal;
