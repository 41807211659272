import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import TaxFlowPdfButton from '@app/src/Components/TaxFlow/Question/TaxFlowPdfButton';
import { detailedAmountsSelector } from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import TaxFlowDetailedBillElement from '@app/src/Components/TaxFlow/Question/TaxFlowDetailedBillElement';
import { getReturnStatus } from '@app/src/services/taxFlowService';
import '@app/src/Components/TaxFlow/Question/TaxFlowAcceptedByIrsElement.scss';
import TaxFlowSettlementStatus from '@app/src/Components/TaxFlow/Question/TaxFlowSettlementStatus';
import capitalize from 'capitalize';
import { trackActivity } from '@app/src/services/analyticsService';
import { TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';

export const TaxFlowAcceptedByIrsElement = ({ refundSummary, billSummary, amounts }) => {
  const [returnStatus, setReturnStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const federalAmt = _.get(amounts, ['drake', 'federal_amount']);
  const states = _.get(amounts, ['drake', 'multistate'], []);

  const hasBill = federalAmt > 0 || states.some((state) => state.amount > 0);
  const hasRefund = federalAmt < 0 || states.some((state) => state.amount < 0);

  useEffect(() => {
    (async () => {
      const data = await getReturnStatus();

      setReturnStatus(data);
      setLoading(false);
    })();
  }, []);

  const filedWithIRSdate = useMemo(() => {
    const date = _.get(returnStatus, ['lastStageChanges', 'Filed with IRS', 'date']);

    if (date && returnStatus) {
      return moment(date);
    }
  }, [returnStatus]);

  const acceptedByIRSDate = useMemo(() => {
    const date = _.get(returnStatus, ['lastStageChanges', 'Accepted by IRS', 'date']);

    if (date && returnStatus) {
      return moment(date);
    }
  }, [returnStatus]);

  const mask = _.get(returnStatus, 'accountMask');

  const settlementTransactions = _.get(returnStatus, 'settlementTransactions');

  const paymentMethod = _.get(returnStatus, 'paymentMethod');

  const withdrawalDate = moment(_.get(returnStatus, 'withdrawalDate', `04-15-${TAX_FILING_YEAR}`), 'MM-DD-YYYY');

  const statuses = _.get(returnStatus, 'statuses');

  const statusesMap = statuses ? _.chain(statuses).keyBy('entity').value() : {};

  useEffect(() => {
    if (statuses) {
      statuses.forEach((status) => {
        const properties = {
          filedWithIRSdate: filedWithIRSdate && filedWithIRSdate.toISOString(),
          acceptedByIRSDate: acceptedByIRSDate && acceptedByIRSDate.toISOString(),
          ..._.omit(status, 'transaction'),
          transactionCleanName: _.get(status, ['transaction', 'clean_name']),
          transactionAmount: _.get(status, ['transaction', 'amount']),
          transactionDate: _.get(status, ['transaction', 'date']),
          hasAccountNumber: !_.isNil(mask),
          paymentMethod,
          status: status.status
        };

        trackActivity('return status: view', {
          ...properties,
          transactionDate: properties.transactionDate && moment(properties.transactionDate).toISOString(),
          completionDate: properties.completionDate && moment(properties.completionDate).toISOString()
        });
      });
    }
  }, [acceptedByIRSDate, filedWithIRSdate, mask, paymentMethod, statuses]);

  return (
    <>
      {!loading && (
        <div className='taxflow-accepted-by-irs-element'>
          {!returnStatus || !filedWithIRSdate || !acceptedByIRSDate ? (
            <>
              {hasRefund && refundSummary && (
                <ReactMarkdown className='taxflow-accepted-by-irs-element-summary' source={refundSummary} />
              )}
              {hasBill && billSummary && (
                <ReactMarkdown className='taxflow-accepted-by-irs-element-summary' source={billSummary} />
              )}
            </>
          ) : (
            (hasRefund || hasBill) && (
              <div className='refund-statuses'>
                <h2>{capitalize(_.compact([hasRefund && 'refund', hasBill && 'tax bill']).join(' and '))} status</h2>
                {amounts.drake.federal_amount != null && (
                  <TaxFlowSettlementStatus
                    amount={amounts.drake.federal_amount}
                    submittedDate={filedWithIRSdate}
                    acceptedDate={acceptedByIRSDate}
                    mask={_.get(returnStatus, 'accountMask')}
                    withdrawalDate={withdrawalDate}
                    transactionFoundDate={(() => {
                      const date = _.get(settlementTransactions, ['federal', 'date']);

                      if (_.isNil(date)) return null;

                      return moment(date);
                    })()}
                    entity='federal'
                    paymentMethod={paymentMethod}
                    status={statusesMap.federal.status}
                  />
                )}
                {amounts.drake.multistate &&
                  amounts.drake.multistate.map(({ state, amount }) => (
                    <TaxFlowSettlementStatus
                      key={state}
                      amount={amount}
                      submittedDate={filedWithIRSdate}
                      acceptedDate={acceptedByIRSDate}
                      withdrawalDate={withdrawalDate}
                      transactionFoundDate={(() => {
                        const date = _.get(settlementTransactions, [state, 'date']);

                        if (_.isNil(date)) return null;

                        return moment(date);
                      })()}
                      mask={mask}
                      entity={state}
                      paymentMethod={paymentMethod}
                      status={statusesMap[state].status}
                    />
                  ))}
              </div>
            )
          )}
          <TaxFlowDetailedBillElement className='taxflow-accepted-by-irs-element-bill' amounts={amounts} />
          <TaxFlowPdfButton className='taxflow-accepted-by-irs-element-pdf-button' />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    amounts: detailedAmountsSelector(state)
  };
};

const ConnectedTaxFlowAcceptedByIrsElement = connect(mapStateToProps)(TaxFlowAcceptedByIrsElement);

export default ConnectedTaxFlowAcceptedByIrsElement;
