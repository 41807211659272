import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import Stack from '@mui/material/Stack';
import '@app/src/Components/Subscription/PaymentModal/PaymentCard.scss';
import { colorDeactivated, colorError, colorFontDark, fontUnselectedBtn } from '@app/src/theme';
import { setIsCardValid } from '@app/src/actions/pricingActions';

const PaymentCard = ({ className, setIsCardValid }) => {
  const elements = useElements();
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
  const [isCardCVCValid, setIsCardCVCValid] = useState(false);

  useEffect(() => {
    setIsCardValid({ isCardValid: isCardNumberValid && isCardExpiryValid && isCardCVCValid });
  }, [setIsCardValid, isCardNumberValid, isCardExpiryValid, isCardCVCValid]);

  const onCardNumberChange = (e) => {
    const valid = e.complete && !e.error;
    setIsCardNumberValid(valid);
    if (valid) {
      const element = elements.getElement('cardExpiry');
      element.focus();
    }
  };

  const onCardExpiryChange = (e) => {
    const valid = e.complete && !e.error;
    setIsCardExpiryValid(valid);
    if (valid) {
      const element = elements.getElement('cardCvc');
      element.focus();
    }
  };

  const onCardCVCChange = (e) => {
    setIsCardCVCValid(e.complete && !e.error);
  };

  const options = {
    cssSrc: 'https://storage.googleapis.com/titanium-diode-208122.appspot.com/fonts.css',
    style: {
      base: {
        fontFamily: 'F37Blanka, sans-serif',
        ...fontUnselectedBtn,
        color: colorFontDark,
        '::placeholder': {
          color: colorDeactivated
        }
      },
      invalid: {
        color: colorError
      }
    }
  };

  return (
    <Stack className={classNames('payment-card', className)} spacing={1}>
      <div className='payment-card-field'>
        <label className='payment-card-label' htmlFor='cardNumber'>
          Credit or debit card
        </label>
        <CardNumberElement
          className='payment-card-element'
          id='cardNumber'
          options={options}
          onChange={onCardNumberChange}
        />
      </div>
      <div className='payment-card-field-group'>
        <div className='payment-card-field payment-card-field-item'>
          <label className='payment-card-label' htmlFor='cardExpiration'>
            Expiration
          </label>
          <CardExpiryElement
            className='payment-card-element'
            id='cardExpiration'
            options={options}
            onChange={onCardExpiryChange}
          />
        </div>
        <div className='payment-card-field payment-card-field-item'>
          <label className='payment-card-label' htmlFor='cardCVC'>
            CVC
          </label>
          <CardCvcElement className='payment-card-element' id='cardCVC' options={options} onChange={onCardCVCChange} />
        </div>
      </div>
    </Stack>
  );
};

const mapDispatchToProps = {
  setIsCardValid
};

const ConnectedPaymentCard = connect(null, mapDispatchToProps)(PaymentCard);

export default ConnectedPaymentCard;
