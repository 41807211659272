import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { setOrigin } from '@app/src/actions/pricingActions';
import { setSettingsModalType } from '@app/src/actions/settingsActions';
import SubscriptionTypeElement from '@app/src/Components/Subscription/Common/SubscriptionTypeElement';
import { userSettingsSelector } from '@app/src/selectors/authSelectors';
import { currentSubscriptionPriceSelector, currentSubscriptionTypeSelector } from '@app/src/selectors/pricingSelectors';
import { isCancelingSubscriptionSelector, nextChargeDateSelector } from '@app/src/selectors/settingsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { colorDeactivatedLight, colorError, colorSecondary } from '@app/src/theme';
import { getMembershipStatusDescription } from '@app/src/utils/subscriptionUtils';

import '@app/src/Components/Settings/SettingsSubscription.scss';

const SettingsSubscription = ({
  accountDetails,
  isCancelingSubscription,
  currentSubscriptionPrice,
  currentSubscriptionType,
  nextChargeDate,
  setSettingsModalType,
  setOrigin
}) => {
  const { push } = useHistory();

  const status = _.get(accountDetails, 'status', null);
  const willCancel = _.get(accountDetails, 'willCancel', false);
  const isPreFreeTrial = status === 'pre free trial';

  useEffect(() => {
    setOrigin('settings');

    return () => {
      setSettingsModalType('');
    };
  }, [setOrigin, setSettingsModalType]);

  useEffect(() => {
    trackActivity('navigation: subscription');
  }, []);

  const membershipStatusDescription = getMembershipStatusDescription({
    accountDetails,
    nextChargeDate,
    currentSubscriptionType,
    currentSubscriptionPrice
  });

  const handleEditSubscriptionClick = () => {
    setSettingsModalType('subscription');
  };

  const onEditPaymentMethodClick = () => {
    setSettingsModalType('payment');
  };

  const onCancelClick = () => {
    setSettingsModalType('churn-initial');
  };

  const onDeleteAccountClick = () => {
    setSettingsModalType('delete-account');
  };

  const onStartFreeTrialClick = () => {
    push('/expenses');
  };

  return (
    <>
      <div className='question-title'>Manage subscription</div>
      <Divider />
      <div className='question-body'>{membershipStatusDescription}</div>
      <Stack spacing={1} sx={{ margin: '16px auto' }}>
        {isPreFreeTrial && (
          <Button fullWidth variant='contained' color='secondary' onClick={onStartFreeTrialClick}>
            Start free trial
          </Button>
        )}
        {!!currentSubscriptionType && !isPreFreeTrial && (
          <>
            <SubscriptionTypeElement
              type={currentSubscriptionType}
              price={currentSubscriptionPrice}
              focused
              style={{ padding: '16px', backgroundColor: colorDeactivatedLight, cursor: 'auto' }}
              priceOverride={currentSubscriptionPrice}
              onClick={() => {}}
              disableRipple
            />
            {willCancel ? (
              <Button
                fullWidth
                variant='contained'
                color='secondary'
                style={{ borderColor: colorSecondary }}
                onClick={handleEditSubscriptionClick}
              >
                <span>Restart subscription</span>
              </Button>
            ) : (
              <>
                <Button
                  fullWidth
                  variant='outlined'
                  color='secondary'
                  style={{ borderColor: colorSecondary }}
                  onClick={handleEditSubscriptionClick}
                >
                  <span>Edit subscription</span>
                </Button>
                <Button
                  fullWidth
                  variant='outlined'
                  color='secondary'
                  style={{ borderColor: colorSecondary }}
                  onClick={onEditPaymentMethodClick}
                >
                  <span>Edit payment method</span>
                </Button>
                <Button fullWidth disabled={isCancelingSubscription} onClick={onCancelClick}>
                  <div>{status === 'free trial' ? 'Cancel free trial' : 'Cancel subscription'}</div>
                </Button>
              </>
            )}
          </>
        )}
        <Button fullWidth disabled={isCancelingSubscription} onClick={onDeleteAccountClick} sx={{ color: colorError }}>
          <div>Delete account and all data</div>
        </Button>
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({
  accountDetails: accountDetailsSelector(state),
  settings: userSettingsSelector(state),
  nextChargeDate: nextChargeDateSelector(state),
  isCancelingSubscription: isCancelingSubscriptionSelector(state),
  currentSubscriptionType: currentSubscriptionTypeSelector(state),
  currentSubscriptionPrice: currentSubscriptionPriceSelector(state)
});

const mapDispatchToProps = {
  setSettingsModalType,
  setOrigin
};

const ConnectedMembershipInfo = connect(mapStateToProps, mapDispatchToProps)(SettingsSubscription);

export default ConnectedMembershipInfo;
