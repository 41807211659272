import { useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import TaxFlowQuestionContent from '@app/src/taxflow/main/components/TaxFlowQuestionContent';

const TaxFlowQuestionStripe = ({ history, location }) => {
  const stripe = useStripe();
  const elements = useElements();

  return <TaxFlowQuestionContent history={history} location={location} stripe={stripe} elements={elements} />;
};

export default TaxFlowQuestionStripe;
