import React from 'react';

import { useSnackbarRef } from '@app/src/Components/Snackbar/Snackbar';
import { SnackbarActions } from '@app/src/Components/Snackbar/SnackbarActions';
import { trackActivity } from '@app/src/services/analyticsService';

export const notify = (message, options = {}, origin = '', type = '') => {
  if (!message) return;

  trackActivity('snackbar: view', {
    type,
    text: message,
    origin
  });

  useSnackbarRef.enqueueSnackbar(message, { ...options, action: <SnackbarActions message={message} /> });
};
