import React, { useEffect } from 'react';
import qs from 'qs';
import { validateLoginOtp } from '@app/src/services/workService';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import classNames from 'classnames';
import '@app/src/Components/ContractorLoading/ContractorLoading.scss';
import { REACT_APP_ENV } from '@app/src/global/Environment';

const ContractorLoading = ({ className, location, validateLoginOtp }) => {
  const phone = qs.parse(location.search, { ignoreQueryPrefix: true }).phone;
  const appToken = qs.parse(location.search, { ignoreQueryPrefix: true }).appToken;
  const link = qs.parse(location.search, { ignoreQueryPrefix: true }).link;

  useEffect(() => {
    const run = async () => {
      await validateLoginOtp({
        phone,
        token: Number(appToken),
        isContractorImpersonation: REACT_APP_ENV === 'staging-prod-db'
      });

      window.location.assign(link);
    };
    run();
  }, [phone, appToken, validateLoginOtp, link]);

  return (
    <div className={classNames('contractor-loading', className)}>
      <Loader type='page' />
    </div>
  );
};

const mapDispatchToProps = {
  validateLoginOtp
};

const ConnectedContractorLoading = connect(null, mapDispatchToProps)(ContractorLoading);

export default ConnectedContractorLoading;
