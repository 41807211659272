import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import OnboardingLabel from '@app/src/Components/Onboarding/Question/OnboardingLabel';
import PhoneField from '@app/src/Components/Common/PhoneField/PhoneField';

const OnboardingPhoneItem = ({ className, question, answer, onChange }) => {
  const onChangeImpl = (e) => {
    const value = `1${e.target.value.replace(/\D/g, '')}`;
    onChange({ slug: question.slug, value });
  };

  return (
    <div className={classNames('form-group', className)}>
      <OnboardingLabel question={question} />
      <PhoneField
        mask='+1 (###) ###-####'
        name={question.slug}
        id={question.slug}
        className='form-control'
        placeholder={question.question_meta.default}
        onChange={onChangeImpl}
        value={answer && !_.isNil(answer.value) ? answer.value.slice(1) : ''}
        type='tel'
        inputMode='tel'
        autoComplete='tel'
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
      />
    </div>
  );
};

export default OnboardingPhoneItem;
