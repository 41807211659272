import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import JobSelect from '@app/src/Components/Common/JobSelect/JobSelect';
import { orderedJobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';

const OnboardingJobCategoryItem = ({ currentQuestion, currentAnswer, setCurrentAnswer, jobCategories }) => {
  const listHeaderText = 'Frequently selected jobs:';
  const placeholderText = _.get(currentQuestion, ['question_meta', 'placeholder'], 'Search');

  const onAddItem = (item) => {
    const existingValue = currentAnswer?.value ? currentAnswer.value : [];
    const newValue = [item.slug, ...existingValue];

    setCurrentAnswer({ value: newValue });
  };

  const onRemoveItem = (item) => {
    const existingValue = currentAnswer?.value ? currentAnswer.value : [];
    const newValue = existingValue.filter((x) => `${x}` !== `${item.slug}`);

    setCurrentAnswer({ value: newValue });
  };

  return (
    <JobSelect
      listHeaderText={listHeaderText}
      onAdd={onAddItem}
      onRemove={onRemoveItem}
      placeholderText={placeholderText}
      selectedJobs={jobCategories.filter((job) => currentAnswer?.value?.includes(job.slug))}
      origin='onboarding'
    />
  );
};

const mapStateToProps = (state) => ({
  jobCategories: orderedJobCategoriesSelector(state)
});

const ConnectedOnboardingJobCategoryItem = connect(mapStateToProps)(OnboardingJobCategoryItem);

export default ConnectedOnboardingJobCategoryItem;
