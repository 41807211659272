import _ from 'lodash';
import { createSelector } from 'reselect';

export const campaignSelector = (state) => _.get(state, ['work', 'campaign']);
export const uiStageSelector = (state) => _.get(state, ['work', 'campaign', 'ui_stage'], '');
export const featuresSelector = (state) => _.get(state, ['work', 'features']);
export const workDetailsSelector = (state) => _.get(state, ['work', 'workDetails']);

export const taxRateSelector = createSelector([workDetailsSelector], (workDetails) =>
  _.get(workDetails, 'tax_rate', 0)
);

export const jobSlugsSelector = createSelector([workDetailsSelector], (workDetails) => _.get(workDetails, 'jobs', []));

export const incomeTypeSelector = createSelector([workDetailsSelector], (workDetails) =>
  _.get(workDetails, 'income_type')
);

const taxProfileFilingStatusSelector = createSelector([workDetailsSelector], (workDetails) =>
  _.get(workDetails, 'filing_status')
);

const taxCalcDependentsSelector = createSelector([workDetailsSelector], (workDetails) => {
  const taxCalcData = _.get(workDetails, 'tax_calc_credits') || {};
  const hasDependents = taxCalcData.hasDependents && taxCalcData.numDependents && Number(taxCalcData.numDependents) > 0;
  return hasDependents;
});

export const aboutYouStatusPrefillSelector = createSelector(
  [taxProfileFilingStatusSelector, taxCalcDependentsSelector],
  (filingStatus, hasDependents) => {
    switch (filingStatus) {
      case 'married': {
        return hasDependents ? ['self', 'spouse', 'dependents'] : ['self', 'spouse'];
      }

      case 'married_separate':
      case 'head': {
        return hasDependents ? ['self', 'dependent'] : ['self'];
      }

      default: {
        return ['self'];
      }
    }
  }
);

export const taxFilingEnabledSelector = createSelector([featuresSelector], (features) => {
  if (features.some((f) => f.name === 'tax-filing-ty23-disabled' && f.value === 1)) {
    return false;
  }
  return features.some((f) => f.name === 'tax-filing-ty23' && f.value === 1);
});

export const developerEnabledSelector = createSelector([featuresSelector], (features) => {
  return features.some((f) => f.name === 'developer' && f.value === 1);
});

export const hideTaxDeadlinesSelector = createSelector([featuresSelector], (features) => {
  return features.some((f) => f.name === 'hide-tax-deadlines' && f.value === 1);
});

export const freeTaxFilingSelector = createSelector([featuresSelector], (features) => {
  return features.some(
    (f) => (f.name === 'free-tax-filing-ty22' || f.name === 'free-tax-filing-ty23') && f.value === 1
  );
});

export const idVerificationEnabledSelector = createSelector([featuresSelector], (features) => {
  return features.some((f) => f.name === 'id-verification' && f.value === 1);
});

export const idVerificationDisabledSelector = createSelector([featuresSelector], (features) => {
  return features.some((f) => f.name === 'id-verification-disabled' && f.value === 1);
});

export const humanReviewEnabledSelector = createSelector([featuresSelector], (features) => {
  return features.some((f) => f.name === 'human-review' && f.value === 1);
});

export const easyRefundsEnabledSelector = createSelector([featuresSelector], (features) => {
  return features.some((f) => f.name === 'easy-refund-enabled' && f.value === 1);
});
