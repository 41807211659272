import _ from 'lodash';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  PATH_COMPONENT__INCOME_DIV_INFO,
  PATH_COMPONENT__INCOME_FREELANCE_1099_TYPE,
  PATH_COMPONENT__INCOME_INTEREST_INFO,
  PATH_COMPONENT__INCOME_INVEST_INFO,
  PATH_COMPONENT__INCOME_RETIREMENT_TYPE,
  PATH_COMPONENT__INCOME_UNEMPLOYMENT_INFO,
  PATH_COMPONENT__INCOME_W2G_INFO,
  PATH_COMPONENT__INCOME_W2_INFO
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  PATH_COMPONENT__SPECIAL_EARLY_EXIT,
  PATH_COMPONENT__SPECIAL_EXIT_BOUNCE,
  SLUG__SPECIAL_EARLY_EXIT,
  SLUG__SPECIAL_EXIT_BOUNCE,
  PATH_COMPONENT__FIND_WRITE_OFFS,
  SLUG__FIND_WRITE_OFFS,
  PATH_COMPONENT__SPECIAL_WHO,
  SLUG__SPECIAL_WHO,
  COLLECTION_TYPE__SPECIAL,
  ENDPOINT_ATTRIBUTE__SPECIAL_WHO,
  SLUG__FILING_QUALIFIED,
  PATH_COMPONENT__FILING_QUALIFIED,
  ENDPOINT_ATTRIBUTE__SPECIAL_START,
  PATH_COMPONENT__BULK_UPLOAD,
  SLUG__BULK_UPLOAD,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_INTRO,
  SLUG__BULK_UPLOAD_QUESTIONS_INTRO,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS,
  SLUG__BULK_UPLOAD_QUESTIONS,
  PATH_COMPONENT__PREMIUM_START,
  SLUG__PREMIUM_START,
  PATH_COMPONENT__MATCHING,
  SLUG__MATCHING,
  PATH_COMPONENT__MATCHING_COMPLETE,
  SLUG__MATCHING_COMPLETE,
  PATH_COMPONENT__BULK_UPLOAD_MULTI_IMAGE,
  SLUG__BULK_UPLOAD_MULTI_IMAGE,
  PATH_COMPONENT__BULK_UPLOAD_PHOTO_CAPTURE,
  SLUG__BULK_UPLOAD_PHOTO_CAPTURE,
  PATH_COMPONENT__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__BULK_UPLOAD_HAS_1099,
  SLUG__BULK_UPLOAD_HAS_W2,
  SLUG__BULK_UPLOAD_HAS_1099G,
  SLUG__BULK_UPLOAD_HAS_INT,
  SLUG__BULK_UPLOAD_HAS_DIV,
  SLUG__BULK_UPLOAD_HAS_INVEST,
  SLUG__BULK_UPLOAD_HAS_RETIREMENT,
  SLUG__BULK_UPLOAD_HAS_W2G,
  SLUG__BULK_UPLOAD_HAS_HSA,
  SLUG__BULK_UPLOAD_HAS_IRA,
  SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS,
  SLUG__BULK_UPLOAD_HAS_MORTGAGE,
  SLUG__BULK_UPLOAD_HAS_ENERGY,
  SLUG__BULK_UPLOAD_HAS_TEACHING,
  SLUG__BULK_UPLOAD_HAS_TUITION,
  SLUG__BULK_UPLOAD_HAS_CHILDCARE,
  SLUG__BULK_UPLOAD_HAS_CHARITY,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_SUMMARY,
  SLUG__BULK_UPLOAD_QUESTIONS_SUMMARY,
  PATH_COMPONENT__BULK_UPLOAD_HAS_1099,
  PATH_COMPONENT__BULK_UPLOAD_HAS_W2,
  PATH_COMPONENT__BULK_UPLOAD_HAS_1099G,
  PATH_COMPONENT__BULK_UPLOAD_HAS_INT,
  PATH_COMPONENT__BULK_UPLOAD_HAS_DIV,
  PATH_COMPONENT__BULK_UPLOAD_HAS_INVEST,
  PATH_COMPONENT__BULK_UPLOAD_HAS_RETIREMENT,
  PATH_COMPONENT__BULK_UPLOAD_HAS_W2G,
  PATH_COMPONENT__BULK_UPLOAD_HAS_HSA,
  PATH_COMPONENT__BULK_UPLOAD_HAS_IRA,
  PATH_COMPONENT__BULK_UPLOAD_HAS_STUDENT_LOANS,
  PATH_COMPONENT__BULK_UPLOAD_HAS_MORTGAGE,
  PATH_COMPONENT__BULK_UPLOAD_HAS_ENERGY,
  PATH_COMPONENT__BULK_UPLOAD_HAS_TEACHING,
  PATH_COMPONENT__BULK_UPLOAD_HAS_TUITION,
  PATH_COMPONENT__BULK_UPLOAD_HAS_CHARITY,
  PATH_COMPONENT__BULK_UPLOAD_HAS_CHILDCARE,
  PATH_COMPONENT__INCOME_ADD_MORE,
  SLUG__INCOME_ADD_MORE,
  PATH_COMPONENT__CREDIT_ADD_MORE,
  SLUG__CREDIT_ADD_MORE,
  SLUG__BULK_UPLOAD_QUESTIONS_ALL_FORMS_UPLOADED,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_ADD_FORMS,
  SLUG__BULK_UPLOAD_QUESTIONS_ADD_FORMS
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__CREDIT_CHARITY_AMOUNT,
  PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL,
  PATH_COMPONENT__CREDIT_ENERGY_INFO,
  PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
  PATH_COMPONENT__CREDIT_HSA_DETAIL,
  PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
  PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
  PATH_COMPONENT__CREDIT_TEACHING_INFO
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

export const getNextPathComponentMap = () => {
  return {
    [SLUG__SPECIAL_WHO]: {
      nextPathComponent: PATH_COMPONENT__SPECIAL_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__SPECIAL_EXIT_BOUNCE]: {
      nextPathComponent: PATH_COMPONENT__BULK_UPLOAD,
      questionnaireEarlyExit: true
    },
    [SLUG__MATCHING]: {
      nextPathComponent: PATH_COMPONENT__MATCHING_COMPLETE,
      questionnaireEarlyExit: true
    },
    [SLUG__MATCHING_COMPLETE]: {
      nextPathComponent: PATH_COMPONENT__SPECIAL_WHO,
      questionnaireEarlyExit: true
    },
    [SLUG__BULK_UPLOAD_MULTI_IMAGE]: {
      nextPathComponent: PATH_COMPONENT__BULK_UPLOAD,
      questionnaireEarlyExit: true
    },
    [SLUG__BULK_UPLOAD_PHOTO_CAPTURE]: {
      nextPathComponent: PATH_COMPONENT__BULK_UPLOAD,
      questionnaireEarlyExit: true
    }
  };
};

export const getOptionPathComponentMap = ({ isPremiumSubscriber }) => {
  return {
    [SLUG__BULK_UPLOAD_QUESTIONS_ALL_FORMS_UPLOADED]: {
      0: {
        nextPathComponent: PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_ADD_FORMS,
        questionnaireEarlyExit: false
      },
      1: {
        questionnaireEarlyExit: true
      }
    },
    ...(isPremiumSubscriber
      ? {
          [SLUG__FILING_QUALIFIED]: {
            0: {
              nextPathComponent: PATH_COMPONENT__PREMIUM_START,
              questionnaireEarlyExit: false
            },
            1: {
              nextPathComponent: PATH_COMPONENT__PREMIUM_START,
              questionnaireEarlyExit: false
            }
          }
        }
      : {
          [SLUG__FILING_QUALIFIED]: {
            0: {
              questionnaireEarlyExit: true
            },
            1: {
              nextPathComponent: PATH_COMPONENT__FILING_QUALIFIED,
              questionnaireEarlyExit: true
            }
          }
        }),
    [SLUG__BULK_UPLOAD_HAS_1099]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__INCOME_FREELANCE_1099_TYPE,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_W2]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__INCOME_W2_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_1099G]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__INCOME_UNEMPLOYMENT_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_INT]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__INCOME_INTEREST_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_DIV]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__INCOME_DIV_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_INVEST]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__INCOME_INVEST_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_RETIREMENT]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__INCOME_RETIREMENT_TYPE,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_W2G]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__INCOME_W2G_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_HSA]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_HSA_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_IRA]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_MORTGAGE]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_ENERGY]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_ENERGY_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_TEACHING]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_TEACHING_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_TUITION]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_CHILDCARE]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_CHARITY]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_CHARITY_AMOUNT,
        questionnaireEarlyExit: false
      }
    }
  };
};
export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__SPECIAL_EARLY_EXIT]: SLUG__SPECIAL_EARLY_EXIT,
    [PATH_COMPONENT__SPECIAL_EXIT_BOUNCE]: SLUG__SPECIAL_EXIT_BOUNCE,
    [PATH_COMPONENT__FIND_WRITE_OFFS]: SLUG__FIND_WRITE_OFFS,
    [PATH_COMPONENT__SPECIAL_WHO]: SLUG__SPECIAL_WHO,
    [PATH_COMPONENT__FILING_QUALIFIED]: SLUG__FILING_QUALIFIED,
    [PATH_COMPONENT__PREMIUM_START]: SLUG__PREMIUM_START,
    [PATH_COMPONENT__BULK_UPLOAD]: SLUG__BULK_UPLOAD,
    [PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_INTRO]: SLUG__BULK_UPLOAD_QUESTIONS_INTRO,
    [PATH_COMPONENT__BULK_UPLOAD_QUESTIONS]: SLUG__BULK_UPLOAD_QUESTIONS,
    [PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_SUMMARY]: SLUG__BULK_UPLOAD_QUESTIONS_SUMMARY,
    [PATH_COMPONENT__BULK_UPLOAD_MULTI_IMAGE]: SLUG__BULK_UPLOAD_MULTI_IMAGE,
    [PATH_COMPONENT__BULK_UPLOAD_PHOTO_CAPTURE]: SLUG__BULK_UPLOAD_PHOTO_CAPTURE,
    [PATH_COMPONENT__BULK_UPLOAD_MANUAL_ENTRY]: SLUG__BULK_UPLOAD_MANUAL_ENTRY,
    [PATH_COMPONENT__MATCHING]: SLUG__MATCHING,
    [PATH_COMPONENT__MATCHING_COMPLETE]: SLUG__MATCHING_COMPLETE,
    [PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_ADD_FORMS]: SLUG__BULK_UPLOAD_QUESTIONS_ADD_FORMS,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_1099]: SLUG__BULK_UPLOAD_HAS_1099,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_W2]: SLUG__BULK_UPLOAD_HAS_W2,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_1099G]: SLUG__BULK_UPLOAD_HAS_1099G,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_INT]: SLUG__BULK_UPLOAD_HAS_INT,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_DIV]: SLUG__BULK_UPLOAD_HAS_DIV,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_INVEST]: SLUG__BULK_UPLOAD_HAS_INVEST,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_RETIREMENT]: SLUG__BULK_UPLOAD_HAS_RETIREMENT,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_W2G]: SLUG__BULK_UPLOAD_HAS_W2G,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_HSA]: SLUG__BULK_UPLOAD_HAS_HSA,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_IRA]: SLUG__BULK_UPLOAD_HAS_IRA,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_STUDENT_LOANS]: SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_MORTGAGE]: SLUG__BULK_UPLOAD_HAS_MORTGAGE,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_ENERGY]: SLUG__BULK_UPLOAD_HAS_ENERGY,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_TEACHING]: SLUG__BULK_UPLOAD_HAS_TEACHING,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_TUITION]: SLUG__BULK_UPLOAD_HAS_TUITION,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_CHARITY]: SLUG__BULK_UPLOAD_HAS_CHARITY,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_CHILDCARE]: SLUG__BULK_UPLOAD_HAS_CHILDCARE,
    [PATH_COMPONENT__INCOME_ADD_MORE]: SLUG__INCOME_ADD_MORE,
    [PATH_COMPONENT__CREDIT_ADD_MORE]: SLUG__CREDIT_ADD_MORE
  };
};

export const getQuestionQueries = ({ question }) => {
  if (question.slug === SLUG__SPECIAL_WHO) {
    return [
      {
        coll_type: COLLECTION_TYPE__SPECIAL,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPECIAL_WHO
      },
      {
        coll_type: COLLECTION_TYPE__SPECIAL,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPECIAL_START
      }
    ];
  } else if (question.slug === 'bulk-upload-questions-state-info') {
    return [{ coll_type: COLLECTION_TYPE__STATE_RETURN, coll_id: '1', slug: SLUG__STATE_RETURN }];
  }

  return [];
};

export const getClarifyingQuestionHeader = ({
  currentQuestion,
  currentCollectionId,
  currentQuestionnaireQuestion,
  bulkUploadPills
}) => {
  const currentCollectionType = _.get(currentQuestion, 'collectionType');
  const maybeBulkUploadPillForForm = _.find(bulkUploadPills, {
    collectionType: currentCollectionType,
    collectionId: currentCollectionId
  });

  if (!_.isNil(maybeBulkUploadPillForForm)) {
    const isFormReviewScreen = _.get(currentQuestionnaireQuestion, 'type') === 'form-review';
    const subText = isFormReviewScreen ? '' : 'We need a couple more details about your form.';
    const formTitle = _.get(maybeBulkUploadPillForForm, 'text');

    if (_.isNil(formTitle)) {
      defaultCaptureMessage('Could not get bulk upload data for form', {
        coll_type: currentCollectionType,
        sub_type: maybeBulkUploadPillForForm.subType,
        coll_id: currentCollectionId
      });
      return {
        title: isFormReviewScreen ? `Let's review your form` : 'Your form',
        summary: subText
      };
    }
    return {
      title: isFormReviewScreen ? `Let's review your ${formTitle}` : formTitle,
      summary: subText
    };
  }

  return {
    title: '',
    summary: ''
  };
};
