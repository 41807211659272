import React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { trackActivity } from '@app/src/services/analyticsService';
import { sendDownloadText } from '@app/src/services/mobileRedirectService';
import { sendingLinkTypeSelector } from '@app/src/selectors/mobileRedirectSelectors';
import { isMobileBrowser, isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { isPlatform } from '@app/src/taxflow/shared/utils/sharedUtils';

const MobileRedirectLink = ({ linkType, clickEvent, onClick, sendDownloadText, mobileText, buttonColor }) => {
  const handleClick = async (e) => {
    if (!isMobileBrowser()) {
      e.preventDefault();
      await sendDownloadText({ linkType: linkType });
    } else if (isReactNative() && linkType === 'write-offs-link') {
      e.preventDefault();
      sentMsgToReactNative('home');
    }

    trackActivity(clickEvent);

    if (onClick) {
      onClick();
    }
  };

  let href = 'https://keeper.tax/get-the-app';

  if (isReactNative()) {
    if (isPlatform('ios')) {
      href = 'https://itunes.apple.com/app/id1471090941';
    } else {
      href = 'market://details?id=com.keepertax';
    }
  }

  return (
    <DialogActions sx={{ ...(linkType === 'write-offs-link' ? { padding: '0px' } : {}) }}>
      <Button
        fullWidth
        href={href}
        variant='contained'
        color={buttonColor || 'primary'}
        size='large'
        onClick={handleClick}
        sx={{ margin: '6px auto' }}
      >
        {isMobileBrowser() ? mobileText : 'Text me the link'}
      </Button>
    </DialogActions>
  );
};

const mapStateToProps = (state, props) => ({
  sendingLinkType: sendingLinkTypeSelector(state, props)
});

const mapDispatchToProps = {
  sendDownloadText
};

const ConnectedMobileRedirectLink = connect(mapStateToProps, mapDispatchToProps)(MobileRedirectLink);

export default ConnectedMobileRedirectLink;
