import React from 'react';

import ExpenseReviewCategoryIcon from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryIcon';
import BasePill from '@app/src/Components/Common/BasePill/BasePill';

import '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCountPill.scss';

const ExpenseReviewCategoryCountPill = ({ id, count }) => (
  <BasePill icon={id && <ExpenseReviewCategoryIcon id={id} size={16} />}>{count}</BasePill>
);

export default ExpenseReviewCategoryCountPill;
