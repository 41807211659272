import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import '@app/src/Components/Common/SectionedProgressBar/SectionedProgressBar.scss';

const SectionedProgressBar = ({ totalSections, currentSection, className }) => {
  if (_.isNil(totalSections) || _.isNil(currentSection)) return null;

  return (
    <div className={classNames('sectioned-progress-bar', className)}>
      {_.range(totalSections).map((_, i) => (
        <div
          key={i}
          className={classNames('step', {
            active: i < currentSection
          })}
        />
      ))}
    </div>
  );
};

export default SectionedProgressBar;
