import React from 'react';
import classNames from 'classnames';
import '@app/src/Components/TaxFlow/Common/TaxFlowPillsSection.scss';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';

const TaxFlowPillsSection = ({ sectionHeader, pills }) => {
  return (
    <div className={classNames('taxflow-pills-section')}>
      <div className='taxflow-pills-section-header'>{sectionHeader}</div>
      <div className='taxflow-pills-section-pills'>
        {pills.map(({ text, loading, removable, icon, variant, onClick, onRemove }, idx) => {
          return (
            <TaxFlowPill
              key={idx}
              text={text}
              icon={icon}
              loading={loading}
              removable={removable}
              variant={variant}
              onClick={onClick}
              onRemove={onRemove}
            ></TaxFlowPill>
          );
        })}
      </div>
    </div>
  );
};

export default TaxFlowPillsSection;
