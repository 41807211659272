import _ from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import { campaignSelector } from '@app/src/selectors/workSelectors';

export const assistantMessagesSelector = (state) => _.get(state, ['assistant', 'messages']);
export const assistantSessionIdSelector = (state) => _.get(state, ['assistant', 'sessionId']);
export const assistantOpenSelector = (state) => _.get(state, ['assistant', 'open']);
export const assistantUnreadMessagesSelector = (state) => _.get(state, ['assistant', 'unreadMessages']);
export const assistantTypingSelector = (state) => _.get(state, ['assistant', 'typing']);
export const assistantIsLoadingSelector = (state) => _.get(state, ['assistant', 'loading']);
export const assistantHasErrorSelector = (state) => _.get(state, ['assistant', 'hasError']);
export const assistantIsConnectedSelector = (state) => _.get(state, ['assistant', 'connected']);

export const assistantSignedUrlSelector = (state, props) => {
  const cache = _.get(state, ['assistant', 'signedUrlCache']);

  if (!cache) {
    return null;
  }

  const result = cache[props.key];

  if (!result) {
    return null;
  }

  const expiresAt = _.get(result, 'expiresAt');

  if (moment().isAfter(expiresAt)) {
    return null;
  }

  return result.data;
};

export const hideAssistantBadgeSelector = createSelector(
  [campaignSelector],
  (campaign) => _.get(campaign, ['test_campaigns', 'hide_assistant_badge']) === 1
);

export const desktopSellModalVisitedSelector = createSelector(
  [campaignSelector],
  (campaign) => _.get(campaign, ['test_campaigns', 'desktop_sell_modal_visited']) === 1
);
