import classNames from 'classnames';
import React from 'react';

import BankLink from '@app/src/Components/BankLink/BankLink';
import AddIcon from '@app/src/assets/add.svg?react';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import CreditCardIcon from '@app/src/assets/card.svg?react';

import '@app/src/Components/ExpenseReview/ExpenseReviewExpensesNoResultsText.scss';

const ExpenseReviewExpensesNoResultsText = ({ className }) => {
  return (
    <div className={classNames('expense-review-expenses-no-results-container', className)}>
      <div className='expense-review-expenses-no-results-text'>
        <div className='expense-review-expenses-no-results-text-header'>No matching transactions found</div>
        <div className='expense-review-expenses-no-results-text-description'>
          Link bank account(s) you use for work and we'll automatically scan your past purchases for eligible deductions
        </div>
        <div style={{ display: 'flex' }}>
          <BankLink
            icon={<BankIcon />}
            className='expense-review-expenses-no-results-link-button expense-review-expenses-no-results-link-button-1'
            fullWidth={false}
            type={'webDashboard'}
            styles={{ justifyContent: 'space-between', flex: 1, marginRight: '10px' }}
          >
            <span>Checking accounts</span>
            <AddIcon />
          </BankLink>
          <BankLink
            icon={<CreditCardIcon />}
            fullWidth={false}
            className='expense-review-expenses-no-results-link-button expense-review-expenses-no-results-link-button-2'
            type={'webDashboard'}
            styles={{ justifyContent: 'space-between', flex: 1 }}
          >
            <span>Credit card</span>
            <AddIcon />
          </BankLink>
        </div>
      </div>
    </div>
  );
};

export default ExpenseReviewExpensesNoResultsText;
