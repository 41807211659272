import _ from 'lodash';
import capitalize from 'capitalize';
import {
  getSlugMap as getSlugMapCar,
  getQuestionQueries as getQuestionQueriesCar
} from '@app/src/taxflow/sections/car/utils/carUtils';
import {
  getSlugMap as getSlugMapCredit,
  getQuestionQueries as getQuestionQueriesCredit
} from '@app/src/taxflow/sections/credit/utils/creditUtils';
import {
  getSlugMap as getSlugMapHome,
  getQuestionQueries as getQuestionQueriesHome
} from '@app/src/taxflow/sections/home/utils/homeUtils';
import {
  getSlugMap as getSlugMapIncome,
  getQuestionQueries as getQuestionQueriesIncome
} from '@app/src/taxflow/sections/income/utils/incomeUtils';
import {
  getSlugMap as getSlugMapPersonal,
  getQuestionQueries as getQuestionQueriesPersonal
} from '@app/src/taxflow/sections/personal/utils/personalUtils';
import {
  getSlugMap as getSlugMapSpecial,
  getQuestionQueries as getQuestionQueriesSpecial
} from '@app/src/taxflow/sections/special/utils/specialUtils';
import { getQuestionQueries as getQuestionQueriesSubmit } from '@app/src/taxflow/sections/submit/utils/submitUtils';
import { getSlugMap as getSlugMapSubmit } from '@app/src/taxflow/sections/submit';
import {
  CATEGORY_TYPE_TAXFLOW_FORM_SLIDER,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN,
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_TAXFLOW_SYSTEM,
  CATEGORY_TYPE_NAVIGATION,
  CATEGORY_TYPE_SUMMARY,
  CATEGORY_TYPE_BOUNCE,
  CATEGORY_TYPE_TEXT,
  CATEGORY_TYPE_TAXFLOW_FORM_EMAIL,
  CATEGORY_TYPE_TAXFLOW_FORM_PHONE,
  CATEGORY_TYPE_TAXFLOW_FORM_SSN,
  CATEGORY_TYPE_TAXFLOW_FORM_NUMBER,
  CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_INFO,
  CATEGORY_TYPE_MONEY,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_INITIAL,
  CATEGORY_TYPE_MILES,
  CATEGORY_TYPE_YEARS,
  CATEGORY_TYPE_PERCENT,
  CATEGORY_TYPE_SQUARE_FOOTAGE,
  CATEGORY_TYPE_EIN,
  CATEGORY_TYPE_SSN_OR_EIN,
  CATEGORY_TYPE_ZIP,
  CATEGORY_TYPE_INT,
  CATEGORY_TYPE_TEMPLATE,
  DEFAULT_WORK,
  CATEGORY_TYPE_BUSINESS_CODE
} from '@app/src/constants/constants';
import {
  DEFAULT_COLLECTION_ID,
  COLLECTION_TYPE__REVIEW,
  ENDPOINT_ATTRIBUTE__REVIEW_STARTED,
  TAX_FILING_YEAR,
  STATE_NAME_MAP,
  SLUG__SETTINGS,
  PATH_COMPONENT__CONTACT_SUPPORT,
  SLUG__CONTACT_SUPPORT,
  PATH_COMPONENT__PAST_RETURNS,
  SLUG__PAST_RETURNS,
  PATH_COMPONENT__SETTINGS,
  NO_INCOME_TAX_STATES,
  PATH_COMPONENT__SWITCH_TO_DESKTOP,
  SLUG__SWITCH_TO_DESKTOP,
  PATH_COMPONENT__LINKED_ACCOUNTS,
  SLUG__LINKED_ACCOUNTS,
  LAST_TAX_FILING_YEAR
} from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
  SLUG__DEPENDENT_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
  COLLECTION_TYPE__HOME_ADDRESS,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE,
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  SLUG__DEPENDENT_UNQUALIFIED,
  COLLECTION_TYPE__SPOUSE,
  ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME,
  SLUG__DEPENDENT_QUALIFIED,
  SLUG__HOME_ADDRESS_DETAIL,
  SLUG__SELF_FIRST_NAME,
  SLUG__SELF_LAST_NAME,
  SLUG__SELF_MARRIAGE,
  SLUG__SELF_MARRIAGE_STATUS,
  SLUG__SELF_MARRIAGE_FILING_OPTION,
  ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS,
  ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION,
  ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED,
  ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED,
  SLUG__SELF_TAX_STATUS,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL,
  SLUG__DEPENDENTS
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { deserializeQuestionAnswer, serializeQuestionAnswer } from '@app/src/taxflow/mapping/utils/mappingUtils';
import { HOME_SLUGS, COLLECTION_TYPE__HOME } from '@app/src/taxflow/sections/home/constants/homeConstants';
import {
  SLUG__CAR_SERVICE_DATE,
  SLUG__CAR_BRAND,
  SLUG__CAR_LEASE,
  SLUG__CAR_PURCHASE_DATE,
  SLUG__CAR_NEW_USED,
  SLUG__CAR_COST,
  SLUG__CAR_LOAN,
  SLUG__CAR_WORK_MILES,
  SLUG__CAR_WORK_MILES_TRACKED,
  SLUG__CAR_TOTAL_MILES,
  SLUG__CAR_PERCENT,
  SLUG__CAR_MILES_CONFIRMATION,
  SLUG__CAR_STANDARD_MILEAGE,
  SLUG__CAR_PAST_DEPRECIATION,
  SLUG__CAR_YEARS_DEPRECIATION,
  SLUG__CAR_DEPRECIATION_CONFIRMATION,
  SLUG__CAR_OPTIONS,
  COLLECTION_TYPE__CAR,
  SLUG__CAR_OTHER_PERSONAL
} from '@app/src/taxflow/sections/car/constants/carConstants';
import {
  SLUG__INCOME_FREELANCE_AGI,
  SLUG__INCOME_FREELANCE_1099_NEC,
  SLUG__INCOME_FREELANCE_1099_MISC,
  SLUG__INCOME_W2_INFO,
  SLUG__INCOME_UNEMPLOYMENT_INFO,
  SLUG__INCOME_UNEMPLOYMENT_FORM_UPLOAD,
  SLUG__INCOME_INTEREST_INFO,
  SLUG__INCOME_INTEREST_FORM_UPLOAD,
  SLUG__INCOME_DIV_INFO,
  SLUG__INCOME_DIV_FORM_UPLOAD,
  SLUG__INCOME_INVEST_INFO,
  SLUG__INCOME_INVEST_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO,
  COLLECTION_TYPE__INCOME_FREELANCE,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE,
  SLUG__INCOME_FREELANCE_JOB_NAME,
  SLUG__INCOME_W2_TWELVE_A,
  SLUG__INCOME_W2_TWELVE_B,
  SLUG__INCOME_W2_TWELVE_C,
  SLUG__INCOME_W2_TWELVE_D,
  SLUG__INCOME_W2_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_SSA_INFO,
  SLUG__INCOME_RETIREMENT_TYPE,
  SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
  SLUG__INCOME_RETIREMENT_PENSION_INFO,
  SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
  COLLECTION_TYPE__INCOME_INVEST,
  COLLECTION_TYPE__INCOME_RETIREMENT,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME,
  SLUG__INCOME_W2_PREFILL_LOADING,
  SLUG__INCOME_INVEST_MORE_INFO,
  SLUG__INCOME_INVEST_UNIFICATION,
  PATH_COMPONENT__INCOME_INVEST_FORM_UPLOAD,
  SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO,
  SLUG__INCOME_FREELANCE_1099K_INFO,
  SLUG__INCOME_FREELANCE_PAYERS_NAME,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED,
  SLUG__INCOME_INVEST_CATEGORY,
  SLUG__INCOME_RETIREMENT_PENSION_ROTH,
  SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR,
  SLUG__INCOME_FREELANCE_1099_TYPE,
  SLUG__INCOME_FREELANCE_JOB,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE,
  SLUG__INCOME_FREELANCE_INDUSTRY
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  SLUG__CA_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__DC_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__RI_HEALTHCARE_DEPENDENT_DURATION,
  COLLECTION_TYPE__STATE_INCOME,
  COLLECTION_TYPE__STATE_RESIDENCY,
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_INCOME,
  SLUG__STATE_RESIDENCY,
  SLUG__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN_COLL_TYPE_STARTED,
  SLUG__STATE_DEFAULT,
  SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
  SLUG__DC_RENT_CREDIT_OPTIONS,
  SLUG__STATE_EXPENSES,
  COLLECTION_TYPE__STATE_EXPENSES,
  SLUG__PA_USE_TAX_PURCHASES,
  ENDPOINT_ATTRIBUTE__STATE_RETURN_STARTED,
  SLUG__TEMPLATE_STATE_DONE,
  ENDPOINT_ATTRIBUTE__STATE_RETURN_DONE,
  SLUG__STATE_NO_INCOME_TAX,
  SLUG__NY_COUNTY,
  SLUG__NY_SCHOOL_DISTRICT
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__CREDIT_HSA_DETAIL,
  SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
  SLUG__CREDIT_STUDENT_LOAN_DETAIL,
  SLUG__CREDIT_CHILD_CARE_DETAIL,
  COLLECTION_TYPE__CREDIT_HEALTHCARE,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS,
  SLUG__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
  SLUG__CREDIT_STUDENT_TUITION_QUALIFY,
  SLUG__CREDIT_STUDENT_TUITION_DETAIL,
  COLLECTION_TYPE__CREDIT_STANDARD,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_HEALTH_INSURANCE,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MEDICAL,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MORTGAGE_INTEREST,
  COLLECTION_TYPE__CREDIT_CHARITY,
  SLUG__CREDIT_STANDARD_ITEMIZED,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_COLLECTION_TYPE_STARTED,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_STARTED,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_DONE,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_STANDARD_RESULT,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_DISALLOWED_NAMES,
  SLUG__CREDIT_DISALLOWED,
  COLLECTION_TYPE__CREDIT_DISALLOWED,
  SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
  SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_MONTHLY_PREMIUM,
  COLLECTION_TYPE__CREDIT_HOMEOWNER,
  ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_STATE_REAL_ESTATE,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_GAMBLING_LOSSES,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_STARTED,
  SLUG__CREDIT_HEALTH_PLAN,
  COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
  SLUG__CREDIT_HSA_DETAIL_FAMILY
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  BULK_UPLOAD_FORM_PREDICT_QUESTIONS,
  ENDPOINT_ATTRIBUTE__SPECIAL_DONE,
  PATH_COMPONENT__SPECIAL_WHO,
  SLUG__BULK_UPLOAD,
  SLUG__BULK_UPLOAD_CHARITY_OPTION,
  SLUG__BULK_UPLOAD_HAS_CHARITY,
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__SPECIAL_WHO
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  getQuestionQueries as getQuestionQueriesNavigation,
  getUIStagePathMap
} from '@app/src/taxflow/navigation/utils/navigationUtils';
import {
  SLUG__SUBMIT_BYE,
  SLUG__SUBMIT_AUDIT_ISSUE,
  SLUG__SUBMIT_IRS_ISSUE,
  SLUG__SUBMIT_ACCEPTED_BY_IRS,
  SLUG__SUBMIT_SENT_TO_IRS,
  SLUG__SUBMIT_DEBIT,
  COLLECTION_TYPE__SUBMIT_DEBIT,
  ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD,
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_UNABLE_TO_FILE,
  SLUG__SUBMIT_BYE_MANUAL,
  SLUG__SUBMIT_BLOCKED,
  SLUG__SUBMIT_EMAIL_OUTBOX,
  SLUG__SUBMIT_BYE_MANUAL_ESC,
  SLUG__SUBMIT_FINISH,
  SLUG__SUBMIT_BLOCKED_FINAL_REVIEW,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED,
  SLUG__SUBMIT_FINISH_LATER,
  PATH_COMPONENT__SUBMIT_FINISH,
  SLUG__SUBMIT_TAX_AMOUNT,
  SLUG__SUBMIT_FILED_WITH_IRS
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  getQuestionQueries as getQuestionQueriesState,
  getSlugMap as getSlugMapState
} from '@app/src/taxflow/sections/state';
import {
  getKnownJobInfo,
  getFilingStatus,
  getQueryResultByEndpointAttribute,
  getQueryResultValueByEndpointattribute,
  getQueryResultsByEndpointAttribute
} from '@app/src/taxflow/shared/utils/sharedUtils';
import { currencyWith0DecimalPlaces, isReactNative } from '@app/src/global/Helpers';
import { getFormUploadEnabledMap } from '@app/src/taxflow/collection/utils/collectionUtils';
import { getStateReturnCollId } from '@app/src/taxflow/sections/state/utils/stateUtils';
import { setUserWithObj } from '@app/src/services/analyticsService';

const getSlugMapNavigation = () => {
  return {
    [PATH_COMPONENT__CONTACT_SUPPORT]: SLUG__CONTACT_SUPPORT,
    [PATH_COMPONENT__PAST_RETURNS]: SLUG__PAST_RETURNS,
    [PATH_COMPONENT__SWITCH_TO_DESKTOP]: SLUG__SWITCH_TO_DESKTOP
  };
};

export const getSlugByLocation = (location) => {
  if (_.get(location, 'pathname') === `/${PATH_COMPONENT__SETTINGS}`) {
    return SLUG__SETTINGS;
  } else if (_.get(location, 'pathname') === `/${PATH_COMPONENT__LINKED_ACCOUNTS}`) {
    return SLUG__LINKED_ACCOUNTS;
  }

  const pathComponents = location.pathname.split('/');
  const primaryPathComponent = pathComponents[2];

  const pathComponentSlugMap = {
    ...getSlugMapCar(),
    ...getSlugMapCredit(),
    ...getSlugMapHome(),
    ...getSlugMapIncome(),
    ...getSlugMapPersonal(),
    ...getSlugMapSpecial(),
    ...getSlugMapSubmit(),
    ...getSlugMapState(),
    ...getSlugMapNavigation()
  };
  return _.get(pathComponentSlugMap, [primaryPathComponent], null);
};

export const getPathComponentBySlug = (slug) => {
  const map = {
    ...getSlugMapCar(),
    ...getSlugMapCredit(),
    ...getSlugMapHome(),
    ...getSlugMapIncome(),
    ...getSlugMapPersonal(),
    ...getSlugMapSpecial(),
    ...getSlugMapSubmit(),
    ...getSlugMapState(),
    ...getSlugMapNavigation()
  };
  const inverted = _.invert(map);
  return _.get(inverted, slug, null);
};

const getSummaryQueries = ({ question, allCollectionTypes }) => {
  if (
    (question.question_type !== CATEGORY_TYPE_SUMMARY || !_.get(question, ['question_meta', 'collectionTypes'])) &&
    question.slug !== SLUG__BULK_UPLOAD
  ) {
    return [];
  }

  const collectionTypes =
    question.slug === SLUG__BULK_UPLOAD ? allCollectionTypes : question.question_meta.collectionTypes;

  return _.flatMap(collectionTypes, (collectionType) => {
    return [
      {
        coll_type: collectionType.type,
        slug: collectionType.startedEndpointAttribute
      },
      ...(collectionType.displayNameEndpointAttribute
        ? [
            {
              coll_type: collectionType.type,
              slug: collectionType.displayNameEndpointAttribute
            }
          ]
        : []),
      {
        coll_type: collectionType.type,
        slug: collectionType.collectionTypeStartedEndpointAttribute
      },
      ...(question.slug === SLUG__BULK_UPLOAD
        ? [
            {
              coll_type: COLLECTION_TYPE__INCOME_RETIREMENT,
              slug: SLUG__INCOME_RETIREMENT_TYPE
            },
            {
              coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
              slug: SLUG__INCOME_FREELANCE_PAYERS_NAME
            },
            {
              coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
              slug: SLUG__INCOME_FREELANCE_1099_TYPE
            },
            {
              coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
              slug: SLUG__INCOME_FREELANCE_JOB_NAME
            }
          ]
        : [])
    ];
  });
};

export const getQuestionQueries = ({ question, collectionId, allCollectionTypes }) => {
  let newQueries = [];
  if (question.slug === SLUG__SUBMIT_FINISH) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__STATE_INCOME
      }
    ];
  } else if (question.slug === SLUG__SUBMIT_FINISH) {
    newQueries = [
      {
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_STARTED
      }
    ];
  } else if (question.slug === SLUG__STATE_RESIDENCY) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__SELF,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
      },
      {
        coll_type: COLLECTION_TYPE__STATE_RESIDENCY,
        coll_id: DEFAULT_COLLECTION_ID
      }
    ];
  } else if (question.slug === SLUG__STATE_INCOME) {
    const jobTypes = [
      'income-freelance',
      'income-1099g',
      'income-interest',
      'income-div',
      'income-invest',
      'income-retirement'
    ];
    newQueries = jobTypes.reduce(
      (queries, jobType) => {
        let nameSlug, incomeSlug;
        if (jobType === 'income-freelance') {
          nameSlug = 'job-name';
          incomeSlug = 'income';
        } else if (jobType === 'income-1099g') {
          nameSlug = 'payer-name';
          incomeSlug = 'compensation';
        } else if (jobType === 'income-interest') {
          nameSlug = 'payer-name';
          incomeSlug = 'amount';
        } else if (jobType === 'income-div') {
          nameSlug = 'payer-name';
          incomeSlug = 'ordinary';
        } else if (jobType === 'income-invest') {
          return [
            ...queries,
            ...[
              'short-term-sale-proceeds',
              'short-term-cost-basis',
              'long-term-sale-proceeds',
              'long-term-cost-basis',
              'unknown-term-sale-proceeds',
              'unknown-term-cost-basis',
              'description',
              'unification'
            ].map((slugSuffix) => ({
              coll_type: jobType,
              slug: `${jobType}-${slugSuffix}`
            }))
          ];
        } else {
          return [
            ...queries,
            {
              coll_type: jobType,
              slug: `${jobType}-type`
            },
            {
              coll_type: jobType,
              slug: `${jobType}-ssa-net-benefits`
            },
            {
              coll_type: jobType,
              slug: `${jobType}-pension-gross-distribution`
            }
          ];
        }

        return [
          ...queries,
          {
            coll_type: jobType,
            slug: `${jobType}-${nameSlug}`
          },
          {
            coll_type: jobType,
            slug: `${jobType}-${incomeSlug}`
          }
        ];
      },
      [
        {
          coll_type: COLLECTION_TYPE__STATE_INCOME
        }
      ]
    );
  } else if (question.slug === SLUG__STATE_EXPENSES) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__STATE_INCOME
      },
      {
        coll_type: COLLECTION_TYPE__STATE_EXPENSES
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME
      }
    ];
  } else if (question.slug === SLUG__NY_COUNTY) {
    newQueries = [
      {
        coll_type: question.collectionType,
        coll_id: collectionId,
        slug: question.endpoint_attr
      },
      { slug: SLUG__NY_SCHOOL_DISTRICT }
    ];
  } else if (question.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_INDUSTRY
      }
    ];
  } else if (question.slug === SLUG__CREDIT_HEALTH_PLAN) {
    newQueries = [
      {
        coll_type: COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED
      },
      {
        coll_type: question.collectionType,
        coll_id: collectionId,
        slug: question.endpoint_attr
      }
    ];
  } else if (
    question.question_type === CATEGORY_TYPE_TAXFLOW_INFO ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_SYSTEM ||
    question.question_type === CATEGORY_TYPE_SUMMARY ||
    question.slug === SLUG__BULK_UPLOAD
  ) {
    newQueries = [];
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    newQueries = question.sub_question.map((question) => ({
      coll_type: question.collectionType,
      coll_id: collectionId,
      slug: question.endpoint_attr
    }));
    if (question.slug === SLUG__HOME_ADDRESS_DETAIL) {
      newQueries = [
        ...newQueries,
        {
          coll_type: COLLECTION_TYPE__STATE_RETURN,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_COLL_TYPE_STARTED
        }
      ];
    } else if (question.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
      newQueries = [
        ...newQueries,
        {
          coll_type: COLLECTION_TYPE__CREDIT_HEALTHCARE,
          slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_MONTHLY_PREMIUM
        },
        {
          coll_type: COLLECTION_TYPE__CREDIT_HOMEOWNER,
          slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
        },
        {
          coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
          slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT
        }
      ];
    }
  } else if (question.slug === SLUG__PA_USE_TAX_PURCHASES) {
    newQueries = [
      ...newQueries,
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_JOB_NAME
      }
    ];
  } else if (question.question_type !== CATEGORY_TYPE_NAVIGATION) {
    newQueries = [
      {
        coll_type: question.collectionType,
        coll_id: collectionId,
        slug: question.endpoint_attr
      }
    ];
  }

  newQueries = [
    ...newQueries,
    // For tax status
    {
      coll_type: COLLECTION_TYPE__SELF,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS
    },
    {
      coll_type: COLLECTION_TYPE__SELF,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION
    },
    {
      coll_type: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED
    },
    {
      coll_type: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED
    },
    {
      coll_type: COLLECTION_TYPE__SELF,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
    },
    {
      coll_type: COLLECTION_TYPE__HOME_ADDRESS,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE
    },
    {
      coll_type: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    },
    {
      coll_type: COLLECTION_TYPE__REVIEW,
      coll_id: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__REVIEW_STARTED
    },
    ...getSummaryQueries({ question, allCollectionTypes }),
    ...getQuestionQueriesNavigation({ question }),
    ...getQuestionQueriesCar({ question, collectionId }),
    ...getQuestionQueriesCredit({ question, collectionId }),
    ...getQuestionQueriesHome({ question, collectionId }),
    ...getQuestionQueriesIncome({ question, collectionId }),
    ...getQuestionQueriesPersonal({ question, collectionId }),
    ...getQuestionQueriesSpecial({ question }),
    ...getQuestionQueriesState({ question }),
    ...getQuestionQueriesSubmit({ question })
  ];

  return newQueries;
};

export const getQuestionQueryResults = ({ allQuestions, items }) => {
  return _.flatMap(items, (item) => {
    if (item.coll_type === COLLECTION_TYPE__STATE_INCOME || item.coll_type === COLLECTION_TYPE__STATE_EXPENSES) {
      if (item.slug.endsWith('started') || item.slug.endsWith('done')) {
        return {
          coll_type: item.coll_type,
          coll_id: item.coll_id,
          slug: item.slug,
          answer: {
            value: item.value
          }
        };
      } else {
        return {
          coll_type: item.coll_type,
          coll_id: item.coll_id,
          slug: item.slug,
          answer: {
            value: parseFloat(item.value)
          }
        };
      }
    }
    if (item.slug === SLUG__INCOME_INVEST_UNIFICATION) {
      return {
        coll_type: item.coll_type,
        coll_id: item.coll_id,
        slug: item.slug,
        answer: {
          value: JSON.parse(item.value)
        }
      };
    }
    const questionForItem = getQuestionByEndpointAttribute({
      allQuestions,
      endpointAttribute: item.slug
    });
    if (!questionForItem) {
      return [];
    }
    return [
      {
        coll_type: item.coll_type,
        coll_id: item.coll_id,
        slug: item.slug,
        answer: deserializeQuestionAnswer({
          question: questionForItem,
          value: item.value
        })
      }
    ];
  });
};

// certain questions should be reformatted or don't get sent into database at all. that happens here
export const getQuestionUpdates = ({
  question,
  answer,
  queryResults,
  collectionType,
  collectionId,
  user,
  jobCategories,
  businessCode,
  bulkUploadItems
}) => {
  if (question.slug === SLUG__CAR_OPTIONS && _.get(answer, 'value') === 'no_car') {
    return [];
  }

  if (
    question.slug === HOME_SLUGS.OPTIONS &&
    (_.get(answer, 'value') === 'free_housing' || _.get(answer, 'value') === 'no_home')
  ) {
    return [];
  }

  if (question.slug === SLUG__SUBMIT_DEBIT) {
    return [
      {
        coll_type: COLLECTION_TYPE__SUBMIT_DEBIT,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD,
        value: _.get(answer, 'value')
      }
    ];
  }

  if (question.slug === SLUG__STATE_INCOME || question.slug === SLUG__STATE_EXPENSES) {
    const answerVal = _.get(answer, ['value']);
    const collectionType =
      question.slug === SLUG__STATE_INCOME ? COLLECTION_TYPE__STATE_INCOME : COLLECTION_TYPE__STATE_EXPENSES;
    const taxData = Object.keys(answerVal).map((subAnswer) => {
      return {
        coll_type: collectionType,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: _.get(answerVal, [subAnswer, 'slug']),
        value: _.get(answerVal, [subAnswer, 'value'])
      };
    });
    return taxData;
  }

  if (question.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
    return [
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE,
        value: _.get(answer, ['value', SLUG__INCOME_FREELANCE_BUSINESS_CODE, 'value'])
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_INDUSTRY,
        value: _.get(answer, ['value', SLUG__INCOME_FREELANCE_INDUSTRY, 'value'])
      }
    ];
  }

  let updates = [];
  if (question.slug === SLUG__INCOME_INVEST_INFO) {
    const collectionIds = Object.keys(_.get(answer, 'value'));
    collectionIds.forEach((collId) => {
      updates = [
        ...updates,
        {
          coll_type: COLLECTION_TYPE__INCOME_INVEST,
          coll_id: collId,
          slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED,
          value: '1'
        },
        ..._.flatMap(question.sub_question, (subQuestion) => {
          const subAnswer = _.get(answer, ['value', collId, subQuestion.slug]);
          const serializedSubAnswer = serializeQuestionAnswer({ question: subQuestion, answer: subAnswer });

          if (_.isNil(serializedSubAnswer)) {
            return [
              {
                coll_type: subQuestion.collectionType,
                coll_id: collId,
                slug: subQuestion.endpoint_attr,
                value: '',
                error: true
              }
            ];
          } else {
            return [
              {
                coll_type: subQuestion.collectionType,
                coll_id: collId,
                slug: subQuestion.endpoint_attr,
                value: serializedSubAnswer
              }
            ];
          }
        })
      ];
    });

    const collIdsNumeric = collectionIds.map((collId) => Number(collId));
    updates = [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__INCOME_INVEST,
        coll_id: Math.min(...collIdsNumeric),
        slug: SLUG__INCOME_INVEST_UNIFICATION,
        value: JSON.stringify(collIdsNumeric)
      }
    ];
    return updates;
  }

  if (question.question_type === CATEGORY_TYPE_BOUNCE) {
    updates = [
      {
        coll_type: question.collectionType,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: question.endpoint_attr,
        value: '1'
      }
    ];
  } else if (
    question.question_type === CATEGORY_TYPE_TAXFLOW_INFO ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_SYSTEM ||
    question.question_type === CATEGORY_TYPE_SUMMARY ||
    question.question_type === CATEGORY_TYPE_NAVIGATION
  ) {
    updates = [];
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    updates = _.flatMap(question.sub_question, (subQuestion) => {
      const subAnswer = _.get(answer, ['value', subQuestion.slug]);
      const serializedSubAnswer = serializeQuestionAnswer({ question: subQuestion, answer: subAnswer });
      if (_.isNil(serializedSubAnswer)) {
        return [
          {
            coll_type: subQuestion.collectionType,
            coll_id: collectionId,
            slug: subQuestion.endpoint_attr,
            value: '',
            error: true
          }
        ];
      } else {
        return [
          {
            coll_type: subQuestion.collectionType,
            coll_id: collectionId,
            slug: subQuestion.endpoint_attr,
            value: serializedSubAnswer
          }
        ];
      }
    });
  } else {
    updates = [
      {
        coll_type: question.collectionType,
        coll_id: collectionId,
        slug: question.endpoint_attr,
        value: serializeQuestionAnswer({ question, answer })
      }
    ];
  }

  // manually add state-return-started flags when user clicks next on state-return select screen
  if (question.slug === SLUG__STATE_RETURN) {
    updates = [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_COLL_TYPE_STARTED,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_STARTED,
        value: '1'
      }
    ];
  }

  // if we have a business code associated with the job, overwrite business code
  if (question.slug === SLUG__INCOME_FREELANCE_JOB) {
    const jobName = _.get(answer, ['value', SLUG__INCOME_FREELANCE_JOB_NAME, 'value']);
    const jobCategory = jobCategories.find((j) => j.slug === jobName);
    const jobBusinessCode = (jobCategory && jobCategory.businessCode) || businessCode;
    if (jobBusinessCode) {
      updates = [
        ...updates,
        {
          coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
          coll_id: collectionId,
          slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE,
          value: businessCode
        }
      ];
    }
  }

  if (question.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
    updates = [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_COLLECTION_TYPE_STARTED,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_STARTED,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT,
        value: _.get(answer, ['value', ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY, 'value'])
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_CHARITY,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_DONE,
        value: '1'
      }
    ];
  }

  if (question.slug === SLUG__CREDIT_STANDARD_RESULT) {
    updates = [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: collectionId,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_DEDUCTION,
        value: 'standard'
      }
    ];
  }

  const subType = _.get(
    {
      [SLUG__INCOME_FREELANCE_1099_MISC]: 'misc',
      [SLUG__INCOME_FREELANCE_1099_NEC]: 'nec',
      [SLUG__INCOME_FREELANCE_1099K_INFO]: 'k',
      [SLUG__INCOME_RETIREMENT_PENSION_INFO]: 'pension',
      [SLUG__INCOME_RETIREMENT_SSA_INFO]: 'ssa'
    },
    question.slug
  );

  if (!_.isNil(subType)) {
    const bulkUploadItem = _.find(bulkUploadItems, { collectionType, subType });
    if (
      !_.isNil(bulkUploadItem.subTypeDifferentiationSlug) &&
      !_.isNil(bulkUploadItem.subTypeDifferentiationSlugValue)
    ) {
      updates = [
        ...updates,
        {
          coll_type: collectionType,
          coll_id: collectionId,
          slug: bulkUploadItem.subTypeDifferentiationSlug,
          value: bulkUploadItem.subTypeDifferentiationSlugValue
        }
      ];
    }
  }

  const filingStatusSlugs = new Set([
    ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS,
    ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION,
    ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED
  ]);

  if (updates.some((update) => filingStatusSlugs.has(_.get(update, 'slug')))) {
    const filingStatus = getFilingStatus({ updates, queryResults });

    if (filingStatus) {
      updates = [
        ...updates,
        {
          coll_type: COLLECTION_TYPE__SELF,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
          value: filingStatus
        }
      ];
      setUserWithObj(user.phone, { tax_filing_status: filingStatus });
    }
  }

  return updates;
};

export const getQuestionDelete = ({ question, collectionId, answer, updates, queryResults, businessCode }) => {
  const deletions = [];

  if (BULK_UPLOAD_FORM_PREDICT_QUESTIONS.includes(question.slug)) {
    const hasCollection =
      question.slug === SLUG__BULK_UPLOAD_HAS_CHARITY
        ? _.get(answer, ['value', SLUG__BULK_UPLOAD_CHARITY_OPTION, 'value'])
        : _.get(answer, 'value');
    if (hasCollection === '0') {
      deletions.push(
        {
          coll_type: _.get(question, ['collectionTypeStarted', 'fields', 'collectionType']),
          coll_id: collectionId
        },
        {
          coll_type: _.get(question, ['collectionTypeStarted', 'fields', 'collectionType']),
          coll_id: DEFAULT_COLLECTION_ID,
          slug: _.get(question, ['collectionTypeStarted', 'fields', 'endpointAttribute'])
        }
      );
    }
  }

  if (question.slug === SLUG__CAR_OPTIONS && _.get(answer, 'value') === 'no_car') {
    deletions.push({
      coll_type: COLLECTION_TYPE__CAR,
      coll_id: collectionId
    });
  }

  if (
    question.slug === HOME_SLUGS.OPTIONS &&
    (_.get(answer, 'value') === 'free_housing' || _.get(answer, 'value') === 'no_home')
  ) {
    deletions.push({
      coll_type: COLLECTION_TYPE__HOME,
      coll_id: collectionId
    });
  }

  if (question.slug === SLUG__DEPENDENT_UNQUALIFIED) {
    deletions.push({
      coll_type: COLLECTION_TYPE__DEPENDENT,
      coll_id: collectionId
    });
  }

  if (question.slug === SLUG__DEPENDENTS && _.get(answer, 'value') === '0') {
    deletions.push({
      coll_type: COLLECTION_TYPE__DEPENDENT,
      coll_id: '1'
    });
  }

  if (question.slug === SLUG__CREDIT_DISALLOWED && _.get(answer, 'value') === '0') {
    deletions.push({
      coll_type: COLLECTION_TYPE__CREDIT_DISALLOWED,
      coll_id: collectionId,
      slug: SLUG__CREDIT_DISALLOWED_NAMES
    });
  }

  if (question.slug === SLUG__SELF_MARRIAGE && _.get(answer, ['value', SLUG__SELF_MARRIAGE_STATUS, 'value']) === '0') {
    deletions.push({
      coll_type: COLLECTION_TYPE__SELF,
      coll_id: collectionId,
      slug: SLUG__SELF_MARRIAGE_FILING_OPTION
    });

    deletions.push({
      coll_type: COLLECTION_TYPE__SPOUSE
    });
  }

  if (question.slug === SLUG__INCOME_FREELANCE_JOB) {
    if (!businessCode) {
      deletions.push({
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_BUSINESS_CODE
      });
      deletions.push({
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        coll_id: collectionId,
        slug: SLUG__INCOME_FREELANCE_INDUSTRY
      });
    }
  }
  const lastAnswer = getQueryResultValueByEndpointattribute({
    queryResults,
    collectionType: question.collectionType,
    collectionId,
    slug: question.slug
  });

  if (
    question.slug === SLUG__CREDIT_STANDARD_RESULT ||
    (question.slug === SLUG__CREDIT_STANDARD_DEDUCTION && _.get(answer, 'value') !== lastAnswer)
  ) {
    deletions.push(
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_HEALTH_INSURANCE
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MEDICAL
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MORTGAGE_INTEREST
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_STATE_REAL_ESTATE
      },
      {
        coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_GAMBLING_LOSSES
      }
    );
  }

  const filingStatus = getFilingStatus({ updates, queryResults });

  if (_.isNil(filingStatus)) {
    deletions.push({
      coll_type: COLLECTION_TYPE__SELF,
      slug: SLUG__SELF_TAX_STATUS
    });
  }

  return deletions;
};

export const getStartedUpdates = ({ question, collectionId, stateTaxSlugs, queryResults }) => {
  // if screen is the first screen in a state return tax flow, then get the coll id of the state return
  // then manually add the state-return-started flag w the correct coll id
  const stateCollId = getStateReturnCollId({
    firstScreenSlug: question.slug,
    collectionId,
    queryResults,
    stateTaxSlugs
  });
  if (stateCollId) {
    return [
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: stateCollId,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_STARTED,
        value: '1'
      },
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_COLL_TYPE_STARTED,
        value: '1'
      }
    ];
  }

  const incomeInvestUpdateSlug = getFormUploadEnabledMap()[PATH_COMPONENT__INCOME_INVEST_FORM_UPLOAD]
    ? SLUG__INCOME_INVEST_FORM_UPLOAD
    : SLUG__INCOME_INVEST_INFO;

  const updates = [
    ...(question.started
      ? [
          {
            coll_type: _.get(question, ['started', 'fields', 'collectionType']),
            coll_id: collectionId,
            slug: _.get(question, ['started', 'fields', 'endpointAttribute']),
            value: '1'
          }
        ]
      : []),
    ...(question.started && question.slug === incomeInvestUpdateSlug
      ? [
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: collectionId,
            slug: SLUG__INCOME_INVEST_CATEGORY,
            value: 'S'
          },
          {
            coll_type: _.get(question, ['started', 'fields', 'collectionType']),
            coll_id: `${Number(collectionId) + 1}`,
            slug: _.get(question, ['started', 'fields', 'endpointAttribute']),
            value: '1'
          },
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: `${Number(collectionId) + 1}`,
            slug: SLUG__INCOME_INVEST_CATEGORY,
            value: 'L'
          },
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: `${Number(collectionId) + 2}`,
            slug: SLUG__INCOME_INVEST_CATEGORY,
            value: 'U'
          },
          {
            coll_type: _.get(question, ['started', 'fields', 'collectionType']),
            coll_id: `${Number(collectionId) + 2}`,
            slug: _.get(question, ['started', 'fields', 'endpointAttribute']),
            value: '1'
          },
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: collectionId,
            slug: SLUG__INCOME_INVEST_UNIFICATION,
            value: JSON.stringify([Number(collectionId), Number(collectionId) + 1, Number(collectionId) + 2])
          }
        ]
      : []),
    ...(question.collectionTypeStarted
      ? [
          {
            coll_type: _.get(question, ['collectionTypeStarted', 'fields', 'collectionType']),
            coll_id: DEFAULT_COLLECTION_ID,
            slug: _.get(question, ['collectionTypeStarted', 'fields', 'endpointAttribute']),
            value: '1'
          }
        ]
      : [])
  ];

  if (_.find(updates, { slug: ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED })) {
    const filingStatus = getFilingStatus({ updates, queryResults });

    return [
      ...updates,
      {
        coll_type: COLLECTION_TYPE__SELF,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
        value: filingStatus
      }
    ];
  }

  return updates;
};

export const getDoneUpdates = ({ question, collectionId }) => {
  // for state returns that have specific flows
  if (_.get(question, ['template', 'slug']) === SLUG__TEMPLATE_STATE_DONE) {
    return [
      {
        coll_type: COLLECTION_TYPE__STATE_RETURN,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_DONE,
        value: '1'
      }
    ];
  }

  if (!question.done) {
    return [];
  }

  const collectionType = _.get(question, ['done', 'fields', 'collectionType']);

  // for state returns with no specific flows
  // (i.e. state returns that go to state-default / state-no-income-tax)
  if (collectionType === COLLECTION_TYPE__STATE_RETURN) {
    return [
      {
        coll_type: collectionType,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: _.get(question, ['done', 'fields', 'endpointAttribute']),
        value: '1'
      }
    ];
  }

  return [
    {
      coll_type: collectionType,
      coll_id: collectionId,
      slug: _.get(question, ['done', 'fields', 'endpointAttribute']),
      value: '1'
    }
  ];
};

export const getQuestionById = ({ allQuestions, slug }) => {
  return allQuestions.find((question) => {
    return question.slug === slug;
  });
};

export const getDerivedQuestion = ({ question, jobOptions }) => {
  if (question.slug === SLUG__INCOME_FREELANCE_JOB_NAME) {
    return {
      ...question,
      question_meta: jobOptions
    };
  }

  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM && question?.sub_question?.map) {
    return {
      ...question,
      sub_question: _.map(question.sub_question, (q) => getDerivedQuestion({ question: q, jobOptions }))
    };
  }

  return question;
};

const getQuestionByEndpointAttribute = ({ allQuestions, endpointAttribute }) => {
  return allQuestions.find((question) => question.endpoint_attr === endpointAttribute);
};

export const isQuestionAnswerEmpty = ({ question, answer }) => {
  const value = _.get(answer, ['value']);
  if (
    question.question_type === CATEGORY_TYPE_OPTIONS ||
    question.question_type === CATEGORY_TYPE_TEXT ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SLIDER ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_EMAIL ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PHONE ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SSN ||
    question.question_type === CATEGORY_TYPE_INITIAL ||
    question.question_type === CATEGORY_TYPE_MILES ||
    question.question_type === CATEGORY_TYPE_YEARS ||
    question.question_type === CATEGORY_TYPE_PERCENT ||
    question.question_type === CATEGORY_TYPE_SQUARE_FOOTAGE ||
    question.question_type === CATEGORY_TYPE_EIN ||
    question.question_type === CATEGORY_TYPE_SSN_OR_EIN ||
    question.question_type === CATEGORY_TYPE_ZIP ||
    question.question_type === CATEGORY_TYPE_INT ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_NUMBER ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE ||
    question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN ||
    question.question_type === CATEGORY_TYPE_STATE ||
    question.question_type === CATEGORY_TYPE_MONEY
  ) {
    if (_.isNumber(value) && !_.isNaN(value)) {
      return false;
    } else {
      return _.isEmpty(value);
    }
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR) {
    if (_.isEmpty(question.question_meta)) {
      return false;
    }

    return _.isEmpty(value);
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    return question.sub_question.every((subQuestion) => {
      const subAnswer = _.get(answer, ['value', subQuestion.slug]);
      return isQuestionAnswerEmpty({ question: subQuestion, answer: subAnswer });
    });
  } else if (question.slug === SLUG__SUBMIT_DEBIT) {
    return _.isEmpty(value);
  } else {
    return false;
  }
};

export const isTaxfilingNonskippable = (question) => {
  return [
    SLUG__SUBMIT_EMAIL_INFO,
    SLUG__STATE_RETURN,
    SLUG__SUBMIT_DEBIT,
    SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
    SLUG__DC_RENT_CREDIT_OPTIONS,
    SLUG__SUBMIT_SIGNATURE,
    SLUG__CREDIT_DISALLOWED_NAMES,
    SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
    SLUG__CREDIT_STUDENT_TUITION_QUALIFY
  ].includes(question.slug);
};

// if false then hide back button
export const isPrev = ({ slug }) => {
  if (
    slug === SLUG__SPECIAL_WHO ||
    slug === SLUG__SUBMIT_BYE ||
    slug === SLUG__SUBMIT_BYE_MANUAL ||
    slug === SLUG__SUBMIT_BYE_MANUAL_ESC ||
    slug === SLUG__SUBMIT_EMAIL_OUTBOX ||
    slug === SLUG__SUBMIT_BLOCKED ||
    slug === SLUG__SUBMIT_BLOCKED_FINAL_REVIEW ||
    slug === SLUG__SUBMIT_TAX_AMOUNT ||
    slug === SLUG__SUBMIT_SENT_TO_IRS ||
    slug === SLUG__SUBMIT_ACCEPTED_BY_IRS ||
    slug === SLUG__SUBMIT_FILED_WITH_IRS ||
    slug === SLUG__SUBMIT_UNABLE_TO_FILE ||
    slug === SLUG__SUBMIT_AUDIT_ISSUE ||
    slug === SLUG__SUBMIT_IRS_ISSUE ||
    slug === SLUG__SETTINGS ||
    slug === SLUG__LINKED_ACCOUNTS ||
    slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
  ) {
    return false;
  }

  return true;
};

export const showNextBtn = ({ slug }) => {
  if (
    slug === SLUG__SUBMIT_BYE ||
    slug === SLUG__SUBMIT_BYE_MANUAL ||
    slug === SLUG__SUBMIT_BYE_MANUAL_ESC ||
    slug === SLUG__SUBMIT_EMAIL_OUTBOX ||
    slug === SLUG__SUBMIT_BLOCKED ||
    slug === SLUG__SUBMIT_BLOCKED_FINAL_REVIEW ||
    slug === SLUG__SUBMIT_SENT_TO_IRS ||
    slug === SLUG__SUBMIT_ACCEPTED_BY_IRS ||
    slug === SLUG__SUBMIT_UNABLE_TO_FILE ||
    slug === SLUG__SETTINGS ||
    slug === SLUG__LINKED_ACCOUNTS ||
    slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
  ) {
    return false;
  }

  return true;
};

export const getNextButtonLabel = ({ question }) => {
  return _.get(question, ['nextButtonLabel'], 'Next');
};

export const getAllowedParams = ({ slug, currentSlug }) => {
  if (
    currentSlug === SLUG__BULK_UPLOAD_MANUAL_ENTRY ||
    [
      HOME_SLUGS.OPTIONS,
      HOME_SLUGS.DURATION,
      HOME_SLUGS.EXPENSES_RENT,
      HOME_SLUGS.EXPENSES_OWN,
      HOME_SLUGS.TYPE,
      HOME_SLUGS.ACQUIRED,
      HOME_SLUGS.VALUE,
      HOME_SLUGS.PAST,
      HOME_SLUGS.DEPRECIATION,
      HOME_SLUGS.DEPRECIATION_TOTAL,
      HOME_SLUGS.DEPRECIATION_CONFIRMATION,
      HOME_SLUGS.DONE,
      HOME_SLUGS.OFFICE_SIZE,
      SLUG__CAR_OPTIONS,
      SLUG__CAR_BRAND,
      SLUG__CAR_SERVICE_DATE,
      SLUG__CAR_OTHER_PERSONAL,
      SLUG__CAR_LEASE,
      SLUG__CAR_PURCHASE_DATE,
      SLUG__CAR_NEW_USED,
      SLUG__CAR_COST,
      SLUG__CAR_LOAN,
      SLUG__CAR_WORK_MILES_TRACKED,
      SLUG__CAR_WORK_MILES,
      SLUG__CAR_TOTAL_MILES,
      SLUG__CAR_PERCENT,
      SLUG__CAR_MILES_CONFIRMATION,
      SLUG__CAR_STANDARD_MILEAGE,
      SLUG__CAR_PAST_DEPRECIATION,
      SLUG__CAR_YEARS_DEPRECIATION,
      SLUG__CAR_DEPRECIATION_CONFIRMATION,
      SLUG__DEPENDENT_DETAIL,
      SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
      SLUG__DEPENDENT_QUALIFIED,
      SLUG__DEPENDENT_UNQUALIFIED,
      SLUG__INCOME_FREELANCE_1099_TYPE,
      SLUG__INCOME_FREELANCE_JOB,
      SLUG__INCOME_FREELANCE_BUSINESS_CODE_INFO,
      SLUG__INCOME_FREELANCE_AGI,
      SLUG__INCOME_FREELANCE_1099_NEC,
      SLUG__INCOME_FREELANCE_1099_MISC,
      SLUG__INCOME_W2_INFO,
      SLUG__INCOME_W2_FORM_UPLOAD,
      SLUG__INCOME_W2_PREFILL_LOADING,
      SLUG__INCOME_W2_TWELVE_A,
      SLUG__INCOME_W2_TWELVE_B,
      SLUG__INCOME_W2_TWELVE_C,
      SLUG__INCOME_W2_TWELVE_D,
      SLUG__INCOME_UNEMPLOYMENT_INFO,
      SLUG__INCOME_UNEMPLOYMENT_FORM_UPLOAD,
      SLUG__INCOME_INTEREST_INFO,
      SLUG__INCOME_INTEREST_FORM_UPLOAD,
      SLUG__INCOME_DIV_INFO,
      SLUG__INCOME_DIV_FORM_UPLOAD,
      SLUG__INCOME_INVEST_INFO,
      SLUG__INCOME_INVEST_MORE_INFO,
      SLUG__INCOME_INVEST_FORM_UPLOAD,
      SLUG__CREDIT_HSA_DETAIL,
      SLUG__CREDIT_HSA_DETAIL_FAMILY,
      SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
      SLUG__CREDIT_STUDENT_LOAN_DETAIL,
      SLUG__CREDIT_STUDENT_TUITION_QUALIFY,
      SLUG__CREDIT_STUDENT_TUITION_DETAIL,
      SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
      SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
      SLUG__CREDIT_CHILD_CARE_DETAIL,
      SLUG__CREDIT_HOMEOWNER_DETAIL,
      SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
      SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
      SLUG__STATE_RETURN,
      SLUG__STATE_DEFAULT,
      SLUG__STATE_NO_INCOME_TAX,
      SLUG__CA_HEALTHCARE_DEPENDENT_DURATION,
      SLUG__DC_HEALTHCARE_DEPENDENT_DURATION,
      SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION,
      SLUG__RI_HEALTHCARE_DEPENDENT_DURATION,
      SLUG__INCOME_RETIREMENT_TYPE,
      SLUG__INCOME_RETIREMENT_PENSION_FORM_UPLOAD,
      SLUG__INCOME_RETIREMENT_SSA_FORM_UPLOAD,
      SLUG__INCOME_RETIREMENT_SSA_INFO,
      SLUG__INCOME_RETIREMENT_PENSION_INFO,
      SLUG__INCOME_FREELANCE_1099_MISC_FORM_UPLOAD,
      SLUG__INCOME_FREELANCE_1099_NEC_FORM_UPLOAD,
      SLUG__INCOME_FREELANCE_1099K_INFO,
      SLUG__INCOME_FREELANCE_1099K_EXPENSES_INFO,
      SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL
    ].indexOf(slug) !== -1
  ) {
    return ['collectionId', 'origin'];
  } else {
    return ['origin'];
  }
};

export const getTemplateQuestions = (questions) => {
  return questions.map((question) => {
    if (question.question_type !== CATEGORY_TYPE_TEMPLATE) {
      return question;
    }

    return {
      ...question,
      ..._.pick(question.template, ['title', 'summary', 'examples', 'question_type', 'question_meta'])
    };
  });
};

const getDynamicYouPossessive1099 = ({ user1099Income, spouse1099Income, spouseName }) => {
  if (user1099Income && spouse1099Income) {
    return `your and ${spouseName}'s`;
  } else if (spouse1099Income) {
    return `${spouseName}'s`;
  }
  return 'your';
};

export const getSubstitutions = ({
  question,
  queryResults,
  collectionId,
  taxFilePrice,
  workDetails,
  taxAmounts,
  jobCategories,
  deductions,
  taxState,
  savings,
  submitEnabled,
  homeOfficeAmounts
}) => {
  const status = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__SELF,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
  });

  const states = _.chain(
    getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    })
  )
    .map('answer.value')
    .value();

  const taxableStates = _.filter(states, (state) => !NO_INCOME_TAX_STATES.has(state));

  const state1Code = _.get(states, 0);
  const state1 = STATE_NAME_MAP[state1Code];
  const state2Code = _.get(states, 1);
  const state2 = STATE_NAME_MAP[state2Code];

  const stateCode = taxState;
  const state = STATE_NAME_MAP[stateCode];
  const specialState = STATE_NAME_MAP[workDetails.state_residence];

  const dependent = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__DEPENDENT,
    collectionId,
    slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
  });

  const healthcareOptions = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CREDIT_HEALTHCARE,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS
  });
  const healthcareOptionsValue = _.get(healthcareOptions, ['answer', 'value']);
  let coverage;
  if (healthcareOptionsValue === '0') {
    coverage = `Since your household didn’t have insurance, we’ll calculate the amount and apply it to your ${stateCode} return.`;
  } else if (healthcareOptionsValue === '1') {
    coverage = 'Since your entire household had healthcare, you’re good to go!';
  } else if (healthcareOptionsValue === '2') {
    coverage =
      'Since some members of your household had insurance, we need more information to determine if the penalty applies to you.';
  } else if (!healthcareOptionsValue) {
    coverage = `Complete healthcare information in credits section to continue your ${state} taxes.`;
  }

  const standardDeduction = _.get(deductions, 'standardDeduction');
  const itemizedDeduction = _.get(deductions, 'itemizedDeduction');

  const SIMPLIFIED_METHOD_CAP = 1500;

  const homeRegularAmount = _.get(homeOfficeAmounts, 'regularAmount');
  const homeSimplifiedAmount = _.get(homeOfficeAmounts, 'simplifiedAmount');
  const homeSimplifiedAmountAboveCap = _.get(homeOfficeAmounts, 'simplifiedAmountAboveCap');
  const homeSimplifiedAmountCapped = _.min([homeSimplifiedAmount, SIMPLIFIED_METHOD_CAP]);
  const homeSimplifiedAboveBelowCap = homeSimplifiedAmountAboveCap ? 'above' : 'below';
  const homeDoneDescription = (() => {
    if (homeRegularAmount === homeSimplifiedAmountCapped) {
      return "You save the same amount using either method. We'll use the simplified method for your return.";
    } else if (homeRegularAmount > homeSimplifiedAmountCapped) {
      return "We'll use the regular method to save you more";
    } else {
      return "We'll use the simplified method to save you more";
    }
  })();

  const mobilePrice = '89.99';
  const webPrice = taxFilePrice ? taxFilePrice : '89';

  const isBill =
    taxAmounts &&
    (_.get(taxAmounts, 'federal_amount') >= 0 || _.get(taxAmounts, 'state_amounts', []).some((amount) => amount >= 0));

  const currentJob = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
    collectionId,
    slug: SLUG__INCOME_FREELANCE_JOB_NAME
  });
  const knownJobCategory = getKnownJobInfo(jobCategories, _.get(currentJob, ['answer', 'value']));
  const knownJobName = _.get(knownJobCategory, ['name'], '');

  const spouseNameResult = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__SPOUSE,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME
  });
  const spouseName = !_.isEmpty(_.get(spouseNameResult, ['answer', 'value'], ''))
    ? capitalize(_.get(spouseNameResult, ['answer', 'value']))
    : 'your spouse';

  const payersName = _.get(
    getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
      collectionId,
      slug: SLUG__INCOME_FREELANCE_PAYERS_NAME
    }),
    ['answer', 'value']
  );

  const [selfFirstName, selfLastName] = [SLUG__SELF_FIRST_NAME, SLUG__SELF_LAST_NAME].map((slug) =>
    _.get(
      getQueryResultByEndpointAttribute({
        queryResults,
        collectionType: 'self',
        collectionId: DEFAULT_COLLECTION_ID,
        slug
      }),
      ['answer', 'value']
    )
  );
  const selfFullName = `${selfFirstName} ${selfLastName}`;

  const freelanceIncomeQueries = getQueryResultsByEndpointAttribute({
    queryResults,
    collectionType: 'income-freelance',
    slug: 'income-freelance-income'
  });

  const freelanceIncomeSum = _.sumBy(freelanceIncomeQueries, (query) => {
    const value = _.get(query, ['answer', 'value'], 0);
    return parseInt(value, 10);
  });
  const qbiSavings = 0.2 * freelanceIncomeSum;

  const filingStatus = getFilingStatus({ queryResults });

  const user1099Income = workDetails.agi && workDetails.agi !== workDetails.w2_income_amount;
  const spouse1099Income = workDetails.spouse_agi && workDetails.spouse_agi !== workDetails.spouse_w2_income;

  return [
    {
      name: '{work}',
      value: DEFAULT_WORK
    },
    {
      name: '{currentJob}',
      value: knownJobName ? knownJobName.toLowerCase() : _.get(currentJob, ['answer', 'value'])
    },
    {
      name: '%s',
      value: _.get(status, ['answer', 'value']) === 'married' ? '(or your spouse)' : ''
    },
    {
      name: '{household}',
      value: _.get(status, ['answer', 'value']) !== 'single' ? 'you' : 'your household'
    },
    {
      name: '{taxFilingYear}',
      value: TAX_FILING_YEAR
    },
    {
      name: '{lastTaxFilingYear}',
      value: LAST_TAX_FILING_YEAR
    },
    {
      name: '{price}',
      value: isReactNative() ? mobilePrice : webPrice
    },
    {
      name: '{stateCode}',
      value: stateCode ? stateCode : ''
    },
    {
      name: '{stateName}',
      value: state || ''
    },
    {
      name: '{state1}',
      value: state1 || ''
    },
    {
      name: '{state2}',
      value: state2 || ''
    },
    {
      name: '{state1Code}',
      value: state1Code ? state1Code : ''
    },
    {
      name: '{state2Code}',
      value: state2Code ? state2Code : ''
    },
    {
      name: '{specialState}',
      value: specialState || ''
    },
    {
      name: '{dependent}',
      value: !_.isEmpty(_.get(dependent, ['answer', 'value'], ''))
        ? capitalize(_.get(dependent, ['answer', 'value']))
        : 'this person'
    },
    {
      name: '{standardType}',
      value: `${_.get(deductions, 'itemized') ? 'Itemized' : 'Standard'} deduction`
    },
    {
      name: '{standardDeduction}',
      value: currencyWith0DecimalPlaces(standardDeduction)
    },
    {
      name: '{itemizedDeduction}',
      value: currencyWith0DecimalPlaces(itemizedDeduction)
    },
    {
      name: '{standardOrItemizedAmount}',
      value: `${_.get(deductions, 'itemized') ? currencyWith0DecimalPlaces(itemizedDeduction) : currencyWith0DecimalPlaces(standardDeduction)}`
    },
    {
      name: '{submitURL}',
      value: PATH_COMPONENT__SUBMIT_FINISH
    },
    {
      name: '{fileButtonLabel}',
      value: 'File my Return'
    },
    {
      name: '{coverage}',
      value: coverage
    },
    {
      name: '{spouseName}',
      value: spouseName
    },
    {
      name: '{billOrRefund}',
      value: isBill ? 'bill' : 'refund'
    },
    {
      name: '{irsAndState}',
      value: (() => {
        const taxEntities = ['the IRS', ...taxableStates];

        if (taxEntities.length === 1) {
          return taxEntities[0];
        }

        return `${_.dropRight(taxEntities).join(', ')} and ${_.last(taxEntities)}`;
      })()
    },
    {
      name: '{savings}',
      value: !!savings ? `(${currencyWith0DecimalPlaces(savings)})` : ''
    },
    {
      name: '{payersName}',
      value: payersName
    },
    {
      name: '{selfFullName}',
      value: selfFullName
    },
    { name: '{qbiSavings}', value: currencyWith0DecimalPlaces(qbiSavings) },
    {
      name: '{homeDeductionAmount}',
      value: currencyWith0DecimalPlaces(_.max([homeRegularAmount, homeSimplifiedAmountCapped]))
    },
    {
      name: '{homeDoneDescription}',
      value: homeDoneDescription
    },
    {
      name: '{homeMonthlyExpenses}',
      value: currencyWith0DecimalPlaces(homeOfficeAmounts.monthlyExpenses)
    },
    { name: '{homeBusinessUsePercent}', value: `${(homeOfficeAmounts.businessUseRatio * 100).toFixed(2)}%` },
    { name: '{homeDuration}', value: homeOfficeAmounts.homeDuration },
    { name: '{homeRegularAmount}', value: currencyWith0DecimalPlaces(homeRegularAmount) },
    { name: '{homeWorkSpaceSize}', value: homeOfficeAmounts.workSpaceSize },
    { name: '{homeDurationPercent}', value: `${(homeOfficeAmounts.durationRatio * 100).toFixed(2)}%` },
    { name: '{homeSimplifiedAmount}', value: currencyWith0DecimalPlaces(homeSimplifiedAmount) },
    { name: '{homeSimplifiedAboveBelowCap}', value: homeSimplifiedAboveBelowCap },
    { name: '{homeSimplifiedCap}', value: currencyWith0DecimalPlaces(SIMPLIFIED_METHOD_CAP) },
    { name: '{dynamicI}', value: filingStatus === 'married' ? 'We' : 'I' },
    { name: '{my/our}', value: filingStatus === 'married' ? 'our' : 'my' },
    {
      name: '{dynamicYouPossessive1099}',
      value: getDynamicYouPossessive1099({ user1099Income, spouse1099Income, spouseName })
    },
    ...(_.includes([SLUG__SUBMIT_FINISH, SLUG__SUBMIT_FINISH, SLUG__SUBMIT_FINISH_LATER], _.get(question, 'slug'))
      ? [
          {
            name: '{title}',
            value: submitEnabled
              ? _.get(question, ['question_meta', 'submitEnabled', 'title'])
              : _.get(question, ['question_meta', 'submitDisabled', 'title'])
          },
          {
            name: '{description}',
            value: submitEnabled
              ? _.get(question, ['question_meta', 'submitEnabled', 'description'])
              : _.get(question, ['question_meta', 'submitDisabled', 'description'])
          },
          {
            name: '{nextButtonLabel}',
            value: submitEnabled && _.get(question, 'slug') === SLUG__SUBMIT_FINISH ? 'Send to review!' : 'Next'
          }
        ]
      : [])
  ];
};

export const getStringWithSubstitutions = ({ str, substitutions }) => {
  return substitutions.reduce(
    (result, substitution) => result.replace(new RegExp(substitution.name, 'g'), substitution.value),
    str
  );
};

export const getQuestionWithSubstitutions = ({ question, substitutions }) => {
  let examples = question.examples;
  if (question.examples) {
    examples = question.examples.map((example) => getStringWithSubstitutions({ str: example, substitutions }));
  }

  let question_meta = question.question_meta;
  if (question.question_type === CATEGORY_TYPE_TEXT || question.question_type === CATEGORY_TYPE_MONEY) {
    const keys = [
      'default',
      'goodDescription',
      'badDescription',
      'defaultStateDescription',
      'startStateDescription',
      'noIncomeTaxDescription'
    ];
    const intersectingKeys = _.intersection(Object.keys(question.question_meta), keys);

    question_meta = {
      ...question_meta,
      ...intersectingKeys.reduce(
        (result, key) => ({
          ...result,
          [key]: getStringWithSubstitutions({ str: _.get(question, ['question_meta', key]), substitutions })
        }),
        {}
      )
    };
  } else if (
    (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE ||
      question.slug === SLUG__INCOME_UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR ||
      question.slug === SLUG__INCOME_RETIREMENT_PENSION_ROTH) &&
    _.get(question_meta, 'warningMessage')
  ) {
    question_meta = {
      ...question_meta,
      warningMessage: getStringWithSubstitutions({ str: question_meta.warningMessage, substitutions })
    };
  } else if (
    [CATEGORY_TYPE_OPTIONS, CATEGORY_TYPE_TAXFLOW_CALENDAR].includes(question.question_type) &&
    Array.isArray(question.question_meta)
  ) {
    question_meta = question.question_meta.map((option) => {
      return { ...option, text: getStringWithSubstitutions({ str: option.text, substitutions }) };
    });
  } else if (question.question_type === CATEGORY_TYPE_SUMMARY) {
    question_meta = {
      ...question.question_meta,
      collectionTypes: question.question_meta.collectionTypes.map((collectionType) => ({
        ...collectionType,
        listName: getStringWithSubstitutions({ str: collectionType.listName, substitutions })
      }))
    };
  }

  let sub_question = question.sub_question;
  if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
    sub_question = question.sub_question.map((subQuestion) =>
      getQuestionWithSubstitutions({ question: subQuestion, substitutions })
    );
  }

  let nextButtonLabel = question.nextButtonLabel;
  if (question.nextButtonLabel) {
    nextButtonLabel = getStringWithSubstitutions({ str: question.nextButtonLabel, substitutions });
  }

  return {
    ...question,
    title: getStringWithSubstitutions({ str: question.title, substitutions }),
    summary: getStringWithSubstitutions({ str: question.summary, substitutions }),
    learnMoreInfo: !question.learnMoreInfo?.fields?.description
      ? question.learnMoreInfo
      : {
          ...question.learnMoreInfo,
          fields: {
            ...question.learnMoreInfo.fields,
            description: getStringWithSubstitutions({ str: question.learnMoreInfo.fields.description, substitutions })
          }
        },
    info: getInfoWithSubstitutions({ info: question.info, substitutions }),
    examples,
    question_meta,
    sub_question,
    nextButtonLabel
  };
};

const getInfoWithSubstitutions = ({ info, substitutions }) => {
  if (info?.default?.fields) {
    info.default.fields.description = getStringWithSubstitutions({
      str: info.default.fields.description,
      substitutions
    });
    info.default.fields.title = getStringWithSubstitutions({ str: info.default.fields.title, substitutions });
  }
  return info;
};

export const getCurrentSectionPathComponent = ({ specialTaxData, navigationSections, uiStage, submitIssues }) => {
  const isSpecialDone = _.find(specialTaxData, { slug: ENDPOINT_ATTRIBUTE__SPECIAL_DONE });
  if (!isSpecialDone) {
    return PATH_COMPONENT__SPECIAL_WHO;
  }

  const uiStagePath = _.get(
    getUIStagePathMap({ uncheckedSubmitIssues: _.filter(submitIssues, { isChecked: false }) }),
    uiStage
  );
  if (!_.isNil(uiStagePath)) {
    return uiStagePath;
  }
  const currentQuestionSlug = _.chain(navigationSections)
    .findLast({ seen: true })
    .defaultTo(_.head(navigationSections))
    .get('linkedQuestionSlug')
    .value();
  return currentQuestionSlug;
};
