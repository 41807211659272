import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { ArrowLeft2 } from 'iconsax-react';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SectionedProgressBar from '@app/src/Components/Common/SectionedProgressBar/SectionedProgressBar';
import { currentProgressSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { colorNeutralGraphite } from '@app/src/theme';

const ProgressBarHeader = ({ currentProgress, loading }) => {
  const { goBack } = useHistory();
  const { totalSections, progress } = currentProgress;

  return (
    <div>
      {progress !== 1 ? (
        <IconButton onClick={goBack}>
          <ArrowLeft2 color={colorNeutralGraphite} />
        </IconButton>
      ) : (
        <div style={{ width: 32, height: 32 }} />
      )}
      {loading ? (
        <Skeleton variant='text' width='100%' height={20} animation='pulse' />
      ) : (
        <SectionedProgressBar totalSections={totalSections} currentSection={progress} className='mb-20' />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentProgress: currentProgressSelector(state, props)
});

const ConnectedProgressBarHeader = connect(mapStateToProps)(ProgressBarHeader);

export default ConnectedProgressBarHeader;
