import _ from 'lodash';
import ButtonBase from '@mui/material/ButtonBase';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setHolisticOnboardingDrawerOpen } from '@app/src/actions/holisticOnboardingActions';
import HelpSupportIcon from '@app/src/assets/HelpSupportIcon';
import { drawerOpenSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { colorAccent } from '@app/src/theme';

const LearnMoreButton = ({ learnMoreInfo, drawerOpen, setHolisticOnboardingDrawerOpen }) => {
  const { pathname } = useLocation();

  const title = _.get(learnMoreInfo, 'title', '');

  const handleClick = () => {
    trackActivity('question: more info clicked', {
      flow: 'holistic onboarding',
      pathname
    });

    setHolisticOnboardingDrawerOpen(!drawerOpen);
  };

  return title ? (
    <ButtonBase
      onClick={handleClick}
      style={{
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'F37Blanka',
        color: colorAccent,
        marginBottom: '16px',
        alignSelf: 'flex-start'
      }}
    >
      {title}
      <HelpSupportIcon color={colorAccent} width={24} height={24} style={{ marginLeft: '5px' }} />
    </ButtonBase>
  ) : null;
};

const mapStateToProps = (state) => ({
  drawerOpen: drawerOpenSelector(state)
});

const mapDispatchToProps = { setHolisticOnboardingDrawerOpen };

const ConnectedLearnMoreButton = connect(mapStateToProps, mapDispatchToProps)(LearnMoreButton);

export default ConnectedLearnMoreButton;
