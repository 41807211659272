import Stack from '@mui/material/Stack';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setHolisticOnboardingHome } from '@app/src/actions/holisticOnboardingActions';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import ResponseText from '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText';
import { homeSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { autoAdvance } from '@app/src/utils/holisticOnboardingUtils';
import { onContinue } from '@app/src/services/holisticOnboardingService';

const OnboardingHome = ({ home, setHolisticOnboardingHome, onContinue }) => {
  const {
    location: { pathname },
    push
  } = useHistory();

  const handleSelect = (value) => {
    setHolisticOnboardingHome(value);

    if (value === 0) {
      autoAdvance({ f: onContinue, push, pathname });
      return;
    }
  };

  return (
    <Stack spacing={1}>
      <ToggleButton selected={home === 1} onClick={() => handleSelect(1)}>
        Yes, I sometimes work from home
      </ToggleButton>
      <ToggleButton selected={home === 0} onClick={() => handleSelect(0)}>
        No, I never work from home
      </ToggleButton>
      <ResponseText pathname={pathname} visible={home === 1} />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  home: homeSelector(state)
});

const mapDispatchToProps = {
  setHolisticOnboardingHome,
  onContinue
};

const ConnectedOnboardingHome = connect(mapStateToProps, mapDispatchToProps)(OnboardingHome);

export default ConnectedOnboardingHome;
