import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginRedirect from '@app/src/Components/Login/LoginWithOTP/LoginRedirect';

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuthenticated === true ? <Component {...props} /> : <LoginRedirect location={props.location} />
    }
  />
);

const mapStateToProps = (state) => ({
  auth: state.auth
});

const ConnectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);

export default ConnectedPrivateRoute;
