import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import KeeperSelect from '@app/src/Components/Common/KeeperSelect/KeeperSelect';
import { trackActivity } from '@app/src/services/analyticsService';

export const AccountItem = ({
  onChangeAccountType,
  account,
  isSettings,
  onDisconnect,
  setSelfUnlockModalOpen,
  isInConfirmAmounts
}) => {
  const [accountType, setAccountType] = useState(account.expense_type);

  const onChange = ({ target: { value } }) => {
    if (isInConfirmAmounts) {
      setSelfUnlockModalOpen(true);
      return;
    }

    const accountTypeObj = {
      account_id: account.account_id,
      expense_type: value
    };

    onChangeAccountType(accountTypeObj, () => {});
    setAccountType(value);

    trackActivity('bank sub-account change', {
      account_type: value
    });
  };

  return (
    <div className='bank-item-details-subaccount'>
      <div className='bank-item-details-subaccount-name'>
        {account.name}
        {account.mask ? <span> - {account.mask}</span> : null}
      </div>
      <KeeperSelect onChange={onChange} fullWidth value={accountType} placeholder='Select usage'>
        <MenuItem value='' disabled>
          Select usage
        </MenuItem>
        <MenuItem value='personal & work'>Used for Work & Personal</MenuItem>
        <MenuItem value='personal only'>Used for Personal Only</MenuItem>
        <MenuItem value='work only'>Used for Work Only</MenuItem>
      </KeeperSelect>
      {isSettings && (
        <div
          className='bank-item-details-subaccount-remove'
          onClick={() => {
            if (isInConfirmAmounts) {
              setSelfUnlockModalOpen(true);
            } else {
              onDisconnect(account);
            }
          }}
        >
          Remove account
        </div>
      )}
    </div>
  );
};
