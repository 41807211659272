import Button from '@mui/material/Button';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { useSnackbarRef } from '@app/src/Components/Snackbar/Snackbar';
import { trackActivity } from '@app/src/services/analyticsService';
import { colorAccent } from '@app/src/theme';

const RetryButton = () => {
  const onRetry = () => {
    trackActivity('snackbar: click', { type: 'retry' });

    window.location.reload();
  };

  return (
    <Button sx={{ color: colorAccent }} onClick={onRetry}>
      Reload
    </Button>
  );
};

const LoginButton = ({ history }) => {
  const onLogin = () => {
    trackActivity('snackbar: click', { type: 'login' });

    history.push('/login');
  };

  return (
    <Button sx={{ color: colorAccent }} onClick={onLogin}>
      Login
    </Button>
  );
};

const DismissButton = () => {
  const onClose = () => {
    trackActivity('snackbar: click', { type: 'dismiss' });

    useSnackbarRef.closeSnackbar();
  };

  return (
    <Button sx={{ color: colorAccent }} onClick={onClose}>
      Dismiss
    </Button>
  );
};

export const SnackbarActions = ({ message }) => {
  const history = useHistory();

  switch (message) {
    case 'Unauthorized Request':
      return <LoginButton history={history} />;
    case 'Network Error':
      return <RetryButton />;
    default:
      return <DismissButton />;
  }
};
