import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import {
  assistantMessagesSelector,
  assistantOpenSelector,
  assistantSessionIdSelector
} from '@app/src/selectors/assistantSelectors';
import {
  setAssistantOpen,
  incrementAssistantUnreadMessages,
  toggleAssistantOpen
} from '@app/src/actions/assistantActions';
import { trackActivity } from '@app/src/services/analyticsService';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/Assistant/Assistant.scss';
import AssistantBottomSheet from '@app/src/Components/Assistant/AssistantBottomSheet';
import AssistantChatPanel from '@app/src/Components/Assistant/AssistantChatPanel';
import { useImageCache } from '@app/src/utils/assistantUtils';

const AssistantContainer = ({
  messages,
  open,
  currentQuestion,
  sessionId,
  footer,
  header,
  isLoading,
  setOpen,
  incrementUnreadMessages,
  virtualizerProps,
  toggleAssistantOpen
}) => {
  const { width: windowWidth } = useWindowSize();
  const isDesktop = windowWidth >= 1024;
  const history = useHistory();
  const location = useLocation();

  const getImage = useImageCache();

  useEffect(() => {
    incrementUnreadMessages(0);
  }, [open, incrementUnreadMessages]);

  const toggleOpen = (origin) => {
    const opening = !open;

    trackActivity(`tax assistant: panel ${opening ? 'open' : 'closed'}`, {
      currentQuestion: _.get(currentQuestion, 'slug'),
      sessionId,
      messages: _.size(messages),
      origin
    });

    toggleAssistantOpen();

    if (!opening) {
      const searchParams = new URLSearchParams(location.search);

      searchParams.delete('assistant');

      history.replace({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
  };

  if (!isDesktop) {
    return (
      <AssistantBottomSheet
        open={!isLoading && open}
        setOpen={setOpen}
        footer={footer}
        toggleOpen={toggleOpen}
        getImage={getImage}
        virtualizerProps={virtualizerProps}
      />
    );
  }

  return (
    <AssistantChatPanel
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      header={header}
      footer={footer}
      toggleOpen={toggleOpen}
      getImage={getImage}
      virtualizerProps={virtualizerProps}
    />
  );
};

const mapStateToProps = (state) => ({
  messages: assistantMessagesSelector(state),
  open: assistantOpenSelector(state),
  sessionId: assistantSessionIdSelector(state),
  currentQuestion: currentQuestionSelector(state)
});

const mapDispatchToProps = {
  setOpen: setAssistantOpen,
  toggleAssistantOpen,
  incrementUnreadMessages: incrementAssistantUnreadMessages
};

const ConnectedAssistantContainer = connect(mapStateToProps, mapDispatchToProps)(AssistantContainer);

export default ConnectedAssistantContainer;
