import React, { Component } from 'react';
import TaxFlowNumberItem from '@app/src/Components/TaxFlow/Question/TaxFlowNumberItem';
import _ from 'lodash';

class TaxFlowYearsItem extends Component {
  render() {
    const { currentQuestion, resultLoading, ...props } = this.props;

    let newQuestion = currentQuestion;
    if (!resultLoading) {
      newQuestion = {
        ...currentQuestion,
        question_meta: {
          ..._.get(currentQuestion, ['question_meta'], {}),
          suffix: 'years'
        }
      };
    }

    return <TaxFlowNumberItem currentQuestion={newQuestion} resultLoading={resultLoading} {...props} />;
  }
}

export default TaxFlowYearsItem;
