import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@app/src/assets/close.svg?react';
import '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal.scss';

const TaxFlowConfirmModal = ({
  open,
  setOpen,
  onConfirm,
  confirmText = 'Confirm',
  header,
  body,
  showCancel = true
}) => {
  const onConfirmClick = () => {
    setOpen(false);
    onConfirm && onConfirm();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ marginTop: 1.5, marginRight: 1.5, marginLeft: 1.5 }}>
        <div>{header}</div>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {body && (
        <DialogContent sx={{ paddingRight: 1.5, paddingLeft: 1.5 }}>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ padding: 1.5 }} className='taxflow-confirm-modal-actions'>
        <Button variant='contained' type='button' onClick={onConfirmClick}>
          {confirmText}
        </Button>
        {showCancel && (
          <Button variant='outlined' type='button' onClick={onClose}>
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TaxFlowConfirmModal;
