// path components
export const PATH_COMPONENT__CAR_OPTIONS = 'car-options';
export const PATH_COMPONENT__CAR_BRAND = 'car-brand';
export const PATH_COMPONENT__CAR_SERVICE_DATE = 'car-service-date';
export const PATH_COMPONENT__CAR_PURCHASE_DATE = 'car-purchase-date';
export const PATH_COMPONENT__CAR_NEW_USED = 'car-new-used';
export const PATH_COMPONENT__CAR_COST = 'car-cost';
export const PATH_COMPONENT__CAR_LOAN = 'car-loan';
export const PATH_COMPONENT__CAR_LEASE = 'car-lease';
export const PATH_COMPONENT__CAR_WORK_MILES_TRACKED = 'car-work-miles-tracked';
export const PATH_COMPONENT__CAR_WORK_MILES = 'car-work-miles';
export const PATH_COMPONENT__CAR_TOTAL_MILES = 'car-total-miles';
export const PATH_COMPONENT__CAR_PERCENT = 'car-percent';
export const PATH_COMPONENT__CAR_MILES_CONFIRMATION = 'car-miles-confirmation';
export const PATH_COMPONENT__CAR_STANDARD_MILEAGE = 'car-standard-mileage';
export const PATH_COMPONENT__CAR_PAST_DEPRECIATION = 'car-past-depreciation';
export const PATH_COMPONENT__CAR_YEARS_DEPRECIATION = 'car-years-depreciation';
export const PATH_COMPONENT__CAR_DEPRECIATION_CONFIRMATION = 'car-depreciation-confirmation';
export const PATH_COMPONENT__CAR_OTHER_PERSONAL = 'car-other-personal';

export const PATH_COMPONENT__CAR_DONE = 'car-done';
export const PATH_COMPONENT__CAR_MILEAGE_BOUNCE = 'car-mileage-bounce';
export const PATH_COMPONENT__CAR_EXIT_BOUNCE = 'car-exit-bounce';

// slugs
export const SLUG__CAR_OPTIONS = 'car-new-options';
export const SLUG__CAR_BRAND = 'car-new-brand';
export const SLUG__CAR_SERVICE_DATE = 'car-new-service-date';
export const SLUG__CAR_PURCHASE_DATE = 'car-new-purchase-date';
export const SLUG__CAR_NEW_USED = 'car-new-new-used';
export const SLUG__CAR_COST = 'car-new-cost';
export const SLUG__CAR_LOAN = 'car-new-loan';
export const SLUG__CAR_LEASE = 'car-new-lease';
export const SLUG__CAR_WORK_MILES_TRACKED = 'car-new-work-miles-tracked';
export const SLUG__CAR_WORK_MILES = 'car-new-work-miles';
export const SLUG__CAR_TOTAL_MILES = 'car-new-total-miles';
export const SLUG__CAR_PERCENT = 'car-new-percent';
export const SLUG__CAR_MILES_CONFIRMATION = 'car-new-miles-confirmation';
export const SLUG__CAR_STANDARD_MILEAGE = 'car-new-standard-mileage';
export const SLUG__CAR_PAST_DEPRECIATION = 'car-new-past-depreciation';
export const SLUG__CAR_YEARS_DEPRECIATION = 'car-new-years-depreciation';
export const SLUG__CAR_DEPRECIATION_CONFIRMATION = 'car-depreciation-confirmation';
export const SLUG__CAR_DONE = 'car-new-done';
export const SLUG__CAR_MILEAGE_BOUNCE = 'car-new-mileage-bounce';
export const SLUG__CAR_EXIT_BOUNCE = 'car-new-exit-bounce';
export const SLUG__CAR_OTHER_PERSONAL = 'car-other-personal';

// endpoint attributes
export const ENDPOINT_ATTRIBUTE__CAR_OPTIONS = 'car-options';
export const ENDPOINT_ATTRIBUTE__CAR_WORK_MILES_TRACKED = 'car-work-miles-tracked';
export const ENDPOINT_ATTRIBUTE__CAR_TOTAL_MILES = 'car-total-miles';
export const ENDPOINT_ATTRIBUTE__CAR_PERCENT = 'car-percent';
export const ENDPOINT_ATTRIBUTE__CAR_SERVICE_DATE = 'car-service-date';
export const ENDPOINT_ATTRIBUTE__CAR_COST = 'car-cost';
export const ENDPOINT_ATTRIBUTE__CAR_YEARS_DEPRECIATION = 'car-years-depreciation';

// collection types
export const COLLECTION_TYPE__CAR = 'car';
