import {
  SET_TAXFILING_IRS_PAYMENT_ACCOUNT,
  SET_TAXFILING_TAX_AMOUNTS,
  SET_TAXFILING_PAID,
  SET_TAXFILING_SUBMIT_TIMESTAMP
} from '@app/src/actions/types';

export const setIRSPaymentAccount = (payload) => ({
  type: SET_TAXFILING_IRS_PAYMENT_ACCOUNT,
  payload
});

export const setTaxAmounts = (payload) => ({
  type: SET_TAXFILING_TAX_AMOUNTS,
  payload
});

export const setSubmitTimestamp = (payload) => ({
  type: SET_TAXFILING_SUBMIT_TIMESTAMP,
  payload
});

export const setPaid = (payload) => ({
  type: SET_TAXFILING_PAID,
  payload
});
