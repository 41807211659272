import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { connect } from 'react-redux';

import { setSettingsModalType } from '@app/src/actions/settingsActions';
import CloseIcon from '@app/src/assets/close.svg?react';
import { settingsModalTypeSelector } from '@app/src/selectors/settingsSelectors';
import SettingsModalContent from '@app/src/Components/Settings/Modals/SettingsModalContent';

import '@app/src/Components/Settings/Modals/SettingsModalContainer.scss';

const SettingsModalContainer = ({ modalType, setSettingsModalType }) => (
  <Dialog
    slotProps={{ root: { sx: { zIndex: 1000 } } }}
    sx={{
      '.MuiPaper-root': {
        padding: '16px',
        borderRadius: '8px'
      }
    }}
    className='settings-modal'
    open={!!modalType}
    onClose={() => setSettingsModalType('')}
  >
    <IconButton
      sx={{
        position: 'absolute',
        right: 8,
        top: 8
      }}
      onClick={() => setSettingsModalType('')}
    >
      <CloseIcon color='primary' />
    </IconButton>
    <SettingsModalContent />
  </Dialog>
);

const mapStateToProps = (state) => ({
  modalType: settingsModalTypeSelector(state)
});

const mapDispatchToProps = {
  setSettingsModalType
};

const ConnectedSettingsModalContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsModalContainer);

export default ConnectedSettingsModalContainer;
