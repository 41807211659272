import React from 'react';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import classNames from 'classnames';
import TaxFlowLearnMoreElement from '@app/src/Components/TaxFlow/Common/TaxFlowLearnMoreElement';
import OnboardingProgressBar from '@app/src/Components/Onboarding/Question/OnboardingProgressBar';

const OnboardingHead = ({ question, title, summary, learnMoreInfo, learnMoreTitle }) => {
  const renderLearnMore = () => {
    const accordionInfo = _.get(learnMoreInfo, ['fields', 'accordionInfo']);
    const contentType = _.get(learnMoreInfo, ['fields', 'contentType']);
    const type = _.get(learnMoreInfo, ['fields', 'type']);
    const slug = _.get(learnMoreInfo, ['fields', 'slug']);
    const title = _.get(learnMoreInfo, ['fields', 'title']);
    const description = _.get(learnMoreInfo, ['fields', 'description']);
    const url = _.get(learnMoreInfo, ['fields', 'url']);
    return (
      <TaxFlowLearnMoreElement
        question={question}
        slug={slug}
        type={type}
        buttonText={learnMoreTitle}
        modalTitle={title}
        description={description}
        url={url}
        accordionInfo={accordionInfo}
        contentType={contentType}
        disableChatMessaging
      />
    );
  };

  return (
    <div
      className={classNames('steps-head', {
        'empty-title': _.isEmpty(title)
      })}
    >
      <OnboardingProgressBar question={question} />
      <div className='taxflow-header'>
        <div>
          <h1>{title}</h1>
        </div>
      </div>
      <div
        className={classNames({
          'step-description': !_.isEmpty(summary)
        })}
      >
        {!_.isEmpty(summary) && <ReactMarkdown className='step-summary' source={summary} linkTarget='_blank' />}
        {renderLearnMore()}
      </div>
    </div>
  );
};

export default OnboardingHead;
