import React from 'react';
import AssistantMessageLinkPreview from '@app/src/Components/Assistant/AssistantMessageLinkPreview';
import '@app/src/Components/Assistant/AssistantMessage.scss';
import { useImageHeight } from '@app/src/utils/assistantUtils';

const AssistantMessageImage = ({ attachment, getImage }) => {
  const image = getImage(attachment.image_url);

  const [height, ref, onLoad] = useImageHeight(image);

  if (!attachment.image_url) return null;

  if (attachment.og_scrape_url) {
    return <AssistantMessageLinkPreview attachment={attachment} getImage={getImage} />;
  }

  return (
    <img
      src={attachment.image_url}
      alt={attachment.title}
      height={height}
      ref={ref}
      onLoad={onLoad}
      className='assistant-message-image'
    />
  );
};

export default AssistantMessageImage;
