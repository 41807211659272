import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Loader from '@app/src/Components/Common/Loader/Loader';
import { BANK_LINK_SKIP_TITLE } from '@app/src/constants/loadingPageConstants';
import { getQuestion } from '@app/src/services/onboardingService';
import '@app/src/Components/Onboarding/OnboardingAppDownload.scss';
import { bankLinkSkippedSelector } from '@app/src/selectors/bankSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { Url_ONBOARDING_APP_DOWNLOAD } from '@app/src/constants/onboardingConstants';
import { currentQuestionSelector, loadingSelector } from '@app/src/selectors/onboardingSelectors';
import getImageUrl from '@app/src/utils/getImageUrl';

const OnboardingAppDownload = ({ className, history, currentQuestion, loading, bankLinkSkipped, getQuestion }) => {
  let title;
  if (bankLinkSkipped) {
    title = BANK_LINK_SKIP_TITLE;
  } else {
    title = _.get(currentQuestion, 'title');
  }

  const summary = _.get(currentQuestion, 'summary');

  useEffect(() => {
    getQuestion({ slug: Url_ONBOARDING_APP_DOWNLOAD, history });
  }, [getQuestion, history]);

  const onDownloadClick = () => {
    trackActivity('app store clicked', { store: 'download' });
  };

  const onAppStoreClick = (store) => {
    trackActivity('app store clicked', { store });
    if (store === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.keepertax&hl=en&gl=US', '_blank');
    } else {
      window.open('https://itunes.apple.com/app/id1471090941', '_blank');
    }
  };

  return (
    <div className={classNames('onboarding-2-download', className)}>
      {loading ? (
        <Loader type='page' />
      ) : (
        <>
          <div className='onboarding-2-download-content'>
            <div className='onboarding-2-download-preview'>
              <img
                src={getImageUrl('download-featured.svg')}
                alt='Featured'
                className='onboarding-2-download-preview-featured'
              />
              <img
                src={getImageUrl('download-rating.svg')}
                alt='Rating'
                className='onboarding-2-download-preview-rating'
              />
              <img
                src={getImageUrl('download-phone.svg')}
                alt='Phone'
                className='onboarding-2-download-preview-phone'
              />
            </div>
            <div className='onboarding-2-download-info'>
              <div className='onboarding-2-download-info-header'>
                <h1>{title}</h1>
                {!_.isEmpty(summary) && <ReactMarkdown source={summary} />}
                <Button
                  variant='contained'
                  color='secondary'
                  size='large'
                  fullWidth
                  type='button'
                  className='btn btn-block btn-primary onboarding-2-download-button'
                  href='https://keeper.tax/get-the-app'
                  onClick={onDownloadClick}
                >
                  Download the app
                </Button>
              </div>
              <div className='onboarding-2-download-link'>
                <div className='onboarding-2-download-store'>
                  <img
                    src={getImageUrl('apple-store-logo.svg')}
                    alt='Apple App Store'
                    className='onboarding-2-download-store-icon'
                    onClick={() => onAppStoreClick('apple')}
                    role='button'
                  />
                  <img
                    src={getImageUrl('google-store-logo.svg')}
                    alt='Google Play Store'
                    className='onboarding-2-download-store-icon'
                    onClick={() => onAppStoreClick('android')}
                    role='button'
                  />
                </div>
                <div className='onboarding-2-download-qr'>
                  <img src={getImageUrl('get-the-app-qr.svg')} alt='QR code' height={130} width={130} />
                  <div className='onboarding-2-download-qr-text'>
                    To download, scan QR code with your phone's camera
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  loading: loadingSelector(state),
  bankLinkSkipped: bankLinkSkippedSelector(state)
});

const mapDispatchToProps = {
  getQuestion
};

const ConnectedOnboardingAppDownload = connect(mapStateToProps, mapDispatchToProps)(OnboardingAppDownload);

export default ConnectedOnboardingAppDownload;
