import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { sendUtms, trackActivity } from '@app/src/services/analyticsService';
import { signupLinkSkippableSelector } from '@app/src/selectors/userSelectors';
import createAnswerEventProperties from '@app/src/utils/onboarding/createAnswerEventProperties';
import {
  Url_FACEBOOK_CLICK_ID_PARAM_KEY,
  Url_FACEBOOK_CLICK_ID_STORAGE_KEY,
  Url_GOOGLE_CLICK_ID_PARAM_KEY,
  Url_GOOGLE_CLICK_ID_STORAGE_KEY,
  Url_ONBOARDING_ANCHOR_BANK,
  Url_ONBOARDING_ANCHOR_FIRST,
  Url_ONBOARDING_ANCHOR_LAST,
  Url_ONBOARDING_APP_DOWNLOAD,
  Url_ONBOARDING_INCOME_TYPE,
  Url_ONBOARDING_JOB_SELECT,
  Url_ONBOARDING_VERIFY,
  Url_SIGNUP_LINK,
  Url_TIKTOK_PARAM_KEY,
  Url_TIKTOK_STORAGE_KEY,
  Url_TUNE_TRANSACTION_PARAM_KEY,
  Url_TUNE_TRANSACTION_STORAGE_KEY
} from '@app/src/constants/onboardingConstants';
import {
  currentAnswerSelector,
  currentQuestionSelector,
  onboardingExperimentsSelector,
  typeSelector
} from '@app/src/selectors/onboardingSelectors';
import { formatFacebookClickId, getCookie } from '@app/src/global/Helpers';
import { REFERRER_STORAGE_KEY } from '@app/src/constants/constants';

const trackAnchorQuestionView = (params) => () => {
  const referrer = getCookie(REFERRER_STORAGE_KEY);
  if (
    params.question === Url_ONBOARDING_INCOME_TYPE ||
    (params.question === Url_ONBOARDING_JOB_SELECT && /^uber/.test(referrer))
  ) {
    trackActivity('question: view', {
      ...params,
      question: Url_ONBOARDING_ANCHOR_FIRST
    });
  }

  if (params.question === Url_ONBOARDING_APP_DOWNLOAD) {
    trackActivity('question: view', {
      ...params,
      question: Url_ONBOARDING_ANCHOR_LAST
    });
  }

  if (params.question === Url_SIGNUP_LINK) {
    trackActivity('question: view', {
      ...params,
      question: Url_ONBOARDING_ANCHOR_BANK
    });
  }
};

export const trackOnboardingQuestionView = () => (dispatch, getState) => {
  const state = getState();

  const question = currentQuestionSelector(getState());
  const signupLinkSkippable = signupLinkSkippableSelector(state);
  const experiments = onboardingExperimentsSelector(state);

  const params = {
    flow: 'onboarding',
    is_authenticated: _.get(state, ['auth', 'isAuthenticated']),
    question: _.get(question, 'slug'),
    title: _.get(question, 'title'),
    preSignupExperiments: experiments,
    ...(question === Url_SIGNUP_LINK ? { signup_link_skippable: signupLinkSkippable } : {})
  };

  trackActivity('question: view', { ...params, client_dedup_id: uuidv4() });
  dispatch(trackAnchorQuestionView({ ...params, client_dedup_id: uuidv4() }));

  if (_.get(question, 'slug') === Url_ONBOARDING_VERIFY) {
    const type = typeSelector(getState());
    trackActivity('onboarding: existing user login', { type });
  }
};

export const trackOnboardingQuestionAnswer = (buttonClicked) => (dispatch, getState) => {
  const state = getState();

  const question = currentQuestionSelector(getState());
  const answer = currentAnswerSelector(getState());
  const signupLinkSkippable = signupLinkSkippableSelector(state);
  const experiments = onboardingExperimentsSelector(state);

  const baseObj = {
    flow: 'onboarding',
    question: _.get(question, 'slug'),
    title: _.get(question, 'title'),
    is_authenticated: _.get(state, ['auth', 'isAuthenticated']),
    ...createAnswerEventProperties({
      currentQuestion: question,
      currentAnswer: answer
    }),
    preSignupExperiments: experiments
  };

  trackActivity('question: answer', {
    ...baseObj,
    ...(question === Url_SIGNUP_LINK ? { signup_link_skippable: signupLinkSkippable, buttonClicked } : {})
  });
};

export const sendOnboardingUtms =
  ({ params }) =>
  () => {
    sendUtms({ params });
  };

export const setAppAnalyticsLocalStorage =
  ({ params }) =>
  () => {
    const fbc = formatFacebookClickId(params[Url_FACEBOOK_CLICK_ID_PARAM_KEY]);
    if (fbc) {
      localStorage.setItem(Url_FACEBOOK_CLICK_ID_STORAGE_KEY, fbc);
      window.analytics.identify({ [Url_FACEBOOK_CLICK_ID_STORAGE_KEY]: fbc });
    }

    const appAnalytics = [
      { [Url_GOOGLE_CLICK_ID_PARAM_KEY]: Url_GOOGLE_CLICK_ID_STORAGE_KEY },
      { [Url_TIKTOK_PARAM_KEY]: Url_TIKTOK_STORAGE_KEY },
      { [Url_TUNE_TRANSACTION_PARAM_KEY]: Url_TUNE_TRANSACTION_STORAGE_KEY }
    ];

    appAnalytics.forEach((type) => {
      const key = Object.keys(type)[0];

      if (key in params) {
        const value = params[key];
        localStorage.setItem([type[key]], value);
        window.analytics.identify({ [type[key]]: value });
      }
    });
  };
