import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serverUrl } from '@app/src/global/Environment';
import { API_POLLING_INTERVAL_MS } from '@app/src/api/constants';
import _ from 'lodash';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { createSelector } from 'reselect';

/**
 * Period at which to require periodic re-fetch
 * Used to prevent data from falling out of sync given bugs pertaining to invalidation of data
 */
const keepUnusedDataFor = API_POLLING_INTERVAL_MS;
const baseUrl = serverUrl();

// Tags
const TAG__TAX_DATA = 'TaxData';
const TAG__NAVIGATION_SECTIONS = 'NavigationSections';
const TAG__SUBMIT_WARNINGS = 'SubmitWarnings';
export const TAG__SUBMIT_ISSUES = 'SubmitIssues';

// Api
const taxDataApi = createApi({
  reducerPath: 'taxDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}api/taxes/`,
    prepareHeaders: (headers) => headers.set('Authorization', localStorage.getItem('KeeperToken'))
  }),
  tagTypes: [TAG__TAX_DATA, TAG__NAVIGATION_SECTIONS, TAG__SUBMIT_WARNINGS, TAG__SUBMIT_ISSUES],
  endpoints: (builder) => ({
    // TODO replace all references to in-state queryResults with this query
    getTaxData: builder.query({
      query: ({ collectionType }) => ({
        url: `user-tax-data/batch-get`,
        method: 'POST',
        body: { queries: [{ coll_type: collectionType }] }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { collectionType }) => [{ type: TAG__TAX_DATA, id: collectionType }],
      keepUnusedDataFor
    }),
    updateTaxData: builder.mutation({
      query: ({ taxData, generateSharedCollectionId }) => ({
        url: `user-tax-data`,
        method: 'POST',
        body: { taxData, generateSharedCollectionId }
      }),
      invalidatesTags: (result, error, { taxData }) =>
        _.chain(taxData)
          .map('coll_type')
          .uniq()
          .map((collectionType) => ({ type: TAG__TAX_DATA, id: collectionType }))
          .concat(TAG__SUBMIT_WARNINGS)
          .value()
    }),
    deleteTaxData: builder.mutation({
      query: ({ coll_type, coll_id, slug }) => ({
        url: `user-tax-data`,
        method: 'DELETE',
        body: { coll_type, coll_id, slug }
      }),
      invalidatesTags: (result, error, { coll_type }) => [{ type: TAG__TAX_DATA, id: coll_type }, TAG__SUBMIT_WARNINGS]
    }),
    getNavigationSections: builder.query({
      query: () => ({
        url: `navigation-sections`,
        method: 'GET'
      }),
      transformResponse: (response) => response.data.navigationSections,
      providesTags: () => [TAG__NAVIGATION_SECTIONS],
      keepUnusedDataFor
    }),
    markNavigationSectionAsSeen: builder.mutation({
      query: ({ sectionSlug }) => ({
        url: `mark-navigation-section-as-seen`,
        method: 'POST',
        body: { sectionSlug }
      }),
      invalidatesTags: () => [TAG__NAVIGATION_SECTIONS]
    }),
    getSubmitWarnings: builder.query({
      query: () => ({
        url: 'submit-warnings',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.submitWarnings,
      providesTags: () => [TAG__SUBMIT_WARNINGS],
      keepUnusedDataFor
    }),
    dismissSubmitWarning: builder.mutation({
      query: ({ slug }) => ({
        url: 'dismiss-submit-warning',
        method: 'POST',
        body: { slug }
      }),
      invalidatesTags: () => [TAG__SUBMIT_WARNINGS],
      async onQueryStarted({ slug }, { dispatch }) {
        dispatch(
          taxDataApi.util.updateQueryData('getSubmitWarnings', undefined, (submitWarnings) => {
            return _.filter(submitWarnings, ({ slug: warningSlug }) => warningSlug !== slug);
          })
        );
      }
    }),
    getSubmitIssues: builder.query({
      query: () => ({
        url: 'submit-issues',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.submitIssues,
      providesTags: () => [TAG__SUBMIT_ISSUES],
      keepUnusedDataFor
    }),
    updateSubmitIssues: builder.mutation({
      query: ({ updatedIssues }) => ({
        url: 'update-submit-issues',
        method: 'POST',
        body: { updatedIssues }
      }),
      invalidatesTags: () => [TAG__SUBMIT_ISSUES],
      async onQueryStarted({ updatedIssues }, { dispatch }) {
        dispatch(
          taxDataApi.util.updateQueryData('getSubmitIssues', undefined, () => {
            return updatedIssues;
          })
        );
      }
    }),
    getIdMatch: builder.mutation({
      query: () => ({
        url: 'get-id-match',
        method: 'POST'
      }),
      transformResponse: (response) => response.data.response.result,
      invalidatesTags: () => ['SubmitWarnings']
    }),
    estimateTaxResults: builder.query({
      query: (body) => ({ url: 'tax-calc-estimate', method: 'POST', body }),
      transformResponse: (response) => response.data.resObj
    })
  })
});

// Actions
export const getTaxData =
  ({ collectionType }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxData.initiate({ collectionType }),
      dispatch
    });

export const updateTaxData =
  ({ taxData, generateSharedCollectionId }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.updateTaxData.initiate({ taxData, generateSharedCollectionId }));
  };

export const deleteTaxData =
  ({ coll_type, coll_id, slug }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.deleteTaxData.initiate({ coll_type, coll_id, slug }));
  };

export const getNavigationSections = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getNavigationSections.initiate, dispatch });

export const markNavigationSectionAsSeen =
  ({ sectionSlug }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.markNavigationSectionAsSeen.initiate({ sectionSlug }));
  };

export const getSubmitWarnings = () => async (dispatch) =>
  getFulfilledRequestData({
    initiateFunction: taxDataApi.endpoints.getSubmitWarnings.initiate,
    dispatch
  });

export const dismissSubmitWarning =
  ({ slug }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.dismissSubmitWarning.initiate({ slug }));
  };

export const getSubmitIssues = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getSubmitIssues.initiate, dispatch });

export const updateSubmitIssues =
  ({ updatedIssues }) =>
  async (dispatch) =>
    await dispatch(taxDataApi.endpoints.updateSubmitIssues.initiate({ updatedIssues }));

export const getIdMatch = () => async (dispatch) => {
  const { data: match } = await dispatch(taxDataApi.endpoints.getIdMatch.initiate());
  return match;
};

// Hooks
export const {
  useGetTaxDataQuery,
  useGetNavigationSectionsQuery,
  useGetSubmitWarningsQuery,
  useGetSubmitIssuesQuery,
  useEstimateTaxResultsQuery
} = taxDataApi;

// Selectors
export const ssnMatchedSelector = createSelector(
  [taxDataApi.endpoints.getIdMatch.select()],
  ({ data: ssnMatched }) => ssnMatched ?? null
);

export const submitIssueItemsSelector = createSelector(
  [taxDataApi.endpoints.getSubmitIssues.select()],
  ({ data: submitIssues }) => submitIssues || []
);

export const submitWarningsSelector = createSelector(
  [taxDataApi.endpoints.getSubmitWarnings.select()],
  ({ data: submitWarnings }) => submitWarnings || []
);

export const taxDataSelector = (collectionType) =>
  createSelector([taxDataApi.endpoints.getTaxData.select(collectionType)], ({ data: taxData }) => taxData);

export default taxDataApi;
