import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import url from 'url';
import history from '@app/src/keeperHistory';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import WarningIcon from '@app/src/assets/warning.svg?react';
import { getQuestionOcrError } from '@app/src/taxflow/shared/utils/sharedUtils';
import { SLUG__STATE_RESIDENCY, SLUG__STATE_RETURN } from '@app/src/taxflow/sections/state/constants/stateConstants';
import { currentCollectionIdSelector, queryResultsSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import {
  ENDPOINT_ATTRIBUTE__CREDIT_IRA_CONTRIBUTION_NEW_YEAR,
  SLUG__CREDIT_CHILD_CARE_WHO
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { PATH_COMPONENT__DEPENDENT_DETAIL } from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD,
  CATEGORY_TYPE_OPTIONS
} from '@app/src/constants/constants';
import { filteredErrorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { collectionUploadStatusSelector } from '@app/src/selectors/taxFlowSelectors';
import { getFormUploadErrorMessage } from '@app/src/taxflow/collection/utils/collectionUtils';
import { allQuestionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { showWarningMessage } from '@app/src/taxflow/shared/utils/warningUtils';
import { bulkUploadErrorAttemptsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { SLUG__BULK_UPLOAD } from '@app/src/taxflow/sections/special/constants/specialConstants';
import '@app/src/Components/TaxValidation/TaxValidationWarning.scss';
import TaxValidationNonBlockingWarning from '@app/src/Components/TaxValidation/TaxValidationNonBlockingWarning';

/**
 * Mapping between slugs and text to display given presence of an ocr error
 * Normally, we would keep this in contentful. However, this would require enlargement of the schema for the our vast collection of Tax Flow Questions
 * Since fetching contentful data is a contributor to performance issues, let's hardcode this here for now
 * TODO migrate out once we address contentful - related perf issues
 */
const SLUG_TO_OCR_ERROR = {
  [ENDPOINT_ATTRIBUTE__CREDIT_IRA_CONTRIBUTION_NEW_YEAR]:
    'If you made IRA contributions during the first 4 months of the year, enter them here.'
};

const TaxValidationWarning = ({
  className,
  currentQuestion,
  currentAnswer,
  question,
  answer,
  queryResults,
  formUploadStatus,
  errors,
  collectionId,
  slug1,
  slug2,
  allQuestions,
  addDependent,
  overrideCollectionId,
  bulkUploadErrorAttempts,
  ...props
}) => {
  const collId = overrideCollectionId ? `${overrideCollectionId}` : collectionId;
  const collType = _.get(question, 'collectionType');

  const blueWarningMessage =
    _.get(props, 'blueWarningMessage', false) || currentQuestion.slug === SLUG__STATE_RESIDENCY;
  const hasOcrError = !!getQuestionOcrError({ errors, question, collectionId: collId });
  const hasUploadError = _.get(formUploadStatus, 'status') === 'failed';
  const hasBulkUploadError = currentQuestion.slug === SLUG__BULK_UPLOAD && bulkUploadErrorAttempts.length > 0;
  const hasWarning = showWarningMessage({
    question: currentQuestion,
    subquestion: question,
    answer: currentAnswer,
    subanswer: answer,
    props: { queryResults, slug1, slug2, errors, allQuestions, addDependent, collectionId: collId }
  });

  const currentError = _.find(errors, {
    slug: question.endpoint_attr,
    coll_id: collId,
    coll_type: collType
  });

  if (
    !(hasWarning || hasOcrError || hasUploadError || hasBulkUploadError) &&
    !(currentError && question.question_type === CATEGORY_TYPE_OPTIONS)
  ) {
    return null;
  }

  let warningMessage;
  if (hasBulkUploadError) {
    warningMessage = 'Please delete any forms that failed upload before proceeding.';
  } else if (hasOcrError) {
    warningMessage = _.get(
      SLUG_TO_OCR_ERROR,
      _.get(question, 'endpoint_attr'),
      "We couldn't detect this field in your form."
    );
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD && hasUploadError) {
    warningMessage = getFormUploadErrorMessage({
      errorMessage: formUploadStatus.errorMessage
    });
  } else if (currentQuestion.slug === SLUG__STATE_RETURN) {
    warningMessage = 'Please select a different state.';
  } else if (addDependent && question.slug === SLUG__CREDIT_CHILD_CARE_WHO) {
    const dependentUrl = url.format({
      pathname: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__DEPENDENT_DETAIL}`,
      query: {
        collectionId: '1'
      }
    });
    warningMessage = (
      <>
        <div className='tax-validation-warning-link-text'>First add a dependent to complete this section.</div>
        <Button
          fullWidth
          variant='label'
          className='tax-flow-add-dependent-btn'
          onClick={() => history.push(dependentUrl)}
        >
          Add a dependent
        </Button>
      </>
    );
  } else if (!addDependent && question.slug === SLUG__CREDIT_CHILD_CARE_WHO && !hasWarning) {
    return null;
  } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE) {
    const formattedAnswer = moment(_.get(answer, 'value'), 'DD/MMM/YYYY');
    if (!formattedAnswer.isValid()) {
      warningMessage = 'Please enter a valid date.';
    } else {
      warningMessage = _.get(question, ['question_meta', 'warningMessage']);
    }
  } else if (
    currentError &&
    question.question_type === CATEGORY_TYPE_OPTIONS &&
    blueWarningMessage &&
    !_.get(currentError, 'warningMessage')
  ) {
    warningMessage = 'This selection is invalid';
  } else {
    warningMessage =
      _.get(currentError, 'warningMessage') ||
      _.get(question, ['question_meta', 'warningMessage']) ||
      _.get(question, ['question_meta_ext', 'warningMessage']);
  }
  if (!warningMessage) {
    return null;
  }

  const isBlocking = _.get(currentError, 'blocking', true);

  return isBlocking ? (
    <div
      className={classNames('tax-validation-warning', className, {
        'tax-validation-warning-blue': blueWarningMessage,
        'tax-validation-warning-champagne': hasBulkUploadError
      })}
    >
      {(blueWarningMessage || hasBulkUploadError) && (
        <div>
          <WarningIcon width={20} height={20} />
        </div>
      )}
      {warningMessage}
    </div>
  ) : (
    <TaxValidationNonBlockingWarning
      warningMessage={warningMessage}
      validationSlug={_.get(currentError, 'validationSlug')}
      coll_id={collId}
      coll_type={collType}
    />
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: state.taxFlow.currentQuestion,
  currentAnswer: state.taxFlow.currentAnswer,
  queryResults: queryResultsSelector(state),
  errors: filteredErrorsSelector(state),
  collectionId: currentCollectionIdSelector(state),
  formUploadStatus: collectionUploadStatusSelector(state),
  allQuestions: allQuestionsSelector(state),
  bulkUploadErrorAttempts: bulkUploadErrorAttemptsSelector(state)
});

const ConnectedTaxValidationWarning = connect(mapStateToProps)(TaxValidationWarning);

export default ConnectedTaxValidationWarning;
