import React from 'react';
import { connect } from 'react-redux';
import MessageQuestionIcon from '@app/src/assets/message-question.svg?react';
import { setInfoModalShow, setInfoModalItem } from '@app/src/actions/taxFlowActions';
import { infoModalItemSelector, infoModalShowSelector } from '@app/src/selectors/taxFlowModalsSelectors';

const TaxFlowInfoModal = ({ setInfoModalShow, setInfoModalItem, title, source, compositeUrl, image }) => {
  const onOpen = () => {
    setInfoModalItem({
      title,
      source,
      compositeUrl,
      image,
      origin: 'taxFlowInfoModal'
    });
    setInfoModalShow(true);
  };

  return <MessageQuestionIcon className='checkbox-item-icon-info' onClick={onOpen} />;
};

const mapStateToProps = (state) => ({
  infoModalShow: infoModalShowSelector(state),
  infoModalItem: infoModalItemSelector(state)
});

const mapDispatchToProps = {
  setInfoModalShow,
  setInfoModalItem
};

const ConnectedTaxFlowInfoModal = connect(mapStateToProps, mapDispatchToProps)(TaxFlowInfoModal);

export default ConnectedTaxFlowInfoModal;
