import {
  GET_WORK_INFO,
  LOAD_WORK_INFO,
  UPDATE_WORK_RES_MSG,
  UPDATE_WORK_CATEGORIES,
  SET_CAMPAIGN,
  SET_FEATURES,
  SET_BUILD,
  SET_FEATURES_LOADING
} from '@app/src/actions/types';

/**
 * @desc Set Bank List
 * @param {Object} payload
 */
export const setWorkDetails = (payload) => {
  return {
    type: GET_WORK_INFO,
    payload: payload
  };
};

/**
 * @desc Set Work Loader
 */
export const setWorkLoader = (payload) => {
  return {
    type: LOAD_WORK_INFO,
    payload: payload
  };
};

/**
 * @desc Update Work Res messages
 */
export const setWorkResMsg = (payload) => {
  const { success, error } = payload;
  const resObj = {
    success: success !== undefined ? success : '',
    error: error !== undefined ? error : ''
  };
  return {
    type: UPDATE_WORK_RES_MSG,
    payload: resObj
  };
};

export const updateWorkCategories = (payload) => ({
  type: UPDATE_WORK_CATEGORIES,
  payload
});

export const setCampaign = (payload) => ({
  type: SET_CAMPAIGN,
  payload
});

export const setFeatures = (payload) => ({
  type: SET_FEATURES,
  payload
});

export const setFeaturesLoading = (payload) => ({
  type: SET_FEATURES_LOADING,
  payload
});

export const setBuild = (payload) => ({
  type: SET_BUILD,
  payload
});
