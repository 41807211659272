import axios from 'axios';
import _ from 'lodash';

import {
  setIsCancelingSubscription,
  setSettingsError,
  setSettingsLoading,
  setSettingsModalType
} from '@app/src/actions/settingsActions';
import { SUBSCRIPTION_FEATURES } from '@app/src/constants/subscriptionConstants';
import { serverUrl } from '@app/src/global/Environment';
import { chargeableAccountSelector } from '@app/src/selectors/bankSelectors';
import { subscriptionTypeSelector, trialLengthSelector } from '@app/src/selectors/pricingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getSettings } from '@app/src/services/authService';
import {
  cancelSubscription,
  createSubscription,
  deleteSubscription,
  getAccountDetails,
  refundSubscription
} from '@app/src/services/pricingService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const sendChurnFeedback = (comment) => async (dispatch, getState) => {
  try {
    const { email, phone } = userSelector(getState());
    const slackMsg = {
      text: 'Got a new feedback comment',
      attachments: [
        {
          title: phone,
          text: email,
          fields: [
            comment
              ? {
                  title: 'Comment',
                  value: String(comment)
                }
              : {}
          ]
        }
      ]
    };
    await axios.post(`${baseUrl}api/profile/churn-feedback`, { slackMsg });
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const cancelMembership =
  ({ comment, refund }) =>
  async (dispatch) => {
    dispatch(setIsCancelingSubscription(true));
    try {
      if (refund) {
        await dispatch(refundSubscription());

        const message =
          'Your membership has been cancelled and a refund has been initiated. Refunds may take 5 - 10 days to appear on your statement.';

        notify(message);
      }

      await dispatch(cancelSubscription());
      await dispatch(deleteSubscription());
      await dispatch(getAccountDetails());

      trackActivity('settings: membership canceled', { origin: 'web', comment });
    } catch (error) {
      defaultCaptureException(error);
    } finally {
      dispatch(setIsCancelingSubscription(false));
    }
  };

export const updateSubscription = () => async (dispatch, getState) => {
  try {
    dispatch(setSettingsLoading(true));

    const chargeableAccount = chargeableAccountSelector(getState());

    const account_id = _.get(chargeableAccount, 'account_id');
    const skipAchLink = _.get(chargeableAccount, 'subtype') === 'credit card';
    const planType = subscriptionTypeSelector(getState());
    const pricing = _.get(SUBSCRIPTION_FEATURES, [planType, 'price']);
    const freeTrial = trialLengthSelector(getState());

    await dispatch(
      createSubscription({
        account_id,
        pricing,
        freeTrial,
        origin: 'settings',
        planType,
        skipAchLink
      })
    );

    await dispatch(getSettings());
    await dispatch(getAccountDetails());

    dispatch(setSettingsError(`Subscription updated to ${planType}.`));
    dispatch(setSettingsModalType(''));
  } catch (e) {
    defaultCaptureException(e);
  } finally {
    dispatch(setSettingsLoading(false));
  }
};
