import _ from 'lodash';
import { useEffect } from 'react';
import history from '@app/src/keeperHistory';
import qs from 'qs';

const BankRelink = () => {
  useEffect(() => {
    // get the item id from storage and return to the correct bank details url
    const itemId = localStorage.getItem('keeper_item_id');
    if (!_.isNil(itemId)) {
      localStorage.removeItem('keeper_item_id');

      const oauth_state_id = qs.parse(window.location.search, { ignoreQueryPrefix: true }).oauth_state_id;

      history.replace({
        pathname: `/bank-details/${itemId}`,
        search: `?${qs.stringify({
          oauth_state_id
        })}`
      });
    }
  }, []);

  return null;
};

export default BankRelink;
