import _ from 'lodash';
import { CATEGORY_TYPE_NAVIGATION } from '@app/src/constants/constants';
import {
  COLLECTION_TYPE__INCOME_1099G,
  COLLECTION_TYPE__INCOME_DIV,
  COLLECTION_TYPE__INCOME_FREELANCE,
  COLLECTION_TYPE__INCOME_INTEREST,
  COLLECTION_TYPE__INCOME_INVEST,
  COLLECTION_TYPE__INCOME_PERSONAL_ITEMS,
  COLLECTION_TYPE__INCOME_RETIREMENT,
  COLLECTION_TYPE__INCOME_W2,
  COLLECTION_TYPE__INCOME_W2G,
  ENDPOINT_ATTRIBUTE__INCOME_1099G_COMPENSATION,
  ENDPOINT_ATTRIBUTE__INCOME_DIV_ORDINARY,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_MISC_BOX_2,
  ENDPOINT_ATTRIBUTE__INCOME_INTEREST_AMOUNT,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_SALE_PROCEEDS,
  ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_AMOUNT,
  ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_PENSION_GROSS,
  ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_SSA_NET,
  ENDPOINT_ATTRIBUTE__INCOME_W2G_REPORTABLE_WINNINGS,
  ENDPOINT_ATTRIBUTE__INCOME_W2_WAGES
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  COLLECTION_TYPE__SPECIAL,
  ENDPOINT_ATTRIBUTE__SPECIAL_WHO
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
  ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT,
  PATH_COMPONENT__SUBMIT_ACCEPTED_BY_IRS,
  PATH_COMPONENT__SUBMIT_FILED_WITH_IRS,
  PATH_COMPONENT__SUBMIT_BLOCKED,
  PATH_COMPONENT__SUBMIT_BLOCKED_FINAL_REVIEW,
  PATH_COMPONENT__SUBMIT_BYE,
  PATH_COMPONENT__SUBMIT_BYE_MANUAL,
  PATH_COMPONENT__SUBMIT_BYE_MANUAL_ESC,
  PATH_COMPONENT__SUBMIT_EMAIL_OUTBOX,
  PATH_COMPONENT__SUBMIT_SENT_TO_IRS,
  PATH_COMPONENT__SUBMIT_UNABLE_TO_FILE,
  PATH_COMPONENT__SUBMIT_TAX_AMOUNT,
  PATH_COMPONENT__SUBMIT_DEBIT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  DEFAULT_COLLECTION_ID,
  SLUG__CONTACT_SUPPORT,
  SLUG__PAST_RETURNS,
  UI_STAGE__ACCEPTED_BY_IRS,
  UI_STAGE__BLOCKED_FINAL_REVIEW,
  UI_STAGE__BLOCKED_PREFIX,
  UI_STAGE__DO_NOT_FILE,
  UI_STAGE__EMAIL_OUTBOX_PREFIX,
  UI_STAGE__FILED_WITH_IRS,
  UI_STAGE__FINAL_REVIEW,
  UI_STAGE__OPS_REVIEW,
  UI_STAGE__PURGATORY,
  UI_STAGE__UPLOAD_ESC,
  UI_STAGE__CONFIRM_AMOUNTS
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { getSlugMap as getSlugMapSubmit } from '@app/src/taxflow/sections/submit';

/**
 * Get a mapping between a ui stage and it's respective landing page
 */
export const getUIStagePathMap = ({ uncheckedSubmitIssues = [] }) => ({
  [UI_STAGE__PURGATORY]: PATH_COMPONENT__SUBMIT_BYE,
  [UI_STAGE__OPS_REVIEW]: PATH_COMPONENT__SUBMIT_BYE_MANUAL,
  [UI_STAGE__UPLOAD_ESC]: PATH_COMPONENT__SUBMIT_BYE_MANUAL_ESC,
  [UI_STAGE__CONFIRM_AMOUNTS]: _.isEmpty(uncheckedSubmitIssues)
    ? PATH_COMPONENT__SUBMIT_TAX_AMOUNT
    : PATH_COMPONENT__SUBMIT_DEBIT,
  [UI_STAGE__FINAL_REVIEW]: PATH_COMPONENT__SUBMIT_SENT_TO_IRS,
  [UI_STAGE__BLOCKED_FINAL_REVIEW]: PATH_COMPONENT__SUBMIT_BLOCKED_FINAL_REVIEW,
  [UI_STAGE__BLOCKED_PREFIX]: PATH_COMPONENT__SUBMIT_BLOCKED,
  [UI_STAGE__EMAIL_OUTBOX_PREFIX]: PATH_COMPONENT__SUBMIT_EMAIL_OUTBOX,
  [UI_STAGE__FILED_WITH_IRS]: PATH_COMPONENT__SUBMIT_FILED_WITH_IRS,
  [UI_STAGE__ACCEPTED_BY_IRS]: PATH_COMPONENT__SUBMIT_ACCEPTED_BY_IRS,
  [UI_STAGE__DO_NOT_FILE]: PATH_COMPONENT__SUBMIT_UNABLE_TO_FILE
});

// redirect locked ui stages to the correct screen
export const getNavigationLockedRedirects = ({ uiStage, slug, isStagingProdDb }) => {
  // special case ops ui stages
  // format: blocked - {prevUiStage} / email outbox - {prevUiStage}
  if (uiStage.startsWith(UI_STAGE__BLOCKED_PREFIX) && uiStage !== UI_STAGE__BLOCKED_FINAL_REVIEW) {
    uiStage = UI_STAGE__BLOCKED_PREFIX;
  } else if (uiStage.startsWith(UI_STAGE__EMAIL_OUTBOX_PREFIX)) {
    uiStage = UI_STAGE__EMAIL_OUTBOX_PREFIX;
  }

  // Skip confirm amounts - this flow is not technically "locked" to a single page as the user can navigate between multiple pages (ex submit-debit and submit-debit-manual)
  const currentUiStagePath = _.chain(getUIStagePathMap({})).omit([UI_STAGE__CONFIRM_AMOUNTS]).get(uiStage).value();
  // dont redirect if user's current ui stage isn't one of the above
  if (!currentUiStagePath) {
    return;
  }

  if (isStagingProdDb) {
    // for staging-prod-db only redirect from initial nav screen instead of strict redirects
    // purpose: ops impersonation -- allow ops to navigate around tax flow even if user is locked
    return;
  } else if (
    _.get(getSlugMapSubmit(), currentUiStagePath) !== slug &&
    slug !== SLUG__CONTACT_SUPPORT &&
    slug !== SLUG__PAST_RETURNS
  ) {
    // redirect if user isn't on the correct screen
    return currentUiStagePath;
  }

  return;
};

export const getQuestionQueries = ({ question }) => {
  if (question.question_type === CATEGORY_TYPE_NAVIGATION) {
    return [
      {
        coll_type: COLLECTION_TYPE__SPECIAL,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SPECIAL_WHO
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INCOME
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_FREELANCE,
        slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_MISC_BOX_2
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_W2,
        slug: ENDPOINT_ATTRIBUTE__INCOME_W2_WAGES
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_INTEREST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INTEREST_AMOUNT
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_DIV,
        slug: ENDPOINT_ATTRIBUTE__INCOME_DIV_ORDINARY
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_INVEST,
        slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_SALE_PROCEEDS
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_1099G,
        slug: ENDPOINT_ATTRIBUTE__INCOME_1099G_COMPENSATION
      },
      {
        collectionType: COLLECTION_TYPE__INCOME_W2G,
        slug: ENDPOINT_ATTRIBUTE__INCOME_W2G_REPORTABLE_WINNINGS
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_RETIREMENT,
        slug: ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_PENSION_GROSS
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_RETIREMENT,
        slug: ENDPOINT_ATTRIBUTE__INCOME_RETIREMENT_SSA_NET
      },
      {
        coll_type: COLLECTION_TYPE__INCOME_PERSONAL_ITEMS,
        slug: ENDPOINT_ATTRIBUTE__INCOME_PERSONAL_ITEMS_AMOUNT
      },
      {
        coll_type: COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
        coll_id: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT
      }
    ];
  }

  return [];
};

export const getOriginParam = ({ location }) => {
  return new URLSearchParams(location.search).get('origin');
};
