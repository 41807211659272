import { useState, useEffect, useRef, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useVirtualizer } from '@tanstack/react-virtual';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

export const formatStreamMessage = (message) => {
  return {
    id: message.id,
    sender: (() => {
      if (message.user.id.startsWith('assistant_id')) {
        return 'keeper';
      }

      if (message.user.id.startsWith('keeper_id')) {
        return 'caitlin';
      }

      if (message.user.id.startsWith('user_id')) {
        return 'user';
      }

      return null;
    })(),
    content: message.text,
    type: message.type,
    created_at: message.created_at,
    user: message.user,
    notification_type: message.notification_type,
    attachments: message.attachments,
    markdown: message.markdown,
    origin: message.origin
  };
};

export const formatAndAddNewMessage = (messages, message) => {
  const lastMessage = messages[messages.length - 1];

  if (message.notification_type) {
    return messages;
  }

  const formattedMessage = {
    ...message,
    hideAvatar: _.get(lastMessage, 'type') !== 'system' && _.get(lastMessage, 'sender') === message.sender
  };

  const timestampMessageThreshold = 300;

  const isTimeForNewTimestamp =
    _.get(lastMessage, 'created_at') &&
    moment(message.created_at).diff(moment(lastMessage.created_at), 'minutes') > timestampMessageThreshold;

  if (_.isEmpty(messages) || isTimeForNewTimestamp) {
    return [...messages, generateTimestampMessage(message), formattedMessage];
  }

  return [...messages, formattedMessage];
};

const generateTimestampMessage = (message) => {
  return {
    sender: null,
    content: moment(message.created_at).format('ddd, MMM D h:mm A'),
    type: 'system',
    created_at: message.created_at
  };
};

export const scrollToBottom = (virtualizer, { observationTime = 200, imageLoadTimeout = 2000 } = {}) => {
  const lastIndex = virtualizer?.options?.count - 1;

  // When images load in, the layout will change and the virtualizer will need to be scrolled to the bottom again
  const observer = new MutationObserver((records) => {
    for (const record of records) {
      for (let node of record.addedNodes) {
        if (typeof node?.querySelectorAll !== 'function') {
          continue;
        }

        const images = node.querySelectorAll('img');

        // Scrolls to bottom when images load in
        for (const image of images) {
          if (!image.complete) {
            const realignAndRemoveLoadListener = () => {
              virtualizer.scrollToIndex(virtualizer.options.count - 1);
              image.removeEventListener('load', realignAndRemoveLoadListener);
            };

            image.addEventListener('load', realignAndRemoveLoadListener);

            setTimeout(() => {
              image.removeEventListener('load', realignAndRemoveLoadListener);
            }, imageLoadTimeout);
          }
        }
      }
    }
  });

  if (virtualizer?.options?.count <= 0) return observer;

  try {
    // Returns an unexpected undefined if the virtualizer count is 0
    virtualizer.scrollToIndex(lastIndex);
  } catch (error) {
    defaultCaptureException(error, {
      extra: {
        lastIndex,
        virtualizerOptions: virtualizer.options
      }
    });
  }

  const messageItems = virtualizer.scrollElement.querySelector('.assistant-messages-items');

  if (!messageItems) return observer;

  observer.observe(messageItems, {
    childList: true,
    subtree: true
  });

  setTimeout(() => {
    observer.disconnect();
  }, observationTime);

  return observer;
};

export const useVirtualizedItems = ({ messages = [], error, getScrollElement, open, estimateSize }) => {
  const items = useMemo(
    () =>
      _.compact([
        ...messages.flatMap((message) => {
          return [
            {
              type: 'message',
              payload: { message }
            },
            !_.isEmpty(message.attachments) && {
              type: 'attachments',
              payload: { message }
            }
          ];
        }),
        error && {
          type: 'error',
          payload: {
            error
          }
        }
      ]),
    [error, messages]
  );

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement,
    estimateSize,
    scrollPaddingStart: 16,
    scrollPaddingEnd: 16,
    paddingStart: 16,
    paddingEnd: 16,
    onChange: () => {},
    overscan: 3
  });

  // Scroll when messages updates or when the assistant opens
  useEffect(() => {
    if (!open) return;

    if (!virtualizer.scrollElement) return;

    const observer = scrollToBottom(virtualizer);

    return () => {
      observer.disconnect();
    };
  }, [open, virtualizer, items]);

  return [items, virtualizer];
};

export const useImageHeight = (image) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);

  const width = ref?.current?.offsetWidth;

  const height = (() => {
    if (loading && image?.height && image?.width && width) {
      return (image.height / image.width) * width;
    }
  })();

  const onLoad = () => {
    setLoading(false);
  };

  return [height, ref, onLoad];
};

export const useImageCache = () => {
  const [imageCache, setImageCache] = useState({});

  const getImage = (src) => {
    if (imageCache[src]) return imageCache[src];

    const image = new Image();

    if (_.isNil(src)) {
      return image;
    }

    image.src = src;

    image.onload = () => {
      setImageCache((cache) => ({
        ...cache,
        [src]: image
      }));
    };

    return image;
  };

  return getImage;
};
