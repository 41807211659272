import _ from 'lodash';
import React from 'react';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import Button from '@mui/material/Button';
import '@app/src/Components/TaxFlow/Question/TaxFlowSupportAccessElement.scss';

const TaxFlowSupportAccessElement = ({ currentQuestion }) => {
  const askTaxAssistantText = _.get(currentQuestion, ['question_meta', 'askTaxAssistantText']);
  const emailSupportText = _.get(currentQuestion, ['question_meta', 'emailSupportText']);
  const visitHelpCenterText = _.get(currentQuestion, ['question_meta', 'visitHelpCenterText']);

  const mobile = isReactNative();

  const onTaxAssistantClick = () => {
    sentMsgToReactNative('tax_support_ask_assistant');
  };

  const onVisitHelpCenterClick = (mobile) => {
    if (mobile) {
      sentMsgToReactNative('tax_support_open_faq');
    } else {
      window.open('https://help.keepertax.com/hc/en-us', '_blank');
    }
  };

  return (
    <div className='taxflow-support-access-element'>
      {mobile
        ? askTaxAssistantText && (
            <div className='taxflow-support-access-element-item'>
              <p>{askTaxAssistantText}</p>
              <Button
                fullWidth
                size='large'
                variant='contained'
                color='secondary'
                onClick={() => onTaxAssistantClick()}
              >
                Ask tax assistant
              </Button>
            </div>
          )
        : emailSupportText && (
            <div className='taxflow-support-access-element-item'>
              <p>{emailSupportText}</p>
              <p>
                <a href='mailto:support@keepertax.com'>support@keepertax.com</a>
              </p>
            </div>
          )}

      {visitHelpCenterText && (
        <div className='taxflow-support-access-element-item'>
          <p>{visitHelpCenterText}</p>
          <Button
            fullWidth
            size='large'
            variant='contained'
            color='secondary'
            onClick={() => onVisitHelpCenterClick(mobile)}
          >
            Visit the help center
          </Button>
        </div>
      )}
    </div>
  );
};

export default TaxFlowSupportAccessElement;
