import _ from 'lodash';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';
import { RETURN_URL_KEY_NAME, Url_TAXFLOW_BASEURL } from '@app/src/constants/constants';

const LoginPublicOnlyRoute = ({ location, component: Component, isAuthenticated, ...props }) => {
  const parseReturnUrl = (location) => {
    const returnUrl = _.get(qs.parse(location.search, { ignoreQueryPrefix: true }), RETURN_URL_KEY_NAME);
    if (!returnUrl || returnUrl[0] !== '/' || returnUrl === '/') {
      return Url_TAXFLOW_BASEURL;
    }
    return returnUrl || Url_TAXFLOW_BASEURL;
  };

  return (
    <Route
      render={(props) =>
        isAuthenticated ? <Redirect to={parseReturnUrl(location)} {...props} /> : <Component {...props} />
      }
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: _.get(state, ['auth', 'isAuthenticated'])
});

const ConnectedLoginPublicOnlyRoute = connect(mapStateToProps)(LoginPublicOnlyRoute);

export default ConnectedLoginPublicOnlyRoute;
