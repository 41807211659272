import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import React, { forwardRef, useCallback, useRef, useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';
import { useLocation } from 'react-router-dom';
import history from '@app/src/keeperHistory';

import ExpenseReviewCategoryCell from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCell';
import ExpenseReviewExpensesNoResultsText from '@app/src/Components/ExpenseReview/ExpenseReviewExpensesNoResultsText';
import ExpenseReviewFilterSelect from '@app/src/Components/ExpenseReview/ExpenseReviewFilterSelect';
import ExpenseReviewStatusCell from '@app/src/Components/ExpenseReview/ExpenseReviewStatusCell';
import { setExpenseReviewModalType, setSelectedExpense } from '@app/src/actions/expenseReviewActions';
import { AMOUNT_FILTER_OPTIONS_LIST, STATUS_FILTER_OPTIONS_LIST } from '@app/src/constants/constants';
import { currencyWith2DecimalPlaces } from '@app/src/global/Helpers';
import {
  accountFilterOptionsListSelector,
  accountFilterSelectionsSelector,
  amountFilterSelectionsSelector,
  categoryFilterOptionsListSelector,
  categoryFilterSelectionsSelector,
  categoryIdsToDisplayNamesSelector,
  expensesCountSelector,
  expensesYearSelector,
  hasFetchedMaxExpensesSelector,
  hasFiltersSelectedSelector,
  isAccountFilterSelectedSelector,
  isAmountFilterSelectedSelector,
  isCategoryFilterSelectedSelector,
  isInitialExpensesLoadSelector,
  isStatusFilterSelectedSelector,
  loadingSelector,
  orderedExpensesSelector,
  statusFilterSelectionsSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import {
  getExpenses,
  updateAccountFilter,
  updateAmountFilter,
  updateCategoryFilter,
  updateExpenseStatus,
  updateStatusFilter
} from '@app/src/services/expenseReviewService';
import { hasActiveSubscriptionSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { colorAccentLight, colorNeutralMetallica, colorPrimaryAlt2, colorSurface } from '@app/src/theme';

import '@app/src/Components/ExpenseReview/ExpenseReview.scss';
import '@app/src/Components/ExpenseReview/ExpenseReviewExpensesTable.scss';

const TableComponents = {
  Scroller: forwardRef((props, ref) => <TableContainer {...props} ref={ref} />),
  Table: (props) => <Table {...props} size='small' style={{ borderCollapse: 'separate' }} />,
  TableHead: TableHead,
  TableRow: ({ context: { highlightedIndex }, ...props }) => {
    return <TableRow {...props} selected={highlightedIndex === props['data-index']} />;
  },
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  EmptyPlaceholder: () => <ExpenseReviewExpensesNoResultsText />
};

const TableHeader = ({ headerCells }) => (
  <TableRow style={{ backgroundColor: colorSurface, height: '36px' }}>
    {headerCells.map(({ id, label, width, sx }) => (
      <TableCell key={id} style={{ width }} sx={sx}>
        {label}
      </TableCell>
    ))}
  </TableRow>
);

const ExpenseReviewExpensesTable = ({
  orderedExpenses,
  isInitialExpensesLoad,
  expensesCount,
  hasFetchedMaxExpenses,
  loading,
  categoryFilterSelections,
  accountFilterSelections,
  statusFilterSelections,
  amountFilterSelections,
  isCategoryFilterSelected,
  categoryIdsToDisplayNames,
  isAmountFilterSelected,
  isAccountFilterSelected,
  isStatusFilterSelected,
  accountFilterOptionsList,
  categoryFilterOptionsList,
  getExpenses,
  updateCategoryFilter,
  updateAccountFilter,
  updateStatusFilter,
  updateAmountFilter,
  setSelectedExpense,
  setExpenseReviewModalType
}) => {
  const tableContainerRef = useRef(null);
  const [highlightedExpenseIndex, setHighlightedExpenseIndex] = useState(null);

  const onRowClick = useCallback(
    ({ transaction_id, clean_name, keeper_category_id, note, ...row }) => {
      trackActivity('navigation: expense quickview', {
        transaction_id,
        clean_name,
        keeper_category_id,
        note,
        origin: 'web dashboard'
      });

      setSelectedExpense({
        transactionId: transaction_id,
        data: { transaction_id, clean_name, keeper_category_id, note, ...row }
      });

      setExpenseReviewModalType('edit');
      setHighlightedExpenseIndex(null);
    },
    [setSelectedExpense, setExpenseReviewModalType]
  );

  const headerCells = [
    {
      id: 'date',
      label: 'Date',
      width: '15%',
      sx: { pl: 2 }
    },
    {
      id: 'merchant',
      label: 'Merchant',
      width: '20%'
    },
    {
      id: 'account',
      label: (
        <ExpenseReviewFilterSelect
          value={accountFilterSelections}
          filterSelected={isAccountFilterSelected}
          onChange={({ target: { value } }) => updateAccountFilter(value)}
          renderValue={() => 'Account'}
          filterOptions={accountFilterOptionsList}
          menuItemValueKey='accountId'
          menuItemDisplayKey='displayName'
        />
      ),
      width: '20%'
    },
    {
      id: 'status',
      label: (
        <ExpenseReviewFilterSelect
          value={statusFilterSelections}
          filterSelected={isStatusFilterSelected}
          onChange={({ target: { value } }) => updateStatusFilter(value)}
          renderValue={() => 'Status'}
          filterOptions={STATUS_FILTER_OPTIONS_LIST}
          menuItemValueKey='value'
          menuItemDisplayKey='status'
        />
      ),
      width: '20%'
    },
    {
      id: 'category',
      label: (
        <ExpenseReviewFilterSelect
          value={categoryFilterSelections}
          filterSelected={isCategoryFilterSelected}
          onChange={({ newValue }) => updateCategoryFilter(newValue?.value)}
          renderValue={() => 'Category'}
          type={'category'}
          filterOptions={categoryFilterOptionsList}
          menuItemValueKey='categoryId'
          menuItemDisplayKey='displayName'
        />
      ),
      width: '20%'
    },
    {
      id: 'amount',
      label: (
        <ExpenseReviewFilterSelect
          value={amountFilterSelections}
          filterSelected={isAmountFilterSelected}
          onChange={({ target: { value } }) => updateAmountFilter(value)}
          renderValue={() => 'Amount'}
          filterOptions={AMOUNT_FILTER_OPTIONS_LIST}
          menuItemValueKey='value'
          menuItemDisplayKey='amount'
        />
      ),
      width: '5%'
    }
  ];

  const tableData = useMemo(
    () => (isInitialExpensesLoad ? Array(30).fill({}) : orderedExpenses),
    [isInitialExpensesLoad, orderedExpenses]
  );

  const { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    // Prevents race condition since year is handled elsewhere
    if (searchParams.has('year')) {
      return;
    }

    if (loading || isInitialExpensesLoad) {
      return;
    }

    if (!searchParams.has('transaction_id')) {
      return;
    }

    const transactionId = searchParams.get('transaction_id');
    const index = _.findIndex(tableData, { transaction_id: transactionId });

    if (index === -1) {
      return;
    }

    tableContainerRef.current.scrollIntoView({
      index,
      align: 'start',
      behavior: 'smooth'
    });

    searchParams.delete('transaction_id');

    history.replace({
      search: searchParams.toString()
    });

    setHighlightedExpenseIndex(index);
  }, [isInitialExpensesLoad, loading, search, tableData]);

  const loadMoreExpenses = async () => {
    if (!hasFetchedMaxExpenses && !loading && !isInitialExpensesLoad) {
      await getExpenses({ offset: expensesCount });
    }
  };

  const renderCellContent = (cell) => {
    if (isInitialExpensesLoad) {
      return (
        <Skeleton
          animation='wave'
          height='36px'
          sx={{ pl: 2, bgcolor: colorPrimaryAlt2, color: colorAccentLight }}
          variant='text'
          width='50%'
        />
      );
    } else if (_.isEmpty(orderedExpenses)) {
      return '-';
    }

    return cell;
  };

  return (
    <TableVirtuoso
      className={'expense-review-expenses-table'}
      ref={tableContainerRef}
      style={{
        backgroundColor: colorSurface,
        borderRadius: '8px',
        border: `1px solid ${colorNeutralMetallica}`
      }}
      data={tableData}
      endReached={loadMoreExpenses}
      components={TableComponents}
      fixedHeaderContent={() => <TableHeader headerCells={headerCells} />}
      context={{ highlightedIndex: highlightedExpenseIndex }}
      defaultItemHeight={36}
      itemContent={(_, data) => (
        <>
          <TableCell sx={{ pl: 2 }} onClick={() => onRowClick(data)}>
            {renderCellContent(<div>{moment(data.date, 'YYYY-MM-DD').format('ddd, M/D/YY')}</div>)}
          </TableCell>
          <TableCell onClick={() => onRowClick(data)}>{renderCellContent(<div>{data.clean_name}</div>)}</TableCell>
          <TableCell onClick={() => onRowClick(data)}>
            {renderCellContent(<div>{data.acct_name || capitalize(data.bank_acct_name) || 'Other'}</div>)}
          </TableCell>
          <TableCell onClick={() => onRowClick(data)}>
            {renderCellContent(<ExpenseReviewStatusCell data={data} />)}
          </TableCell>
          <TableCell onClick={() => onRowClick(data)}>
            {renderCellContent(
              <ExpenseReviewCategoryCell
                id={data.keeper_category_id}
                className='expense-review-expenses-table-cell-text-cat'
                isPersonal={data.isPersonal}
                value={categoryIdsToDisplayNames[data.keeper_category_id]}
              />
            )}
          </TableCell>
          <TableCell onClick={() => onRowClick(data)}>
            {renderCellContent(<div>{currencyWith2DecimalPlaces(data.amount)}</div>)}
          </TableCell>
        </>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  orderedExpenses: orderedExpensesSelector(state),
  isInitialExpensesLoad: isInitialExpensesLoadSelector(state),
  expensesCount: expensesCountSelector(state),
  hasFetchedMaxExpenses: hasFetchedMaxExpensesSelector(state),
  loading: loadingSelector(state),
  expensesYear: expensesYearSelector(state),
  categoryFilterOptionsList: categoryFilterOptionsListSelector(state),
  categoryFilterSelections: categoryFilterSelectionsSelector(state),
  accountFilterSelections: accountFilterSelectionsSelector(state),
  statusFilterSelections: statusFilterSelectionsSelector(state),
  isCategoryFilterSelected: isCategoryFilterSelectedSelector(state),
  isAccountFilterSelected: isAccountFilterSelectedSelector(state),
  isAmountFilterSelected: isAmountFilterSelectedSelector(state),
  isStatusFilterSelected: isStatusFilterSelectedSelector(state),
  amountFilterSelections: amountFilterSelectionsSelector(state),
  accountFilterOptionsList: accountFilterOptionsListSelector(state),
  hasFiltersSelected: hasFiltersSelectedSelector(state),
  hasActiveSubscription: hasActiveSubscriptionSelector(state),
  categoryIdsToDisplayNames: categoryIdsToDisplayNamesSelector(state)
});

const mapDispatchToProps = {
  getExpenses,
  updateExpenseStatus,
  updateCategoryFilter,
  updateAccountFilter,
  updateStatusFilter,
  updateAmountFilter,
  setSelectedExpense,
  setExpenseReviewModalType
};

const ConnectedExpenseReviewExpensesTable = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewExpensesTable);

export default ConnectedExpenseReviewExpensesTable;
