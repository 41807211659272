import React from 'react';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import classNames from 'classnames';
import VerifyIcon from '@app/src/assets/verify.svg?react';

const TaxFlowResponseText = ({ className, responseTexts, replaceStrings }) => {
  if (_.isEmpty(responseTexts.warningTexts)) {
    return null;
  }

  return (
    <div className={classNames('steps-benefits-text-container', className)}>
      {responseTexts.warningTexts.map((response_text, i) => (
        <div
          key={i}
          className={classNames('steps-benefits-text-new', {
            'mb-20': i !== responseTexts.warningTexts.length - 1,
            'steps-benefits-text-new-warning': responseTexts.isWarningMessage
          })}
        >
          <div className='steps-benefits-text-new-icon-container'>
            <VerifyIcon width={16} height={16} />
          </div>
          <ReactMarkdown source={replaceStrings(response_text)} />
        </div>
      ))}
    </div>
  );
};

export default TaxFlowResponseText;
