import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { taxDeadlineMessagesSelector } from '@app/src/selectors/taxFlowSelectors';
import { resultLoadingSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { hideTaxDeadlinesSelector } from '@app/src/selectors/workSelectors';
import { setInfoModalItem, setInfoModalShow } from '@app/src/actions/taxFlowActions';
import TaxFlowLearnMoreElement from '@app/src/Components/TaxFlow/Common/TaxFlowLearnMoreElement';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const TaxFlowDeadlineMessageItem = ({ question, resultLoading, deadlineMessage, hideTaxDeadlines }) => {
  if (!_.get(deadlineMessage, 'isActive') || hideTaxDeadlines || resultLoading) {
    return null;
  }

  const slug = _.get(deadlineMessage, 'slug', '');
  const buttonText = _.get(deadlineMessage, 'chipText', '');
  const promptText = _.get(deadlineMessage, 'modalTitle', '');
  const responseText = _.get(deadlineMessage, 'modalBody', '');

  if (_.isEmpty(slug) || _.isEmpty(buttonText) || _.isEmpty(promptText) || _.isEmpty(responseText)) {
    defaultCaptureMessage(`Could not get all info required to render TaxFlowDeadlineMessageItem`, {
      slug,
      buttonText,
      promptText,
      responseText
    });
    return null;
  }

  return (
    <TaxFlowLearnMoreElement
      question={question}
      slug={slug}
      buttonText={buttonText}
      modalTitle={promptText}
      description={responseText}
    ></TaxFlowLearnMoreElement>
  );
};

const mapStateToProps = (state) => ({
  resultLoading: resultLoadingSelector(state),
  deadlineMessage: taxDeadlineMessagesSelector(state),
  hideTaxDeadlines: hideTaxDeadlinesSelector(state)
});

const mapDispatchToProps = {
  setInfoModalShow,
  setInfoModalItem
};

const ConnectedTaxFlowDeadlineMessageItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowDeadlineMessageItem);

export default ConnectedTaxFlowDeadlineMessageItem;
