import classNames from 'classnames';
import React from 'react';

import { trackActivity } from '@app/src/services/analyticsService';
import getImageUrl from '@app/src/utils/getImageUrl';

import '@app/src/Components/ExpenseReview/ExpenseReviewExpensesEmpty.scss';

const ExpenseReviewExpensesEmpty = ({ className }) => {
  const onAppStoreClick = (store) => {
    trackActivity('app store clicked', { store });
    if (store === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.keepertax&hl=en&gl=US', '_blank');
    } else {
      window.open('https://itunes.apple.com/app/id1471090941', '_blank');
    }
  };

  return (
    <div className={classNames('expense-review-expenses-empty', className)}>
      <h1 className='expense-review-expenses-empty-text-header'>No active subscription</h1>
      <div className='expense-review-expenses-empty-text-line' />
      <p className='expense-review-expenses-empty-text-body'>
        To access your deductions, please download the app and subscribe to Keeper
      </p>

      <div className='expense-review-expenses-empty-link'>
        <div className='expense-review-expenses-empty-store'>
          <img
            src={getImageUrl('apple-store-logo.svg')}
            alt='Apple App Store'
            className='expense-review-expenses-empty-store-icon expense-review-expenses-empty-store-icon-apple'
            onClick={() => onAppStoreClick('apple')}
            role='button'
          />
          <img
            src={getImageUrl('google-store-logo.svg')}
            alt='Google Play Store'
            className='expense-review-expenses-empty-store-icon expense-review-expenses-empty-store-icon-google'
            onClick={() => onAppStoreClick('android')}
            role='button'
          />
        </div>
        <div className='expense-review-expenses-empty-qr'>
          <img src={getImageUrl('get-the-app-qr.svg')} alt='QR code' height={130} width={130} />
          <div className='expense-review-expenses-empty-qr-text'>
            To download, scan QR code with your phone's camera
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseReviewExpensesEmpty;
