import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import _ from 'lodash';
import React from 'react';

const ConfirmDisconnectModal = ({ onConfirm, onCancel, subaccount }) => {
  const handleConfirm = () => {
    onConfirm({
      accountId: subaccount.account_id,
      itemId: subaccount.item_id,
      institutionName: subaccount.institution_name,
      usage: subaccount.type
    });
  };

  return (
    <Dialog open={!!subaccount} onClose={onCancel}>
      <Stack direction='column' spacing={2} style={{ padding: 16 }}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContentText>
          Keeper won&apos;t be able to detect deductions for this account going forward. You'll still be able to manage
          the transactions already detected by Keeper.
        </DialogContentText>
        <Stack direction={'row'} spacing={1}>
          <Button variant='outlined' fullWidth onClick={onCancel}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleConfirm} fullWidth autoFocus>
            Remove
            {subaccount?.type && subaccount?.mask
              ? ` ${_.capitalize(subaccount?.type)} - ${subaccount?.mask}`
              : ' account'}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfirmDisconnectModal;
