import React, { useEffect } from 'react';
import _ from 'lodash';
import HumanReviewSeansTeam from '@app/src/assets/human-review-match.png';
import HumanReviewMatching from '@app/src/assets/human-review-matching.webp';
import '@app/src/Components/TaxFlow/Question/TaxFlowPastReturns.scss';
import { SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import TaxFlowSubmitProgressElement from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitProgressElement';
import '@app/src/Components/TaxFlow/Question/TaxFlowHumanReview.scss';
import { SLUG__MATCHING, SLUG__MATCHING_COMPLETE } from '@app/src/taxflow/sections/special/constants/specialConstants';

const TaxFlowHumanReview = ({ currentQuestion, ...props }) => {
  const humanReviewHeader = _.get(currentQuestion, ['question_meta', 'humanReviewHeader']);
  const humanReviewBody = _.get(currentQuestion, ['question_meta', 'humanReviewBody']);
  const history = props.history;

  useEffect(() => {
    if (currentQuestion.slug === SLUG__MATCHING) {
      const timer = setTimeout(() => {
        history.push(SLUG__MATCHING_COMPLETE);
      }, 7500);

      return () => clearTimeout(timer);
    }
  }, [currentQuestion.slug, history]);

  return (
    <>
      <div className='steps-head'>
        {currentQuestion.slug === SLUG__MATCHING ? (
          <img className='taxflow-human-review-image' src={HumanReviewMatching} alt="Sean's Team" />
        ) : (
          <img className='taxflow-human-review-image' src={HumanReviewSeansTeam} alt="Sean's Team" />
        )}
        <h1 style={{ textAlign: currentQuestion.slug === SLUG__MATCHING ? 'center' : 'left' }}>{humanReviewHeader}</h1>{' '}
        <div className='step-description'>
          <div className='step-summary'>{humanReviewBody}</div>
        </div>
      </div>
      <div className='steps-body-wrapper'>
        {currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED ? (
          <TaxFlowSubmitProgressElement {...props} />
        ) : null}
      </div>
    </>
  );
};

export default TaxFlowHumanReview;
