import { isEmpty } from '@app/src/global/Helpers';

import {
  GET_AUTH_RES_ERROR,
  SET_CURRENT_USER,
  SET_AUTH_LOADER,
  SET_SETTINGS,
  SET_EMAIL_UNIQUE,
  SET_PHONE_UNIQUE
} from '@app/src/actions/types';

const initialState = {
  isAuthenticated: false,
  user: {},
  resError: '',
  loading: false,
  settings: {},
  isEmailUnique: false,
  isPhoneUnique: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case GET_AUTH_RES_ERROR:
      return {
        ...state,
        resError: action.payload
      };
    case SET_AUTH_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
    case SET_EMAIL_UNIQUE:
      return {
        ...state,
        isEmailUnique: action.payload
      };
    case SET_PHONE_UNIQUE:
      return {
        ...state,
        isPhoneUnique: action.payload
      };
    default:
      return state;
  }
}
