import React from 'react';
import { connect } from 'react-redux';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  PATH_COMPONENT__SUBMIT_FINISH,
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_FINISH,
  SLUG__SUBMIT_FINISH_LATER,
  SLUG__SUBMIT_TAX_AMOUNT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { currentQuestionSelector, resultLoadingSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import TaxFlowPdfButton from '@app/src/Components/TaxFlow/Question/TaxFlowPdfButton';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitSummaryElement.scss';
import { formatPhoneNumberToDisplay } from '@app/src/global/Helpers';
import MailIcon from '@app/src/assets/mail.svg?react';
import PhoneIcon from '@app/src/assets/phone.svg?react';
import { submitEnabledSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { moveToNotStarted } from '@app/src/services/taxFlowService';
import TaxFlowReviewPillsSection from '@app/src/Components/TaxFlow/Question/TaxFlowReviewPillsSection';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';

const TaxFlowSubmitSummaryElement = ({
  user,
  submitEnabled,
  currentQuestion,
  resultLoading,
  selfUnlockModalOpen,
  setSelfUnlockModalOpen
}) => {
  if (resultLoading) return null;

  return (
    <div className='taxflow-submit-summary'>
      {(currentQuestion.slug === SLUG__SUBMIT_FINISH || currentQuestion.slug === SLUG__SUBMIT_FINISH_LATER) &&
        !submitEnabled && (
          <div className='taxflow-submit-summary-chips'>
            <TaxFlowPill text={user.email} icon={<MailIcon width={16} height={16} />} />
            <TaxFlowPill text={formatPhoneNumberToDisplay(user.phone)} icon={<PhoneIcon width={16} height={16} />} />
          </div>
        )}
      <TaxFlowReviewPillsSection></TaxFlowReviewPillsSection>
      <div className='taxflow-submit-summary-elements'>
        {(currentQuestion.slug === SLUG__SUBMIT_TAX_AMOUNT || currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION) && (
          <TaxFlowPdfButton className='taxflow-submit-summary-elements-pdf' />
        )}
      </div>
      <TaxFlowConfirmModal
        open={selfUnlockModalOpen}
        setOpen={setSelfUnlockModalOpen}
        header='Edit your return and re-submit?'
        body="You'll need to re-submit for review after editing your return."
        // non breaking hyphen
        confirmText='Yes, edit and re&#8209;submit'
        onConfirm={async () => {
          trackActivity('self unlock: modal confirmed', {
            question: currentQuestion?.slug
          });

          await moveToNotStarted();

          history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_FINISH}`);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  submitEnabled: submitEnabledSelector(state),
  currentQuestion: currentQuestionSelector(state),
  resultLoading: resultLoadingSelector(state),
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state)
});

const mapDispatchToProps = {
  setSelfUnlockModalOpen
};

const ConnectedTaxFlowSubmitSummaryElement = connect(mapStateToProps, mapDispatchToProps)(TaxFlowSubmitSummaryElement);

export default ConnectedTaxFlowSubmitSummaryElement;
