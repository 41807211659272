// path components
export const PATH_COMPONENT__SUBMIT_PIN = 'submit-pin';
export const PATH_COMPONENT__SUBMIT_FINISH = 'submit-finish';
export const PATH_COMPONENT__SUBMIT_FINISH_LATER = 'submit-finish-later';
export const PATH_COMPONENT__SUBMIT_BYE = 'submit-bye';
export const PATH_COMPONENT__SUBMIT_BYE_MANUAL = 'submit-bye-manual';
export const PATH_COMPONENT__SUBMIT_BYE_MANUAL_ESC = 'submit-bye-manual-esc';
export const PATH_COMPONENT__SUBMIT_EMAIL_OUTBOX = 'submit-email-outbox';
export const PATH_COMPONENT__SUBMIT_TAX_AMOUNT = 'submit-tax-amount';
export const PATH_COMPONENT__SUBMIT_DEBIT = 'submit-debit';
export const PATH_COMPONENT__SUBMIT_DEBIT_MANUAL = 'submit-debit-manual';
export const PATH_COMPONENT__SUBMIT_BANK_NUMBERS = 'submit-bank-numbers';
export const PATH_COMPONENT__SUBMIT_SWITCH_TO_ANNUAL = 'submit-switch-to-annual';
export const PATH_COMPONENT__SUBMIT_PAY_NOW = 'submit-pay-now';
export const PATH_COMPONENT__SUBMIT_PAY_TEST = 'submit-pay-test';
export const PATH_COMPONENT__SUBMIT_SIGNATURE = 'submit-signature';
export const PATH_COMPONENT__SUBMIT_BLOCKED = 'submit-blocked';
export const PATH_COMPONENT__SUBMIT_BLOCKED_FINAL_REVIEW = 'submit-blocked-final-review';
export const PATH_COMPONENT__SUBMIT_SENT_TO_IRS = 'submit-sent-to-irs';
export const PATH_COMPONENT__SUBMIT_ACCEPTED_BY_IRS = 'submit-accepted-by-irs';
export const PATH_COMPONENT__SUBMIT_FILED_WITH_IRS = 'submit-filed-with-irs';
export const PATH_COMPONENT__SUBMIT_UNABLE_TO_FILE = 'submit-unable-to-file';
export const PATH_COMPONENT__SUBMIT_AUDIT_ISSUE = 'submit-audit-issue';
export const PATH_COMPONENT__SUBMIT_IRS_ISSUE = 'submit-irs-issue';
export const PATH_COMPONENT__SUBMIT_EXIT_BOUNCE = 'submit-exit-bounce';
export const PATH_COMPONENT__SUBMIT_EMAIL_INFO = 'submit-email-info';
export const PATH_COMPONENT__SUBMIT_CONFIRMATION = 'submit-confirmation';
export const PATH_COMPONENT__SUBMIT_CONFIRM_ID = 'submit-confirm-id';
export const PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO = 'submit-confirm-id-intro';
export const PATH_COMPONENT__SUBMIT_EXPERT_REVIEW_ASSIGNED = 'submit-expert-review-assigned';
// slugs
export const SLUG__SUBMIT_PIN = 'submit-pin';
export const SLUG__SUBMIT_HAVE_PIN = 'submit-have-pin';
export const SLUG__SUBMIT_SELF_PIN_NUMBER = 'submit-self-pin-number';
export const SLUG__SUBMIT_SPOUSE_PIN_NUMBER = 'submit-spouse-pin-number';
export const SLUG__SUBMIT_FINISH = 'submit-finish';
export const SLUG__SUBMIT_FINISH_LATER = 'submit-finish-later';
export const SLUG__SUBMIT_BYE = 'submit-bye';
export const SLUG__SUBMIT_BYE_MANUAL = 'submit-bye-manual';
export const SLUG__SUBMIT_BYE_MANUAL_ESC = 'submit-bye-manual-esc';
export const SLUG__SUBMIT_EMAIL_OUTBOX = 'submit-email-outbox';
export const SLUG__SUBMIT_TAX_AMOUNT = 'submit-tax-amount';
export const SLUG__SUBMIT_DEBIT = 'submit-debit';
export const SLUG__SUBMIT_DEBIT_MANUAL_HEADER = 'submit-debit-manual-header';
export const SLUG__SUBMIT_DEBIT_MANUAL_OPTION = 'submit-debit-manual-option';
export const SLUG__SUBMIT_DEBIT_INSTITUTION = 'submit-debit-institution';
export const SLUG__SUBMIT_DEBIT_ACCOUNT = 'submit-debit-account';
export const SLUG__SUBMIT_DEBIT_TYPE = 'submit-debit-type';
export const SLUG__SUBMIT_DEBIT_MANUAL = 'submit-debit-manual';
export const SLUG__SUBMIT_BANK_NUMBERS = 'submit-bank-numbers';
export const SLUG__SUBMIT_DEBIT_ROUTING = 'submit-debit-routing';
export const SLUG__SUBMIT_SWITCH_TO_ANNUAL = 'submit-switch-to-annual';
export const SLUG__SUBMIT_PAY_NOW = 'submit-pay-now';
export const SLUG__SUBMIT_PAY_TEST = 'submit-pay-test';
export const SLUG__SUBMIT_SIGNATURE = 'submit-signature';
export const SLUG__SUBMIT_SIGNATURE_SELF = 'submit-signature-self';
export const SLUG__SUBMIT_SIGNATURE_SPOUSE = 'submit-signature-spouse';
export const SLUG__SUBMIT_CONFIRMATION = 'submit-confirmation';
export const SLUG__SUBMIT_CONFIRMATION_MODAL = 'submit-confirmation-modal';
export const SLUG__SUBMIT_BLOCKED = 'submit-blocked';
export const SLUG__SUBMIT_BLOCKED_FINAL_REVIEW = 'submit-blocked-final-review';
export const SLUG__SUBMIT_SENT_TO_IRS = 'submit-sent-to-irs';
export const SLUG__SUBMIT_ACCEPTED_BY_IRS = 'submit-accepted-by-irs';
export const SLUG__SUBMIT_FILED_WITH_IRS = 'submit-filed-with-irs';
export const SLUG__SUBMIT_UNABLE_TO_FILE = 'submit-unable-to-file';
export const SLUG__SUBMIT_AUDIT_ISSUE = 'submit-audit-issue';
export const SLUG__SUBMIT_IRS_ISSUE = 'submit-irs-issue';
export const SLUG__SUBMIT_EXIT_BOUNCE = 'submit-exit-bounce';
export const SLUG__SUBMIT_SUBSCRIPTION_PROMPT = 'submit-subscription-prompt';
export const SLUG__SUBMIT_SUBSCRIPTION_PROMPT_ANNUAL = 'submit-subscription-prompt-annual';
export const SLUG__SUBMIT_SUBSCRIPTION_PROMPT_MONTHLY = 'submit-subscription-prompt-monthly';
export const SLUG__SUBMIT_EMAIL_INFO = 'submit-email-info';
export const SLUG__SUBMIT_CONFIRM_ID = 'submit-confirm-id';
export const SLUG__SUBMIT_CONFIRM_ID_INTRO = 'submit-confirm-id-intro';
export const SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED = 'submit-expert-review-assigned';

// endpoint attributes
export const ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD = 'submit-debit-payment-method';
export const ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT = 'submit-subscription-prompt';

// collection types
export const COLLECTION_TYPE__SUBMIT_DEBIT = 'submit-debit';
export const COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT = 'submit-subscription-prompt';
export const COLLECTION_TYPE__SUBMIT_SIGNATURE = 'submit-signature';

// submit audit types
export const SUBMIT_AUDIT_TYPE = 'submit-audit-type';
export const SUBMIT_AUDIT_ISSUE = 'submit-audit-issue';
