import _ from 'lodash';
import { usePlaidLink } from 'react-plaid-link';
import { connect } from 'react-redux';
import { runOnEvent, runOnSuccess, runOnExit } from '@app/src/services/bankLinkService';

const BankLinkPlaid = ({
  type,
  renderButton,
  linkToken,
  runOnSuccess,
  runOnExit,
  runOnEvent,
  preSuccess,
  postSuccess
}) => {
  const onSuccess = async (publicToken, metadata) => {
    if (preSuccess) {
      preSuccess();
    }

    const runRetro = type === 'webDashboard' || type === 'settings';

    await runOnSuccess({ type, runRetro, publicToken, metadata });

    if (postSuccess) {
      postSuccess({ metadata });
    }
  };

  const onExit = async (error, metadata) => {
    await runOnExit({ type, error, metadata });
  };

  const onEvent = async (eventName, metadata) => {
    await runOnEvent({ type, eventName, metadata });
  };

  const config = {
    token: linkToken,
    onSuccess,
    onExit,
    onEvent
  };

  const { open, ready } = usePlaidLink(config);

  return renderButton({ open, ready });
};

const mapStateToProps = (state) => ({
  linkToken: _.get(state, ['bank', 'linkToken'])
});

const mapDispatchToProps = {
  runOnSuccess,
  runOnExit,
  runOnEvent
};

const ConnectedBankLinkPlaid = connect(mapStateToProps, mapDispatchToProps)(BankLinkPlaid);

export default ConnectedBankLinkPlaid;
