import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import { getPastReturns } from '@app/src/services/taxFlowService';
import TaxFlowPastReturnItem from '@app/src/Components/TaxFlow/Question/TaxFlowPastReturnItem';
import NoPastReturnsImage from '@app/src/assets/no-past-returns.svg?react';
import '@app/src/Components/TaxFlow/Question/TaxFlowPastReturns.scss';
import { trackQuestionView } from '@app/src/taxflow/main/services/mainService';

const TaxFlowPastReturns = ({ currentQuestion, trackQuestionView }) => {
  const [pastReturns, setPastReturns] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const pastReturns = await getPastReturns();
        trackQuestionView(currentQuestion, { count: pastReturns.length, years: _.map(pastReturns, 'year') });
        if (isMounted.current) {
          setPastReturns(pastReturns);
        }
      } catch (e) {
        if (isMounted.current) {
          setPastReturns(null);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    })();

    return () => {
      // Avoids React state update on unmounted component
      isMounted.current = false;
    };
  }, [currentQuestion, trackQuestionView]);

  if (loading) {
    return <Loader type='screen' />;
  }

  if ((_.isNil(pastReturns) || _.isEmpty(pastReturns)) && !loading) {
    return (
      <div className='tax-flow-no-past-returns'>
        <div className='steps-head'>
          <NoPastReturnsImage />
          <div className='taxflow-header'>
            <h1>{_.get(currentQuestion, ['question_meta', 'noPastReturnsTitle'])}</h1>
          </div>
          <div className='step-summary'>
            <p>{_.get(currentQuestion, ['question_meta', 'noPastReturnsDescription'])}</p>
          </div>
        </div>
        <div className='steps-body'></div>
      </div>
    );
  }

  return (
    <>
      <div className='steps-head'>
        <h1>{_.get(currentQuestion, 'title')}</h1>
      </div>
      <div className='tax-flow-past-returns steps-body'>
        {pastReturns.map(({ id, year, amounts }) => (
          <TaxFlowPastReturnItem key={id} year={year} amounts={amounts} />
        ))}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  trackQuestionView
};

const ConnectedTaxFlowPastReturns = connect(null, mapDispatchToProps)(TaxFlowPastReturns);

export default ConnectedTaxFlowPastReturns;
