import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import TaxFlowRatingFeedbackElement from '@app/src/Components/TaxFlow/Question/TaxFlowRatingFeedbackElement';
import TaxFlowDetailedBillElement from '@app/src/Components/TaxFlow/Question/TaxFlowDetailedBillElement';
import TaxFlowPdfButton from '@app/src/Components/TaxFlow/Question/TaxFlowPdfButton';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitToIrsElement.scss';
import { detailedAmountsSelector } from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import { taxAmountsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { connect } from 'react-redux';
import TaxFlowSettlementStatus from '@app/src/Components/TaxFlow/Question/TaxFlowSettlementStatus';
import { getReturnStatus } from '@app/src/services/taxFlowService';
import { SLUG__SUBMIT_SENT_TO_IRS } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import capitalize from 'capitalize';

const TaxFlowSubmitToIrsElement = (props) => {
  const [returnStatus, setReturnStatus] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getReturnStatus();

      setReturnStatus(data);
    })();
  }, []);

  const mask = _.get(returnStatus, 'accountMask');
  const paymentMethod = _.get(returnStatus, 'paymentMethod');

  return (
    <div className='taxflow-submit-to-irs-element'>
      {props.taxFlow.currentQuestion.slug === SLUG__SUBMIT_SENT_TO_IRS && (
        <div className='refund-statuses'>
          <h2>
            {capitalize(_.compact([props.hasRefund && 'refund', props.hasBill && 'tax bill']).join(' and '))} status
          </h2>
          {props.amounts.drake.federal_amount != null && (
            <TaxFlowSettlementStatus
              amount={props.amounts.drake.federal_amount}
              mask={mask}
              entity='federal'
              paymentMethod={paymentMethod}
              status='In Review'
            />
          )}
          {props.amounts.drake.multistate &&
            props.amounts.drake.multistate.map(({ state, amount }) => (
              <TaxFlowSettlementStatus
                key={state}
                amount={amount}
                mask={mask}
                entity={state}
                paymentMethod={paymentMethod}
                status='In Review'
              />
            ))}
        </div>
      )}

      <TaxFlowDetailedBillElement className='taxflow-submit-to-irs-element-bill' {...props} />
      <TaxFlowPdfButton className='pdf-button' />
      <TaxFlowRatingFeedbackElement />
    </div>
  );
};

const mapStateToProps = (state) => {
  const taxAmounts = taxAmountsSelector(state);
  const federalAmt = _.get(taxAmounts, 'federal_amount');
  const states = _.get(taxAmounts, 'state_amounts', []);

  return {
    amounts: detailedAmountsSelector(state),
    hasBill: federalAmt > 0 || states.some((state) => state.amount > 0),
    hasRefund: federalAmt < 0 || states.some((state) => state.amount < 0)
  };
};

const ConnectedTaxFlowSubmitToIrsElement = connect(mapStateToProps)(TaxFlowSubmitToIrsElement);

export default ConnectedTaxFlowSubmitToIrsElement;
