import _ from 'lodash';
import moment from 'moment';

export const getMembershipStatusDescription = ({
  accountDetails,
  currentSubscriptionType,
  currentSubscriptionPrice,
  nextChargeDate
}) => {
  if (_.isEmpty(accountDetails)) return;

  const { status, willCancel, currentPeriodEnd } = accountDetails;

  const productName = currentSubscriptionType === 'premium' ? 'Keeper Premium' : 'Keeper';

  const paymentPeriod = ['annual', 'premium'].includes(currentSubscriptionType) ? 'year' : 'month';
  const currentPeriodEndFormatted = moment(_.get(accountDetails, 'currentPeriodEnd', null)).format('M/D/YYYY');
  const paymentDate = moment(_.get(accountDetails, 'firstChargeDate', null), 'YYYY-MM-DD').format('M/D/YYYY');

  const formattedSubscription = `$${currentSubscriptionPrice} / ${paymentPeriod}`;

  switch (status) {
    case 'active':
      if (willCancel && currentPeriodEnd) {
        return `Your ${formattedSubscription} subscription is set to cancel on ${currentPeriodEndFormatted}. Restart your subscription to continue using ${productName}.`;
      }

      if (nextChargeDate) {
        return `You are currently subscribed to ${productName} for ${formattedSubscription}. Your next payment is due on ${moment(
          nextChargeDate
        ).format('M/D/YYYY')}`;
      }

      return `You are currently subscribed to ${productName} for ${formattedSubscription}. The remaining credit from your existing plan has been applied.`;
    case 'free trial':
      return `Your ${productName} free trial ends on ${paymentDate}. ${
        willCancel
          ? `Restart your subscription to continue using ${productName}.`
          : `After that you'll be charged ${formattedSubscription}.`
      }`;
    case 'canceled':
      return `Restart your subscription to continue using ${productName}.`;
    case 'delinquent':
      return `Your ${formattedSubscription} payment was unsuccessful. Please update your payment information to continue using ${productName}.`;
    default:
      return `Try ${productName} free for 7 days. Cancel anytime.`;
  }
};
