import _ from 'lodash';

import { setExpensesError } from '@app/src/actions/expenseReviewActions';
import { setTaxFlowError } from '@app/src/actions/taxFlowActions';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

export const showDefaultError = (error) => async (dispatch) => {
  const errorMessage = _.get(error, 'message') || 'Something went wrong. Please try again.';
  dispatch(setTaxFlowError(errorMessage));
  notify(errorMessage);

  if (errorMessage !== 'You already have an account!') {
    defaultCaptureException(error);
  }
};

export const setTaxFilingError = (error) => async (dispatch) => {
  const errorMessage = _.get(error, ['message'], null);

  if (errorMessage) {
    dispatch(setTaxFlowError(errorMessage));
    notify(errorMessage);
  }

  defaultCaptureException(error);
};

export const setExpenseReviewError = (error) => async (dispatch) => {
  const errorMessage = _.get(error, ['message'], null);

  if (errorMessage) {
    dispatch(setExpensesError(errorMessage));
    notify(errorMessage);
  }

  defaultCaptureException(error);
};
