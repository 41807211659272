import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { savingsSelector } from '@app/src/selectors/dashboardSelectors';
import {
  currentQuestionSelector,
  queryResultsSelector,
  questionSlugsAnsweredSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getQueryResultsByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import {
  COLLECTION_TYPE__INCOME_INVEST,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_DESCRIPTION
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  COLLECTION_TYPE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_BYE,
  SLUG__SUBMIT_BYE_MANUAL,
  SLUG__SUBMIT_BYE_MANUAL_ESC,
  SUBMIT_AUDIT_ISSUE,
  SUBMIT_AUDIT_TYPE,
  SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { summaryItemsSelector } from '@app/src/selectors/summarySelectors';
import { substitutionsSelector, taxAmountsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getStringWithSubstitutions } from '@app/src/taxflow/main/utils/mainUtils';
import { submitIssueItemsSelector } from '@app/src/api/taxDataApi';

export const isPaymentValidSelector = (state) => _.get(state, ['taxFlow', 'isPaymentValid']);
const submitTimestampSelector = (state) => _.get(state, ['taxFlow', 'submitTimestamp']);

export const detailedAmountsSelector = createSelector([taxAmountsSelector], (amounts) => {
  const pdfAmounts = _.pick(amounts, [
    'adjusted_gross_income',
    'standard_or_itemized_deduction',
    'before_credits_tax',
    'other_tax',
    'business_income_deduction',
    'taxable_income',
    'non_refundable_credits',
    'total_tax_owed',
    'refundable_credits',
    'total_payments_made'
  ]);

  const drakeAmounts = _.pick(amounts, ['federal_amount', 'multistate']);

  return {
    pdf: Object.values(pdfAmounts).every(_.isNil) ? null : pdfAmounts,
    drake: drakeAmounts
  };
});

export const irsEnabledSelector = (state) => {
  const navigationItems = _.get(state, ['taxFlowNavigation', 'navigationItems']);
  const queryResults = _.get(state, ['taxFlow', 'queryResults']);

  const submitNavItem = navigationItems.find((item) => item.id === 'submit');
  const irsEnabledStatus = _.get(submitNavItem, ['meta', 'irsEnabled']);

  const states = queryResults
    .filter(
      (queryResult) =>
        queryResult.coll_type === COLLECTION_TYPE__STATE_RETURN && queryResult.slug === SLUG__STATE_RETURN
    )
    .map((state) => _.get(state, ['answer', 'value']));

  return states.every((state) => _.get(irsEnabledStatus, state));
};

export const instantReviewCountdownSelector = createSelector(
  [currentQuestionSelector, submitTimestampSelector],
  (currentQuestion, submitTimestamp) => {
    const engEscReviewTime = {
      days: 0,
      hours: 72,
      minutes: 0,
      seconds: 0
    };
    const manualReviewTime = {
      days: 0,
      hours: 24,
      minutes: 0,
      seconds: 0
    };
    const instantReviewTime = {
      minutes: 5
    };
    const humanReviewTime = {
      hours: 1,
      minutes: 30
    };

    const manualReviewFinish = moment(submitTimestamp).add(manualReviewTime);
    const instantReviewFinish = moment(submitTimestamp).add(instantReviewTime);
    const engEscReviewFinish = moment(submitTimestamp).add(engEscReviewTime);
    const humanReviewFinish = moment(submitTimestamp).add(humanReviewTime);

    if (currentQuestion.slug === SLUG__SUBMIT_BYE) {
      return instantReviewFinish;
    } else if (currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL) {
      return manualReviewFinish;
    } else if (currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL_ESC) {
      return engEscReviewFinish;
    } else if (currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED) {
      return humanReviewFinish;
    } else {
      return null;
    }
  }
);

export const instantReviewItemsSelector = createSelector(
  [queryResultsSelector, currentQuestionSelector, submitTimestampSelector, savingsSelector],
  (queryResults, currentQuestion, submitTimestamp, writeOffSavings) => {
    // make items show up one by one with an animation delay on submit-bye
    // duration = duration in seconds for item to animate before next item appears
    const animate =
      currentQuestion.slug === SLUG__SUBMIT_BYE || currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED;

    // forms
    const items = [{ label: 'Form completion', duration: animate ? 4.5 : 0 }];

    // sched c
    if (writeOffSavings > 0) {
      items.push({ label: 'Schedule C deductions', duration: animate ? 7 : 0 });
    }

    // dependents
    const dependents = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
    });
    if (dependents.length) {
      items.push({ label: 'Child tax credit', duration: animate ? 6 : 0 });
    }

    // filing status
    items.push({ label: 'Filing status', duration: animate ? 3 : 0 });

    // investments
    const investments = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__INCOME_INVEST,
      slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_DESCRIPTION
    });
    if (investments.length) {
      items.push({ label: 'Investments', duration: animate ? 7.5 : 0 });
    }

    // credits and deductions and state returns
    const states = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    });
    if (currentQuestion.slug === SLUG__SUBMIT_BYE || currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED) {
      items.push({ label: 'Credits and deductions', duration: 'infinite' });
    } else {
      items.push({ label: 'Credits and deductions', duration: 0 });

      for (const state of states) {
        if (_.get(state, ['answer', 'value'])) {
          items.push({ label: _.get(state, ['answer', 'value']) + ' return', duration: 0 });
        }
      }

      // only for manual review
      if (currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL || currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL_ESC) {
        items.push({
          label: 'Tax expert assignment',
          duration: 'infinite'
        });
      }
    }

    // format array of items with css animation metadata
    // calculate delay and duration for each item based on previous item and submit timestamp
    // delay and duration are the preset delay and duration values for each item from above
    // actual delay and actual duration = preset values + adjustments based on current situation (e.g.
    // if user is viewing the screen for the first time or returning, if page is refreshed mid-animation)
    let i;
    const itemsWithDelay = [];
    for (i = 0; i < items.length; i++) {
      if (i === 0) {
        // if the page is refreshed, dont re-show previous animations
        let actualDuration = items[i].duration;
        const animationEnd = moment(submitTimestamp).add({ seconds: items[i].duration });
        if (animationEnd.isBefore()) {
          actualDuration = 0;
        }

        itemsWithDelay.push({
          label: items[i].label,
          delay: 0,
          actualDelay: 0,
          duration: items[i].duration,
          actualDuration
        });
      } else {
        // make each item appear when the last item is done
        const delay = itemsWithDelay[i - 1].duration + itemsWithDelay[i - 1].delay;

        // if the page is refreshed, dont re-show previous animations
        let actualDelay = itemsWithDelay[i - 1].actualDuration + itemsWithDelay[i - 1].actualDelay;
        let actualDuration = items[i].duration;
        const animationStart = moment(submitTimestamp).add({ seconds: delay });
        if (animationStart.isBefore()) {
          actualDelay = 0;

          if (actualDuration !== 'infinite') {
            const animationEnd = moment(submitTimestamp).add({ seconds: delay + actualDuration });
            actualDuration = animationEnd.isBefore()
              ? 0
              : 1.5 * Math.ceil(animationEnd.diff(moment(), 'seconds') / 1.5);
          }
        }

        itemsWithDelay.push({
          label: items[i].label,
          delay,
          actualDelay,
          duration: items[i].duration,
          actualDuration
        });
      }
    }

    return itemsWithDelay;
  }
);

export const completedSummaryItemNamesSelector = createSelector(
  [
    currentQuestionSelector,
    (state) => _.get(state, ['taxFlow', 'allQuestions']),
    questionSlugsAnsweredSelector,
    summaryItemsSelector,
    substitutionsSelector
  ],
  (currentQuestion, allQuestions, slugsAnswered, summaryItems, substitutions) => {
    if (currentQuestion.slug === SLUG__SUBMIT_BYE || currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED) {
      const answeredQuestions = allQuestions.filter((question) => slugsAnswered.has(question.endpoint_attr));
      const answeredCollectionTypes = new Set(answeredQuestions.map((question) => question.collectionType));
      const answeredSummaryItems = _.values(summaryItems)
        .filter((subSummaryItems) => _.isArray(subSummaryItems))
        .flatMap((subSummaryItems) =>
          subSummaryItems.filter((summaryItem) => answeredCollectionTypes.has(summaryItem.collectionType))
        )
        .map((summaryItem) => getStringWithSubstitutions({ str: summaryItem.listName, substitutions }));

      return _.compact(_.uniq([...answeredSummaryItems, 'Done!']));
    }

    return null;
  }
);

export const submitIssueMessagesSelector = createSelector([submitIssueItemsSelector], (submitIssueItems) =>
  submitIssueItems.filter((e) => e.slug === SUBMIT_AUDIT_ISSUE)
);

export const submitIssueTypeSelector = createSelector([submitIssueItemsSelector], (submitIssueItems) => {
  const submitIssueType = submitIssueItems.find((s) => s.slug === SUBMIT_AUDIT_TYPE);
  return _.get(submitIssueType, 'value');
});

export const uncheckedSubmitIssueMessagesSelector = createSelector([submitIssueItemsSelector], (submitIssueItems) => {
  return submitIssueItems.filter((e) => e.slug === SUBMIT_AUDIT_ISSUE && !e.isChecked);
});
