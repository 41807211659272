import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getQuestion, updateAnswer } from '@app/src/services/onboardingService';
import OnboardingQuestion from '@app/src/Components/Onboarding/Question/OnboardingQuestion';
import { Url_SIGNUP_PHONE_ONLY } from '@app/src/constants/onboardingConstants';

const OnboardingAccountPhoneOnly = ({ history, getQuestion, updateAnswer }) => {
  useEffect(() => {
    getQuestion({ slug: Url_SIGNUP_PHONE_ONLY, history });
  }, [getQuestion, history]);

  const onNext = async () => {
    await updateAnswer({ history });
  };

  return <OnboardingQuestion onNext={onNext} />;
};

const mapDispatchToProps = {
  getQuestion,
  updateAnswer
};

const ConnectedOnboardingAccountPhoneOnly = connect(null, mapDispatchToProps)(OnboardingAccountPhoneOnly);

export default ConnectedOnboardingAccountPhoneOnly;
