import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { ArrowRight2 } from 'iconsax-react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setExpenseReviewCurrentRule, setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import { rulesSelector } from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { colorPrimary } from '@app/src/theme';

import '@app/src/Components/ExpenseReview/ExpenseReviewSidebarRules.scss';

const getRuleCopy = (status) => {
  switch (status) {
    case 'no':
      return 'not deductible';
    case 'yes':
      return 'deduction';
    default:
      return 'always ask me';
  }
};

const ExpenseReviewSidebarRules = ({ rules, setExpenseReviewCurrentRule, setExpenseReviewModalType }) => {
  useEffect(() => {
    trackActivity('navigation: expense rules', { ruleCount: rules.length });
  }, [rules]);

  const handleEditRule = (rule) => {
    setExpenseReviewCurrentRule(rule);
    setExpenseReviewModalType('rule');
  };

  return (
    <div style={{ paddingBottom: '60px' }}>
      <h1 className='text-xl bold'>Your rules</h1>
      <div>
        <Divider />
      </div>
      <h4 className='savings-info-text'>
        You can create rules to deduct or recategorize expenses from specific merchants. Your rules apply automatically
        to past and future transactions.
      </h4>
      <Stack spacing={2} className='rules'>
        {rules.map((rule, idx) => {
          const { name, prediction } = rule;

          return (
            <Button key={idx} variant='outlined' className='rule' fullWidth onClick={() => handleEditRule(rule)}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                <div>
                  IF{' '}
                  <span className='bold' style={{ color: colorPrimary }}>
                    {_.truncate(name, { length: 20 })}
                  </span>
                  , THEN <span className={`rules-sidebar-text-${prediction}`}>{getRuleCopy(prediction)}</span>
                </div>
                <ArrowRight2 />
              </div>
            </Button>
          );
        })}
        <Button variant='contained' fullWidth onClick={() => handleEditRule({})}>
          Create a rule
        </Button>
      </Stack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  rules: rulesSelector(state)
});

const mapDispatchToProps = {
  setExpenseReviewCurrentRule,
  setExpenseReviewModalType
};

const ConnectedExpenseReviewSidebarRules = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewSidebarRules);

export default ConnectedExpenseReviewSidebarRules;
