import _ from 'lodash';
import axios from 'axios';
import { serverUrl } from '@app/src/global/Environment';
import { setCurrentNavigationSection } from '@app/src/actions/taxFlowActions';
import {
  currentNavigationSectionSelector,
  isFilingQualifiedSelector,
  navSectionsStartedSelector
} from '@app/src/selectors/navigationListSelectors';
import { CATEGORY_TYPE_NAVIGATION, CATEGORY_TYPE_SUMMARY } from '@app/src/constants/constants';
import {
  SLUG__SUBMIT_BLOCKED,
  SLUG__SUBMIT_BLOCKED_FINAL_REVIEW,
  SLUG__SUBMIT_BYE,
  SLUG__SUBMIT_BYE_MANUAL,
  SLUG__SUBMIT_BYE_MANUAL_ESC,
  SLUG__SUBMIT_EMAIL_OUTBOX,
  SLUG__SUBMIT_FINISH,
  SLUG__SUBMIT_SENT_TO_IRS,
  SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED,
  PATH_COMPONENT__SUBMIT_FINISH
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { selectedBulkManualEntryItemSelector } from '@app/src/selectors/taxFlowSelectors';
import {
  PATH_COMPONENT__BULK_UPLOAD,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_INTRO,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS,
  PATH_COMPONENT__FILING_QUALIFIED,
  SLUG__BULK_UPLOAD,
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__BULK_UPLOAD_QUESTIONS_SUMMARY
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { getNextCollectionId } from '@app/src/taxflow/main/services/taxFlowDataService';
import { getBulkUploadFormUrl } from '@app/src/taxflow/collection/utils/collectionUtils';
import { isTaxFilingSkipEnabledSelector } from '@app/src/selectors/taxValidationSelectors';
import { fetchCurrentQuestionnaireQuestion } from '@app/src/services/questionnaireService';
import { bulkUploadItemsSelector } from '@app/src//taxflow/main/selectors/formUploadSelectors';

const baseUrl = serverUrl();

const getCurrentNavigationSection = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/get-current-nav-section`);
  const currentNavSection = _.get(res, ['data', 'data', 'currentNavigationSection'], {});
  dispatch(setCurrentNavigationSection(currentNavSection));
};

export const requireNavigationData =
  ({ currentQuestion }) =>
  async (dispatch, getState) => {
    const currentNavigationSection = currentNavigationSectionSelector(getState());

    if (currentQuestion.question_type === CATEGORY_TYPE_NAVIGATION) {
      await dispatch(getCurrentNavigationSection());
    } else if (
      _.isEmpty(currentNavigationSection) ||
      currentQuestion.question_type === CATEGORY_TYPE_SUMMARY ||
      currentQuestion.slug === SLUG__SUBMIT_FINISH ||
      currentQuestion.slug === SLUG__SUBMIT_BYE ||
      currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL ||
      currentQuestion.slug === SLUG__SUBMIT_BYE_MANUAL_ESC ||
      currentQuestion.slug === SLUG__SUBMIT_BLOCKED ||
      currentQuestion.slug === SLUG__SUBMIT_EMAIL_OUTBOX ||
      currentQuestion.slug === SLUG__SUBMIT_BLOCKED_FINAL_REVIEW ||
      currentQuestion.slug === SLUG__SUBMIT_SENT_TO_IRS ||
      currentQuestion.slug === SLUG__SUBMIT_EXPERT_REVIEW_ASSIGNED
    ) {
      await dispatch(getCurrentNavigationSection());
    }
  };

export const getNextUrlNavigation =
  ({ navigationItem }) =>
  (dispatch, getState) => {
    const isFilingQualified = isFilingQualifiedSelector(getState());
    const sectionsStarted = navSectionsStartedSelector(getState());

    if (navigationItem.id === 'personal' && !sectionsStarted.includes('personal') && !isFilingQualified) {
      return `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__FILING_QUALIFIED}`;
    }

    return navigationItem.url;
  };

export const getNextUrlWithBulkUpload =
  ({ currentQuestion }) =>
  async (dispatch, getState) => {
    let nextPathComponent;

    if (currentQuestion.slug === SLUG__BULK_UPLOAD) {
      const { question } = await dispatch(fetchCurrentQuestionnaireQuestion());
      const isSkipEnabled = isTaxFilingSkipEnabledSelector(getState());
      nextPathComponent =
        _.isNil(question) || isSkipEnabled
          ? PATH_COMPONENT__SUBMIT_FINISH
          : PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_INTRO;
    } else if (currentQuestion.slug === PATH_COMPONENT__BULK_UPLOAD_QUESTIONS_INTRO) {
      nextPathComponent = PATH_COMPONENT__BULK_UPLOAD_QUESTIONS;
    } else if (currentQuestion.slug === SLUG__BULK_UPLOAD_QUESTIONS_SUMMARY) {
      nextPathComponent = PATH_COMPONENT__SUBMIT_FINISH;
    } else if (currentQuestion.slug === SLUG__BULK_UPLOAD_MANUAL_ENTRY) {
      const manualEntryItem = selectedBulkManualEntryItemSelector(getState());
      if (_.isNil(manualEntryItem)) {
        nextPathComponent = PATH_COMPONENT__BULK_UPLOAD;
      } else {
        const { collectionType, subType } = manualEntryItem;
        const bulkUploadItems = bulkUploadItemsSelector(getState());
        const { formInputQuestionSlug } = _.find(bulkUploadItems, { collectionType, subType });
        const nextCollectionId = await getNextCollectionId({ collectionType });
        return getBulkUploadFormUrl({ nextPathComponent: formInputQuestionSlug, collectionId: nextCollectionId });
      }
    }

    return nextPathComponent ? `/${TAXFLOW_BASE_URL}/${nextPathComponent}` : null;
  };
