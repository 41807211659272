// @ts-check

import moment from 'moment';
import React from 'react';
import TaxFlowSettlementStatusContainer from '@app/src/Components/TaxFlow/Question/TaxFlowSettlementStatusContainer';

/**
 * @typedef {Object} TaxFlowBillStatusProps
 * @property {string} mask
 * @property {number} amount
 * @property {import('moment').Moment} withdrawalDate
 * @property {string} entity
 * @property {import('moment').Moment} billDate
 * @property {string} paymentMethod
 * @property {string} status
 */

/** @type {React.FC<TaxFlowBillStatusProps>} */
const TaxFlowBillStatus = ({ mask, amount, withdrawalDate, entity, billDate, paymentMethod, status }) => {
  if (paymentMethod === 'paymentPlan') {
    return (
      <TaxFlowSettlementStatusContainer description='Payment plan' amount={amount} entity={entity} status={status}>
        You need to set up a payment plan with {entity === 'federal' ? 'the IRS' : entity} to pay your tax bill. Follow
        the steps outlined in{' '}
        <a
          href={(() => {
            if (entity === 'federal') {
              return 'https://help.keepertax.com/hc/en-us/articles/16759300984855-How-can-I-set-up-a-payment-plan-with-the-IRS-';
            }

            return 'https://help.keepertax.com/hc/en-us/articles/16759313306135-Need-an-installment-plan-for-your-state-taxes-';
          })()}
          target='_blank'
          rel='noreferrer'
        >
          this article
        </a>
        .
      </TaxFlowSettlementStatusContainer>
    );
  }

  if (billDate) {
    return (
      <TaxFlowSettlementStatusContainer
        amount={amount}
        description={billDate.format('MMM D, YYYY')}
        entity={entity}
        status={status}
        completed
      >
        Your tax bill was withdrawn {mask ? `from account ending in ${mask}` : `on ${billDate.format('MMM D, YYYY')}`}.
      </TaxFlowSettlementStatusContainer>
    );
  }

  const datePassed = withdrawalDate ? withdrawalDate.isSameOrBefore(moment().startOf('day')) : null;

  if (datePassed) {
    return (
      <TaxFlowSettlementStatusContainer
        amount={amount}
        estimatedDate={withdrawalDate}
        entity={entity}
        status={status}
        completed
      >
        Your tax bill is estimated to have been withdrawn from {mask ? `account ending in ${mask}` : 'your account'}.
      </TaxFlowSettlementStatusContainer>
    );
  }

  if (withdrawalDate) {
    return (
      <TaxFlowSettlementStatusContainer
        amount={amount}
        estimatedDate={withdrawalDate}
        entity={entity}
        status={status}
        completed={false}
      >
        Your tax bill will be withdrawn{' '}
        {mask ? `from account ending in ${mask}` : `on ${withdrawalDate.format('MMM D, YYYY')}`}. If the IRS isn't able
        to withdraw from the account you provided due to incorrect details or low balance, they will reach out via mail.
      </TaxFlowSettlementStatusContainer>
    );
  }

  return (
    <TaxFlowSettlementStatusContainer amount={amount} entity={entity} status={status} completed={false}>
      {mask
        ? `Your tax bill will be withdrawn from account ending in ${mask} once the IRS has accepted your return.`
        : 'Your tax bill will be processed once the IRS has accepted your return.'}
    </TaxFlowSettlementStatusContainer>
  );
};

export default TaxFlowBillStatus;
