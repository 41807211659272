import {
  SET_TAXFILING_DELETE_MODAL_COLLECTION_ID,
  SET_TAXFILING_DELETE_MODAL_COLLECTION_TYPE,
  SET_TAXFILING_DELETE_MODAL_SHOW,
  SET_TAXFILING_DELETE_MODAL_SLUG,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_SHOW,
  SET_TAXFILING_CURRENT_COLLECTION_ID,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_COLLECTION_ID,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_COLLECTION_TYPE,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_ATTEMPT_ID,
  SET_TAXFILING_CURRENT_TAX_STATE,
  SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_HEADER
} from '@app/src/actions/types';

export const setCollectionDeleteModalShow = (payload) => ({
  type: SET_TAXFILING_DELETE_MODAL_SHOW,
  payload
});

export const setCollectionDeleteModalCollectionType = (payload) => ({
  type: SET_TAXFILING_DELETE_MODAL_COLLECTION_TYPE,
  payload
});

export const setCollectionDeleteModalCollectionId = (payload) => ({
  type: SET_TAXFILING_DELETE_MODAL_COLLECTION_ID,
  payload
});

export const setCollectionDeleteModalSlug = (payload) => ({
  type: SET_TAXFILING_DELETE_MODAL_SLUG,
  payload
});

export const setCurrentCollectionId = (payload) => ({
  type: SET_TAXFILING_CURRENT_COLLECTION_ID,
  payload
});

export const setCollectionFormUploadErrorModalShow = (payload) => ({
  type: SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_SHOW,
  payload
});

export const setCollectionFormUploadErrorModalHeader = (payload) => ({
  type: SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_HEADER,
  payload
});

export const setCollectionFormUploadErrorModalCollectionId = (payload) => ({
  type: SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_COLLECTION_ID,
  payload
});

export const setCollectionFormUploadErrorModalCollectionType = (payload) => ({
  type: SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_COLLECTION_TYPE,
  payload
});

export const setCollectionFormUploadErrorModalAttemptId = (payload) => ({
  type: SET_TAXFILING_FORM_UPLOAD_ERROR_MODAL_ATTEMPT_ID,
  payload
});

export const setCurrentTaxState = (payload) => ({
  type: SET_TAXFILING_CURRENT_TAX_STATE,
  payload
});
