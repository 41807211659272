import React, { useCallback } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { PATH_COMPONENT__EXPENSES, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitSummaryElement.scss';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { reviewPillsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import { showCollectionDeleteModal } from '@app/src/taxflow/collection/services/collectionService';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import { collectionDeleteModalShow } from '@app/src/selectors/taxFlowModalsSelectors';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import url from 'url';
import { fetchReviewPills } from '@app/src/taxflow/main/services/taxFlowDataService';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import '@app/src/Components/TaxFlow/Question/TaxFlowReviewPillsSection.scss';
import LockIcon from '@app/src/assets/lock.svg?react';
import { getErrors } from '@app/src/services/taxValidationService';
import { isPreSubmitSelector, selfUnlockEnabledSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { isMobileBrowser, isReactNative } from '@app/src/global/Helpers';
import { setReviewPills } from '@app/src/actions/taxFlowActions';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import { SLUG__SUBMIT_FINISH } from '@app/src/taxflow/sections/submit/constants/submitConstants';

const TaxFlowReviewPillsSection = ({
  reviewPills,
  currentQuestion,
  selfUnlockEnabled,
  taxDataEditEnabled,
  selfUnlockModalOpen,
  collectionDeleteModalShow,
  fetchReviewPills,
  setReviewPills,
  showCollectionDeleteModal,
  setSelfUnlockModalOpen,
  refreshValidation
}) => {
  const pillSectionsLHS = _.take(reviewPills, _.size(reviewPills) / 2);
  const pillSectionsRHS = _.takeRight(reviewPills, _.size(reviewPills) / 2);
  const isUserBeingImpersonated = REACT_APP_ENV === 'staging-prod-db';
  // When impersonating, always allow edit on submit-finish
  const editEnabled = taxDataEditEnabled || (isUserBeingImpersonated && currentQuestion.slug === SLUG__SUBMIT_FINISH);

  const navigateToLinkedQuestion = useCallback(
    ({ startSlug, collectionType, collectionId, nextQuestionCollectionId, text }) => {
      trackActivity('review: pill clicked', { collectionType, collectionId, pillName: text });
      if (!editEnabled) {
        return;
      }
      if (collectionType === 'deductions') {
        !isReactNative() &&
          !isMobileBrowser() &&
          history.push(
            url.format({
              pathname: `/${PATH_COMPONENT__EXPENSES}`
            })
          );
        return;
      }
      const nextPathComponent = getPathComponentBySlug(startSlug);
      const nextCollectionId = nextQuestionCollectionId || collectionId;
      if (_.isNil(nextPathComponent)) {
        defaultCaptureMessage('Could not advance to review question', {
          coll_type: collectionType,
          coll_id: nextCollectionId,
          slug: startSlug
        });
        return;
      }
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: nextCollectionId === '0' ? undefined : nextCollectionId
          }
        })
      );
    },
    [editEnabled]
  );

  const confirmPillDeletion = useCallback(
    ({ collectionType, collectionId, text }) => {
      trackActivity('review: pill removal requested', { collectionType, collectionId, pillName: text });
      if (!editEnabled) {
        return;
      }
      showCollectionDeleteModal({
        collectionType: collectionType,
        collectionId
      });
    },
    [showCollectionDeleteModal, editEnabled]
  );

  const removePillAndRefresh = useCallback(
    ({ collectionType, collectionId }) => {
      setReviewPills(
        _.map(reviewPills, ({ pills, ...otherFields }) => ({
          ...otherFields,
          pills: _.filter(pills, _.negate(_.matches({ collectionType, collectionId })))
        }))
      );
      fetchReviewPills();
      refreshValidation();
    },
    [reviewPills, setReviewPills, fetchReviewPills, refreshValidation]
  );

  const onEditClick = () => {
    if (!selfUnlockModalOpen) {
      trackActivity('self unlock: modal opened', {
        question: currentQuestion?.slug
      });
      setSelfUnlockModalOpen(true);
    }
  };

  return (
    <>
      <div className='taxflow-review-pills-section'>
        <div className='taxflow-review-pills-section-button'>
          {selfUnlockEnabled ? (
            <div className='taxflow-review-pills-section-button-edit' onClick={onEditClick}>
              Edit
            </div>
          ) : null}
          {!editEnabled && !selfUnlockEnabled && <LockIcon></LockIcon>}
        </div>
        <div className='taxflow-review-pills-section-pills'>
          <div className='taxflow-review-pills-section-pills-column'>
            {pillSectionsLHS.map(({ section, pills }) => (
              <PillsSubSection
                key={section}
                heading={section}
                pills={pills}
                editEnabled={editEnabled}
                onClickPill={navigateToLinkedQuestion}
                onRemovePill={confirmPillDeletion}
              />
            ))}
          </div>
          <div className='taxflow-review-pills-section-pills-separator'></div>
          <div className='taxflow-review-pills-section-pills-column'>
            {pillSectionsRHS.map(({ section, pills }) => (
              <PillsSubSection
                key={section}
                heading={section}
                pills={pills}
                editEnabled={editEnabled}
                onClickPill={navigateToLinkedQuestion}
                onRemovePill={confirmPillDeletion}
              />
            ))}
          </div>
        </div>
      </div>
      {collectionDeleteModalShow && (
        <TaxFlowCollectionDeleteModal currentQuestion={currentQuestion} onDelete={removePillAndRefresh} />
      )}
    </>
  );
};

const PillsSubSection = ({ heading, pills = [], editEnabled, onClickPill, onRemovePill }) => {
  const deriveVariant = (pill) => {
    if (!editEnabled) {
      return 'disabled';
    }
    if (pill.warning) {
      return 'warning';
    }
    if (pill.addable) {
      return 'add';
    }
    return 'default';
  };

  return (
    <div className='taxflow-review-pills-section-pills-section'>
      <div className='taxflow-review-pills-section-pills-section-heading'>{heading}</div>
      <div className='taxflow-review-pills-section-pills-section-pills'>
        {pills
          .filter((pill) => !pill.addable || editEnabled)
          .map((pill) => {
            const key = (() => {
              if (pill.startSlug) {
                return `${pill.startSlug}-${pill.collectionId}`;
              }

              return `${pill.collectionType}-${pill.collectionId}`;
            })();

            return (
              <TaxFlowPill
                key={key}
                text={pill.text}
                icon={pill.icon}
                removable={editEnabled && pill.removable}
                variant={pill.variant || deriveVariant(pill)}
                onClick={() => onClickPill(pill)}
                onRemove={() => onRemovePill(pill)}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reviewPills: reviewPillsSelector(state),
  currentQuestion: currentQuestionSelector(state),
  collectionDeleteModalShow: collectionDeleteModalShow(state),
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state),
  selfUnlockEnabled: selfUnlockEnabledSelector(state),
  taxDataEditEnabled: isPreSubmitSelector(state)
});

const mapDispatchToProps = {
  showCollectionDeleteModal,
  fetchReviewPills,
  setReviewPills,
  setSelfUnlockModalOpen,
  refreshValidation: getErrors
};

const ConnectedTaxFlowReviewPillsSection = connect(mapStateToProps, mapDispatchToProps)(TaxFlowReviewPillsSection);

export default ConnectedTaxFlowReviewPillsSection;
