import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { CloseCircle, TickCircle } from 'iconsax-react';
import _ from 'lodash';
import React from 'react';

import { SUBSCRIPTION_FEATURES } from '@app/src/constants/subscriptionConstants';
import { colorError, colorFontDark, colorSuccess } from '@app/src/theme';

import '@app/src/Components/Subscription/Common/SubscriptionTypeElement.scss';

export const SubscriptionTypeElement = ({
  type,
  onClick,
  focused,
  selected,
  style,
  priceOverride,
  children,
  ...props
}) => {
  const { title, subtitle, price, frequency, features } = _.get(SUBSCRIPTION_FEATURES, type, {});

  const formattedPrice = priceOverride || price;

  const handleSelect = () => {
    onClick(type);
  };

  return (
    <Button
      className='subscription-type-element'
      onClick={handleSelect}
      style={{ ...style, padding: focused ? '16px' : '16px 16px 0' }}
      variant={selected ? 'subscription-type-selected' : 'subscription-type'}
      sx={{ height: 'fit-content', position: 'relative' }}
      {...props}
    >
      <div style={{ width: '100%' }}>
        <Stack direction='column' justifyContent='space-between'>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='column' justifyContent='space-between'>
              <div className='base-label title'>{title}</div>
              <div className='base-label subtitle'>{subtitle}</div>
            </Stack>
            <Stack direction='column' justifyContent='space-between'>
              <div style={{ color: colorFontDark, display: 'flex' }}>
                <span style={{ alignSelf: 'flex-start', fontSize: '12px' }}>$</span>
                <span className='base-label' style={{ fontSize: '24px' }}>
                  {formattedPrice}
                </span>
                <span style={{ alignSelf: 'center', fontSize: '12px' }}>{frequency}</span>
              </div>
              {type !== 'monthly' && (
                <div
                  className='base-label'
                  style={{ color: '#054628', backgroundColor: '#DBFFDB', padding: '4px 8px', borderRadius: '14px' }}
                >
                  ${Math.floor(formattedPrice / 12)} / mo
                </div>
              )}
            </Stack>
          </Stack>
          <Collapse in={focused}>
            <Stack direction='column' justifyContent='space-between' spacing={1}>
              {features.map((feature, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                  {feature.included ? (
                    <TickCircle variant='Bulk' color={colorSuccess} />
                  ) : (
                    <CloseCircle variant='Bulk' color={colorError} />
                  )}
                  <div style={{ marginLeft: '4px' }}>{feature.text}</div>
                </div>
              ))}
            </Stack>
          </Collapse>
        </Stack>
        {children}
      </div>
    </Button>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default SubscriptionTypeElement;
