import _ from 'lodash';
import {
  getQueryResultByEndpointAttribute,
  getQueryResultsByEndpointAttribute
} from '@app/src/taxflow/shared/utils/sharedUtils';
import {
  COLLECTION_TYPE__INCOME_FREELANCE,
  COLLECTION_TYPE__INCOME_INVEST,
  COLLECTION_TYPE__INCOME_RETIREMENT,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_STARTED,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_LONG_TERM_COST_BASIS,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_LONG_TERM_SALE_PROCEEDS,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_SHORT_TERM_COST_BASIS,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_SHORT_TERM_SALE_PROCEEDS,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_UNKNOWN_TERM_COST_BASIS,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_UNKNOWN_TERM_SALE_PROCEEDS,
  SLUG__INCOME_INVEST_UNIFICATION
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  COLLECTION_TYPE__STATE_EXPENSES,
  COLLECTION_TYPE__STATE_INCOME,
  COLLECTION_TYPE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';

export const getExpenseSplitJobs = (queryResults) => {
  return getQueryResultsByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__INCOME_FREELANCE,
    slug: ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_STARTED
  });
};

export const getExpenseSplitQuestions = ({ queryResults, getJobName }) => {
  const states = getQueryResultsByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__STATE_RETURN,
    slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
  });

  return getExpenseSplitJobs(queryResults).map((job) => {
    const jobName = getJobName(job);

    const subQuestion1 = {
      title: `${_.get(states, [0, 'answer', 'value'])} percentage`,
      question_meta: {
        default: 'e.g. 50%'
      },
      slug: `state-expenses-${_.get(states, [0, 'answer', 'value'])}-${job.coll_type}-${job.coll_id}`,
      collectionType: COLLECTION_TYPE__STATE_EXPENSES,
      header_style: 'embedded'
    };
    const subQuestion2 = {
      title: `${_.get(states, [1, 'answer', 'value'])} percentage`,
      question_meta: {
        default: 'e.g. 50%'
      },
      slug: `state-expenses-${_.get(states, [1, 'answer', 'value'])}-${job.coll_type}-${job.coll_id}`,
      collectionType: COLLECTION_TYPE__STATE_EXPENSES,
      header_style: 'embedded'
    };

    return {
      jobName,
      questions: [subQuestion1, subQuestion2]
    };
  });
};

export const getIncomeSplitQuestions = ({ queryResults, getJobName }) => {
  const jobTypes = {
    '1099 / business income': 'income-freelance',
    'Unemployment income': 'income-1099g',
    'Interest income': 'income-interest',
    'Dividend income': 'income-div',
    'Investment income': 'income-invest',
    'Retirement income': 'income-retirement'
  };
  const states = getQueryResultsByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__STATE_RETURN,
    slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
  });

  // generate questions for income split form
  return Object.keys(jobTypes).flatMap((jobType) => {
    let jobs = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: jobTypes[jobType],
      slug: `${jobTypes[jobType]}-started`
    });

    const unificationRecords = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__INCOME_INVEST,
      slug: SLUG__INCOME_INVEST_UNIFICATION
    });
    let unificationArray = [];
    if (unificationRecords.length) {
      jobs = jobs.filter((job) => {
        if (job.coll_type !== COLLECTION_TYPE__INCOME_INVEST) {
          return true;
        }

        unificationArray = unificationRecords
          .map((unif) => _.get(unif, ['answer', 'value']))
          .find((unif) => unif.includes(Number(job.coll_id)));

        if (!unificationArray.length || Number(job.coll_id) !== Math.min(...unificationArray)) {
          return false;
        }

        return true;
      });
    }

    const incomeSlug = getIncomeSlug({ jobType });

    const jobTypeQuestions = jobs.map((job) => {
      let incomeAmount;
      const jobName = getJobName(job);

      if (job.coll_type === COLLECTION_TYPE__INCOME_RETIREMENT) {
        const retirementType = _.get(
          getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: job.coll_type,
            collectionId: job.coll_id,
            slug: `${job.coll_type}-type`
          }),
          ['answer', 'value']
        );

        if (retirementType === 'pension') {
          incomeAmount = _.get(
            getQueryResultByEndpointAttribute({
              queryResults,
              collectionType: job.coll_type,
              collectionId: job.coll_id,
              slug: `${job.coll_type}-pension-gross-distribution`
            }),
            ['answer', 'value']
          );
        } else {
          incomeAmount = _.get(
            getQueryResultByEndpointAttribute({
              queryResults,
              collectionType: job.coll_type,
              collectionId: job.coll_id,
              slug: `${job.coll_type}-ssa-net-benefits`
            }),
            ['answer', 'value']
          );
        }
      } else if (job.coll_type === COLLECTION_TYPE__INCOME_INVEST) {
        const proceedsSum = [
          ENDPOINT_ATTRIBUTE__INCOME_INVEST_LONG_TERM_SALE_PROCEEDS,
          ENDPOINT_ATTRIBUTE__INCOME_INVEST_SHORT_TERM_SALE_PROCEEDS,
          ENDPOINT_ATTRIBUTE__INCOME_INVEST_UNKNOWN_TERM_SALE_PROCEEDS
        ]
          .map((slug) => {
            const incomeAmount = _.get(
              getQueryResultByEndpointAttribute({
                queryResults,
                collectionType: job.coll_type,
                collectionId: job.coll_id,
                slug
              }),
              ['answer', 'value']
            );

            return incomeAmount ? Number(incomeAmount) : 0;
          })
          .reduce((acc, cur) => acc + cur, 0);

        const costsSum = [
          ENDPOINT_ATTRIBUTE__INCOME_INVEST_LONG_TERM_COST_BASIS,
          ENDPOINT_ATTRIBUTE__INCOME_INVEST_SHORT_TERM_COST_BASIS,
          ENDPOINT_ATTRIBUTE__INCOME_INVEST_UNKNOWN_TERM_COST_BASIS
        ]
          .map((slug) => {
            const incomeAmount = _.get(
              getQueryResultByEndpointAttribute({
                queryResults,
                collectionType: job.coll_type,
                collectionId: job.coll_id,
                slug
              }),
              ['answer', 'value']
            );

            return incomeAmount ? Number(incomeAmount) : 0;
          })
          .reduce((acc, cur) => acc + cur, 0);

        const gain = Number(proceedsSum) - Number(costsSum);
        incomeAmount = gain > 0 ? `${gain}` : '0';
      } else {
        incomeAmount = _.get(
          getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: job.coll_type,
            collectionId: job.coll_id,
            slug: `${job.coll_type}-${incomeSlug}`
          }),
          ['answer', 'value']
        );
      }

      const subQuestion1 = {
        title: `${_.get(states, [0, 'answer', 'value'])} percentage`,
        question_meta: {
          default: 'e.g. 50%'
        },
        slug: `state-income-${_.get(states, [0, 'answer', 'value'])}-${job.coll_type}-${job.coll_id}`,
        collectionType: COLLECTION_TYPE__STATE_INCOME,
        header_style: 'embedded'
      };
      const subQuestion2 = {
        title: `${_.get(states, [1, 'answer', 'value'])} percentage`,
        question_meta: {
          default: 'e.g. 50%'
        },
        slug: `state-income-${_.get(states, [1, 'answer', 'value'])}-${job.coll_type}-${job.coll_id}`,
        collectionType: COLLECTION_TYPE__STATE_INCOME,
        header_style: 'embedded'
      };

      return {
        jobName,
        jobType,
        incomeAmount,
        questions: [subQuestion1, subQuestion2]
      };
    });

    return jobTypeQuestions;
  });
};

const getIncomeSlug = ({ jobType }) => {
  if (jobType === '1099 / business income') {
    return 'income';
  } else if (jobType === 'Unemployment income') {
    return 'compensation';
  } else if (jobType === 'Interest income') {
    return 'amount';
  } else if (jobType === 'Dividend income') {
    return 'ordinary';
  } else if (jobType === 'Investment income') {
    return 'sale-proceeds';
  }
};
