import _ from 'lodash';
import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import TextField from '@mui/material/TextField';
import { createStructuredSelector } from 'reselect';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import TaxValidationWarning from '@app/src/Components/TaxValidation/TaxValidationWarning';

const TaxFlowFormZipItem = ({ question, answer, onChange, onFocus, hasError }) => {
  const handleChange = ({ value }) => {
    onChange({
      slug: _.get(question, 'slug'),
      value,
      endpoint_attr: _.get(question, 'endpoint_attr')
    });
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus({ endpoint_attr: question.endpoint_attr });
    }
  };

  return (
    <div className='form-group'>
      <TaxValidationInputLabel question={question} htmlFor={question.slug} />
      <div className='tax-flow-input-wrap'>
        <TextField
          fullWidth
          name={question.slug}
          value={_.get(answer, 'value')}
          onChange={handleChange}
          onFocus={handleFocus}
          error={hasError}
          id={question.slug}
          placeholder={question.question_meta.default}
          InputProps={{
            inputComponent: NumberFormatCustom
          }}
        />
      </div>
      <TaxValidationWarning question={question} answer={answer} />
    </div>
  );
};

const NumberFormatCustom = forwardRef(({ onChange, ...props }, ref) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          value: values.value
        });
      }}
      inputMode='numeric'
      format='#####'
      mask='_'
    />
  );
});

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxFlowFormZipItem = connect(mapStateToProps)(TaxFlowFormZipItem);

export default ConnectedTaxFlowFormZipItem;
