import React from 'react';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

const DelayedLoader = ({ className }) => {
  return (
    <Fade
      in
      style={{
        transitionDelay: '200ms'
      }}
      unmountOnExit
    >
      <CircularProgress className={className} color='secondary' size={50} />
    </Fade>
  );
};

export default DelayedLoader;
