import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  CATEGORY_TYPE_BUSINESS_CODE,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD
} from '@app/src/constants/constants';
import {
  currentQuestionSelector,
  currentCollectionIdSelector,
  currentAnswerSelector,
  queryResultsSelector,
  statusSelector,
  isAlabamaOrMinnesotaSelector,
  questionsAnsweredSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import {
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_FINISH,
  SLUG__SUBMIT_PIN,
  SLUG__SUBMIT_HAVE_PIN,
  SLUG__SUBMIT_SELF_PIN_NUMBER,
  SLUG__SUBMIT_SPOUSE_PIN_NUMBER,
  SLUG__SUBMIT_DEBIT_MANUAL,
  SLUG__SUBMIT_PAY_NOW,
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_AUDIT_ISSUE,
  SLUG__SUBMIT_IRS_ISSUE,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_DEBIT_MANUAL_OPTION,
  SLUG__SUBMIT_PAY_TEST,
  SLUG__SUBMIT_BANK_NUMBERS,
  SLUG__SUBMIT_CONFIRM_ID,
  SLUG__SUBMIT_FINISH_LATER
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { hasQuestionError, isQuestionPresent } from '@app/src/utils/taxValidationUtils';
import {
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_MULTISTATE,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE_MULTISTATE,
  SLUG__DEPENDENT_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL_REASON,
  SLUG__DEPENDENT_RELATIONSHIP_TYPE,
  SLUG__HOME_ADDRESS_DETAIL,
  SLUG__HOME_ADDRESS_STATE
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { SLUG__CONTACT_SUPPORT, SLUG__PAST_RETURNS } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  currentUploadsSelector,
  selectedBulkManualEntryItemSelector,
  isQuestionnaireFlowSelector
} from '@app/src/selectors/taxFlowSelectors';
import { isTaxfilingNonskippable } from '@app/src/taxflow/main/utils/mainUtils';
import {
  bulkUploadItemsSelector,
  bulkUploadPillsSelector,
  failedUploadAttemptsSelector,
  formUploadAttemptsSelector,
  isBulkUploadSelector,
  isCurrentCollectionUploadedSelector,
  nonDeletedUploadAttemptsSelector,
  unclassifiedUploadsSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  COLLECTION_TYPE__INCOME_INVEST,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE,
  ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INDUSTRY,
  SLUG__INCOME_FREELANCE_1099_TYPE,
  SLUG__INCOME_INVEST_UNIFICATION,
  SLUG__INCOME_INVEST_INFO
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import {
  SLUG__CAR_OPTIONS,
  SLUG__CAR_WORK_MILES_TRACKED,
  SLUG__CAR_STANDARD_MILEAGE
} from '@app/src/taxflow/sections/car/constants/carConstants';
import { HOME_SLUGS } from '@app/src/taxflow/sections/home/constants/homeConstants';
import {
  SLUG__BULK_UPLOAD,
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__BULK_UPLOAD_QUESTIONS_ADD_FORMS,
  SLUG__BULK_UPLOAD_QUESTIONS_ALL_FORMS_UPLOADED,
  SLUG__CREDIT_ADD_MORE,
  SLUG__FILING_QUALIFIED,
  SLUG__INCOME_ADD_MORE,
  SLUG__SPECIAL_EARLY_EXIT
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  COLLECTION_TYPE__STATE_EXPENSES,
  COLLECTION_TYPE__STATE_INCOME,
  COLLECTION_TYPE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  SLUG__DC_RENT_CREDIT_OPTIONS,
  SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import isValidInput from '@app/src/services/taxFlow/isValidInput';
import { isOnboardingQuestion } from '@app/src/services/taxFlow/isOnboardingQuestion';
import {
  isPaymentValidSelector,
  submitIssueMessagesSelector,
  uncheckedSubmitIssueMessagesSelector
} from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import { Url_SIGNUP_PHONE_ONLY_PHONE } from '@app/src/constants/onboardingConstants';
import { taxFilingEnabledSelector } from '@app/src/selectors/workSelectors';
import {
  SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
  SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
  SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { submitEnabledSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { submitWarningsSelector } from '@app/src/api/taxDataApi';

const questionSelector = (state, props) => props.question;
const overrideCollectionIdSelector = (state, props) => props.overridecollectionid;
export const taxValidationErrorsSelector = (state) => _.get(state, ['taxValidation', 'errors']);

export const errorsSelector = createSelector(
  [taxValidationErrorsSelector, isBulkUploadSelector],
  (taxValidationErrors, isBulkUpload) => {
    if (!isBulkUpload) {
      return taxValidationErrors;
    }

    // exclude errors that will get asked in clarifying flow
    const clarifyingQuestionErrors = taxValidationErrors.filter(
      (error) =>
        ![
          'income-freelance-job-name',
          'income-freelance-business-code',
          'credit-healthcare-options',
          'credit-healthcare-employer-option',
          'credit-healthcare-1095a',
          'credit-student-tuition-qualify',
          'credit-student-tuition-aotc-qualify'
        ].includes(error.slug)
    );

    return clarifyingQuestionErrors;
  }
);

export const filteredErrorsSelector = createSelector(
  [
    errorsSelector,
    questionsAnsweredSelector,
    isCurrentCollectionUploadedSelector,
    bulkUploadItemsSelector,
    currentQuestionSelector
  ],
  (errors, questionsAnswered, isCurrentCollectionUploaded, bulkUploadItems, currentQuestion) => {
    return _.some(bulkUploadItems, { formInputQuestionSlug: currentQuestion.slug }) && isCurrentCollectionUploaded
      ? errors
      : errors.filter(({ slug, coll_id }) => questionsAnswered.has(JSON.stringify({ slug, coll_id })));
  }
);

export const formErrorsSelector = createSelector(
  [
    filteredErrorsSelector,
    currentQuestionSelector,
    currentCollectionIdSelector,
    currentAnswerSelector,
    statusSelector,
    isBulkUploadSelector,
    nonDeletedUploadAttemptsSelector,
    failedUploadAttemptsSelector
  ],
  (errors, question, collectionId, answer, status, isBulkUpload, nonDeletedUploadAttempts, failedUploadAttempts) => {
    const collectionIds =
      question.slug === SLUG__INCOME_INVEST_INFO ? [collectionId, `${Number(collectionId) + 1}`] : [collectionId];

    if (isBulkUpload) return _.isEmpty(nonDeletedUploadAttempts) ? [true] : failedUploadAttempts;
    return _.chain(errors)
      .filter(({ slug, coll_id }) => {
        if (_.get(question, 'question_type') === CATEGORY_TYPE_TAXFLOW_FORM) {
          const questionEndpointAttrs = new Set(
            _.chain(question)
              .get('sub_question', [])
              .filter((subQuestion) =>
                isQuestionPresent({
                  currentQuestion: question,
                  question: subQuestion,
                  answer,
                  status
                })
              )
              .map('endpoint_attr')
              .value()
          );
          return questionEndpointAttrs.has(slug) && collectionIds.includes(coll_id);
        }

        return slug === question.endpoint_attr && collectionIds.includes(coll_id);
      })
      .keyBy('slug')
      .value();
  }
);

const isPrefillSelector = createSelector(
  [formUploadAttemptsSelector, questionSelector, currentCollectionIdSelector],
  (formUploadAttempts, question, currentCollectionId) => {
    const collectionType = _.get(question, 'collectionType');
    const collectionId = currentCollectionId;
    if (_.isNil(collectionType) || _.isNil(collectionId)) {
      return false;
    }

    const collectionAttempt = _.get(formUploadAttempts, [collectionType, collectionId], {});
    return _.get(collectionAttempt, 'status') === 'prefilled';
  }
);

export const hasFieldErrorSelector = createSelector(
  [
    questionSelector,
    currentAnswerSelector,
    currentCollectionIdSelector,
    filteredErrorsSelector,
    queryResultsSelector,
    isPrefillSelector,
    overrideCollectionIdSelector
  ],
  (question, answer, collectionId, errors, queryResults, isPrefill, overrideCollectionId) => {
    if (
      question.slug === Url_SIGNUP_PHONE_ONLY_PHONE &&
      _.get(answer, ['value', Url_SIGNUP_PHONE_ONLY_PHONE, 'value'], '').startsWith('11')
    ) {
      return true;
    } else if (question.collectionType === COLLECTION_TYPE__STATE_RETURN) {
      const firstStateReturn = queryResults.find((result) => {
        return result.coll_type === COLLECTION_TYPE__STATE_RETURN && result.slug === ENDPOINT_ATTRIBUTE__STATE_RETURN;
      });
      return firstStateReturn && _.get(answer, ['value']) === _.get(firstStateReturn, ['answer', 'value']);
    }

    let collectionErrors;
    if (question.collectionType === COLLECTION_TYPE__INCOME_INVEST) {
      const unificationRecord = queryResults.find(
        (result) => result.slug === SLUG__INCOME_INVEST_UNIFICATION && result.coll_id === collectionId
      );
      const unifiedCollIds = _.get(unificationRecord, ['answer', 'value']) || [Number(collectionId)];
      collectionErrors = errors.filter(
        (error) =>
          error.coll_type === question.collectionType &&
          unifiedCollIds.includes(Number(error.coll_id)) &&
          error.slug === question.endpoint_attr &&
          (_.isNil(overrideCollectionId) || overrideCollectionId === Number(error.coll_id))
      );
    } else if (
      question.collectionType === COLLECTION_TYPE__STATE_INCOME ||
      question.collectionType === COLLECTION_TYPE__STATE_EXPENSES
    ) {
      if (_.isArray(question.slug)) {
        collectionErrors = errors.filter(
          (error) => error.coll_type === question.collectionType && question.slug.includes(error.slug)
        );
      } else {
        collectionErrors = errors.filter(
          (error) => error.coll_type === question.collectionType && error.slug === question.slug
        );
      }
    } else {
      collectionErrors = errors.filter(
        (error) =>
          error.coll_type === question.collectionType &&
          error.coll_id === collectionId &&
          error.slug === question.endpoint_attr
      );
    }

    const ocrErrors = collectionErrors.filter((error) => error.code === 'ocr_error');
    return (isPrefill && !!ocrErrors.length) || collectionErrors.length > ocrErrors.length;
  }
);

export const isTaxFilingNextEnabledSelector = createSelector(
  [
    currentQuestionSelector,
    currentAnswerSelector,
    queryResultsSelector,
    errorsSelector,
    statusSelector,
    isPaymentValidSelector,
    submitIssueMessagesSelector,
    submitWarningsSelector,
    submitEnabledSelector,
    taxFilingEnabledSelector,
    currentUploadsSelector,
    unclassifiedUploadsSelector,
    isAlabamaOrMinnesotaSelector,
    isQuestionnaireFlowSelector,
    bulkUploadPillsSelector,
    uncheckedSubmitIssueMessagesSelector
  ],
  (
    question,
    answer,
    queryResults,
    errors,
    status,
    isPaymentValid,
    submitIssueMessages,
    submitWarnings,
    submitEnabled,
    taxFilingEnabled,
    currentUploads,
    unclassifiedUploads,
    isAlabamaOrMinnesota,
    isBulkUploadFlow,
    bulkUploadPills,
    uncheckedSubmitIssues
  ) => {
    const hasError = !_.isEmpty(errors);
    if (_.includes([SLUG__INCOME_ADD_MORE, SLUG__CREDIT_ADD_MORE], question.slug)) {
      return false;
    }

    if (_.includes([SLUG__SUBMIT_FINISH, SLUG__SUBMIT_FINISH_LATER], question.slug)) {
      return submitEnabled && !hasError && _.isEmpty(submitWarnings) && _.isEmpty(uncheckedSubmitIssues);
    }

    if (
      question.slug === SLUG__SUBMIT_DEBIT_MANUAL &&
      _.get(answer, ['value', SLUG__SUBMIT_DEBIT_MANUAL_OPTION, 'value']) !== 'manual'
    ) {
      return false;
    }

    if (question.slug === SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD) {
      return false;
    }

    if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD) {
      const hasPendingUpload = currentUploads.some((upload) => upload.status === 'pending');

      if (question.slug === SLUG__BULK_UPLOAD) {
        return (
          !hasPendingUpload &&
          _.isEmpty(unclassifiedUploads) &&
          !_.some(bulkUploadPills, { parseFailed: true }) &&
          !_.some(bulkUploadPills, { loading: true })
        );
      }

      // form upload is required for AL and MN residents
      return !hasPendingUpload && !isAlabamaOrMinnesota;
    }

    // required questions without default are non skippable
    if (
      [
        SLUG__SUBMIT_DEBIT,
        SLUG__SUBMIT_DEBIT_MANUAL,
        SLUG__SUBMIT_BANK_NUMBERS,
        SLUG__INCOME_FREELANCE_1099_TYPE,
        SLUG__CAR_OPTIONS,
        SLUG__CAR_WORK_MILES_TRACKED,
        SLUG__CAR_STANDARD_MILEAGE,
        HOME_SLUGS.OPTIONS,
        HOME_SLUGS.TYPE,
        SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
        SLUG__DC_RENT_CREDIT_OPTIONS,
        SLUG__SUBMIT_SIGNATURE,
        SLUG__SUBMIT_EMAIL_INFO,
        SLUG__SUBMIT_CONFIRM_ID,
        SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
        SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
        SLUG__FILING_QUALIFIED,
        SLUG__BULK_UPLOAD_QUESTIONS_ALL_FORMS_UPLOADED
      ].includes(question.slug)
    ) {
      return !hasQuestionError({ question, answer, status, queryResults });
    }

    if (question.slug === SLUG__SUBMIT_PIN) {
      if (_.get(answer, ['value', SLUG__SUBMIT_HAVE_PIN, 'value']) !== '1') {
        return true;
      } else {
        const pinQuestion = question.sub_question.find((x) => x.slug === SLUG__SUBMIT_SELF_PIN_NUMBER);
        const pinMinLength = _.get(pinQuestion, ['question_meta', 'minLength'], 6);
        const selfPin = _.get(answer, ['value', SLUG__SUBMIT_SELF_PIN_NUMBER, 'value']);
        const spousePin = _.get(answer, ['value', SLUG__SUBMIT_SPOUSE_PIN_NUMBER, 'value']);
        const selfPinValid = !_.isNil(selfPin) ? _.isEmpty(selfPin) || selfPin.length >= pinMinLength : false;
        const isSpousePinPresent = isQuestionPresent({
          question: question.sub_question.find((x) => x.slug === SLUG__SUBMIT_SPOUSE_PIN_NUMBER),
          answer,
          status
        });
        const spousePinValid = !_.isNil(spousePin) ? _.isEmpty(spousePin) || spousePin.length >= pinMinLength : false;

        if (isSpousePinPresent) {
          return selfPinValid && spousePinValid && (!_.isEmpty(selfPin) || !_.isEmpty(spousePin));
        }

        return selfPinValid && !_.isEmpty(selfPin);
      }
    }

    if (question.slug === SLUG__SUBMIT_AUDIT_ISSUE || question.slug === SLUG__SUBMIT_IRS_ISSUE) {
      return submitIssueMessages.filter((e) => e.blocking).every((e) => e.isChecked);
    }

    if (question.slug === SLUG__SUBMIT_PAY_NOW) {
      return isPaymentValid;
    }

    if (question.slug === SLUG__SUBMIT_PAY_TEST) {
      return isPaymentValid || _.get(answer, ['value', 'paymentType']) === 'paymentRequest';
    }

    if (question.slug === SLUG__HOME_ADDRESS_DETAIL) {
      const addressSubquestions = question.sub_question || [];
      const multistateOnly = !addressSubquestions.some((q) => q.slug === SLUG__HOME_ADDRESS_STATE);

      if (isBulkUploadFlow && multistateOnly) {
        const multistateOption = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_MULTISTATE, 'value']);
        if (_.isNil(multistateOption)) {
          return false;
        }

        const secondState = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE_MULTISTATE, 'value']);
        return multistateOption === '0' || !_.isEmpty(secondState);
      } else {
        const state = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE, 'value']);
        return !_.isNil(state) && !_.isEmpty(state);
      }
    }

    if (question.slug === SLUG__DEPENDENT_DETAIL) {
      const dependentFirstName = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME, 'value']);
      return !_.isNil(dependentFirstName) && !_.isEmpty(dependentFirstName);
    }

    if (question.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
      const businessCode = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_BUSINESS_CODE, 'value']);
      const industry = _.get(answer, ['value', ENDPOINT_ATTRIBUTE__INCOME_FREELANCE_INDUSTRY, 'value']);
      return !_.isEmpty(businessCode) && !_.isEmpty(industry);
    }

    if (question.slug === SLUG__STATE_RETURN) {
      return isValidInput({ question, answer, status, queryResults });
    }

    if (
      question.slug === SLUG__SPECIAL_EARLY_EXIT ||
      question.slug === SLUG__CONTACT_SUPPORT ||
      question.slug === SLUG__PAST_RETURNS ||
      question.slug === SLUG__BULK_UPLOAD_QUESTIONS_ADD_FORMS
    ) {
      return false;
    }

    if (
      question.slug === SLUG__DEPENDENT_RELATIONSHIP_DETAIL &&
      !_.get(answer, ['value', SLUG__DEPENDENT_RELATIONSHIP_TYPE, 'value'])
    ) {
      return false;
    }

    if (
      question.slug === SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL &&
      !_.get(answer, ['value', SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL_REASON, 'value'])
    ) {
      return false;
    }

    return true;
  }
);

export const isTaxFilingSkipEnabledSelector = createSelector(
  [
    currentQuestionSelector,
    currentAnswerSelector,
    isTaxFilingNextEnabledSelector,
    isAlabamaOrMinnesotaSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    statusSelector,
    errorsSelector,
    formErrorsSelector,
    currentUploadsSelector,
    nonDeletedUploadAttemptsSelector,
    selectedBulkManualEntryItemSelector
  ],
  (
    currentQuestion,
    currentAnswer,
    isTaxFilingNextEnabled,
    isAlabamaOrMinnesota,
    queryResults,
    currentCollectionId,
    status,
    errors,
    formErrors,
    selectedBulkManualEntryItem
  ) => {
    const ocrErrors = (errors || []).filter(
      (error) =>
        error.coll_type === currentQuestion.collectionType &&
        error.coll_id === currentCollectionId &&
        error.code === 'ocr_error'
    );

    if (currentQuestion.slug === SLUG__BULK_UPLOAD_MANUAL_ENTRY) {
      return _.isNil(selectedBulkManualEntryItem);
    }

    return (
      !isOnboardingQuestion(currentQuestion) &&
      !isTaxfilingNonskippable(currentQuestion, isAlabamaOrMinnesota) &&
      isTaxFilingNextEnabled &&
      !_.isEmpty(formErrors) &&
      (!isValidInput({
        question: currentQuestion,
        answer: currentAnswer,
        status,
        queryResults,
        currentCollectionId,
        formErrors
      }) ||
        !_.isEmpty(ocrErrors))
    );
  }
);
