import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { updateTaxState } from '@app/src/taxflow/collection/services/collectionService';
import TaxValidationSelect from '@app/src/Components/TaxValidation/TaxValidationSelect';
import { SLUG__STATE_RETURN } from '@app/src/taxflow/sections/state/constants/stateConstants';

class TaxFlowDropdownItem extends Component {
  onChange = (e) => {
    this.props.setCurrentAnswer({ value: e.target.value });
    if (this.props.currentQuestion.slug === SLUG__STATE_RETURN) {
      this.props.updateTaxState(e.target.value);
    }
  };

  render() {
    const { currentQuestion, currentAnswer, resultLoading } = this.props;

    if (resultLoading) return null;

    return (
      <div className='steps-body'>
        <TaxValidationSelect
          question={currentQuestion}
          answer={currentAnswer}
          name={currentQuestion.slug}
          onChange={this.onChange}
          value={currentAnswer && !_.isNil(currentAnswer.value) ? currentAnswer.value : ''}
        >
          {currentQuestion.question_meta.map((item) => (
            <option value={item.value} key={item.value}>
              {item.text}
            </option>
          ))}
        </TaxValidationSelect>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentQuestion: _.get(state, ['taxFlow', 'currentQuestion']),
  currentAnswer: _.get(state, ['taxFlow', 'currentAnswer'])
});

const mapDispatchToProps = {
  setCurrentAnswer,
  updateTaxState
};

const ConnectedTaxFlowDropdownItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowDropdownItem);

export default ConnectedTaxFlowDropdownItem;
