import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import { hasSpecialChars } from '@app/src/global/Helpers';
import { getKnownJobInfo } from '@app/src/taxflow/shared/utils/sharedUtils';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import { SLUG__INCOME_FREELANCE_JOB_NAME } from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { getDerivedQuestion } from '@app/src/taxflow/main/utils/mainUtils';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { jobOptionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import AddIcon from '@app/src/assets/add.svg?react';
import { currentAnswerSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

const TaxFlowFormCustomJobItem = (props) => {
  const {
    jobCategories,
    question,
    currentFormAnswer,
    answer,
    jobOptions,
    isCustomJobSelected,
    setCurrentAnswer,
    setIsCustomJobSelected,
    setBusinessCode,
    onFocus
  } = props;
  const [customJobText, setCustomJobText] = useState('');

  useEffect(() => {
    const answerVal = _.get(answer, 'value');

    if (question.slug === SLUG__INCOME_FREELANCE_JOB_NAME && !isPrefilledJob(question, answer, jobOptions)) {
      setCustomJobText(answerVal);
    }
  }, [question, answer, jobOptions]);

  const inputRef = useRef();

  const setJobNameAnswer = (value) => {
    setCurrentAnswer({
      value: { ..._.get(currentFormAnswer, 'value', {}), [SLUG__INCOME_FREELANCE_JOB_NAME]: { value: value } }
    });
  };

  const onChange = (e) => {
    if (e.target.value && hasSpecialChars(e.target.value, 'lettersNumbersSpacesHyphens')) {
      return;
    }

    setJobNameAnswer(e.target.value);
    setBusinessCode(null);

    if (question.slug === SLUG__INCOME_FREELANCE_JOB_NAME) {
      setCustomJobText(e.target.value);
    }
  };

  const onCustomJobInputFocus = () => {
    setJobNameAnswer(customJobText);
    onFocus();
  };

  const handleListItemChange = () => {
    setIsCustomJobSelected(true);
    setJobNameAnswer(customJobText);
    setBusinessCode(null);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  let customJobName = customJobText;

  if (!_.isEmpty(customJobText)) {
    const knownJobCategory = getKnownJobInfo(jobCategories, customJobText);
    if (knownJobCategory && knownJobCategory.name) {
      customJobName = knownJobCategory.name;
    }
  }

  const isPrefilledJob = (question, answer, jobOptions) => {
    const derivedQuestion = getDerivedQuestion({ question, jobOptions });
    return derivedQuestion.question_meta.some((job) => job.value === _.get(answer, 'value'));
  };

  return (
    <>
      <TaxFlowListItem handleChange={handleListItemChange} checked={isCustomJobSelected}>
        <AddIcon className='tax-flow-list-item-job-icon' />
        <span>Something else</span>
      </TaxFlowListItem>
      {isCustomJobSelected && (
        <div className='tax-flow-input-wrap tax-flow-custom-job-item'>
          <TaxValidationInputLabel question={{ title: 'Enter another job or LLC' }} />
          <TextField
            fullWidth
            className={classNames('form-control', {
              'tax-flow-custom-job-item-selected': isCustomJobSelected
            })}
            inputRef={inputRef}
            type='text'
            value={customJobName}
            onChange={onChange}
            placeholder='e.g. Cleaner'
            onFocus={onCustomJobInputFocus}
            onKeyPress={onKeyPress}
            inputProps={{ 'data-testid': 'tax-flow-custom-job-item-selected' }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentFormAnswer: currentAnswerSelector(state),
  jobOptions: jobOptionsSelector(state),
  jobCategories: jobCategoriesSelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowFormCustomJobItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormCustomJobItem);

export default ConnectedTaxFlowFormCustomJobItem;
