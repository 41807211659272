import React, { forwardRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import AssistantMessageImage from '@app/src/Components/Assistant/AssistantMessageLinkImage';
import AssistantMessageLinkPreview from '@app/src/Components/Assistant/AssistantMessageLinkPreview';
import '@app/src/Components/Assistant/AssistantMessageAttachments.scss';
import AssistantImageFileAttachment from '@app/src/Components/Assistant/AssistantImageFileAttachment';
import AssistantPdfFileAttachment from '@app/src/Components/Assistant/AssistantPdfFileAttachment';

const AssistantMessageAttachments = forwardRef(({ attachments, role, getImage, ...props }, ref) => {
  if (_.isEmpty(attachments)) return null;

  return (
    <div
      className={classNames('assistant-message-attachments', {
        'assistant-message-attachments-user': role === 'user'
      })}
      ref={ref}
      {...props}
    >
      {attachments.map((attachment, i) => {
        switch (_.get(attachment, 'type')) {
          case 'file/image': {
            return <AssistantImageFileAttachment key={i} attachment={attachment} getImage={getImage} />;
          }
          case 'file/pdf': {
            return <AssistantPdfFileAttachment key={i} attachment={attachment} />;
          }
          case 'image': {
            return <AssistantMessageImage key={i} attachment={attachment} getImage={getImage} />;
          }
          default: {
            if (attachment.og_scrape_url) {
              return <AssistantMessageLinkPreview key={i} attachment={attachment} getImage={getImage} />;
            }
            return null;
          }
        }
      })}
    </div>
  );
});

export default AssistantMessageAttachments;
