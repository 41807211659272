import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { setExpensesYear } from '@app/src/actions/expenseReviewActions';
import BankIcon from '@app/src/assets/bank-white.svg?react';
import IconClose from '@app/src/assets/close-white.svg?react';
import DepositedIcon from '@app/src/assets/dollar-circle-white.svg?react';
import IconLogo from '@app/src/assets/logo-white.svg?react';
import ExitIcon from '@app/src/assets/logout-white.svg?react';
import FileOnComputerIcon from '@app/src/assets/monitor-white.svg?react';
import SettingsIcon from '@app/src/assets/settings-white.svg?react';
import StreamAssistant from '@app/src/Components/Assistant/StreamAssistant';
import { Url_LOGIN } from '@app/src/constants/constants';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import history from '@app/src/keeperHistory';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import {
  isNavigationPanelEnabledSelector,
  isNavigationPanelOpenSelector
} from '@app/src/selectors/navigationListSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { uiStageSelector } from '@app/src/selectors/workSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { logout } from '@app/src/services/authService';
import { advanceToCurrentSectionPage } from '@app/src/taxflow/main/services/mainService';
import { getCurrentSectionPathComponent } from '@app/src/taxflow/main/utils/mainUtils';
import { useGetNavigationSectionsQuery, useGetSubmitIssuesQuery, useGetTaxDataQuery } from '@app/src/api/taxDataApi';
import { setIsNavigationPanelOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { COLLECTION_TYPE__SPECIAL } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__EXPENSES,
  PATH_COMPONENT__PAST_RETURNS,
  PATH_COMPONENT__SWITCH_TO_DESKTOP,
  TAX_FILING_YEAR,
  TAXFLOW_BASE_URL
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { colorPrimary } from '@app/src/theme';

import '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel.scss';
import { API_POLLING_INTERVAL_MS } from '@app/src/api/constants';

const TaxFlowNavigationPanel = ({
  user,
  currentQuestion,
  isNavigationPanelEnabled,
  isNavigationPanelOpen,
  uiStage,
  advanceToCurrentSectionPage,
  setIsNavigationPanelOpen,
  setExpensesYear,
  expensesYear,
  logout
}) => {
  const { pathname } = useLocation();
  const { width: windowWidth } = useWindowSize();

  const isExpenseReview = pathname === '/expenses';
  const isMobileApp = isReactNative();
  const isDesktop = windowWidth >= 1024;

  const title = user && user.firstname ? `Welcome, ${user.firstname}!` : `My ${TAX_FILING_YEAR} Taxes`;

  const currentSectionPathComponent = useCurrentSectionPathComponent({ uiStage });

  const isSubsectionHighlighted = (sub) => {
    if (pathname.includes('/expenses')) {
      return expensesYear === sub.title;
    } else {
      return pathname === sub.path;
    }
  };

  const closeNavPanel = () => {
    setIsNavigationPanelOpen(false);
  };

  const onHomeClick = async () => {
    setIsNavigationPanelOpen(false);
    await advanceToCurrentSectionPage({ history });
  };

  const onWriteOffsClick = () => {
    setIsNavigationPanelOpen(false);
    history.push('/expenses');
  };

  const onSettingsClick = () => {
    setIsNavigationPanelOpen(false);
    history.push('/settings/linked-accounts');
  };

  const onFileOnComputerClick = () => {
    setIsNavigationPanelOpen(false);
    history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SWITCH_TO_DESKTOP}`);
  };

  const onExitClick = () => {
    if (isMobileApp) {
      trackActivity('navigation panel: exit to app', {
        origin: _.get(currentQuestion, ['slug'])
      });
      sentMsgToReactNative('taxes_home');
    } else {
      trackActivity('navigation panel: log out button clicked', {
        origin: _.get(currentQuestion, ['slug'])
      });
      logout();
      history.push(`/${TAXFLOW_BASE_URL}/${Url_LOGIN}`);
    }
  };

  if (isMobileApp && !isNavigationPanelEnabled && !isExpenseReview) {
    return null;
  }

  return (
    <>
      {isNavigationPanelOpen && <div className='taxflow-navigation-panel-background' onClick={closeNavPanel} />}
      <Paper
        sx={{ zIndex: 1200, backgroundColor: colorPrimary, position: 'fixed' }}
        square
        elevation={2}
        className={classNames('taxflow-navigation-panel', { open: isNavigationPanelOpen })}
      >
        {(!isDesktop || isMobileApp) && (
          <div className='taxflow-navigation-panel-close'>
            <IconClose onClick={closeNavPanel} />
          </div>
        )}
        <IconLogo className='taxflow-navigation-panel-logo' />
        <div className='taxflow-navigation-panel-header-title'>{title}</div>
        <div className='taxflow-navigation-panel-list'>
          {[
            {
              sectionTitle: 'Deductions',
              basePath: '/expenses',
              subSections: [
                { title: `${moment().year()}`, path: `/${PATH_COMPONENT__EXPENSES}` },
                { title: `${moment().year() - 1}`, path: `/${PATH_COMPONENT__EXPENSES}` }
              ],
              renderCondition: !isMobileApp,
              renderIcon: () => <DepositedIcon />,
              onSectionClick: () => onWriteOffsClick(),
              onSubsectionClick: (sub) => {
                setExpensesYear(sub.title);
                setIsNavigationPanelOpen(false);
              },
              key: 'deductions'
            },
            {
              sectionTitle: 'Tax filing',
              basePath: `/${TAXFLOW_BASE_URL}`,
              subSections: [
                {
                  title: 'File taxes',
                  path: `/${TAXFLOW_BASE_URL}/${currentSectionPathComponent}`
                },
                { title: 'Past returns', path: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__PAST_RETURNS}` }
              ],
              renderCondition: true,
              renderIcon: () => <BankIcon />,
              onSectionClick: () => onHomeClick(),
              onSubsectionClick: () => {
                setIsNavigationPanelOpen(false);
              },
              key: 'tax-filing'
            },
            {
              sectionTitle: 'Settings',
              basePath: '/settings',
              subSections: [
                { title: 'Linked accounts', path: `/settings/linked-accounts` },
                { title: 'Notifications', path: `/settings/notifications` },
                { title: 'Login credentials', path: `/settings/login-credentials` },
                { title: 'Subscription', path: `/settings/subscription` }
              ],
              renderCondition: !isMobileApp,
              renderIcon: () => <SettingsIcon />,
              onSectionClick: () => onSettingsClick(),
              onSubsectionClick: () => {
                setIsNavigationPanelOpen(false);
              },
              key: 'settings'
            }
          ].map((s) => {
            if (!s.renderCondition) {
              return null;
            }

            const isOpen = pathname.includes(s.basePath);

            return (
              <div key={s.key} className='taxflow-navigation-panel-list-sub-item'>
                <div
                  className={classNames('taxflow-navigation-panel-list-item', {
                    'taxflow-navigation-panel-list-item-open': isOpen
                  })}
                  onClick={s.onSectionClick}
                  role='button'
                >
                  {s.renderIcon()}
                  <span>{s.sectionTitle}</span>
                </div>
                {isOpen ? (
                  <>
                    {s.subSections.map((sub, idx) => (
                      <div
                        role='button'
                        onClick={() => {
                          s.onSubsectionClick(sub);
                          history.push(sub.path);
                        }}
                        key={`${s.key}-${idx}`}
                        className={classNames('taxflow-navigation-panel-list-item-subsection', {
                          'taxflow-navigation-panel-list-item-subsection-last': idx === s.subSections.length - 1,
                          'taxflow-navigation-panel-list-item-subsection-selected': isSubsectionHighlighted(sub)
                        })}
                      >
                        {sub.title}
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            );
          })}
          {isMobileApp && (
            <div className='taxflow-navigation-panel-list-item' onClick={onFileOnComputerClick} role='button'>
              <FileOnComputerIcon />
              <span>File on your computer</span>
            </div>
          )}
          <div className='taxflow-navigation-panel-list-item' onClick={onExitClick} role='button'>
            <ExitIcon />
            <span>{isMobileApp ? 'Exit tax filing' : 'Log out'}</span>
          </div>
        </div>
      </Paper>
      <StreamAssistant />
    </>
  );
};

const useCurrentSectionPathComponent = ({ uiStage }) => {
  const [currentSectionPathComponent, setCurrentSectionPathComponent] = useState(undefined);

  const { data: navigationSections } = useGetNavigationSectionsQuery(undefined, {
    pollingInterval: API_POLLING_INTERVAL_MS
  });
  const { data: specialTaxData } = useGetTaxDataQuery(
    { collectionType: COLLECTION_TYPE__SPECIAL },
    { pollingInterval: API_POLLING_INTERVAL_MS }
  );
  const { data: submitIssues } = useGetSubmitIssuesQuery(undefined, { pollingInterval: API_POLLING_INTERVAL_MS });

  useEffect(() => {
    setCurrentSectionPathComponent(
      getCurrentSectionPathComponent({
        specialTaxData,
        navigationSections,
        uiStage,
        submitIssues
      })
    );
  }, [uiStage, navigationSections, specialTaxData, submitIssues]);
  return currentSectionPathComponent;
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  currentQuestion: currentQuestionSelector(state),
  isNavigationPanelOpen: isNavigationPanelOpenSelector(state),
  isNavigationPanelEnabled: isNavigationPanelEnabledSelector(state),
  uiStage: uiStageSelector(state),
  expensesYear: expensesYearSelector(state)
});

const mapDispatchToProps = {
  advanceToCurrentSectionPage,
  setIsNavigationPanelOpen,
  setExpensesYear,
  logout
};

const ConnectedTaxFlowNavigationPanel = connect(mapStateToProps, mapDispatchToProps)(TaxFlowNavigationPanel);

export default ConnectedTaxFlowNavigationPanel;
