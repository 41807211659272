import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getQuestion, updateAnswer, initJobSelect } from '@app/src/services/onboardingService';
import OnboardingQuestion from '@app/src/Components/Onboarding/Question/OnboardingQuestion';
import { Url_ONBOARDING_JOB_SELECT } from '@app/src/constants/onboardingConstants';

const OnboardingJobSelection = ({ history, location, params, initJobSelect, getQuestion, updateAnswer }) => {
  useEffect(() => {
    const run = async () => {
      await initJobSelect({ location });
      await getQuestion({
        slug: Url_ONBOARDING_JOB_SELECT,
        history,
        location,
        params
      });
    };
    run();
  }, [initJobSelect, getQuestion, history, location, params]);

  const onNext = async () => {
    await updateAnswer({ history });
  };

  return <OnboardingQuestion onNext={onNext} />;
};

const mapDispatchToProps = {
  initJobSelect,
  getQuestion,
  updateAnswer
};

const ConnectedOnboardingJobSelection = connect(null, mapDispatchToProps)(OnboardingJobSelection);

export default ConnectedOnboardingJobSelection;
