import Button from '@mui/material/Button';
import classNames from 'classnames';
import React from 'react';
import { colorFontDark, colorNeutralMetallica, colorPrimary, colorPrimaryAlt3, colorSurface } from '@app/src/theme';
import '@app/src/Components/HolisticOnboarding/components/VerticalIconButton/VerticalIconButton.scss';

const VerticalIconButton = ({ Icon, text, selected, onClick, style }) => (
  <Button
    onClick={onClick}
    className={classNames('vertical-icon-button', { style })}
    variant='vertical-icon-button'
    style={{ backgroundColor: selected ? colorPrimaryAlt3 : colorSurface }}
    sx={{
      borderWidth: selected ? '2px' : '1px',
      borderColor: selected ? colorPrimary : colorNeutralMetallica
    }}
  >
    <Icon size={24} color={colorFontDark} />
    <div className='vertical-icon-button-text'>{text}</div>
  </Button>
);

export default VerticalIconButton;
