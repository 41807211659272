import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serverUrl } from '@app/src/global/Environment';

const baseUrl = serverUrl();

const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}api/profile/`,
    prepareHeaders: (headers) => headers.set('Authorization', localStorage.getItem('KeeperToken'))
  }),
  tagTypes: ['WorkInfo'],
  endpoints: (builder) => ({
    getWorkInfo: builder.query({
      query: () => ({
        url: 'workinfo',
        method: 'GET'
      }),
      transformResponse: (response) => response.data
    })
  })
});

export default profileApi;

export const { useGetWorkInfoQuery } = profileApi;
