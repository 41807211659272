import Rating from '@mui/material/Rating';
import { Android, Apple, TickCircle } from 'iconsax-react';
import moment from 'moment';
import React from 'react';

import { colorNeutralGainsboro, colorSuccess } from '@app/src/theme';

import '@app/src/Components/ExpenseReview/ExpenseReviewFreeTrialContent.scss';

const ExpenseReviewFreeTrialContent = () => (
  <div className='expense-review-free-trial-container'>
    <div className='expense-review-free-trial-component'>
      <div className='flex row mb-20'>
        <div className='expense-review-free-trial-icon'>
          <TickCircle size='24' color={colorSuccess} variant='Bold' />
          <div
            className='expense-review-free-trial-vertical-line expense-review-free-trial-vertical-line-green'
            style={{ backgroundColor: colorSuccess }}
          />
        </div>
        <div>
          <div className='text-base bold'>Today: Get instant access</div>
          <div className='base-label'>Get your deductions and find out how much money you'll save!</div>
        </div>
      </div>
      <div className='flex row mb-20'>
        <div className='expense-review-free-trial-icon'>
          <TickCircle size='24' color={colorNeutralGainsboro} />
          <div className='expense-review-free-trial-vertical-line expense-review-free-trial-vertical-line-grey' />
        </div>
        <div>
          <div className='text-base bold'>Your free trial ends on {moment().add(7, 'days').format('MMM DD')}</div>
          <div className='base-label'>Easily cancel anytime if not satisfied.</div>
        </div>
      </div>
      <div className='flex row'>
        <div className='expense-review-free-trial-icon'>
          <TickCircle size='24' color={colorNeutralGainsboro} />
        </div>
        <div>
          <div className='text-base bold'>At tax time</div>
          <div className='base-label'>
            File with Keeper or easily export your deductions, your largest tax refund guaranteed!
          </div>
        </div>
      </div>
    </div>
    <div className='expense-review-free-trial-component'>
      <div className='expense-review-free-trial-grey-box'>
        <div className='text-base bold'>100% satisfied or your money back</div>
        <div className='base-label'>
          No-questions-asked refund available until {moment().add(30, 'days').format('MMM DD')}.
        </div>
      </div>
      <div className='flex row space-evenly'>
        <div className='flex column center text-center'>
          <div>
            <span className='bold' style={{ fontSize: '24px' }}>
              4.8
            </span>{' '}
            out of 5
          </div>
          <Rating name='text-feedback' value={4.8} readOnly precision={0.1} />
        </div>
        <div className='flex column center' style={{ lineHeight: '24px' }}>
          <div className='bold'>10,000+ reviews</div>
          <div className='flex row space-evenly'>
            <Apple variant='Bold' />
            <Android variant='Bold' />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ExpenseReviewFreeTrialContent;
