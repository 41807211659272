import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

function TaxFlowNavigationProgressBar({ percentComplete }) {
  return (
    <div className='taxflow-navigation-item-progress-bar'>
      {_.range(1, 11).map((step) => {
        const stepProgress = percentComplete - step * 10;

        return (
          <div
            key={step}
            className={classNames('taxflow-navigation-item-progress-bar-step', {
              'taxflow-navigation-item-progress-bar-step-complete': stepProgress >= 0,
              'taxflow-navigation-item-progress-bar-step-partial': stepProgress < 0 && stepProgress > -10
            })}
          ></div>
        );
      })}
    </div>
  );
}

export default TaxFlowNavigationProgressBar;
