import React from 'react';
import caitlinAvatar from '@app/src/assets/caitlin-avatar.png';
import AssistantAvatar from '@app/src/assets/assistant-avatar.svg?react';
import '@app/src/Components/Assistant/AssistantMessageAvatar.scss';

const AssistantMessageAvatar = ({ sender }) => {
  switch (sender) {
    case 'user':
      return null;
    case 'caitlin':
      return <img src={caitlinAvatar} alt='Caitlin' className='assistant-message-avatar assistant-caitlin-avatar' />;
    case 'keeper':
    default:
      return <AssistantAvatar className='assistant-message-avatar assistant-assistant-avatar' />;
  }
};

export default AssistantMessageAvatar;
