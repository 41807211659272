import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import _ from 'lodash';
import {
  CONTENTFUL_CONTENT_TYPE,
  CONTENTFUL_CONTENT_TYPE_DROPDOWN_WARNINGS,
  CONTENTFUL_CONTENT_TYPE_ABOUT_YOU_ITEMS,
  CONTENTFUL_CONTENT_INFO_TYPE,
  CONTENTFUL_CONTENT_TYPE_NAVIGATION,
  CONTENTFUL_CONTENT_TYPE_BUSINESS_CODES,
  serverUrl
} from '@app/src/global/Environment';
import { getTemplateQuestions } from '@app/src/taxflow/main/utils/mainUtils';
import { setAllQuestions } from '@app/src/taxflow/shared/actions/sharedActions';
import { allDerivedQuestionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { setTaxFilingError } from '@app/src/taxflow/common/services/errors';
import {
  replaceInfoItems,
  setBulkUploadItems,
  setBusinessCodes,
  setCollectionTypeItems,
  setDropdownWarnings,
  setIndustries,
  setNavigationItems,
  setTaxFlowError,
  setTaxFlowLoader
} from '@app/src/actions/taxFlowActions';
import {
  getContentfulEntries,
  getContentfulMappedItem,
  requireJobCategoryList
} from '@app/src/services/contentfulService';
import axios from 'axios';
import { bulkUploadItemsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';

const baseUrl = serverUrl();

const getAllQuestions = () => async (dispatch) => {
  const [res1, res2] = await Promise.all([
    getContentfulEntries(CONTENTFUL_CONTENT_TYPE, {
      limit: 1000,
      'fields.version': '2',
      include: 2,
      order: 'sys.createdAt'
    }),
    getContentfulEntries(CONTENTFUL_CONTENT_TYPE, {
      limit: 1000,
      skip: 1000,
      'fields.version': '2',
      include: 2,
      order: 'sys.createdAt'
    })
  ]);

  const allInContentful = res1.total;
  if (allInContentful > res1.items.length + res2.items.length) {
    defaultCaptureException('not fetching all the taxflow questions');
  }
  const mappedQuestions = [...res1.items, ...res2.items].map((question) => getContentfulMappedItem(question));
  const templateQuestions = getTemplateQuestions(mappedQuestions);
  dispatch(setAllQuestions(templateQuestions));
};

const getAllQuestionsLazy = () => async (dispatch, getState) => {
  const allQuestions = allDerivedQuestionsSelector(getState());
  if (_.isEmpty(allQuestions)) {
    await dispatch(getAllQuestions());
  }
};

const getDropdownWarnings = () => async (dispatch) => {
  const res = await getContentfulEntries(CONTENTFUL_CONTENT_TYPE_DROPDOWN_WARNINGS);

  const mappedEntries = res.items.map(({ fields }) => ({
    ...fields
  }));
  dispatch(setDropdownWarnings(mappedEntries));
};

const getDropdownWarningsLazy = () => async (dispatch, getState) => {
  if (_.isEmpty(_.get(getState(), ['taxFlowNavigation', 'dropdownWarnings']))) {
    await dispatch(getDropdownWarnings());
  }
};

export const getTaxFilingContentfulEntries = () => async (dispatch) => {
  try {
    await Promise.all([
      dispatch(getAllQuestionsLazy()),
      dispatch(requireInfoItems()),
      dispatch(getNavigationItemsLazy()),
      dispatch(getBusinessCodesLazy()),
      dispatch(requireJobCategoryList()),
      dispatch(getDropdownWarningsLazy()),
      dispatch(requireBulkUploadItems())
    ]);
  } catch (e) {
    await dispatch(setTaxFilingError(e));
  }
};

export const getCollectionTypeItems = () => async (dispatch) => {
  dispatch(setTaxFlowLoader(true));
  try {
    const res = await getContentfulEntries(CONTENTFUL_CONTENT_TYPE_ABOUT_YOU_ITEMS);
    const orderedItems = _.sortBy(res.items, 'fields.order');

    dispatch(setCollectionTypeItems(orderedItems));
    dispatch(setTaxFlowLoader(false));
  } catch (error) {
    dispatch(setTaxFlowError('Something went wrong, please try again'));
    dispatch(setTaxFlowLoader(false));
    defaultCaptureException(error);
    return error;
  }
};

const forceRefreshInfoItems = () => async (dispatch) => {
  try {
    const res = await getContentfulEntries(CONTENTFUL_CONTENT_INFO_TYPE, { limit: 1000 });
    const { items } = res;
    const normalizedItems = {
      allIds: items.map((item) => item.fields.slug),
      byId: _.assign({}, ...items.map((item) => ({ [item.fields.slug]: item })))
    };
    dispatch(replaceInfoItems(normalizedItems));
  } catch (error) {
    dispatch(setTaxFlowError('Something went wrong. Please try again.'));
    defaultCaptureException(error);
  }
};

const requireInfoItems = () => async (dispatch, getState) => {
  if (_.isEmpty(_.get(getState(), ['taxFlow', 'normalizedInfoItems', 'allIds']))) {
    await dispatch(forceRefreshInfoItems());
    return;
  }
};

const getNavigationItems = () => async (dispatch) => {
  const res = await getContentfulEntries(CONTENTFUL_CONTENT_TYPE_NAVIGATION, {
    'fields.version': '2',
    'fields.flow': 'tax file'
  });
  const sortedEntries = _.sortBy(res.items, (entry) => entry.fields.navigationIndex);
  const mappedEntries = sortedEntries.map((entry) => ({
    id: entry.fields.slug,
    name: entry.fields.title,
    description: entry.fields.description,
    nextButtonLabel: entry.fields.nextButtonLabel || entry.fields.title,
    url: entry.fields.url,
    meta: entry.fields.navigationMeta
  }));
  dispatch(setNavigationItems(mappedEntries));
};

const getNavigationItemsLazy = () => async (dispatch, getState) => {
  if (_.isEmpty(_.get(getState(), ['taxFlowNavigation', 'navigationItems']))) {
    await dispatch(getNavigationItems());
  }
};

const getBusinessCodes = () => async (dispatch) => {
  const res = await getContentfulEntries(CONTENTFUL_CONTENT_TYPE_BUSINESS_CODES, {
    limit: 1000
  });
  const businessCodes = _.sortBy(
    res.items.map((entry) => _.pick(entry.fields, ['code', 'label', 'industry', 'order', 'fallback'])),
    (businessCode) => `${!/^All other/.test(businessCode.label) ? '0' : '1'}:${businessCode.label}`
  );
  dispatch(setBusinessCodes(businessCodes));

  const industries = _.sortBy(
    _.uniqBy(res.items, (entry) => entry.fields.industry).map((entry) =>
      _.pick(entry.fields, ['industry', 'industryLabel'])
    ),
    (industry) => `${industry.industry !== 'Other' ? '0' : '1'}:${industry.industryLabel}`
  );
  dispatch(setIndustries(industries));
};

const getBusinessCodesLazy = () => async (dispatch, getState) => {
  if (_.isEmpty(_.get(getState(), ['taxFlow', 'businessCodes']))) {
    await dispatch(getBusinessCodes());
  }
};

const requireBulkUploadItems = () => async (dispatch, getState) => {
  const bulkUploadItems = bulkUploadItemsSelector(getState());
  if (_.isEmpty(bulkUploadItems)) {
    const res = await axios.get(`${baseUrl}api/taxes/get-bulk-upload-items`);
    const entryItems = _.get(res, ['data', 'data', 'bulkUploadItems'], []);
    dispatch(setBulkUploadItems(entryItems));
  }
};
