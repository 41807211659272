import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setOrigin } from '@app/src/actions/pricingActions';
import PaymentElements from '@app/src/Components/Subscription/PaymentModal/PaymentElements';
import { SUBSCRIPTION_STATUS_MAP } from '@app/src/constants/subscriptionConstants';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { accountDetailsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

const ExpenseReviewModalPayment = ({ subscriptionStatus, setOrigin }) => {
  useEffect(() => {
    trackActivity('Expense review: payment', { subscriptionStatus });
    setOrigin({ origin: 'expense-review' });
  });

  const freeTrial = subscriptionStatus === 'pre free trial';
  const title = SUBSCRIPTION_STATUS_MAP[subscriptionStatus]?.title;

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <Stack spacing={1}>
        <DialogContentText className='center-text'>
          {freeTrial ? '7-day free trial, then $192/year.' : '$192/year.'} Cancel anytime.
        </DialogContentText>
        <PaymentElements buttonCopy='Continue' history={history} />
        {freeTrial && (
          <DialogContentText>
            No-questions-asked refund available until {moment().add(7, 'days').format('MMMM D, YYYY')}.
          </DialogContentText>
        )}
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionStatus: accountDetailsSelector(state).status
});

const mapDispatchToProps = {
  setOrigin
};

const ConnectedExpenseReviewModalPayment = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalPayment);

export default ConnectedExpenseReviewModalPayment;
