import axios from 'axios';
import _ from 'lodash';

import {
  setAccountFilterSelections,
  setAccounts,
  setAllCategories,
  setAmountFilterSelections,
  setAutoRule,
  setCategoryAmounts,
  setCategoryFilterSelections,
  setExpenseReviewCurrentRule,
  setExpenseReviewModalType,
  setExpenseReviewRules,
  setExpenses,
  setHasFetchedMaxExpenses,
  setIsExporting,
  setIsInitialExpensesLoad,
  setIsLoadingExpenses,
  setIsModalSubmitting,
  setManualExpenseAmount,
  setManualExpenseData,
  setMerchantNameFilterQuery,
  setMerchantNameSearchResults,
  setMerchantSearchLoading,
  setRetroInterval,
  setRetroStatus,
  setSavingsAmount,
  setSelectedExpense,
  setSidebarComponent,
  setStatusFilterSelections
} from '@app/src/actions/expenseReviewActions';
import { EXPENSE_REVIEW_QUERY_AMOUNT, maybeStatusesArr, yesStatusesArr } from '@app/src/constants/constants';
import { serverUrl } from '@app/src/global/Environment';
import { userSettingsSelector } from '@app/src/selectors/authSelectors';
import {
  accountFilterSelectionsSelector,
  amountFilterSelectionsSelector,
  autoRuleSelector,
  categoryFilterSelectionsSelector,
  categoryIdsToDisplayNamesSelector,
  currentRuleSelector,
  expenseReviewModalTypeSelector,
  expensesSelector,
  expensesYearEndSelector,
  expensesYearSelector,
  expensesYearStartSelector,
  hasEditedSelectedExpenseSelector,
  hasNewBusinessUsePercentageSelector,
  hideStatusEditModalSelector,
  manualExpenseAmountSelector,
  manualExpenseDataSelector,
  merchantNameFilterQuerySelector,
  merchantNameSearchSelector,
  nonBusinessDeductionIdsSelector,
  retroIntervalSelector,
  retroStatusSelector,
  rulesSelector,
  savingsAmountSelector,
  searchStatusSelector,
  selectedExpenseSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { requireAccountDetails } from '@app/src/services/pricingService';
import { getWorkInfoLazy } from '@app/src/services/workService';
import { setExpenseReviewError } from '@app/src/taxflow/common';
import { hasActiveSubscriptionSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const getExpenses =
  ({ offset, filterChanged }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoadingExpenses(true));

      const searchStatus = searchStatusSelector(getState());
      const accountQuery = accountFilterSelectionsSelector(getState());
      const amountQuery = amountFilterSelectionsSelector(getState());
      const categoryQuery = categoryFilterSelectionsSelector(getState());
      const nameQuery = merchantNameFilterQuerySelector(getState());
      const start = expensesYearStartSelector(getState());
      const end = expensesYearEndSelector(getState());

      const res = await axios.get(`${baseUrl}api/expense/search`, {
        params: {
          includeNotes: 1,
          limit: EXPENSE_REVIEW_QUERY_AMOUNT,
          ...(offset && { offset }),
          start,
          end,
          ...(searchStatus.length > 0 && { searchStatus }),
          ...(accountQuery.length > 0 && { accountQuery }),
          ...(amountQuery.length > 0 && { amountQuery }),
          ...(categoryQuery.length > 0 && { categoryQuery }),
          ...(nameQuery && { nameQuery })
        }
      });

      const expenses = _.get(res, ['data', 'data', 'expenses'], []);
      const orderedTransactionIds = _.map(expenses, 'transaction_id');

      if (filterChanged) {
        trackActivity('expense review: filter changed', {
          filterChanged,
          accountQuery,
          amountQuery,
          categoryQuery,
          nameQuery,
          searchStatus
        });
      }

      if (_.isEmpty(expenses) && !filterChanged) {
        dispatch(setHasFetchedMaxExpenses(true));
        dispatch(setIsLoadingExpenses(false));
        if (!offset) {
          dispatch(setExpenses({ orderedTransactionIds: [], expensesById: {} }));
        }
        return;
      }

      const previousExpenses = expensesSelector(getState());

      const newOrderedTransactionIds = offset
        ? [...previousExpenses.orderedTransactionIds, ...orderedTransactionIds]
        : orderedTransactionIds;

      const expensesById = expenses.reduce(
        (result, e) => ({
          ...result,
          [e.transaction_id]: e
        }),
        offset ? previousExpenses.expensesById : {}
      );

      dispatch(setExpenses({ orderedTransactionIds: newOrderedTransactionIds, expensesById }));
      dispatch(setIsLoadingExpenses(false));
      return true;
    } catch (e) {
      dispatch(setExpenseReviewError({ message: e.message }));
    }
  };

export const updateExpenseStatus =
  ({ newStatus, transactionId, categoryId }) =>
  async (dispatch, getState) => {
    const savingsAmount = savingsAmountSelector(getState());
    const expenses = expensesSelector(getState());
    const selectedExpense = selectedExpenseSelector(getState());

    try {
      const oldExpense = { ...expenses.expensesById[transactionId] };
      const updatedExpense = { ...expenses.expensesById[transactionId], status: newStatus };

      if (oldExpense.status === newStatus) {
        return;
      }

      if (_.get(selectedExpense, 'transactionId') === transactionId) {
        dispatch(setSelectedExpense({ transactionId, data: updatedExpense }));
      }

      dispatch(
        setExpenses({
          expensesById: { ...expenses.expensesById, [transactionId]: updatedExpense },
          orderedTransactionIds: expenses.orderedTransactionIds
        })
      );
      dispatch(
        updateSavingsAmount({
          newStatus,
          oldStatus: oldExpense.status,
          expenseSavings: oldExpense.taxSavings,
          oldCategory: oldExpense.keeper_category_id,
          newCategory: categoryId
        })
      );
      const origin = 'web dashboard: expenses table';

      const editExpenseParams = {
        edited_expense: {
          transaction_id: transactionId,
          status: newStatus,
          keeper_category_id: categoryId
        },
        origin
      };

      await axios.post(`${baseUrl}api/expense/edit-expense`, editExpenseParams);

      trackActivity('expense: save', {
        clean_name: updatedExpense.clean_name,
        keeper_category_id: categoryId,
        transaction_id: transactionId,
        origin
      });

      dispatch(
        setExpenseAutoRuleData({
          clean_name: updatedExpense.clean_name,
          status: newStatus,
          category: categoryId
        })
      );

      await dispatch(getExpenseReviewDetails());
    } catch (e) {
      dispatch(setExpenseReviewError({ message: e.message }));
      dispatch(setExpenses(expenses));
      dispatch(setSelectedExpense(selectedExpense));
      dispatch(setSavingsAmount(savingsAmount));
    }
  };

const getExpenseReviewDetails = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${baseUrl}api/expense/get-expense-review-details`, {
      params: {
        start: expensesYearStartSelector(getState()),
        end: expensesYearEndSelector(getState())
      }
    });

    dispatch(setAccounts(_.get(res, ['data', 'data', 'allAccounts'], [])));
    dispatch(setSavingsAmount(_.get(res, ['data', 'data', 'savingsAmount'], 0)));
    dispatch(setCategoryAmounts(_.get(res, ['data', 'data', 'categoryCounts'], {})));
    dispatch(setAllCategories(_.get(res, ['data', 'data', 'allCategories'], [])));
  } catch (e) {
    dispatch(setExpenseReviewError({ message: e.message }));
  }
};

const updateSavingsAmount =
  ({ newStatus, oldStatus, oldCategory, newCategory, expenseSavings }) =>
  async (dispatch, getState) => {
    try {
      const savingsAmount = savingsAmountSelector(getState());
      const nonBusinessDeductionIds = nonBusinessDeductionIdsSelector(getState());
      const isPrevCategoryBusinessCat = !nonBusinessDeductionIds.includes(oldCategory);
      const isNewCategoryBusinessCat = !nonBusinessDeductionIds.includes(newCategory);

      let savingsAmountChange = 0;
      if (isPrevCategoryBusinessCat === isNewCategoryBusinessCat) {
        if (oldStatus === newStatus) {
          return savingsAmountChange;
        } else if (newStatus !== oldStatus && newStatus === 'yes') {
          savingsAmountChange = expenseSavings;
          dispatch(setSavingsAmount(savingsAmount + expenseSavings));
        } else if (newStatus !== oldStatus && newStatus === 'no' && yesStatusesArr.includes(oldStatus)) {
          savingsAmountChange = -expenseSavings;
          dispatch(setSavingsAmount(savingsAmount - expenseSavings));
        }
        return savingsAmountChange;
      } else if (isPrevCategoryBusinessCat && !isNewCategoryBusinessCat) {
        if (yesStatusesArr.includes(oldStatus)) {
          savingsAmountChange = -expenseSavings;
          dispatch(setSavingsAmount(savingsAmount - expenseSavings));
        } else if (oldStatus === 'no' || maybeStatusesArr.includes(oldStatus)) {
          return savingsAmountChange;
        }
      } else if (!isPrevCategoryBusinessCat && isNewCategoryBusinessCat) {
        if (newStatus === 'yes') {
          savingsAmountChange = expenseSavings;
          dispatch(setSavingsAmount(savingsAmount + expenseSavings));
        } else if (newStatus === 'no' || maybeStatusesArr.includes(newStatus)) {
          return savingsAmountChange;
        }
      }

      return savingsAmountChange;
    } catch (e) {
      dispatch(setExpenseReviewError({ message: e.message }));
    }
  };

export const getInitialExpenses = () => async (dispatch, getState) => {
  try {
    dispatch(setIsInitialExpensesLoad(true));
    dispatch(setHasFetchedMaxExpenses(false));
    dispatch(setSelectedExpense({}));
    dispatch(setAccountFilterSelections([]));
    dispatch(setAmountFilterSelections([]));
    dispatch(setCategoryFilterSelections([]));
    dispatch(setStatusFilterSelections([]));

    await Promise.all([
      dispatch(getExpenseReviewDetails()),
      dispatch(getExpenses({})),
      dispatch(requireAccountDetails()),
      dispatch(getWorkInfoLazy()),
      dispatch(getMerchantsWithCounts())
    ]);

    const savingsAmount = savingsAmountSelector(getState());
    const expensesYear = expensesYearSelector(getState());
    const hasActiveSubscription = hasActiveSubscriptionSelector(getState());

    trackActivity('navigation: dashboard', { year: expensesYear, savingsAmount, hasActiveSubscription });
  } catch (e) {
    dispatch(setExpenseReviewError({ message: e.message }));
  } finally {
    dispatch(setIsInitialExpensesLoad(false));
  }
};

export const updateCategoryFilter = (value) => async (dispatch, getState) => {
  const currentCategoryFilter = categoryFilterSelectionsSelector(getState());
  dispatch(setIsLoadingExpenses(true));

  if (currentCategoryFilter[0] === value) {
    dispatch(setCategoryFilterSelections([]));
  } else {
    dispatch(setCategoryFilterSelections([value]));
  }
  dispatch(setHasFetchedMaxExpenses(false));
  await dispatch(getExpenses({ filterChanged: 'category' }));
};

export const updateAccountFilter = (value) => async (dispatch) => {
  dispatch(setIsLoadingExpenses(true));
  dispatch(setAccountFilterSelections(_.isString(value) ? _.split(value, ',') : value));
  dispatch(setHasFetchedMaxExpenses(false));
  await dispatch(getExpenses({ filterChanged: 'account' }));
};

export const updateStatusFilter = (value) => async (dispatch) => {
  dispatch(setIsLoadingExpenses(true));
  dispatch(setStatusFilterSelections(_.isString(value) ? _.split(value, ',') : value));
  dispatch(setHasFetchedMaxExpenses(false));
  await dispatch(getExpenses({ filterChanged: 'status' }));
};

export const updateAmountFilter = (value) => async (dispatch) => {
  dispatch(setIsLoadingExpenses(true));
  dispatch(setAmountFilterSelections(_.isString(value) ? _.split(value, ',') : value));
  dispatch(setHasFetchedMaxExpenses(false));
  await dispatch(getExpenses({ filterChanged: 'amount' }));
};

export const updateMerchantNameFilter = () => async (dispatch, getState) => {
  dispatch(setIsLoadingExpenses(true));
  dispatch(setMerchantNameFilterQuery(merchantNameSearchSelector(getState())));
  dispatch(setHasFetchedMaxExpenses(false));
  await dispatch(getExpenses({ filterChanged: 'merchant' }));
};

const editExpenseInternal = () => async (dispatch, getState) => {
  dispatch(setIsModalSubmitting(true));
  const expenses = expensesSelector(getState());
  const oldExpense = expenses.expensesById[selectedExpenseSelector(getState()).transactionId];
  const selectedExpense = selectedExpenseSelector(getState());
  const savingsAmount = savingsAmountSelector(getState());
  const hasNewBusinessUsePercentage = hasNewBusinessUsePercentageSelector(getState());
  const hideStatus = hideStatusEditModalSelector(getState());

  const newStatus = hideStatus ? 'no' : selectedExpense.data.status;
  const newExpense = { ...selectedExpense.data, status: newStatus };

  try {
    dispatch(
      setExpenses({
        expensesById: {
          ...expensesSelector(getState()).expensesById,
          [selectedExpense.transactionId]: newExpense
        },
        orderedTransactionIds: expensesSelector(getState()).orderedTransactionIds
      })
    );
    dispatch(
      updateSavingsAmount({
        newStatus: newStatus,
        oldStatus: oldExpense.status,
        oldCategory: oldExpense.keeper_category_id,
        newCategory: selectedExpense.data.keeper_category_id,
        expenseSavings: oldExpense.taxSavings
      })
    );

    await dispatch(
      editExpenseRemote({
        hasNewBusinessUsePercentage,
        oldBusinessUsePercentage: oldExpense.businessUsePercentage,
        newBusinessUsePercentage: selectedExpense.data.businessUsePercentage
      })
    );

    dispatch(
      setExpenseAutoRuleData({
        clean_name: selectedExpense.data.clean_name,
        status: newStatus,
        category: selectedExpense.data.keeper_category_id
      })
    );

    dispatch(setExpenseReviewModalType(''));
  } catch {
    dispatch(setExpenseReviewError({ message: 'Error editing expense' }));
    dispatch(setExpenses(expenses));
    dispatch(setSavingsAmount(savingsAmount));
  } finally {
    dispatch(setIsModalSubmitting(false));
  }
};

const editExpenseRemote =
  ({ hasNewBusinessUsePercentage, oldBusinessUsePercentage, newBusinessUsePercentage, archived }) =>
  async (dispatch, getState) => {
    const selectedExpense = selectedExpenseSelector(getState());
    const expenses = expensesSelector(getState());
    const origin = 'web dashboard: edit modal';

    const response = await axios.post(`${baseUrl}api/expense/edit-expense`, {
      edited_expense: {
        transaction_id: selectedExpense.transactionId,
        status: selectedExpense.data.status,
        keeper_category_id: selectedExpense.data.keeper_category_id,
        note: selectedExpense.data.note,
        newAllocation: hasNewBusinessUsePercentage && newBusinessUsePercentage,
        oldAllocation: hasNewBusinessUsePercentage && oldBusinessUsePercentage,
        archived: archived && {}
      },
      origin,
      returnWithTransactionData: true
    });

    const newTransactionData = _.get(response, ['data', 'data', 'newTransactionData'], {});

    if (newTransactionData.transaction_id) {
      setExpenses({
        expensesById: { ...expenses.expensesById, [newTransactionData.transaction_id]: newTransactionData },
        orderedTransactionIds: expenses.orderedTransactionIds
      });
    }

    trackActivity('expense: save', {
      clean_name: selectedExpense.clean_name,
      keeper_category_id: selectedExpense.data.keeper_category_id,
      transaction_id: selectedExpense.transactionId,
      origin
    });

    await dispatch(getExpenseReviewDetails());
  };

export const addManualExpenseInternal = () => async (dispatch, getState) => {
  try {
    dispatch(setIsModalSubmitting(true));
    const manualExpenseData = manualExpenseDataSelector(getState());
    const manualExpenseAmount = manualExpenseAmountSelector(getState());
    const categoryIdsToDisplayNames = categoryIdsToDisplayNamesSelector(getState());

    const description = manualExpenseData.description;
    const amount = manualExpenseAmount;
    const date = manualExpenseData.date;
    const keeper_category_id = manualExpenseData.keeper_category_id;
    const category = categoryIdsToDisplayNames[manualExpenseData.keeper_category_id];

    await addExpenseRemote({
      description,
      amount,
      date,
      keeper_category_id,
      category,
      origin: 'web dashboard',
      note: manualExpenseData.note
    });

    trackActivity('manually added expense', {
      name: description,
      amount,
      date,
      category
    });

    const previousExpenses = expensesSelector(getState());

    await dispatch(
      getExpenses({
        limit: Math.max(EXPENSE_REVIEW_QUERY_AMOUNT, previousExpenses.orderedTransactionIds.length)
      })
    );

    await dispatch(getExpenseReviewDetails());

    dispatch(setExpenseReviewModalType(''));
    dispatch(setManualExpenseData({}));
    dispatch(setManualExpenseAmount(0));
  } catch (e) {
    dispatch(setExpenseReviewError({ message: 'Error adding expense' }));
  } finally {
    dispatch(setIsModalSubmitting(false));
  }
};

const addExpenseRemote = async (manualExpense) => {
  await axios.post(`${baseUrl}api/expense/add-new-expense`, manualExpense);
};

export const deleteManualExpense = () => async (dispatch, getState) => {
  const expense = selectedExpenseSelector(getState());
  const origin = 'web dashboard: edit modal';

  const expenses = expensesSelector(getState());
  const oldTransactionIds = expenses.orderedTransactionIds;

  const newExpenses = {
    ...expenses,
    expensesById: _.omit(expenses.expensesById, expense.transactionId),
    orderedTransactionIds: _.filter(oldTransactionIds, (id) => id !== expense.transactionId)
  };

  try {
    dispatch(setIsLoadingExpenses(true));
    dispatch(setExpenses(newExpenses));

    dispatch(
      updateSavingsAmount({
        newStatus: 'no',
        oldStatus: expense.status,
        savingsAmount: expense.taxSavings
      })
    );

    await dispatch(
      editExpenseRemote({
        archived: true
      })
    );

    trackActivity('expense: delete', {
      clean_name: expense.clean_name,
      keeper_category_id: expense.data.keeper_category_id,
      transaction_id: expense.transactionId,
      origin
    });

    dispatch(setExpenseReviewError({ message: 'Expense deleted' }));
  } catch (e) {
    dispatch(setExpenseReviewError({ message: 'Failed to delete expense' }));
    defaultCaptureException(e);
  } finally {
    dispatch(setIsLoadingExpenses(false));
    dispatch(setExpenseReviewModalType(''));
  }
};

export const onModalClose = () => async (dispatch, getState) => {
  const modalType = expenseReviewModalTypeSelector(getState());

  if (modalType === 'add') {
    dispatch(setManualExpenseData({}));
    dispatch(setManualExpenseAmount(0));
  } else if (modalType === 'edit') {
    if (hasEditedSelectedExpenseSelector(getState())) {
      await dispatch(editExpenseInternal());
    }
  }
  dispatch(setExpenseReviewModalType(''));
};

const getRetroStatus = () => async (dispatch, getState) => {
  const res = await axios.get(`${baseUrl}api/profile/run-retro`, {
    params: { year: expensesYearSelector(getState()) }
  });

  const data = _.get(res, ['data', 'status'], '');
  dispatch(setRetroStatus(data));
};

export const getExpenseWaiting = () => async (dispatch, getState) => {
  dispatch(clearRetroInterval());

  await dispatch(getRetroStatus());
  const retroStatus = retroStatusSelector(getState());

  if (retroStatus === 'waiting') {
    notify('Keeper is scanning your statements. This can take up to 60 seconds.');

    let counter = 0;
    const newInterval = setInterval(async () => {
      if (counter >= 180) {
        dispatch(clearRetroInterval());
        notify("Your scan is taking a while. We'll send you a text message when it's ready.");
        return;
      }

      await dispatch(getRetroStatus());
      const retroStatus = retroStatusSelector(getState());
      if (retroStatus && retroStatus !== 'waiting' && retroStatus !== 'error') {
        await Promise.all([dispatch(clearRetroInterval()), dispatch(getInitialExpenses())]);
        return;
      }

      counter++;
    }, 1000);
    dispatch(setRetroInterval(newInterval));
  }
};

const clearRetroInterval = () => (dispatch, getState) => {
  const interval = retroIntervalSelector(getState());
  clearInterval(interval);
  dispatch(setRetroInterval(0));
};
export const getMerchantsWithCounts = () => async (dispatch, getState) => {
  dispatch(setMerchantSearchLoading(true));

  try {
    const year = expensesYearSelector(getState());
    const search = merchantNameSearchSelector(getState());

    const res = await axios.get(`${baseUrl}api/expense/search-merchants-with-counts`, {
      params: {
        year,
        search
      }
    });

    dispatch(setMerchantNameSearchResults(_.get(res, ['data', 'data', 'merchants'], [])));
  } catch (e) {
    dispatch(setExpenseReviewError({ message: e.message }));
  } finally {
    dispatch(setMerchantSearchLoading(false));
  }
};

export const exportWriteOffs = () => async (dispatch, getState) => {
  dispatch(setIsExporting(true));
  const year = expensesYearSelector(getState());
  try {
    const settings = userSettingsSelector(getState());
    const email = settings.email;
    const start = `${year}-01-01`;
    const end = `${year}-12-31`;

    const res = await axios.get(`${baseUrl}api/expense/export-writeoffs`, {
      params: {
        start,
        end
      }
    });

    const message = _.get(res, 'data.status') === 'error' && _.get(res, 'data.msg');

    dispatch(setExpenseReviewError({ message: message || `Export spreadsheet sent! Check ${email}.` }));
  } catch (e) {
    dispatch(setExpenseReviewError({ message: 'Error exporting deductions' }));
  } finally {
    trackActivity('export write offs', {
      origin: 'web dashboard',
      year
    });
    dispatch(setIsExporting(false));
  }
};

// Rules
export const getRules = () => async (dispatch) => {
  try {
    dispatch(setIsLoadingExpenses(true));

    const res = await axios.get(`${baseUrl}api/rules/user-rules`);

    const rules = _.get(res, ['data', 'data', 'rules'], []);

    dispatch(setExpenseReviewRules(rules));
  } catch (e) {
    dispatch(setExpenseReviewError({ message: e.message }));
  } finally {
    dispatch(setIsLoadingExpenses(false));
  }
};

export const createExpenseRule = () => async (dispatch, getState) => {
  try {
    dispatch(setIsLoadingExpenses(true));

    const rule = currentRuleSelector(getState());

    const res = await axios.post(`${baseUrl}api/rules/create-rule`, rule);

    const status = _.get(res, 'status');
    const transactions = _.get(res, 'data.data.transactions', []);

    if (status === 200) {
      const message = `Rule saved! ${transactions ? `${transactions} ${_.get(rule, 'name')} transactions were updated.` : ''}`;

      trackActivity('expense rules: new rule created', {
        rule_name: _.get(rule, 'name'),
        prediction: _.get(rule, 'prediction'),
        origin: 'edit rule screen'
      });

      dispatch(setExpenseReviewModalType(''));
      dispatch(getRules());

      notify(message);

      if (transactions) {
        await dispatch(getExpenses({ offset: 0 }));
        await dispatch(getExpenseReviewDetails());
      }
    }
  } catch ({ message }) {
    dispatch(setExpenseReviewError({ message }));
  } finally {
    dispatch(setIsLoadingExpenses(false));
  }
};

export const updateExpenseRule = () => async (dispatch, getState) => {
  try {
    dispatch(setIsLoadingExpenses(true));

    const rule = currentRuleSelector(getState());

    const { status } = await axios.put(`${baseUrl}api/rules/update-rule`, { ...rule });

    if (status === 200) {
      notify('Rule updated.');

      dispatch(getRules());

      trackActivity('expense rules: rule edited', {
        origin: 'edit rule screen',
        name: _.get(rule, 'name'),
        prediction: _.get(rule, 'prediction')
      });
    }
  } catch (e) {
    dispatch(
      setExpenseReviewError({
        message: 'Something went wrong updating the rule. Please try again.'
      })
    );
  } finally {
    dispatch(setIsLoadingExpenses(false));
  }
};

export const deleteExpenseRule = () => async (dispatch, getState) => {
  try {
    dispatch(setIsLoadingExpenses(true));

    const ruleId = _.get(currentRuleSelector(getState()), 'rule_id');
    const ruleName = _.get(currentRuleSelector(getState()), 'name');
    const prediction = _.get(currentRuleSelector(getState()), 'prediction');

    const { status } = await axios.post(`${baseUrl}api/rules/delete-rule`, { rule_id: ruleId });

    if (status === 200) {
      notify('Rule deleted');
      dispatch(getRules());

      trackActivity('expense rules: rule deleted', {
        origin: 'edit rule screen',
        name: ruleName,
        prediction
      });
    }
  } catch (e) {
    dispatch(setExpenseReviewError({ message: 'Something went wrong while deleting this rule! Please try again.' }));
  } finally {
    dispatch(setIsLoadingExpenses(false));
  }
};

const setExpenseAutoRuleData =
  ({ status, clean_name, category }) =>
  async (dispatch, getState) => {
    const autoRules = autoRuleSelector(getState());
    const existingRule = rulesSelector(getState()).find((rule) => rule.name === clean_name);

    const existingAutoRule = autoRules[clean_name];

    if (existingAutoRule === false || existingRule) return;

    if (existingAutoRule === undefined) {
      dispatch(
        setAutoRule({
          ...autoRules,
          [clean_name]: {
            status,
            category
          }
        })
      );
    } else {
      if (existingAutoRule.status === status && existingAutoRule.category === category) {
        dispatch(
          setExpenseReviewCurrentRule({
            name: clean_name,
            prediction: status,
            category_update: category,
            update_expenses: true
          })
        );

        setTimeout(() => {
          dispatch(setSidebarComponent('Rules'));
          dispatch(setExpenseReviewModalType('auto-rule'));
        }, 100);
      }

      dispatch(
        setAutoRule({
          ...autoRules,
          [clean_name]: false
        })
      );
    }
  };
