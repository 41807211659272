import { useDispatch, useSelector } from 'react-redux';
import { useInterval, usePrevious } from 'react-use';
import { advanceToCurrentSectionPage } from '@app/src/taxflow/main/services/mainService';
import history from '@app/src/keeperHistory';
import { uiStageSelector } from '@app/src/selectors/workSelectors';
import taxDataApi, { TAG__SUBMIT_ISSUES } from '@app/src/api/taxDataApi';
import { getCampaign } from '@app/src/services/workService';
import { useEffect, useState } from 'react';

/**
 * Hook routing to, given a change in ui stage, the new ui stage's landing page
 * Polls for ui stage changes every 15 secs
 */
export const useUpdatePageOnUIStageChange = () => {
  const dispatch = useDispatch();
  const uiStage = useSelector(uiStageSelector);
  const prevUIStage = usePrevious(uiStage);
  const [hasFirstCheckOccurred, setHasFirstCheckOccurred] = useState(false);

  useInterval(() => {
    dispatch(getCampaign());
    setHasFirstCheckOccurred(true);
  }, 15000);

  useEffect(() => {
    // Don't do anything if the UI stage has not changed or if the change is due to initial load of data into store
    if (uiStage === prevUIStage || !hasFirstCheckOccurred) {
      return;
    }
    dispatch(taxDataApi.util.invalidateTags([TAG__SUBMIT_ISSUES]));
    dispatch(advanceToCurrentSectionPage({ history }));
  }, [uiStage, prevUIStage, dispatch, hasFirstCheckOccurred]);
};
